.grid-container {
  display: grid;
  gap: 20px 10px;
  grid-template-columns: 25% 25% 25% 25%;
}

.grid-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  gap: 20px;
  border: 1px solid #e5e5e4;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}
