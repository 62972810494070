@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.c00ef214.eot);
  src: url(/static/media/icomoon.c00ef214.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.2d704b19.ttf) format('truetype'),
    url(/static/media/icomoon.0a564552.woff) format('woff'),
    url(/static/media/icomoon.61623d4f.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-ham-bordered:before {
  content: '\e9ca';
}
.icon-add-group:before {
  content: '\e900';
}
.icon-angle-left:before {
  content: '\e901';
}
.icon-arrow-down:before {
  content: '\e902';
}
.icon-arrow-down-alt:before {
  content: '\e903';
}
.icon-arrow-left:before {
  content: '\e904';
}
.icon-arrow-left-alt:before {
  content: '\e905';
}
.icon-arrow-right:before {
  content: '\e906';
}
.icon-arrow-right-alt:before {
  content: '\e907';
}
.icon-arrow-to-bottom:before {
  content: '\e908';
}
.icon-arrow-up:before {
  content: '\e909';
}
.icon-arrow-up-alt:before {
  content: '\e90a';
}
.icon-at:before {
  content: '\e90b';
}
.icon-audio:before {
  content: '\e90c';
}
.icon-average-floor:before {
  content: '\e90d';
}
.icon-bank-building:before {
  content: '\e90e';
  color: #15235c;
}
.icon-bars:before {
  content: '\e90f';
}
.icon-bolt:before {
  content: '\e910';
}
.icon-brush:before {
  content: '\e911';
}
.icon-building:before {
  content: '\e912';
}
.icon-building-alt:before {
  content: '\e913';
}
.icon-buildings:before {
  content: '\e914';
}
.icon-bus:before {
  content: '\e915';
}
.icon-business:before {
  content: '\e916';
}
.icon-calendar:before {
  content: '\e917';
}
.icon-camera:before {
  content: '\e918';
}
.icon-camera-alt:before {
  content: '\e919';
}
.icon-caret-down:before {
  content: '\e91a';
}
.icon-caret-up:before {
  content: '\e91b';
}
.icon-chart-line:before {
  content: '\e91c';
}
.icon-chat:before {
  content: '\e91d';
  color: #fff;
}
.icon-check:before {
  content: '\e91e';
}
.icon-check-circle:before {
  content: '\e91f';
}
.icon-chevron-bottom:before {
  content: '\e920';
}
.icon-chevron-down-alt:before {
  content: '\e921';
}
.icon-chevron-down-solid:before {
  content: '\e922';
}
.icon-chevron-left:before {
  content: '\e923';
}
.icon-chevron-left-alt:before {
  content: '\e924';
}
.icon-chevron-right:before {
  content: '\e925';
}
.icon-chevron-right-alt:before {
  content: '\e926';
}
.icon-chevron-top:before {
  content: '\e927';
}
.icon-chevron-top-alt:before {
  content: '\e928';
}
.icon-chevron-up-solid:before {
  content: '\e929';
}
.icon-circle:before {
  content: '\e92a';
}
.icon-clap:before {
  content: '\e92b';
}
.icon-clipboard-list:before {
  content: '\e92c';
}
.icon-clock:before {
  content: '\e92d';
}
.icon-clock-alt:before {
  content: '\e92e';
}
.icon-clock-standard:before {
  content: '\e92f';
}
.icon-comment-dots:before {
  content: '\e930';
}
.icon-compress:before {
  content: '\e931';
}
.icon-compress-alt:before {
  content: '\e932';
}
.icon-compress-wide:before {
  content: '\e933';
}
.icon-condition:before {
  content: '\e934';
}
.icon-copyright:before {
  content: '\e935';
}
.icon-cross:before {
  content: '\e936';
}
.icon-cross-alt:before {
  content: '\e937';
}
.icon-cross-cirlce:before {
  content: '\e938';
}
.icon-cursor:before {
  content: '\e939';
}
.icon-cycling:before {
  content: '\e93a';
}
.icon-dashboard:before {
  content: '\e93b';
}
.icon-dashboard-alt:before {
  content: '\e93c';
}
.icon-description:before {
  content: '\e93d';
}
.icon-desktop:before {
  content: '\e93e';
}
.icon-details:before {
  content: '\e93f';
}
.icon-disk:before {
  content: '\e940';
}
.icon-dot-circle:before {
  content: '\e941';
}
.icon-edit:before {
  content: '\e942';
}
.icon-edit-alt:before {
  content: '\e943';
}
.icon-edit-custom:before {
  content: '\e944';
}
.icon-egg-box:before {
  content: '\e945';
}
.icon-ellipsis-h:before {
  content: '\e946';
}
.icon-ellipsis-v:before {
  content: '\e947';
}
.icon-envelope:before {
  content: '\e948';
}
.icon-expand:before {
  content: '\e949';
}
.icon-expand-alt:before {
  content: '\e94a';
}
.icon-external-link:before {
  content: '\e94b';
}
.icon-eye-alt:before {
  content: '\e94c';
}
.icon-eye-custom:before {
  content: '\e94d';
}
.icon-eye-slash-alt:before {
  content: '\e94e';
}
.icon-facebook:before {
  content: '\e94f';
}
.icon-facebook-square:before {
  content: '\e950';
}
.icon-file:before {
  content: '\e951';
}
.icon-floorplan:before {
  content: '\e952';
}
.icon-floorplan-alt:before {
  content: '\e953';
}
.icon-freight-elevator:before {
  content: '\e954';
}
.icon-gallery:before {
  content: '\e955';
}
.icon-gallery-upload:before {
  content: '\e956';
}
.icon-globe:before {
  content: '\e957';
}
.icon-grid-horizontal:before {
  content: '\e958';
}
.icon-grid-vertical:before {
  content: '\e959';
}
.icon-heart:before {
  content: '\e95a';
}
.icon-heart-fill:before {
  content: '\e95b';
}
.icon-help:before {
  content: '\e95c';
}
.icon-home:before {
  content: '\e95d';
}
.icon-instagram:before {
  content: '\e95e';
}
.icon-integration:before {
  content: '\e95f';
  color: #341062;
}
.icon-Itinerary:before {
  content: '\e960';
}
.icon-join:before {
  content: '\e961';
}
.icon-key:before {
  content: '\e962';
}
.icon-keys:before {
  content: '\e963';
}
.icon-layers:before {
  content: '\e964';
}
.icon-library:before {
  content: '\e965';
}
.icon-lightbulb:before {
  content: '\e966';
}
.icon-link:before {
  content: '\e967';
}
.icon-linkedin:before {
  content: '\e968';
}
.icon-linkedin-square:before {
  content: '\e969';
}
.icon-list:before {
  content: '\e96a';
}
.icon-live-integrate:before {
  content: '\e96b';
}
.icon-location:before {
  content: '\e96c';
}
.icon-lock:before {
  content: '\e96d';
}
.icon-lock-alt:before {
  content: '\e96e';
}
.icon-lock-fill:before {
  content: '\e96f';
}
.icon-login:before {
  content: '\e970';
}
.icon-logout:before {
  content: '\e971';
}
.icon-mail:before {
  content: '\e972';
}
.icon-management:before {
  content: '\e973';
}
.icon-map-marker:before {
  content: '\e974';
}
.icon-map-marker-alt:before {
  content: '\e975';
}
.icon-map-marker-light:before {
  content: '\e976';
}
.icon-menu:before {
  content: '\e977';
}
.icon-metro:before {
  content: '\e978';
}
.icon-microphone:before {
  content: '\e979';
}
.icon-microphone-slash:before {
  content: '\e97a';
}
.icon-minus:before {
  content: '\e97b';
}
.icon-minus-circle:before {
  content: '\e97c';
}
.icon-mobile:before {
  content: '\e97d';
}
.icon-month:before {
  content: '\e97e';
}
.icon-mute:before {
  content: '\e97f';
}
.icon-notification:before {
  content: '\e980';
}
.icon-organisation:before {
  content: '\e981';
}
.icon-paperclip:before {
  content: '\e982';
}
.icon-parking-ratio:before {
  content: '\e983';
}
.icon-passenger-elevator:before {
  content: '\e984';
}
.icon-pause:before {
  content: '\e985';
}
.icon-pause-circle:before {
  content: '\e986';
}
.icon-pdf:before {
  content: '\e987';
}
.icon-pdf-alt:before {
  content: '\e988';
}
.icon-pencil-fill:before {
  content: '\e989';
}
.icon-phone:before {
  content: '\e98a';
}
.icon-platform:before {
  content: '\e98b';
}
.icon-play:before {
  content: '\e98c';
}
.icon-play-circle:before {
  content: '\e98d';
}
.icon-play-outline:before {
  content: '\e98e';
}
.icon-play-video:before {
  content: '\e98f';
}
.icon-plus:before {
  content: '\e990';
}
.icon-plus-circle:before {
  content: '\e991';
}
.icon-pointer:before {
  content: '\e992';
}
.icon-portal:before {
  content: '\e993';
  color: #341062;
}
.icon-pulse:before {
  content: '\e994';
}
.icon-radio:before {
  content: '\e995';
}
.icon-renovate:before {
  content: '\e996';
}
.icon-repeat:before {
  content: '\e997';
}
.icon-replace:before {
  content: '\e998';
}
.icon-review:before {
  content: '\e999';
}
.icon-rocket:before {
  content: '\e99a';
}
.icon-rocket-alt:before {
  content: '\e99b';
}
.icon-ruler-combined:before {
  content: '\e99c';
}
.icon-save:before {
  content: '\e99d';
}
.icon-schedule:before {
  content: '\e99e';
}
.icon-schedule-alt:before {
  content: '\e99f';
}
.icon-schedule-dashboard:before {
  content: '\e9a0';
}
.icon-screen-ratio:before {
  content: '\e9a1';
}
.icon-search:before {
  content: '\e9a2';
}
.icon-search-right:before {
  content: '\e9a3';
}
.icon-send:before {
  content: '\e9a4';
}
.icon-setting:before {
  content: '\e9a5';
}
.icon-share:before {
  content: '\e9a6';
}
.icon-share-screen:before {
  content: '\e9a7';
}
.icon-signout:before {
  content: '\e9a8';
}
.icon-size:before {
  content: '\e9a9';
}
.icon-smile:before {
  content: '\e9aa';
}
.icon-sortby:before {
  content: '\e9ab';
}
.icon-sortby-ascending:before {
  content: '\e9ac';
}
.icon-spacing-horizontal:before {
  content: '\e9ad';
}
.icon-spacing-vertical:before {
  content: '\e9ae';
}
.icon-stop:before {
  content: '\e9af';
}
.icon-stop-circle:before {
  content: '\e9b0';
}
.icon-suite:before {
  content: '\e9b1';
  color: #6a7074;
}
.icon-telecom:before {
  content: '\e9b2';
}
.icon-tour:before {
  content: '\e9b3';
}
.icon-trash:before {
  content: '\e9b4';
}
.icon-tv:before {
  content: '\e9b5';
}
.icon-tv-alt:before {
  content: '\e9b6';
}
.icon-tv-check:before {
  content: '\e9b7';
}
.icon-twitter:before {
  content: '\e9b8';
}
.icon-upload:before {
  content: '\e9b9';
}
.icon-user:before {
  content: '\e9ba';
}
.icon-user-circle:before {
  content: '\e9bb';
}
.icon-user-left:before {
  content: '\e9bc';
}
.icon-user-question:before {
  content: '\e9bd';
}
.icon-user-right:before {
  content: '\e9be';
}
.icon-users:before {
  content: '\e9bf';
}
.icon-user-up:before {
  content: '\e9c0';
}
.icon-video:before {
  content: '\e9c1';
}
.icon-video-slash:before {
  content: '\e9c2';
}
.icon-virtual:before {
  content: '\e9c3';
}
.icon-volume:before {
  content: '\e9c4';
}
.icon-walk:before {
  content: '\e9c5';
}
.icon-webcam:before {
  content: '\e9c6';
}
.icon-year-built:before {
  content: '\e9c7';
}
.icon-zoom-in:before {
  content: '\e9c8';
}
.icon-zoom-out:before {
  content: '\e9c9';
}

body {
  font-family: 'Poppins', sans-serif;
}
ul,
ol {
  padding: 0;
  margin: 0;
}
.cursor-disabled {
  cursor: not-allowed;
}
.w-45 {
  width: 45%;
}
.w-75p {
  width: 75px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.h-screen {
  min-height: 80vh;
}
ul li,
ol li {
  list-style: none;
}
a,
button {
  cursor: pointer;
  transition: all 0.2s ease;
}
.pointer-event-none {
  pointer-events: none;
}
.import-button {
  display: inline-flex;
  width: 80px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  height: 49px;
  background: #ebddb5;
  border-radius: 0px 10px 10px 0px;
}
.import-input-field-modal {
  width: 100%;
  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #bcbab8;
  border-radius: 10px 0px 0px 10px;
}
.ml-8p {
  margin-left: 8px;
}

.mt-10-minus {
  margin-top: -26px;
}
.max-h-600 {
  max-height: 625px;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.max-h-screen {
  max-height: 100vh;
  overflow-y: auto;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
img {
  max-width: 100%;
}
.underline {
  text-decoration: underline;
}
button:focus,
input:focus,
textarea:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-end {
  align-items: flex-end;
}
.justify-end {
  justify-content: flex-end;
}
.flex-cols {
  flex-direction: column;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.line {
  border-top: 1px solid #d3d3d3;
}
.line-black {
  border-top: 1px solid #000000;
}
.p-2 {
  padding: 2px;
}
.p-4 {
  padding: 4px;
}
.p-3 {
  padding: 4px;
}
.bold {
  font-weight: 700;
}
.m-4 {
  margin: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.-mt-15 {
  margin-top: -15px;
}
.w-35 {
  width: 35%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.h-15 {
  height: 15px;
}
.w-15 {
  width: 15px;
}
.h-20 {
  height: 20px;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20px;
}
.w-20p {
  width: 20px;
}
.w-25p {
  width: 25px;
}
.h-20p {
  height: 20px;
}
.w-25p {
  width: 25px;
}
.h-25p {
  height: 25px;
}
.min-h-90 {
  min-height: 90%;
}
.font-34 {
  font-size: 34px;
}
.px-4p5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.py-2p5 {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
.bg-white {
  background-color: white;
}
.bg-light-green {
  background-color: #cfefb5;
}
.bg-visa {
  border-radius: 4px;
  padding: 4px 5px;
  background: #1565c0;
}
.bg-warm-gray {
  background: #e5e5e5;
}
.text-gray-one {
  color: #6a7074;
}
.text-gray-one-important {
  color: #6a7074 !important;
}
.text-primary-important {
  color: #222222 !important;
}
.py-96p {
  padding-top: 96px;
  padding-bottom: 96px;
}
.w-16p {
  width: 16px;
}
.text-gray-two {
  color: #495053;
}
.text-gray-three {
  color: #6a7074;
}
.text-gray-four {
  color: #9fa7ad;
}
.text-gray-five {
  color: #96a1a5;
}
.text-slate-one {
  color: #646668;
}
.text-slate-two {
  color: #6a7074;
}
.text-blue-one {
  color: #4b7e8f;
}
.text-neutral-two-900 {
  color: #222222;
}
.text-alert {
  color: #e7063c;
}
.text-aqua {
  color: #f4fcff;
}
.font-bold {
  font-weight: bold;
}
.font-bolder {
  font-weight: bolder;
}
.font-600 {
  font-weight: 600;
}
.font-12 {
  font-size: 12px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-16 {
  font-size: 16px;
}
.font-15 {
  font-size: 15px;
}
.line-height-34 {
  line-height: 34px;
}
.font-14 {
  font-size: 14px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 24px;
}
.ml-50p {
  margin-left: 50px;
}
.ml-16p {
  margin-left: 16px;
}
.rounded-sm {
  border-radius: 4px;
}
.rounded-sm-plus {
  border-radius: 8px;
}
.rounded-md {
  border-radius: 12px;
}
.rounded-full {
  border-radius: 500px;
}
.border-light-gray {
  border: 1px solid #d3d3d3;
}
.border-bottom-black {
  border-bottom: 2px solid black;
}
.border-bottom-dark-gray {
  border-bottom: 1px solid #bcbab8;
}
.border-top-black {
  border-top: 2px solid black;
}
.border-b-1 {
  border-bottom: 1px solid #ebe9e9;
}
.small-avatar {
  width: 30px;
  height: 30px;
}
.add--button {
  border: 1px solid black;
  border-radius: 100%;
  cursor: pointer;
  padding: 1px 2px 1px 2px;
}
.broker--img {
  max-width: 38px;
  border-radius: 50%;
}
.min-h-38p {
  min-height: 38px;
  border-radius: 100%;
}
.min-w-35p {
  width: 35px;
}
.min-h-full {
  min-height: 100vh;
}
.max-w-100p {
  max-width: 100px;
}
.brokers--container {
  border: 1px solid #d3d3d3;
  justify-content: space-between;
  margin-top: 10px;
  overflow-y: auto;
  min-height: 400px;
  max-height: 410px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 12px;
}
.link-active {
  color: #1c1f20;
  font-size: 18px;
  font-weight: 500;
}
.nav-tabs .nav-link {
  background-color: transparent;
  border: none;
}
.link-active:hover {
  color: black;
}
.link-inactive {
  color: #6a7074;
  font-size: 16px;
}
.link-two {
  color: #4179cd !important;
}
.link-two:hover {
  color: #2c65bb !important;
}
.plan-card {
  border: 2px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
}
.custom--checkbox {
  min-width: 20px;
  min-height: 20px;
}
.custom--checkbox:disabled {
  cursor: not-allowed;
}
.selected-plan {
  border: 2px solid black;
}
.active-plan {
  border-color: #464646;
}
.button-full {
  background: #f2e9d0;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  color: #071033;
  font-weight: 500;
  font-size: 14px;
}
.modal-content {
  border-radius: 6px !important;
}
.button-full:hover {
  background: #ebddb5;
  color: #071033;
}
.submarket--list--input {
  padding: 13px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #bcbab8;
}
.submarket--list--input:hover {
  border: 1px solid #313131;
}
.confirmation--no--button {
  background: #bb452b;
  border-radius: 4px;
  color: white;
  padding: 3px 6px;
  margin-left: 7px;
}
.confirmation--no--button:hover {
  background: #af3b21;
  color: white;
}
.confirmation--yes--button {
  background: #2b666f;
  border-radius: 4px;
  margin-left: 7px;
  color: white;
  padding: 3px 6px;
}
.confirmation--yes--button:hover {
  background: #255d66;
  color: white;
}
.nav-tabs .nav-link.active {
  color: #222222;
  background-color: #e4e4e4;
  border-radius: 10px;
  font-weight: 500;
}
.nav-tabs .nav-link {
  color: #222222;
  font-weight: 500;
}
.team--member-icon {
  max-width: 40px;
  min-width: 35px;
}
.tab-inactive {
  font-weight: 400;
  color: #6a7074 !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px !important;
}
.tab-active {
  border-radius: 0px !important;
  font-weight: 500;
  background-color: transparent !important;
  border: 0px !important;
  border-bottom: 2px solid black !important;
}
.pending--state {
  padding: 2px 4px;
  border: 2px solid black;
}
.nav-tabs {
  border-bottom: none;
}
.tab-content {
  /* border-radius: 12px;
  background-color: white; */
  padding: 5px;
  width: 100%;
}

.custom-link {
  color: #6a7074 !important;
  text-decoration: underline !important;
}
.custom--button {
  border: none;
  border-radius: 10px;
  display: inline-block;
  background-color: #ebddb5;
  color: #071033;
  padding: 14px 20px;
  font-weight: 500;
}
.custom--button:hover {
  cursor: pointer;
  background-color: #071033;
  color: white;
}
.custom--button:disabled {
  background-color: #f5f5f5;
  color: #c9c9c9;
}
.custom--button:disabled:hover {
  cursor: not-allowed;
}
.custom--input--container {
  display: flex;
  align-items: center;
  padding: 2px;
}
.filled--input--container {
  display: flex;
  border: 1px solid #d3d3d3;
  justify-content: space-between;
  padding: 2px;
  border-radius: 4px;
  padding: 12px 24px;
}
.filled--input--container .text {
  font-size: 14px;
  line-height: 14px;
  color: #071033;
}
.filled--input--container .link {
  color: #6a7074;
}
.ElementsApp,
.ElementsApp .InputElement {
  background-color: #23a293 !important;
}
.custom--input--container .custom--input {
  border: 1px solid #d3d3d3;
  padding: 18px 12px 12px 14px;
  border-radius: 4px;
  position: relative;
  width: 100%;
}
.custom--input--container .custom--input:not(:focus):hover {
  border: 1px solid #4a516d;
}
.custom--input--label:hover + .custom--input {
  border: 1px solid #4a516d;
}
.custom--input--label {
  pointer-events: none;
  position: absolute;
  margin-left: 14px;
  transition: 0.3s;
  padding: 2px;
  font-size: 14px;
  color: #a4acb1;
}
.custom--input:focus {
  border: 2px solid #071033;
}
.custom--input:focus + .custom--input--label {
  margin-bottom: 32px;
  font-size: 12px;
  color: #626262;
}

.custom--input--container input:not([value='']) + .custom--input--label {
  margin-bottom: 32px;
  font-size: 12px;
  color: #626262;
}
.custom--input--containerinput input[value=''] + .custom--input--label {
  margin-bottom: 0px;
  font-size: 12px;
  color: #c51414;
}
.error {
  border: 1px solid red !important;
}
.custom--input--container input[value=''] + .error {
  border: 1px solid red !important;
}
.disabled-input > * {
  cursor: not-allowed;
}
.disabled-input input[type='text'],
input[type='email'] {
  background-color: rgb(243, 243, 243);
}
/*
  Navbar Style
*/

.navbar-brand {
  line-height: 25px;
}
.navbar-brand img {
  width: 150px;
  height: 26px;
}
.navbar-light .navbar__list .nav-link,
.navbar__list .nav-link {
  color: #e6e6e6;
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  margin: 4px 0;
  line-height: 18px;
}
.navbar-light .navbar__list .nav-link.active,
.navbar-light .navbar__list .nav-link:hover,
.navbar-light .navbar__list .nav-link:focus,
.navbar__list .nav-link:hover {
  color: #c2fff0;
}
.navbar__list {
  margin-right: auto;
  padding-left: 20px;
}
.landing__header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
}
.navbar__header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 26px;
  padding-bottom: 26px;
  min-height: 88px;
}
.navbar-toggler {
  width: 26px;
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  border: none;
  margin-left: auto;
  margin-top: 6px;
  margin-bottom: 6px;
}
.navbar-toggler span {
  display: inline-block;
  width: 26px;
  height: 2px;
  background-color: #c2fff0;
}
.navbar-toggler span:nth-of-type(2) {
  width: 20px;
}
.navbar--btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container--expand {
  padding-left: 20px;
  padding-right: 20px;
}
.app__button,
.app__button-outline {
  color: #1c1f20;
  font-size: 16px;
  font-weight: normal;
  border-radius: 0;
  border: 1px solid #c2fff0;
  min-width: 200px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  background-color: #c2fff0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.app__button-outline {
  color: #143452;
  border: 2px solid #c2fff0;
  background-color: transparent;
}
.app__button:not(:disabled):not(.disabled):active:focus,
.app__button:not(:disabled):not(.disabled):active,
.app__button:hover,
.app__button:focus {
  background-color: #a6e8d8;
  color: #1c1f20;
  box-shadow: none;
  border-color: #a6e8d8;
}
.app__button.disabled {
  background-color: transparent !important;
  border: 1px solid #bcbab8 !important;
  color: #bcbab8 !important;
}
.app__button-outline:not(:disabled):not(.disabled):active:focus,
.app__button-outline:not(:disabled):not(.disabled):active,
.app__button-outline:hover,
.app__button-outline:focus {
  border-color: #a6e8d8;
  color: #1c1f20;
  background-color: #a6e8d8;
  box-shadow: none;
}
.app__button-white {
  color: #341062;
  background-color: #fff;
}
.app__button-white:not(:disabled):not(.disabled):active:focus,
.app__button-white:not(:disabled):not(.disabled):active,
.app__button-white:hover,
.app__button-white:focus {
  background-color: #341062;
  color: #fff;
}
.navbar-light .navbar-nav .app__button-blue,
.app__button-blue {
  color: #0638e7;
  border-color: #0638e7;
  background-color: transparent;
}
.navbar-light .navbar-nav .app__button-blue:hover,
.navbar-light .navbar-nav .app__button-blue:focus,
.app__button-blue:not(:disabled):not(.disabled):active:focus,
.app__button-blue:not(:disabled):not(.disabled):active,
.app__button-blue:hover,
.app__button-blue:focus {
  background-color: #0638e7;
  border-color: #0638e7;
  color: #fff;
}
.min-h-100p {
  min-height: 100px;
}
.underline__button {
  position: relative;
}
.underline__button:after {
  content: '';
  background-color: #1c1f20;
  height: 1px;
  width: 120px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.underline__button.disabled:after {
  background-color: #bcbab8;
}
.nav__signin-button {
  color: #c2fff0;
  border: none;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
}
.nav__signin-button:not(:disabled):not(.disabled).active,
.nav__signin-button:not(:disabled):not(.disabled):active,
.nav__signin-button:hover,
.nav__signin-button:focus {
  color: #a6e8d8;
  background-color: transparent;
}

/*
  Navbar Style
*/

/*
  Component Style
*/

.gradient--component {
  padding-top: 170px;
  background: linear-gradient(76.88deg, #1c1f20 36.61%, #3b4c66 164.46%);
}
.heading__landing {
  font-size: 48px;
  font-weight: normal;
}
.heading__landing-white {
  color: #fff;
}
.heading__landing-green {
  color: #c2fff0;
}
.video--block {
  position: relative;
}
.video--component {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
}
.landing--video {
  position: relative;
  padding-top: 44px;
}
.landing--video .landing--video-canvas {
  position: relative;
}
.landing--video .landing--video-canvas > img {
  pointer-events: none;
}
.landing--video iframe {
  position: absolute;
  top: 7%;
  left: 14%;
  width: calc(100% - 27.5%);
  height: calc(100% - 26%);
}
.video--component img,
.video--block img {
  width: 100%;
}
.video--component video:focus {
  outline: none;
}
.status__circle {
  color: #e42346;
  font-size: 12px;
  display: inline-block;
}
.heading__component {
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}
.heading__component-white {
  color: #e6e6e6;
}
.heading__component-black {
  color: #222222;
}
.steps--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.steps--content {
  margin-top: 90px;
}
.steps--block {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 326px;
  margin-left: auto;
  margin-right: auto;
}
.steps--img {
  color: #1a1725;
  font-size: 24px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c2fff0;
  margin-left: auto;
  margin-right: auto;
}
.steps--block .heading__medium {
  margin: 44px 0px 40px 0px;
}
.heading__medium {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.heading__medium-green {
  color: #c2fff0;
}
.heading__medium-black {
  color: #222222;
}
.heading__medium-dark {
  color: #1c1f20;
}
.steps__paragraph {
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 0;
}
.mt--btns-block {
  margin-top: 70px;
}
.steps__button {
  color: #fff;
}
.subdomain--component {
  padding-top: 80px;
  background: url(/static/media/subdomain-bg.8e760c99.png) center bottom no-repeat;
  background-size: cover;
  background-color: #dbffff;
  height: 620px;
}
.about--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.about__heading {
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.about__paragraph {
  color: #6a7074;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 24px;
}
.about--block .row {
  align-items: center;
}
.about--block {
  margin-bottom: 90px;
}
.about--block .about--img {
  pointer-events: none;
}
.about--content {
  margin-top: 110px;
}
.lists--component {
  padding-top: 90px;
  padding-bottom: 90px;
  background: linear-gradient(58.95deg, #1c1f20 36.61%, #3b4c66 164.46%);
}
.lists--block {
  margin-top: 66px;
  border: 4px solid #c2fff0;
}
.lists--block .lists--head {
  background-color: #c2fff0;
  text-align: center;
  padding: 22px 18px;
}
.lists__heading {
  color: #1c1f20;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}
.lists__subHeading {
  color: #1c1f20;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 0;
}
.lists-info {
  padding: 70px 15px 70px 36px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.lists-info li {
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
  list-style-type: disc;
}
.lists-info li:last-child {
  margin-bottom: 0;
}
.schedule--component {
  padding-top: 90px;
  padding-bottom: 90px;
}
.schedule__paragraph {
  font-size: 18px;
  color: #6a7074;
  font-weight: 500;
  text-align: center;
}
.schedule--conent {
  margin-top: 66px;
}
.fields--group {
  position: relative;
  margin-bottom: 30px;
}
.fields--group .form-control {
  font-size: 16px;
  font-weight: normal;
  height: 48px;
  border-radius: 0;
  border: 1px solid #bcbab8;
  padding: 12px 16px;
  color: rgba(106, 112, 116, 0.8);
  background-color: transparent;
}
.fields--group .form-control::-webkit-input-placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.fields--group .form-control::-ms-input-placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.fields--group .form-control::placeholder {
  color: rgba(106, 112, 116, 0.8);
}
.custom-checkbox {
  padding-left: 0;
}
.custom-checkbox .custom-control-input {
  width: 16px;
  height: 16px;
  top: 3px;
}
.custom-checkbox .custom-control-label {
  font-size: 16px;
  color: #6a7074;
  font-weight: normal;
  padding-left: 28px;
  padding-top: 0px;
  padding-bottom: 0;
  cursor: pointer;
  line-height: 24px;
}
.custom-checkbox .custom-control-label::before {
  width: 16px;
  height: 16px;
  border: 1px solid #6a7074;
  border-radius: 0;
  box-shadow: none;
  left: 0;
  top: 3px;
}
.custom-checkbox .custom-control-label::after {
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #341062;
  background-color: #341062;
  box-shadow: none;
}
.custom-checkbox .custom-control-label:focus {
  outline: none;
}
.schedule__button {
  width: 100%;
}
.container--app {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

/*
  Component Style
*/

/*
  Footer Style
*/

.footer {
  background-color: #222222;
  padding-top: 70px;
  padding-bottom: 24px;
}
.company__img {
  display: inline-block;
}
.company__about {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: 500;
}
.social__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social__list li a {
  color: #fff;
  font-size: 20px;
  margin-left: 20px;
  display: inline-block;
}
.social__list li a:hover,
.social__list li a:focus {
  color: #c2fff0;
}
.footer__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;
}
.footer__list li a {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  line-height: 20px;
}
.footer__list li:last-child a {
  border-right: 0;
}
.footer__list li a:hover,
.footer__list li a:focus {
  color: #c2fff0;
}

/*
  Footer Style
*/

/*
  Forms Style
*/

.form--page {
  display: flex;
}
.form--content {
  width: 100%;
  padding: 90px 40px 60px 40px;
}
.form--banner {
  padding-left: 45px;
  padding-right: 25px;
  width: 490px;
  min-width: 490px;
  background-color: #1c1f20;
}
.formBanner--img {
  margin-top: 24px;
  pointer-events: none;
}
.form--content .navbar__header .container {
  padding-left: 0;
  padding-right: 0;
}
.form--banner .form__brand {
  display: none;
}
.form__brand img {
  width: 155px;
}
.formBanner--canvas {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px;
}
.signup--inner {
  margin-top: 65px;
  margin-bottom: 65px;
}
.container--xs {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.mt-sixty {
  margin-top: 60px;
}
.form__heading {
  font-size: 36px;
  font-weight: 400;
  color: #1c1f20;
  margin-bottom: 24px;
}
.form__subheading {
  font-size: 14px;
  color: #071033;
}
.form--banner .form__heading {
  margin-top: 46px;
  line-height: 54px;
  margin-bottom: auto;
}
.form__heading-green {
  color: #c2fff0;
}
.form__heading-blue {
  color: #0099ff;
}
.form__heading-white {
  color: #fff;
}
.form__para {
  color: #6a7074;
  font-size: 16px;
  margin-bottom: 0;
}
.forms--group {
  position: relative;
  margin-bottom: 30px;
}
.datePicker--group .ant-picker,
.forms--group .form-control {
  border: 1px solid #bcbab8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
}
.datePicker--group input {
  width: 100%;
  padding-right: 32px;
  line-height: 19px;
}
.forms--group .form-control::-webkit-input-placeholder {
  color: #6a7074;
}
.forms--group .form-control::-ms-input-placeholder {
  color: #6a7074;
}
.forms--group .form-control::placeholder {
  color: #6a7074;
}
.forms--group .form-control:focus {
  border-color: #0638e7;
}
.forms--group .form-control.error {
  border-color: #e7063c;
}
.forms--group .error__msg {
  left: 0;
  top: 50px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.signin--btn-canvas {
  margin-top: 78px;
}
.form--option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formOpt--terms {
  margin-bottom: 38px;
}
.form__text {
  color: #6a7074;
  font-size: 14px;
  line-height: 22px;
}
.form__link:not([href]),
.form__link {
  color: #1c1f20;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 22px;
  display: inline-block;
  position: relative;
}
.form__link-underline:after {
  content: '';
  height: 1px;
  width: 92px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.form__link:not([href]):hover,
.form__link:not([href]):focus,
.form__link:hover,
.form__link:focus {
  color: #280e49;
}
.form__icon {
  display: inline-block;
  margin-left: 28px;
  line-height: 16px;
}
.form--opt-r {
  display: flex;
  align-items: center;
}
.select--group .form-control {
  padding-right: 36px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select::-ms-expand {
  display: none;
}
.select--group .select__caret-down {
  position: absolute;
  top: 21px;
  right: 16px;
  font-size: 10px;
  line-height: 10px;
  pointer-events: none;
}
.checkbox--set {
  display: block;
  position: relative;
  padding-left: 32px;
  padding-top: 1px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  color: #6a7074;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox__link {
  color: #1c1f20;
  text-decoration: underline;
}
.checkbox__link:hover,
.checkbox__link:focus {
  color: #1c1f20;
}
.checkbox--set input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox--set .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid #bcbab8;
}
.checkbox--set input:checked ~ .checkmark {
  background-color: #341062;
  border-color: #341062;
}
.checkbox--set .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox--set input:checked ~ .checkmark:after {
  display: block;
}
.checkbox--set .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox--alt {
  font-size: 16px;
  font-weight: normal;
  padding-left: 28px;
}
.checkbox--alt .checkmark {
  width: 16px;
  height: 16px;
  top: 4px;
  border: 1px solid #6a7074;
}
.checkbox--alt .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
}
.admin__button:not([href]),
.admin__button-outline:not([href]),
.admin__button,
.admin__button-outline {
  font-size: 16px;
  font-weight: normal;
  border-radius: 0;
  border: none;
  min-width: 200px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #0638e7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.admin__button-outline:not([href]),
.admin__button-outline {
  background-color: transparent;
  border: 1px solid #0638e7;
  color: #0638e7;
}
.admin__button-outline:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button-outline:not([href]):not(:disabled):not(.disabled):active,
.admin__button-outline:not([href]):hover,
.admin__button-outline:not([href]):focus,
.admin__button:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button:not([href]):not(:disabled):not(.disabled):active,
.admin__button:not([href]):hover,
.admin__button:not([href]):focus,
.admin__button-outline:not(:disabled):not(.disabled):active:focus,
.admin__button-outline:not(:disabled):not(.disabled):active,
.admin__button-outline:hover,
.admin__button-outline:focus,
.admin__button:not(:disabled):not(.disabled):active:focus,
.admin__button:not(:disabled):not(.disabled):active,
.admin__button:hover,
.admin__button:focus {
  background-color: #0c30af;
  color: #fff;
  box-shadow: none;
  border: none;
}
.admin__button-outline.disabled,
.admin__button.disabled {
  background-color: transparent !important;
  border: 1px solid #bcbab8 !important;
  color: #bcbab8 !important;
}
.admin__button .icon-arrow-right {
  margin-left: 10px;
  font-size: 12px;
}
.admin__button-sm:not([href]),
.admin__button-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.admin__button-radius:not([href]),
.admin__button-radius {
  border-radius: 10px !important;
}
.admin__button-mini:not([href]),
.admin__button-mini {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 160px;
}
.arrow__btn-set:not([href]),
.arrow__btn-set {
  padding-left: 30px;
  padding-right: 30px;
}
.arrow__btn-set .icon-arrow-right {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.outline__button:not([href]),
.outline__button {
  border: 1px solid #222222;
  color: #222222;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
  padding: 10px 20px;
  min-height: 48px;
  border-radius: 0;
  min-width: 200px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.outline__button-sm:not([href]),
.outline__button-sm {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 160px;
}
.outline__button-md:not([href]),
.outline__button-md {
  min-height: 32px;
  padding: 6px 10px;
  min-width: 188px;
}
.outline__button:not([href]):not(:disabled):not(.disabled):active,
.outline__button:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button:not([href]):hover,
.outline__button:not([href]):focus,
.outline__button:not(:disabled):not(.disabled):active,
.outline__button:not(:disabled):not(.disabled):active:focus,
.outline__button:hover,
.outline__button:focus {
  background-color: #222222;
  color: #fff;
  border-color: #222222;
  box-shadow: none;
}
.outline__button.disabled {
  color: #bcbab8 !important;
  border-color: #bcbab8 !important;
}
.outline__button-white:not([href]),
.outline__button-white {
  border-color: #fff;
  color: #fff;
}
.outline__button-white:not([href]):not(:disabled):not(.disabled):active,
.outline__button-white:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button-white:not([href]):hover,
.outline__button-white:not([href]):focus,
.outline__button-white:not(:disabled):not(.disabled):active,
.outline__button-white:not(:disabled):not(.disabled):active:focus,
.outline__button-white:hover,
.outline__button-white:focus {
  background-color: #fff;
  color: #222222;
  border-color: #fff;
  box-shadow: none;
}
.outline__button-blue:not([href]),
.outline__button-blue {
  border-color: #0638e7;
  color: #0638e7;
}
.outline__button-blue:not([href]):not(:disabled):not(.disabled):active,
.outline__button-blue:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button-blue:not([href]):hover,
.outline__button-blue:not([href]):focus,
.outline__button-blue:not(:disabled):not(.disabled):active,
.outline__button-blue:not(:disabled):not(.disabled):active:focus,
.outline__button-blue:hover,
.outline__button-blue:focus {
  background-color: #0638e7;
  color: #fff;
  border-color: #0638e7;
  box-shadow: none;
}
.form--banner::-webkit-scrollbar {
  width: 6px;
}
.form--banner::-webkit-scrollbar-thumb {
  background: #0099ff;
}
.row--space-10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row--space-10 .col,
.row--space-10 .col-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.chat__app {
  position: fixed;
  right: 50px;
  bottom: 20px;
  width: 60px;
}
.custom__file {
  color: #1c1f20;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  padding-bottom: 3px;
}
.custom__file input {
  display: none;
}
.custom__file-icon {
  margin-left: 12px;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.custom__file:before {
  content: '';
  width: 140px;
  height: 1px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.custom__file-alt {
  padding-top: 0;
}
.custom__file-alt:before {
  content: none;
}
.uploaded--img-block {
  margin-right: 32px;
  margin-bottom: 30px;
}
.uploaded--img {
  width: 66px;
  height: 66px;
  min-width: 66px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploaded--img-new {
  width: 182px;
  height: 182px;
  display: flex;

  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploaded--img-new img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.uploaded--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploaded-trash {
  display: inline-block;
  color: #6a7074;
}
.file--uploaded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form-label {
  font-size: 12px;
}
.custom__radioBtn {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-top: 16px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom__radioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
}
.custom__radioBtn .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #6a7074;
  border-radius: 50%;
}
.custom__radioBtn .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.custom__radioBtn input:checked ~ .checkmark:after {
  display: block;
}
.custom__radioBtn .checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0638e7;
}
.signin--page .form--banner {
  background-color: #071033;
}
.signin--page .form__heading-green {
  color: #f2e9d0;
}
.signin--page .app__button {
  background-color: #f2e9d0;
  border-color: #f2e9d0;
}
.signin--page .app__button:not(:disabled):not(.disabled):active:focus,
.signin--page .app__button:not(:disabled):not(.disabled):active,
.signin--page .app__button:hover,
.signin--page .app__button:focus {
  color: #1c1f20;
  background-color: #e8dec0;
  border-color: #e8dec0;
}
.signin--page .app__button.disabled,
.signin--page .app__button:disabled {
  background-color: transparent !important;
  border: 1px solid #bcbab8 !important;
  color: #bcbab8 !important;
}

/*
  Forms Style
*/

/*
  Sidebar Style
*/

.sidebar {
  background-color: #1c1f20;
  z-index: 55;
  position: relative;
  border-radius: 0px 20px 20px 0px;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.sidebar--canvas {
  width: 270px;
  min-width: 270px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.2s ease;
}
.sidebar--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px 38px 20px;
}
.sidebar__logo {
  height: 39px;
  overflow: hidden;
  display: flex;
}
.sidebar__logo-alt {
  width: 34px;
  height: 39px;
  margin-left: auto;
  margin-right: auto;
}
.sidebar .sidebar__logo-alt {
  display: none;
}
.sidebar--content {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px;
}
.sidebar--content::-webkit-scrollbar {
  width: 5px;
}
.sidebar--content::-webkit-scrollbar-thumb {
  background: rgba(188, 186, 184, 0.47);
  border-radius: 5px;
}
.sidebar__list {
  margin-bottom: 24px;
}
.sidebar__list li a {
  color: #bcbab8;
  font-size: 16px;
  font-weight: normal;
  padding: 12px 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  line-height: 24px;
}
.sidebar__list li a i {
  font-size: 16px;
  margin-right: 10px;
}
.sidebar__list li a:hover,
.sidebar__list li a.active {
  color: #61e3ff;
}
.sidebar__list li a .sidebar__list-text {
  white-space: nowrap;
}
.sidebar__list-alt {
  margin-bottom: 10px;
  margin-top: auto;
}
.sidebar__toggle {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  width: 28px;
  height: 36px;
  border-radius: 21px;
  font-size: 14px;
  background-color: rgba(188, 186, 184, 0.47);
}
.menu__toggle {
  width: 22px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-left: auto;
}
.toggle__span {
  display: inline-block;
  width: 22px;
  height: 2px;
  background-color: #341062;
}
.menu__toggle .toggle__span:nth-of-type(2) {
  width: 14px;
}
.sidebar--canvas::-webkit-scrollbar {
  width: 6px;
}
.sidebar--canvas::-webkit-scrollbar-thumb {
  background: #270f46;
}
.sidebar--user-canvas {
  padding: 20px 20px 24px 20px;
}
.sidebar--user {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 270px;
  min-height: 56px;
  cursor: pointer;
}
.sidebar--user .sidebar--img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
  -khtml-border-radius: 50%;
}
.sidebar--user .sidebar--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar--user .sidebar__name {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0;
  word-break: break-word;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.sidebar--user .sidebar__mention {
  font-size: 14px;
  color: #bcbab8;
  margin-bottom: 0;
  margin-top: 4px;
  word-break: break-word;
  font-weight: normal;
}
.sidebar--user .dropdown__item-span {
  color: #1c1f20;
  margin-left: 10px;
  font-size: 14px;
  display: inline-flex;
}
.sidebar--user .sidebar--detail {
  width: 100%;
}
.sidebar--popover {
  border-radius: 10px;
  width: 220px;
  border: 1px solid rgba(188, 186, 184, 0.33);
  box-shadow: 0px 4px 15px rgba(165, 165, 165, 0.22);
  left: -20px !important;
  margin-top: -26px !important;
}
.sidebar--popover-sm {
  left: -8px !important;
  margin-top: -15px !important;
}
.sidebar--popover[x-placement^='right'] > .arrow::after,
.sidebar--popover > .arrow::after {
  border-width: 0.55rem 0.7rem 0.55rem 0;
}
.sidebar--popover .arrow {
  top: 8px !important;
}
.sidebar--popover .popover-body {
  padding: 15px;
}
.options__list li a {
  color: #6a7074;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  word-break: break-word;
  white-space: normal;
}
.options__list li a .options__list-icon {
  margin-right: 10px;
}
.options__list li a:hover,
.options__list li a:focus {
  color: #0638e7;
}
.sidebar--content-bottom {
  display: flex;
  flex-direction: column;
}
.sidebar--hide {
  /* margin-right: 40px; */
}
.sidebar--hide .sidebar--canvas {
  width: 70px;
  min-width: 70px;
}
.sidebar--hide .sidebar--card .card-body,
.sidebar--hide .sidebar__list li a .sidebar__list-text,
.sidebar--hide .sidebar--card .card-header .btn-link .sidebar__list-text,
.sidebar--hide .sidebar--detail,
.sidebar--hide .sidebar__logo {
  display: none;
}
.sidebar--hide .sidebar--content::-webkit-scrollbar {
  width: 2px;
}
.sidebar--hide .sidebar--content {
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}
.sidebar--hide .sidebar--head {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar--hide .sidebar--content-bottom {
  align-items: center;
}
.sidebar--hide .sidebar--img {
  margin-left: auto;
  margin-right: auto;
}
.sidebar--hide .sidebar__logo-alt {
  display: block;
}
.sidebar--hide .sidebar__list li a,
.sidebar--hide .sidebar--card .card-header .btn-link {
  justify-content: center;
}
.sidebar--hide .sidebar__list li a i,
.sidebar--hide .sidebar--card .card-header .btn-link i {
  margin-left: auto;
  margin-right: auto;
}
.sidebar--hide .sidebar--user-canvas {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar--hide .sidebar--user {
  min-height: 10px;
}
.sidebar--hide .sidebar__toggle {
  transform: rotate(180deg);
  right: 8px;
}
.sidebar--card {
  background-color: transparent;
  border: none;
}
.accordion > .sidebar--card > .card-header,
.sidebar--card .card-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 0;
  background-color: transparent;
}
.sidebar--card .card-header .btn-link {
  color: #6a7074;
  font-size: 16px;
  font-weight: normal;
  padding: 12px 0;
  min-height: 46px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
}
.sidebar--card .card-header .btn-link i {
  font-size: 16px;
  margin-right: 10px;
}
.sidebar--card .card-header .btn-link:hover,
.sidebar--card .card-header .btn-link:focus,
.sidebar--card .card-header .btn-link.active {
  color: #0930ba;
  box-shadow: none;
  text-decoration: none;
}
.sidebar--card .card-body {
  padding: 0px 0px 0px 36px;
}
.toggle__btn {
  display: none;
}

/*
  Sidebar Style
*/

/*
  Dashboard Style
*/

.dashboard--page {
  display: flex;
}
.dashboard--content {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 40px;
}
.dashboard--container {
  width: 100%;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.dashboard--opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard--opt .back__btn,
.dashboard--opt .menu__toggle {
  margin-bottom: 20px;
}
.back__btn:not([href]),
.back__btn {
  font-size: 16px;
  color: #6a7074;
  display: inline-flex;
  align-items: center;
}
.back__btn-icon {
  margin-right: 8px;
  font-size: 11px;
  line-height: 12px;
}
.back__btn:hover,
.back__btn:focus {
  color: #0638e7;
}
.dashboard--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dashboard__heading {
  color: #1c1f20;
  font-size: 34px;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 0;
}
.step__text {
  color: #6a7074;
  font-size: 14px;
  display: inline-block;
  margin-right: 12px;
}
.step__info {
  color: #1c1f20;
  font-size: 14px;
  font-weight: 500;
}
.step--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.dashboard--select-n select.form-control {
  border-color: #222222;
  font-size: 14px;
  color: #222222;
  padding: 12px 35px 12px 15px;
  min-width: 200px;
}
.dashboard--select-n:after {
  content: '';
  background-color: #222222;
  height: 1px;
  width: 100px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.search--group .form-control {
  padding: 13px 13px 13px 44px;
}
.search--group .search__icon {
  position: absolute;
  left: 16px;
  top: 16px;
  color: #6a7074;
  font-size: 16px;
  line-height: 20px;
}
.step__button {
  min-width: 240px;
}
.btn__mw {
  min-width: 10px;
  width: 100%;
}
.dashboard--top-content {
  margin-top: 36px;
}
.dashboard--top-content .forms--group {
  margin-bottom: 30px;
}
.dashboard__paragraph {
  color: #222222;
  font-size: 18px;
}
.dashboard--canvas {
  width: 100%;
}
.mb-66 {
  margin-bottom: 66px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-28 {
  margin-bottom: 28px;
}
.step--upload-block {
  min-height: 72px;
  margin-bottom: 36px;
}
.step--upload-block .custom__file {
  margin-top: 40px;
}
.stepUploadBlock--alt {
  padding-top: 34px;
}
.stepUploadBlock--alt .custom__file {
  margin-top: 0px;
}
.stepUploadBlock--alt .uploaded--img-block .uploaded--img {
  width: 125px;
  height: 40px;
  margin-right: 16px;
}
.stepUploadBlock--alt .uploaded--img-block .uploaded--img img {
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  object-fit: initial;
}
.text-black {
  color: #000;
}
.stepBtn-canvas {
  margin-top: 60px;
  text-align: right;
}
.stepBtn-canvas-1 {
  padding-top: 32px;
}
.row--space-12 {
  margin-left: -12px;
  margin-right: -12px;
}
.row--space-12 .col-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.sf--group .form-control {
  padding-right: 30px;
}
.sf--group .sf__text {
  color: #6a7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 15px;
  pointer-events: none;
  line-height: 18px;
  text-transform: uppercase;
}
.combine--group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}
.combine--group .forms--group {
  margin-bottom: 0;
  width: 100%;
}
.combine__group-cross {
  font-size: 10px;
  color: #1c1f20;
  padding: 6px;
}
.listing--schedule-block {
  margin-bottom: 38px;
}
.listing--schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.listing--schedule-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.listing--schedule-col .select--group {
  min-width: 110px;
  margin-bottom: 0;
}
.week--time {
  width: 100%;
  padding-right: 10px;
}
.hours__change {
  color: #000000;
  font-size: 16px;
  display: inline-block;
}
.hours--time {
  display: flex;
  align-items: center;
}
.hours__separate {
  display: inline-block;
  min-width: 14px;
  text-align: center;
}
.listing--schedule-left {
  padding-right: 10px;
}
.listing--schedule-right {
  padding-left: 10px;
}
.add__options-button {
  font-size: 14px;
  color: #222222;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  padding: 4px 10px;
  min-width: 220px;
}
.add__options-button:hover,
.add__options-button:focus {
  color: #fff;
  background-color: #222222;
}
.listing--dropdown .error__msg {
  position: absolute;
  left: 0;
  top: 52px;
  color: #e7063c;
  font-size: 12px;
}

.listing--dropdown {
  margin-bottom: 38px;
}
.delete--team {
  border: 1px solid rgb(216, 215, 215);
  border-radius: 8px;
  background-color: white;
  color: #222222;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.delete--team span {
  display: block;
  padding: 8px 12px;
}
.delete--building {
  border: 1px solid rgb(216, 215, 215);
  border-radius: 8px;
  margin-left: -40px;
  background-color: white;
  color: #222222;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.delete--building span {
  display: block;
  padding: 8px 12px;
}
.delete--popup {
  border: 1px solid rgb(216, 215, 215);
  position: absolute;
  background-color: white;
  color: #222222;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  min-width: 250px;
  right: 30px;
  padding: 5px;
  border-radius: 8px;
  z-index: 999;
}
.delete--confirmation--buttons {
  display: flex;
  justify-content: space-around;
  padding: 3px;
}
.delete--confirmation--buttons span {
  color: #9fa7ad;
  border-radius: 4px;
  padding: 2px 5px;
}
.delete--confirmation--buttons span:first-child {
  color: #222222;
  border: 2px solid #222222;
  border-radius: 4px;
}
.listing--dropdown.mb-66 {
  margin-bottom: 66px;
}
.listing--dropdown .dropdown-toggle {
  border: 1px solid #bcbab8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listing--dropdown-pad .dropdown-toggle {
  padding-right: 36px;
}
.listing--dropdown .dropdown-toggle .select__caret-down {
  position: absolute;
  top: 18px;
  right: 16px;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  pointer-events: none;
}
.listing--dropdown .dropdown-toggle:after {
  content: none;
}
.listing--dropdown.show > .dropdown-toggle,
.listing--dropdown.show > .dropdown-toggle:focus,
.listing--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active,
.listing--dropdown .dropdown-toggle:focus,
.listing--dropdown .dropdown-toggle:active {
  color: #222222;
  background-color: transparent;
  box-shadow: none;
  border-color: #0638e7;
}
.listing--dropdown.show .dropdown-toggle .select__caret-down {
  transform: rotate(180deg);
  top: 17px;
}
.listing--dropdown .dropdown-menu {
  right: 0px !important;
  padding: 10px 13px;
  margin-top: -1px;
  border-radius: 0;
  border-color: #0638e7;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}
.listing--dropdown .dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.listing--dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background: #bcbab8;
}
.listing--dropdown .dropdown-item {
  padding: 8px 13px;
  font-size: 14px;
  color: #6a7074;
  border-radius: 2px;
  word-break: break-word;
  white-space: normal;
}
.listing--dropdown .dropdown-item:hover,
.listing--dropdown .dropdown-item:focus,
.listing--dropdown .dropdown-item:active {
  background-color: #0638e7;
  color: #fff;
}
.accordion > .listing--card:not(:last-of-type),
.listing--card {
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #bcbab8;
}
.listing--card .card-header {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 0;
}
.listing--card .btn-link {
  font-size: 14px;
  color: #6a7074;
  padding: 10px 0;
  width: 100%;
  text-align: left;
}
.listing--card .btn-link:hover,
.listing--card .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.listing--card .accordian__icon {
  position: absolute;
  right: 1px;
  top: 16px;
  font-size: 12px;
  line-height: 10px;
}
.listing--card .accrodian__minus {
  display: none;
}
.listing--card .btn-link.active .accrodian__plus {
  display: none;
}
.listing--card .btn-link.active .accrodian__minus {
  display: block;
}
.listing--accordian .listing--card:last-child {
  border-bottom: 0;
}
.listing--card .card-body {
  padding: 0px;
}
.listing--card .card-body a {
  font-size: 14px;
  color: #6a7074;
  display: flex;
}
.link__gray:not([href]),
.link__gray {
  color: #222222;
  text-decoration: underline;
}
.link__gray:not([href]):hover,
.link__gray:not([href]):focus,
.link__gray:hover,
.link__gray:focus {
  color: #222222;
}
.text__gray {
  color: #6a7074;
}
.custom__file-sm:before {
  width: 66px;
}
.step--content-3 .step--upload-block {
  min-height: 30px;
}
.forms--group textarea.form-control {
  height: 130px;
}
.rent--group .form-control {
  padding-right: 68px;
}
.rent--group .rent__text {
  color: #6a7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 15px;
  pointer-events: none;
  line-height: 18px;
}
.icons--group .form-control {
  padding-right: 32px;
}
.icons--group .icons__text {
  color: #6a7074;
  font-size: 14px;
  position: absolute;
  right: 14px;
  top: 16px;
  pointer-events: none;
  line-height: 16px;
}
.editListing--head-btn {
  display: flex;
  align-items: center;
}
.editListing--head-btn .sm__button {
  margin-left: 20px;
}
.prev--next-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.pages__option {
  display: inline-flex;
  align-items: center;
  color: #222222;
}
.pages__option .pages__option-text {
  text-decoration: underline;
  display: inline-block;
}
.pages__option .pages__option-icon {
  line-height: 16px;
}
.prev__option .pages__option-icon {
  margin-right: 10px;
}
.next__option .pages__option-icon {
  margin-left: 10px;
}
.pages__option:hover,
.pages__option:focus {
  color: #0638e7;
}
.upload__button {
  color: #1c1f20;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
  padding-bottom: 3px;
  background-color: transparent;
  padding: 0;
  border: none;
  white-space: nowrap;
}
.upload__button:before {
  content: '';
  width: 66px;
  height: 1px;
  background-color: #222222;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}
.upload__button .upload__button-icon {
  margin-left: 12px;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.upload__button:not(:disabled):not(.disabled):active,
.upload__button:not(:disabled):not(.disabled):active:focus,
.upload__button:hover,
.upload__button:focus {
  background-color: transparent;
  box-shadow: none;
  color: #1c1f20;
}

/*
  Dashboard Style
*/

/*
  Admin/Broker Home Style
*/

.admin--home {
  padding-top: 0;
}
.admin--banner {
  background: url(/static/media/admin-home.c2197111.png) center center no-repeat;
  background-size: cover;
  height: 270px;
  position: relative;
}
.home__mark {
  background: linear-gradient(218.62deg, #1b8ffa 4.75%, #0638e7 95.82%);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
  margin-left: auto;
  margin-right: auto;
}
.adminHome--canvas {
  margin-top: 100px;
}
.annex--block {
  margin-top: 44px;
}
.annex--card {
  border: 1px solid #bcbab8;
  padding: 32px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.annex--info {
  display: flex;
  margin-bottom: 14px;
}
.annex__info-icon {
  color: #222222;
  font-size: 17px;
  min-width: 18px;
  text-align: center;
  display: inline-block;
  line-height: 18px;
  margin-right: 10px;
  margin-top: 3px;
}
.annex__info-heading {
  margin-bottom: 0;
  color: #222222;
  font-size: 20px;
  font-weight: normal;
}
.sm__button:not([href]),
.sm__button {
  min-width: 156px;
}
.sm__button.underline__button:not([href]):hover,
.sm__button.underline__button:after {
  width: 78px;
}
.menuToggle--canvas {
  text-align: right;
  padding-top: 26px;
  display: flex;
  justify-content: flex-end;
}
.menu__toggle-second .toggle__span {
  background-color: #fff;
}
.annex--card-blue {
  background-color: #0638e7;
  border-color: #0638e7;
}
.annex--card-blue .annex__info-icon,
.annex--card-blue .annex__info-heading,
.annex--card-blue .form__para {
  color: #fff;
}
.admin__button-white:not([href]),
.admin__button-white {
  font-size: 14px;
  font-weight: normal;
  border-radius: 0;
  min-width: 156px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #0638e7;
  background-color: #fff;
  border: 1px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.admin__button-white:not([href]):not(:disabled):not(.disabled):active:focus,
.admin__button-white:not([href]):not(:disabled):not(.disabled):active,
.admin__button-white:not([href]):hover,
.admin__button-white:not([href]):focus,
.admin__button-white:not(:disabled):not(.disabled):active:focus,
.admin__button-white:not(:disabled):not(.disabled):active,
.admin__button-white:hover,
.admin__button-white:focus {
  background-color: transparent;
  color: #fff;
  box-shadow: none;
  border-color: #fff;
}
.adminButton__white-lg:not([href]),
.adminButton__white-lg {
  min-width: 200px;
}
.admin__button-white:after {
  content: '';
  background-color: #fff;
  height: 1px;
  width: 78px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -8px;
}
.adminButton__white-lg:after:not([href]),
.adminButton__white-lg:after {
  width: 120px;
}

/*
  Admin/Broker Home Style
*/

/*
  Listing Style
*/

.container--large {
  max-width: 1330px;
}
.listing {
  padding-top: 25px;
}
.listing--content {
  margin-top: 50px;
}
.listing--head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.listing--head .dashboard__heading {
  margin-top: 4px;
}
.listing--head .heading--group .dashboard__heading {
  margin-top: 0;
}
.listing--options {
  display: inline-flex;
  align-items: center;
}
.options__box {
  width: 38px;
  height: 38px;
  min-width: 38px;
  color: #fff;
  background-color: #341062;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 7px;
}
.option__share {
  background-color: #4c316f;
}
.options__box:hover,
.options__box:focus {
  color: #fff;
  background-color: #542492;
}
.listing--video {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.listing--video img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.listing--video video {
  height: 360px;
  width: 100%;
}
.listing--video-overlay {
  background-color: rgba(0, 102, 178, 0.21);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play__button:not([href]),
.play__button {
  display: inline-block;
  color: #fff;
  font-size: 40px;
  width: 34px;
  height: 40px;
  line-height: 40px;
}
.play__button:not([href]):hover,
.play__button:not([href]):focus,
.play__button:hover,
.play__button:focus {
  color: #0638e7;
}
.gallery--box {
  width: 100%;
  height: 200px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.gallery--box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.small__paragraph {
  color: #6a7074;
  font-size: 14px;
  line-height: 24px;
}
.small__heading {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
.description__para {
  font-size: 16px;
}
.listing-component {
  margin-bottom: 60px;
}
.spec--data .small__heading {
  text-transform: uppercase;
}
.spec--data {
  margin-bottom: 6px;
}
.key--details {
  padding: 16px;
  margin-top: 20px;
  background-color: #f7f7f7;
  min-height: 232px;
}
.keyDetails__heading {
  color: #222222;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 34px;
}
.key--box {
  margin-bottom: 25px;
}
.keyBox--head {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.keyBox--head i {
  color: #7d7d7d;
  margin-right: 8px;
  font-size: 15px;
}
.keyBox--head i.icon-size {
  font-size: 14px;
}
.keyBox--head .small__heading,
.keyBox--head .small__paragraph {
  margin-bottom: 0;
}
.key--box .small__heading {
  color: #6a7074;
  font-weight: normal;
}
.key--box .small__paragraph {
  color: #222222;
  font-weight: normal;
  margin-bottom: 0;
  line-height: inherit;
}
.listing--map {
  position: relative;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 20px;
}
.listing--map img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.pdf__button {
  font-size: 16px;
}

/*
  Listing Style
*/

/*
  Building / Building Listings Style
*/

.available--listing {
  padding: 25px 0;
}
.available--listing-content {
  margin-top: 50px;
}
.al__heading {
  margin-bottom: 20px;
}
.available--listing-view {
  margin-top: 52px;
  margin-bottom: 32px;
}
.available--listing-view .forms--group,
.available--listing-view .listing--dropdown {
  margin-bottom: 0;
}
.viewBy__text {
  color: #000000;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
}
.viewBy__marker {
  font-size: 20px;
  color: #0638e7;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
}
.viewBy__marker.disabled {
  color: #bcbab8;
}
.grid__view {
  line-height: 20px;
  font-size: 20px;
  cursor: pointer;
}
.grid__horizontal {
  color: #bcbab8;
}
.grid__vertical {
  color: #0638e7;
}
.dropdown--checkbox {
  border-bottom: 1px solid #bcbab8;
  padding-left: 30px;
  line-height: 16px;
  padding-bottom: 15px;
  margin-top: 10px;
}
.dropdown--checkbox .checkmark {
  width: 16px;
  height: 16px;
  top: 1px;
}
.dropdown--checkbox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 9px;
}
.available--listing-off {
  margin-bottom: 32px;
}
.available--listing-cols {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 32px;
}
.available--listing-cols .available--listing-off:last-child {
  margin-bottom: 0;
}
.available--listing-cols .available--listing-off:last-child .al--block:last-child {
  margin-bottom: 0;
}
.available--listing-cols .scroll--listing-cols::-webkit-scrollbar {
  width: 0px;
}
.subdomain__footer {
  background-color: #1c1f20;
  border-radius: 22px 22px 0px 0px;
  padding-top: 54px;
  padding-bottom: 32px;
}
.sd__footer-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sd__footer-list li a {
  color: #0099ff;
  font-size: 14px;
  margin-right: 24px;
  display: inline-block;
}
.sd__footer-list li:last-child a {
  margin-right: 0;
}
.sd__footer-list li a:hover,
.sd__footer-list li a:focus {
  color: #288ed2;
}
.sd__footer-para {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  line-height: 24px;
}
.subdomain__footer .sd__footer-l {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 100%;
  justify-content: space-between;
}
.subdomain__footer .sd__footer-l .sd__footer-para {
  font-size: 12px;
  line-height: 16px;
}
.subdomain__footer .sd__footer-para .icon-copyright {
  font-size: 10px;
}
.al__heading-medium {
  color: #222222;
  margin-bottom: 28px;
  font-weight: normal;
  font-size: 24px;
}
.al--block {
  display: flex;
  margin-bottom: 20px;
}
.al--block .al--block-img {
  width: 242px;
  height: 158px;
  min-width: 242px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.al--block .al--block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.al--block .al--block-detail {
  padding: 22px 20px;
  width: 100%;
  margin-left: -1px;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  border: 1px solid #bcbab8;
}
.al__address {
  font-size: 20px;
  color: #222222;
  margin-bottom: 12px;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  align-items: center;
  word-break: break-word;
}
.al__address .add__logo {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 18px;
  margin-left: 12px;
}
.al--listing {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.al--listing-box .al--listing:last-child {
  margin-bottom: 0;
}
.al--listing .al__icon {
  display: inline-block;
  line-height: 18px;
  color: #6a7074;
  margin-right: 10px;
}
.al--listing .al__text {
  color: #6a7074;
  font-size: 15px;
  margin-right: 8px;
  display: inline-block;
  min-width: 46px;
}
.al--listing .alText__detail {
  color: #222222;
  margin-right: 0;
}
.al--block .al--btn-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.heading--group .form-control {
  padding: 0;
  border: none;
  color: #1c1f20;
  font-size: 34px;
  font-weight: normal;
  word-break: break-word;
  margin-bottom: 0;
  height: auto;
  line-height: 46px;
}
.heading--group .form-control::-webkit-input-placeholder {
  color: #1c1f20;
}
.heading--group .form-control::-ms-input-placeholder {
  color: #1c1f20;
}
.heading--group .form-control::placeholder {
  color: #1c1f20;
}
.heading--group .heading__edit {
  color: #222222;
  font-size: 18px;
  line-height: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.heading--group .heading__edit:hover {
  color: #222222;
}
.heading--group {
  display: flex;
  padding-right: 24px;
  width: 100%;
  overflow: hidden;
}
.list__button:not([href]),
.list__button {
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  padding: 4px 7px;
  min-width: 92px;
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
}
.list__button-draft {
  background-color: #bcbab8;
}
.list__button-review {
  background-color: rgba(255, 141, 57, 0.8);
}
.list__button-published {
  background-color: #222222;
}
.list__button:not([href]):hover,
.list__button:hover {
  color: #fff;
  opacity: 0.8;
}
.customer--block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
}
.customer--img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;
}
.customer--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customer--block .customer--img:first-child {
  margin-left: 0;
}
.customer--add:not([href]),
.customer--add {
  font-size: 16px;
  color: #fff;
  min-width: 32px;
  height: 32px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0638e7;
  margin-left: -6px;
}
.customer--add:not([href]):hover,
.customer--add:not([href]):focus,
.customer--add:hover,
.customer--add:focus {
  color: #fff;
  background-color: #0c30af;
}
.buildingListing--inner {
  padding-top: 30px;
}
.bl--head-btn {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.modified__text {
  color: #bcbab8;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-top: auto;
}
.modified__text .modified__text-icon {
  display: inline-block;
  line-height: 18px;
  margin-right: 10px;
}
.buildings--block .ald--inner {
  display: flex;
  flex-direction: column;
}
.buildings--block .bb-sf {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.buildings--block .bb--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buildings--block .bb--inner {
  width: 50%;
}
.b--sf-heading,
.b--sf-text {
  color: #222222;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}
.b--sf-text {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.sd__bars:not([href]),
.sd__bars {
  display: inline-flex;
  flex-direction: column;
  color: #c4c4c4;
}
.sd__bars:not([href]):hover,
.sd__bars:not([href]):focus,
.sd__bars:hover,
.sd__bars:focus {
  color: #c4c4c4;
}
.sd__bars .bars__bg {
  display: block;
  width: 36px;
  height: 4px;
  background: #c4c4c4;
  margin-bottom: 6px;
}
.contact__btn:not([href]),
.contact__btn {
  color: #222222;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  padding: 3px 10px;
  text-align: center;
}
.contact__btn:not([href]):hover,
.contact__btn:not([href]):focus,
.contact__btn:hover,
.contact__btn:focus {
  color: #fff;
  background-color: #222222;
}
.helping--block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.requestTour--box {
  border: 1px solid #0638e7;
  padding: 43px 25px;
  position: relative;
  min-height: 360px;
}
.cross__btn {
  color: #6a7074;
  background-color: transparent;
  border: navajowhite;
  padding: 0;
  line-height: 16px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -32px;
}
.requestTour--box-sent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rt--sent-canvas {
  text-align: center;
}
.rt__ent-heading {
  color: #0638e7;
  font-size: 24px;
}
.rt--sent-logo,
.sub__logo {
  width: 80px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.rt--sent-logo img,
.sub__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.listing__logo,
.available--listing-logo {
  width: 151px;
  height: 39px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.gallery--carousel .carousel-indicators {
  bottom: -15px;
}
.gallery--carousel .carousel-indicators li {
  width: 16px;
  height: 16px;
  background-color: #cdd7fa;
  margin-left: 11px;
  margin-right: 11px;
  border: none;
  opacity: 1;
}
.gallery--carousel .carousel-indicators li.active {
  background-color: #0638e7;
}
.gallery--carousel .carousel-control-next,
.gallery--carousel .carousel-control-prev {
  display: none;
}
.buildingsBlock--update .bb-sf {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.buildingsBlock--update .bb--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  text-align: center;
}
.buildingsBlock--update .bb--inner span {
  word-break: break-word;
}
.buildingsBtn--canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buildingsBtn--canvas .admin__button {
  width: 100%;
  min-width: 10px;
  margin-bottom: 20px;
}
.link__blue-set {
  display: inline-flex;
  align-items: center;
}

/*
  Building / Building Listings Style
*/

/*
  Subdomain Style
*/

.virtualTour--content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}
.tour--video {
  width: 100%;
  max-width: 420px;
  height: 236px;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
.tour--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour--video video {
  height: 236px;
  width: 100%;
}
.font-semibold {
  font-weight: 600;
}
.text__dark {
  color: #222222;
}
.vc--mobile {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 0 !important;
  padding-top: 16px;
}
.tenat--feature {
  margin-bottom: 30px;
}
.tenat--feature .tf__icon {
  font-size: 42px;
  color: #000;
  display: inline-block;
  line-height: 40px;
}
.app__link:not([href]),
.app__link {
  font-size: 16px;
  color: #0638e7;
  font-weight: normal;
  display: inline-block;
}
.app__link:not([href]):hover,
.app__link:not([href]):focus,
.app__link:hover,
.app__link:focus {
  color: #0638e7;
  text-decoration: underline;
}
.virtualTour--video {
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.virtualTour--video video {
  height: 360px;
  width: 100%;
}
.virtualTour--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video__expand {
  color: #222222;
  font-size: 22px;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(78, 72, 72, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.video__expand:hover {
  color: #0638e7;
}
.virtualTour--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(21, 35, 92, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.virtualTour__overlay-icon {
  color: #fff;
  font-size: 32px;
  line-height: 32px;
}
.virtualTour__overlay-text {
  color: #fff;
  font-size: 16px;
  margin-top: 14px;
}
.gallery--box-overlay {
  background-color: rgba(0, 102, 178, 0.21);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery--overlay-icon {
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
}
.virtualTour--video-sm {
  height: 224px;
}
.virtualTour--video-sm video {
  height: 224px;
}
.key--details-vt {
  min-height: 100px;
}
.virtual--video-block {
  width: 100%;
  max-width: 368px;
  position: relative;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
}
.virtual--video-main {
  border: 1px solid #222222;
}
.virtual--video-off {
  display: flex;
  flex-wrap: wrap;
}
.virtual--video {
  width: 100%;
  height: 210px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.virtual--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.virtual--video video {
  height: 210px;
  width: 100%;
}
.virtual--video-col .virtual--video {
  width: 50%;
}
.small--video-col .virtual--video {
  height: 105px;
}
.small--video-col .virtual--video video {
  height: 105px;
}
.vv--overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
}
.vv--overlay .vv--overlay-off {
  padding: 5px 10px;
  min-width: 230px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.7);
}
.vv--overlay .vv--overlay-text,
.vv--overlay .vv--overlay-btn {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  line-height: 24px;
}
.virtual--video-col .vv--overlay .vv--overlay-off {
  min-width: 100px;
}
.virtual--video-col .vv--overlay .vv--overlay-text {
  font-size: 12px;
}
.vv--overlay .vv--overlay-btn {
  margin-left: 12px;
  font-size: 18px;
  background-color: transparent;
  border: none;
  line-height: 20px;
}
.vv--options {
  border: 1px solid #222222;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 15px;
  margin-top: -1px;
  min-height: 66px;
  margin-bottom: 20px;
}
.vvOptions-btn {
  border: none;
  background-color: transparent;
  margin-right: 20px;
  padding: 0;
  font-size: 16px;
  min-width: 20px;
}
.vvOptions-rec {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #222222;
  border-radius: 2px;
  padding: 2px 6px;
  line-height: 16px;
  text-align: center;
  border: none;
  text-transform: uppercase;
}
.more--dropdown .dropdown-toggle {
  color: #434343;
  font-size: 16px;
  min-width: 18px;
  text-align: center;
  line-height: 16px;
  padding: 0 2px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  transition: all 0.2s ease;
}
.more--dropdown .dropdown-toggle:after {
  content: none;
}
.more--dropdown .btn-primary:not(:disabled):not(.disabled).active,
.more--dropdown .btn-primary:not(:disabled):not(.disabled):active,
.more--dropdown.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: #4a4a4a;
}
.more--dropdown .dropdown-menu {
  right: 24px !important;
  left: inherit !important;
  border-radius: 0px;
  margin-top: 4px;
  transform: translate3d(0px, 0px, 0px) !important;
  border: none;
  background-color: #f7f7f7;
  padding: 0;
  min-width: 144px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.14);
}
.more--dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #434343;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}
.more--dropdown .dropdown-menu .dropdown-item i {
  font-size: 10px;
  margin-right: 10px;
  min-width: 10px;
  text-align: center;
}
.more--dropdown .dropdown-menu .dropdown-item i.icon-slack {
  font-size: 11px;
}
.more--dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #0638e7;
  color: #fff;
}
.virtual--expanded {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 5;
  padding: 48px 0px 24px 0px;
}
.virtual--gallery {
  background-color: rgba(34, 34, 34, 0.8);
}
.back__btn-white:not([href]),
.back__btn-white,
.back__btn-white:not([href]):hover,
.back__btn-white:hover,
.back__btn-white:not([href]):focus,
.back__btn-white:focus {
  color: #fff;
}
.virtual--carousel .carousel-indicators {
  display: none;
}
.virtual--carousel .carousel-control-next,
.virtual--carousel .carousel-control-prev {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
.virtual--carousel .carousel-control-prev {
  left: 15px;
}
.virtual--carousel .carousel-control-next {
  right: 15px;
}
.virtual--carousel .carousel-control-prev .carousel-control-prev-icon {
  background: url(/static/media/prev.9feac3f8.svg) center center no-repeat;
  background-size: 13px;
}
.virtual--carousel .carousel-control-next .carousel-control-next-icon {
  background: url(/static/media/next.996e3a0b.svg) center center no-repeat;
  background-size: 13px;
}
.virtual--carousel .carousel-control-next:hover,
.virtual--carousel .carousel-control-prev:hover {
  background-color: #222222;
  border-color: #222222;
}
.virtual--carousel .carousel-item {
  height: 500px;
}
.virtual--carousel .carousel-item img {
  height: 100%;
  object-fit: cover;
}
.virtual--carousel {
  margin-bottom: 36px;
}
.gallery--box-sm {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  margin-right: 20px;
}
.virtual__frame {
  width: 100%;
  height: 100%;
  border: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.virtual--expanded .back__btn {
  position: relative;
  z-index: 5;
}
.ve--gradient:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 150px;
  z-index: 5;
  background: linear-gradient(180deg, #1a1a1a 0%, rgba(26, 26, 26, 0) 100%);
}
.direction__control:not([href]),
.direction__control {
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #564f49;
  position: absolute;
  top: 10px;
  z-index: 5;
}
.direction__control:not([href]):hover,
.direction__control:hover {
  background-color: #222222;
  color: #fff;
}
.direction__control-next {
  right: 10px;
}
.direction__control-prev {
  left: 10px;
}
.tourEnd--content .form__para {
  font-size: 18px;
}

/*
  Subdomain Style
*/

/*
  Admin Settings Style
*/
.text-green-active {
  color: #057123;
}
.adminSettings--head {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.adminSettings--head .adminSettings__icon {
  font-size: 24px;
  display: inline-block;
  margin-right: 16px;
  line-height: 24px;
}
.adminSettings--head .adminSettings__text {
  color: #222222;
  font-size: 24px;
}
.adminSettings__text {
  color: #222222;
  font-size: 24px;
}
.profile--img {
  width: 150px;
  height: 150px;
  min-width: 150px;
  overflow: hidden;
  background-color: #bcbab8;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile--img-upload {
  cursor: pointer;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 90px;
  background-color: rgba(34, 34, 34, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile--img-upload input {
  display: none;
}
.profile--img-upload .imgUpload-icon {
  font-size: 20px;
  display: inline-block;
  line-height: 20px;
  color: #fff;
}
.profile--img-upload .imgUpload-text {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  margin: 8px 0;
}
.saveBtn__icon {
  line-height: 14px;
  display: inline-block;
}
.adminSettings--row {
  margin-bottom: 60px;
}
.adminSettings--upload {
  display: flex;
  align-items: center;
  position: relative;
}
.adminSettings--upload-item {
  border: 1px solid #bcbab8;
  border-radius: 0;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
  width: 60%;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.adminSettings--upload-item input {
  display: none;
}
.adminSettings--upload-del {
  display: inline-block;
  font-size: 16px;
  color: #222222;
  margin-left: 20px;
  margin-right: 10px;
}
.adminSettings--upload-del:hover,
.adminSettings--upload-del:focus {
  color: #0638e7;
}
.uploaded--content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 11px 13px;
}
.uploaded--content .uploadedContent__img {
  width: 70px;
  min-width: 50px;
  height: 24px;
  margin-right: 12px;
  background-color: rgba(188, 186, 184, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploaded--content .uploadedContent__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploaded--content .uploadedContent__name {
  color: #222222;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.default--upload-item input {
  display: block;
  font-size: 14px;
  line-height: 14px;
  width: 84px;
  min-width: 96px;
  overflow: hidden;
}
.default--upload-item .de__text {
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.interface--dropdown .dropdown-menu {
  padding-left: 0;
  padding-right: 0;
}
.interface--dropdown .dropdown-item:hover,
.interface--dropdown .dropdown-item:focus {
  background-color: #f8f9fa;
}
.color__item {
  display: flex;
  align-items: center;
}
.color__item .color__box {
  width: 16px;
  height: 16px;
  min-width: 16px;
  display: inline-block;
  text-indent: -999px;
  margin-right: 10px;
}
.color__item .color__text {
  color: #6a7074;
  font-size: 16px;
  display: inline-block;
}
.color__item .bg-white {
  border: 1px solid #6a7074;
}
.bg-blue {
  background-color: #0638e7;
}
.bg-lightBlue {
  background-color: #0099ff;
}
.bg-purple {
  background-color: #621eb8;
}
.bg-orange {
  background-color: #ea5c0c;
}
.bg-red {
  background-color: #ed2c2c;
}
.bg-green {
  background-color: #219d35;
}
.bg-green-active {
  background-color: #1b9610;
}
.bg-mintGreen {
  background-color: #c2fff0;
}
.bg-black {
  background-color: #000;
}
.listingFinal--detail {
  margin: 20px 0;
}
.listingFinal__logo {
  width: 80px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  overflow: hidden;
}
.listingFinal__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
  Admin Settings Style
*/

/*
  Modal Style
*/

.modal .modal-content {
  border: none;
  border-radius: 0;
}
.modal .modal-title {
  font-size: 30px;
  font-weight: normal;
  color: #000;
  line-height: 32px;
}
.cross__circle-modal {
  width: 34px;
  height: 34px;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 34px;
  line-height: 34px;
  position: absolute;
  right: 10px;
  top: 12px;
  margin: 0;
}
.cross__circle-modal:not(:disabled):not(.disabled):active:focus,
.cross__circle-modal:not(:disabled):not(.disabled):active,
.cross__circle-modal:hover,
.cross__circle-modal:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.modal--uploadPhotos .modal-header {
  padding: 32px;
  border-bottom: 0;
  align-items: center;
}
.modal--uploadPhotos .modal-body {
  padding: 16px 32px 32px 32px;
}
.custom__file-modal {
  color: #000;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  border: 2px dashed #bcbab8;
  width: 100%;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.custom__file-modal input {
  display: none;
}
.cf__modal-para {
  color: #bcbab8;
  margin: 22px 0px 0px 0px;
}
.uploadPhoto--row {
  margin-bottom: 14px;
  display: flex;
}
.uploadPhoto--img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadPhoto--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploadPhoto--detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadPhoto--inner {
  width: 100%;
  padding-left: 12px;
  padding-right: 20px;
}
.uploadPhoto__name {
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  line-height: 20px;
  display: inline-block;
  word-break: break-word;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.uploadPhoto--progress {
  display: flex;
  align-items: center;
}
.uploadPhoto--progress .uploadPhoto__bar {
  display: inline-block;
  width: 100%;
  height: 5px;
  text-indent: -999px;
  background-color: #bcbab8;
  overflow: hidden;
  position: relative;
}
.uploadPhoto--progress .uploadPhoto__bar .bar__fill {
  background-color: #0638e7;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.uploadPhoto__text {
  font-size: 11px;
  color: #bcbab8;
  display: inline-block;
  margin-left: 10px;
}
.uploadPhoto__delete {
  color: #222222;
  font-size: 18px;
  display: inline-block;
  line-height: 22px;
}
.uploadPhoto--block .uploadPhoto--row:last-child {
  margin-bottom: 0;
}
.uploadPhoto--block {
  max-height: 310px;
  overflow-y: auto;
}
.uploadPhoto--block::-webkit-scrollbar {
  width: 1px;
}
.modal .modal-body::-webkit-scrollbar {
  width: 3px;
}
.modal .modal-body::-webkit-scrollbar-thumb {
  background: #bcbab8;
}
.modal__cross {
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #6a7074;
  padding: 0;
  line-height: 22px;
}
.modal__cross:not(:disabled):not(.disabled):active:focus,
.modal__cross:not(:disabled):not(.disabled):active,
.modal__cross:hover,
.modal__cross:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #6a7074;
}
.modal__cross-xl {
  font-size: 24px;
}
.modal-settings .close {
  display: none;
}
.modal-settings .modal-header {
  padding: 34px 28px;
  border-bottom: 0;
  align-items: center;
}
.modal-settings .modal-body {
  padding: 32px 28px;
}
.modalSetting-canvas {
  width: 100%;
  max-width: 510px;
  margin-left: auto;
  margin-right: auto;
}
.office-modal .modal-header {
  border-bottom: 0;
  padding: 34px 60px 28px 60px;
}
.office-modal .modal-body {
  padding: 0px 60px 30px 60px;
}
.modal--video {
  background-color: rgba(26, 27, 30, 0.25);
}
.modal--video .modal-content {
  background-color: transparent;
  height: 100%;
}
.modal--video .modal-body {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal--video-set {
  width: 100% !important;
  max-width: 540px;
  height: 300px !important;
  background-color: #6a7074;
}
.mv__cross {
  position: absolute;
  right: 32px;
  top: 0;
  color: #fff;
  padding: 0;
  background-color: transparent;
  border: none;
}
.mv__cross:not(:disabled):not(.disabled):active:focus,
.mv__cross:not(:disabled):not(.disabled):active,
.mv__cross:not(:disabled):not(.disabled):active,
.mv__cross:hover,
.mv__cross:focus {
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.listing--modal .modal-content {
  border-radius: 20px;
}
.listing--modal .modal-body {
  padding: 40px 40px 34px 40px;
}
.listing--modal .forms--group textarea.form-control {
  height: 104px;
}
.btn--canvas-sl .admin__button.disabled {
  background-color: #bcbab8 !important;
  border-color: #bcbab8 !important;
  color: #fff !important;
}
.listingModal--request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 420px;
}
.request__logo {
  width: 80px;
  height: 90px;
}
.para__separator {
  display: inline-block;
  width: 100%;
}
.set--modal .modal-content {
  border-radius: 20px;
}
.set--modal .modal-body {
  padding-bottom: 60px;
}
.set--modal .modal-title {
  font-size: 24px;
  font-weight: 600;
}
.app--modal .modal-content {
  padding: 40px;
  border-radius: 20px;
}
.app--modal .modal-dialog.modal-lg {
  max-width: 860px;
}
.app--modal .modal-header {
  padding: 0;
  border: none;
}
.app--modal .modal-title {
  line-height: 44px;
}
.app--modal .modal__cross {
  margin-top: 10px;
}
.app--modal .modal-body {
  padding: 0;
}
.text--field {
  padding: 10px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
  min-height: 48px;
}

/*
  Modal Style
*/

/*
  Terms & Privacy Style
*/

.terms {
  padding: 25px 0;
}
.terms--content {
  margin-top: 58px;
  width: 100%;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
}
.terms .dashboard__heading {
  margin-bottom: 40px;
}
/* .terms p.text__gray {
  text-align: justify;
} */
.terms__list li {
  list-style-type: decimal;
  padding-left: 10px;
  font-weight: 600;
  font-size: 18px;
  word-break: break-word;
}
.alpha__list,
.roman__list {
  padding-left: 20px;
}
.alpha__list li,
.roman__list li {
  color: #6a7074;
  padding-left: 10px;
  font-weight: normal;
  margin-bottom: 24px;
  font-size: 16px;
  word-break: break-word;
}
.alpha__list li {
  list-style-type: lower-alpha;
}
.roman__list li {
  list-style-type: lower-roman;
}
.terms__label {
  font-weight: bold;
  color: #1c1f20;
  display: inline-block;
  text-decoration: underline;
}
.terms__list li .text__gray {
  font-weight: normal;
  font-size: 16px;
}
.terms__list li .text__blue {
  font-weight: 600;
  font-size: 16px;
  word-break: break-word;
}
.terms__list li strong {
  font-weight: 600;
  font-size: 16px;
}

/*
  Terms & Privacy Style
*/

/*
  Empty Buildings/Listings Style
*/

.empty--box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}
.empty--box .emptyBox__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.empty--buidlings .emptyBox__img {
  width: 190px;
  height: 210px;
}
.empty--listings .emptyBox__img {
  width: 200px;
  height: 160px;
}
.em__para {
  font-size: 20px;
}

/*
  Empty Buildings/Listings Style
*/

/*
  Broker Landing Style
*/

.pearl--bg {
  background-color: #f2e9d0;
}
.component--top {
  padding-top: 184px;
  min-height: 510px;
}
.landing__heading {
  color: #15235c;
  font-size: 62px;
  font-weight: 700;
}
.landing__para {
  color: #1c1f20;
  font-size: 21px;
}
.schedule__btn:not([href]),
.schedule__btn-outline:not([href]),
.schedule__btn,
.schedule__btn-outline {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 18px;
  line-height: 24px;
  text-align: center;
  border-radius: 0;
  border-color: #15235c;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}
.schedule__btn:not([href]),
.schedule__btn {
  color: #f2e9d0;
  background-color: #15235c;
}
.schedule__btn-n:not([href]),
.schedule__btn-n {
  color: #fff;
}
.schedule__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn:not([href]):hover,
.schedule__btn:not([href]):focus,
.schedule__btn:not([href]):active,
.schedule__btn:not(:disabled):not(.disabled):active:focus,
.schedule__btn:not(:disabled):not(.disabled):active,
.schedule__btn:hover,
.schedule__btn:focus,
.schedule__btn:active {
  color: #f2e9d0;
  border-color: #08091c;
  background-color: #08091c;
  box-shadow: none;
}
.schedule__btn-outline:not([href]),
.schedule__btn-outline {
  color: #15235c;
  background-color: transparent;
}
.schedule__btn-outline:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn-outline:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn-outline:not([href]):hover,
.schedule__btn-outline:not([href]):focus,
.schedule__btn-outline:not([href]):active,
.schedule__btn-outline:not(:disabled):not(.disabled):active:focus,
.schedule__btn-outline:not(:disabled):not(.disabled):active,
.schedule__btn-outline:hover,
.schedule__btn-outline:focus,
.schedule__btn-outline:active {
  color: #f2e9d0;
  border-color: #15235c;
  background-color: #15235c;
  box-shadow: none;
}
.key__span {
  display: inline-block;
  line-height: 18px;
  margin-left: 8px;
}
.navbar-light .navbar__list-n .nav-link,
.navbar__list-n .nav-link,
.navSignin__btn {
  color: #1c1f20;
  font-size: 16px;
}
.navbar-light .navbar__list-n .nav-link:hover,
.navbar__list-n .nav-link:hover,
.navbar-light .navbar__list-n .nav-link.active,
.navbar__list-n .nav-link.active,
.navSignin__btn:not(:disabled):not(.disabled).active,
.navSignin__btn:not(:disabled):not(.disabled):active,
.navSignin__btn:hover,
.navSignin__btn:focus,
.navSignin__btn.active {
  color: #15235c;
}
.vt--demand-content {
  margin-top: 86px;
  min-height: 450px;
}
.broker--card {
  background-color: transparent;
  border: none;
  margin-bottom: 44px;
}
.broker--card .card-header {
  background-color: transparent;
  border: none;
  padding: 0;
}
.broker--card .btn-link {
  color: #15235c;
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;
}
.broker--card .btn-link .bc__icon {
  display: inline-block;
  line-height: 20px;
  margin-right: 12px;
  width: 22px;
  height: 22px;
}
.broker--card .btn-link:hover,
.broker--card .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.broker--card .card-body {
  position: relative;
  padding: 0px 0px 0px 34px;
  margin-top: 10px;
}
.broker--card .card-body:before {
  content: '';
  width: 2px;
  background-color: #15235c;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
}
.bc__paragraph {
  font-size: 16px;
  color: #555555;
}
.text__blue:not([href]),
.text__blue {
  color: #15235c;
}
.broker--card .card-body .bc__paragraph:last-child {
  margin-bottom: 0;
}
.broker--card-demo .card-body {
  display: none;
}
.broker--video {
  width: 100%;
  margin-bottom: -66px;
  position: relative;
  z-index: 5;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
}
.video-placement {
  position: relative;
}
.broker--video-placement {
  height: 440px;
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 0px 5px 15px #4644421f;
}
.broker--video-placement video,
.broker--video-placement iframe {
  height: 440px;
  border-radius: 0px 0px 15px 15px;
  border: none;
}
.broker--video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video--bar {
  pointer-events: none;
}
.vp__box {
  width: 62px;
  height: 62px;
  min-width: 62px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #5a585829;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  position: absolute;
  right: -14px;
  top: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: -9999px;
}
.vp__box:before {
  content: '';
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #0638e7;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.vp__box:after {
  content: '';
  width: 1px;
  height: 19px;
  display: inline-block;
  background-color: #e6e6e6;
  position: absolute;
  left: 13px;
  bottom: -16px;
  transform: rotate(28deg);
}
.vp__logo {
  width: 182px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #5a585829;
  border: 1px solid #e6e6e6;
  position: absolute;
  left: -72px;
  top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.vp__logo img {
  width: 151px;
  height: 39px;
  object-fit: initial;
}
.vp__logo:before {
  content: '';
  width: 1px;
  height: 20px;
  display: inline-block;
  background-color: #e6e6e6;
  position: absolute;
  right: 67px;
  top: -19px;
  transform: rotate(28deg);
}
.vp__secure {
  font-size: 21px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: #01061a;
  padding: 4px 35px;
  position: absolute;
  top: -76px;
  left: 83px;
}
.vp__secure .vp__secure-icon {
  display: inline-block;
  font-size: 22px;
  margin-right: 12px;
  line-height: 22px;
}
.vp__secure:after {
  content: '';
  width: 1px;
  height: 15px;
  display: inline-block;
  background-color: #e6e6e6;
  position: absolute;
  left: 34px;
  bottom: -14px;
  transform: rotate(28deg);
}
.heading__component-blue,
.heading__medium-blue {
  color: #15235c;
}
.info--component {
  padding-top: 250px;
  padding-bottom: 140px;
  background: url(/static/media/deals-bg.3cee0332.png) bottom no-repeat;
  position: relative;
  background-size: 100% 124%;
  background-position: left 0px bottom -550px;
}
.info--component:after {
  content: '';
  background: transparent linear-gradient(181deg, #fcfcfc 0%, #fcfcfc00 100%) 0% 0% no-repeat
    padding-box;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.info--content {
  margin-top: 110px;
  text-align: center;
  position: relative;
  z-index: 5;
}
.info--component .heading__component {
  position: relative;
  z-index: 5;
}
.info--block {
  margin-bottom: 20px;
}
.info--block .info--img {
  width: 200px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 34px;
  pointer-events: none;
}
.info--block .info--img img {
  width: 100%;
  height: 100%;
}
.info--block .heading__medium {
  margin-bottom: 20px;
}
.info--block .steps__paragraph {
  color: #1c1f20;
}
.opinion--component {
  padding-top: 100px;
  padding-bottom: 170px;
  background-color: #15235c;
}
.opinion--canvas {
  width: 100%;
  max-width: 704px;
  margin-left: auto;
  margin-right: auto;
}
.opinion--content {
  margin-top: 94px;
}
.opinion--block .opinion--img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
}
.opinion--block .opinion--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.opinion__para {
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  font-weight: normal;
}
.opinion__para:before {
  content: '';
  background: url(/static/media/quote-left.2ef33a9b.svg) center center no-repeat;
  background-size: 32px;
  width: 34px;
  height: 22px;
  position: absolute;
  left: -44px;
  top: -10px;
  color: #fff;
  display: inline-block;
}
.opinion__para:after {
  content: '';
  background: url(/static/media/quote-right.ec154a15.svg) center center no-repeat;
  background-size: 32px;
  width: 34px;
  height: 22px;
  position: absolute;
  right: -32px;
  bottom: 20px;
  color: #fff;
  display: inline-block;
}
.op--info {
  margin-top: 30px;
  margin-bottom: 30px;
}
.op--name {
  color: #f2e9d0;
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 20px;
  font-weight: normal;
}
.op__logo {
  display: inline-flex;
  align-items: center;
  width: 151px;
  height: 39px;
}
.personalize--block {
  background-color: #fff;
  max-width: 640px;
  width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 3px 15px #5c5b5a29;
  padding: 44px 48px 54px 48px;
  margin-top: -50px;
  margin-bottom: -128px;
  position: relative;
  z-index: 5;
}
.personalize--block .heading__component {
  color: #1c1f20;
  margin-bottom: 46px;
}
.personalize--block .forms--group {
  margin-bottom: 20px;
}
.personalize--block .forms--group .form-control {
  color: #6a7074;
}
.personalize--component {
  display: inline-block;
  width: 100%;
}
.footer-n {
  background-color: #08091c;
  padding-bottom: 70px;
  padding-top: 270px;
  display: inline-block;
  width: 100%;
}
.footer-n .footer--top {
  padding-bottom: 36px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
}
.footer--copyright {
  color: #f2e9d0;
  font-size: 16px;
}
.f__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.f__list li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  display: inline-block;
  margin-right: 66px;
}
.f__list-right li:last-child a {
  margin-right: 0;
}
.f__list li a:hover,
.f__list li a:focus {
  color: #f2e9d0;
}
.social__list-f li a {
  font-size: 22px;
  color: #f2e9d0;
}
.social__list-f li a:hover,
.social__list-f li a:focus {
  color: #fff;
}
.toggler--n span {
  background-color: #15235c;
}

/*
  Broker Landing Style
*/

/*
  Tenant Landing Style
*/

.figures--component {
  padding-top: 100px;
  padding-bottom: 380px;
}
.tenant--video {
  margin-top: -275px;
  margin-bottom: 275px;
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
}
.tenant--video-placement {
  height: 550px;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 5px 15px #4644421f;
}
.tenant--video-placement video,
.tenant--video-placement iframe {
  height: 550px;
  border: none;
  border-radius: 24px;
}
.tour--component {
  margin-top: -154px;
}
.tour--row {
  display: flex;
  overflow-x: auto;
}
.tour--row::-webkit-scrollbar {
  width: 0px;
}
.tour--col {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 23%;
}
.tour--box {
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 15px #5c5b5a29;
}
.tour--box .tourBox--img {
  width: 100%;
  height: 184px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tour--box .tourBox--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tour--box .tourBox--detail {
  padding: 14px 24px;
}
.tour--box .td__name {
  font-size: 18px;
  color: #15235c;
  font-weight: 500;
  margin-bottom: 10px;
}
.td__opt {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}
.td__opt .td__opt-icon {
  color: #6a7074;
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}
.td__opt .td__opt-text {
  color: #1c1f20;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
}
.schedule__btn-v:not([href]),
.schedule__btn-v {
  color: #15235c;
  background-color: #f2e9d0;
}
.schedule__btn-v:not([href]):not(:disabled):not(.disabled):active:focus,
.schedule__btn-v:not([href]):not(:disabled):not(.disabled):active,
.schedule__btn-v:not([href]):hover,
.schedule__btn-v:not([href]):focus,
.schedule__btn-v:not([href]):active,
.schedule__btn-v:not(:disabled):not(.disabled):active:focus,
.schedule__btn-v:not(:disabled):not(.disabled):active,
.schedule__btn-v:hover,
.schedule__btn-v:focus,
.schedule__btn-v:active {
  color: #15235c;
  background-color: #f9e8ba;
}
.figures--component .info--block {
  padding-left: 0;
  padding-right: 0;
}

/*
  Tenant Landing Style
*/

/*
  About Us Style
*/

.gif--component {
  margin-top: -68px;
}
.gif--box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 138px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 15px #5d5d5d29;
  margin-bottom: 20px;
  overflow: hidden;
}
.gif--box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gif--box .gif__text {
  font-size: 18px;
  color: #15235c;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  opacity: 0.4;
}
.strive--component {
  padding-top: 100px;
  padding-bottom: 100px;
}
.strive--content {
  padding-bottom: 100px;
  border-bottom: 1px solid #f2e9d0;
}
.team--component {
  padding-bottom: 200px;
}
.team--content {
  margin-top: 100px;
}
.team--box {
  text-align: center;
  margin-bottom: 74px;
}
.team--box .team--img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  background-color: #f2e9d0;
  pointer-events: none;
}
.team--box .team__name {
  color: #15235c;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}
.team--box .team__designation {
  color: #1c1f20;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0;
}
.personalize--block .forms--group textarea.form-control {
  height: 120px;
}
.personalize--block-about {
  margin-top: -122px;
  margin-bottom: 190px;
}

/*
  About Us Style
*/

/*
  Virtual Tour Tenant/Broker/StandBy
*/

.virtualTour--combine {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vt--container {
  width: 100%;
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.vt--combine {
  margin: 30px 0;
}
.vt--combine .dashboard__heading {
  margin-bottom: 32px;
  font-size: 24px;
}
.vt--combine .adminSettings--head {
  margin-bottom: 20px;
  align-items: flex-start;
}
.vt--combine .adminSettings--head .adminSettings__icon {
  font-size: 30px;
  color: #0638e7;
  margin-right: 12px;
  margin-top: 4px;
}
.vt--combine .adminSettings--head .adminSettings__text {
  font-size: 34px;
  color: #0638e7;
  font-weight: 500;
  line-height: 38px;
}
.vt--combine .schedule__paragraph {
  font-weight: normal;
  margin-bottom: 40px;
}
.vt__btn {
  min-width: 10px;
}
.vt__btn-icon {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 22px;
}
.video--player {
  margin-top: 70px;
  text-align: center;
}
.video__link {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #0638e7;
}
.video__link .video__link-text {
  text-decoration: underline;
  display: inline-flex;
}
.video__link .video__link-icon {
  font-size: 24px;
  margin-right: 12px;
  line-height: 24px;
  display: inline-flex;
}
.video__link:hover,
.video__link:focus {
  color: #0c30af;
}
.video__link:hover .video__link-text,
.video__link:focus .video__link-text {
  text-decoration: none;
}
.vt--footer {
  background-color: #0638e7;
  text-align: center;
  padding: 32px 0;
}
.vt--footer .container--large {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.vt--footer .vtFooter__icon {
  width: 44px;
  height: 44px;
  min-width: 44px;
  margin: 4px 14px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vt--footer .vtFooter__text {
  color: #fff;
  font-size: 18px;
  margin: 4px 0;
}
.vt--footer .vtFooter__text a {
  text-decoration: underline;
}
.meeting--block {
  border: 1px solid #bcbab8;
  border-radius: 10px;
  padding: 18px 24px;
  margin-bottom: 20px;
}
.meeting--block .meetingBlock--top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.meeting--block .meetingBlock__icon {
  color: #222222;
  font-size: 20px;
  width: 28px;
  height: 22px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting--block .heading__medium {
  line-height: 30px;
  margin-bottom: 0;
}
.meetingBlock__para {
  font-size: 14px;
  color: #6a7074;
  margin-bottom: 0;
  line-height: 22px;
}
.virtualTour--combine-alt {
  justify-content: flex-start;
}
.suite--box {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #f7f7f7;
  min-height: 54px;
  margin-bottom: 20px;
}
.suite--box .suite--box-f {
  display: flex;
  align-items: center;
  min-width: 30%;
}
.suite--box .suiteBox__icon {
  font-size: 18px;
  color: #6a7074;
  margin-right: 10px;
  display: inline-flex;
  line-height: 18px;
}
.suite--box .suiteBox__label {
  font-size: 18px;
  margin-bottom: 0;
  color: #6a7074;
  line-height: 20px;
}
.suiteBox__sf {
  display: flex;
  align-items: flex-end;
  padding-left: 6px;
  min-width: 70%;
}
.suiteBox__sf .suiteBox__no {
  font-size: 28px;
  margin-bottom: 0;
  color: #222222;
  line-height: 30px;
  display: flex;
  margin-left: auto;
}
.suiteBox__sf .suiteBox__text {
  font-size: 14px;
  margin-left: 6px;
  text-transform: uppercase;
}
.suite--canvas {
  margin-bottom: 18px;
}
.copyUrl__para {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.copyUrl__para .url__link {
  color: #0638e7;
  text-decoration: underline;
  margin-right: 4px;
}
.copyUrl__para .url__link:hover {
  text-decoration: none;
  color: #0c30af;
}
.team--canvas {
  padding-top: 36px;
  border-top: 1px solid rgba(188, 186, 184, 0.4);
}
.team--head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team--head .teamHead__img {
  width: 30px;
  height: 34px;
  min-width: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team--head .heading__medium {
  margin-bottom: 0;
  line-height: 26px;
}
.team--block {
  margin-bottom: 32px;
  text-align: center;
}
.team--block .teamBlock--img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}
.team--block .teamBlock__user {
  font-size: 15px;
  color: #222222;
  font-weight: normal;
  margin-bottom: 0px;
  word-break: break-word;
}
.team--block .teamBlock__email {
  font-size: 12px;
  color: #0638e7;
  text-decoration: none;
  word-break: break-word;
}
.team--block .teamBlock__email:hover,
.team--block .teamBlock__email:focus {
  color: #0c30af;
  text-decoration: underline;
}
.team--main {
  margin-top: 50px;
}

/*
  Virtual Tour Tenant/Broker/StandBy
*/

/*
  Record Voice Over
*/

.approve--video {
  color: #222222;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.av-mute {
  min-width: 24px;
  margin-right: 10px;
  font-size: 24px;
  display: inline-block;
  line-height: 22px;
}
.av__link:not([href]),
.av__link {
  color: #0638e7;
  text-decoration: underline;
}
.av__link:not([href]):hover,
.av__link:not([href]):focus,
.av__link:hover,
.av__link:focus {
  color: #0638e7;
  text-decoration: none;
}
.lb__arrow,
.lb__record {
  position: relative;
}
.arrow__icon {
  display: inline-flex;
  position: absolute;
  right: 20px;
  top: 17px;
}
.arrow__icon .icon-arrow-right {
  font-size: 14px;
}
.recording--overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.8);
}
.recording__heading {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 16px;
}
.recording__timer {
  font-size: 120px;
  color: #fff;
  font-weight: 700;
  line-height: 120px;
}
.lb__record-icon {
  min-width: 24px;
  font-size: 24px;
  color: #ee1a23;
  display: inline-flex;
  position: absolute;
  left: 12px;
  top: 12px;
  transition: all 0.2s ease;
}
.admin__button.lb__pause__resume .lb__record-icon {
  color: #ffffff;
}
.lb__record.active {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}
.lb__record.active .lb__record-icon,
.lb__record:hover .lb__record-icon,
.lb__record:focus .lb__record-icon,
.lb__record:active .lb__record-icon {
  color: #fff;
}
.key--details-record {
  min-height: 10px;
}
.comment--group textarea.form-control {
  min-height: 116px;
  height: auto;
}
.checkbox--set-dark {
  color: #1c1f20;
}
.upload__media {
  font-size: 14px;
}
.upload__media:before {
  width: 98px;
}
.upload__media .upload__button-icon {
  font-size: 14px;
}
.segment__option:not([href]),
.segment__option {
  width: 18px;
  height: 18px;
  color: #6a7074;
  font-size: 8px;
  border: 1px solid #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.segment__option:not([href]):hover,
.segment__option:not([href]):focus,
.segment__option:not([href]):active,
.segment__option:hover,
.segment__option:focus,
.segment__option:active {
  color: #fff;
  background-color: #222222;
}
.voiceRecord--block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.voiceRecord--box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 16px;
  width: 33.33%;
}
.record__voice:not([href]),
.record__voice {
  color: #0638e7;
  font-size: 20px;
  width: 48px;
  height: 48px;
  min-width: 48px;
  border: 1px solid #0638e7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
.record__voice .icon-play {
  margin-left: 4px;
}
.record__voice:not([href]):hover,
.record__voice:not([href]):focus,
.record__voice:not([href]):active,
.record__voice:hover,
.record__voice:focus,
.record__voice:active {
  color: #fff;
  background-color: #0638e7;
}
.record__time {
  color: #6a7074;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 16px;
  min-width: 40px;
  display: inline-block;
}
.record__trash {
  color: #6a7074;
  font-size: 16px;
  display: inline-flex;
}
.record__trash:hover,
.record__trash:focus {
  color: #6a7074;
}
.comment--attach {
  padding-top: 28px;
  border-top: 1px solid #bcbab8;
  display: flex;
}
.voice--info {
  min-width: 100px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
}
.voice--info .voiceInfo__name {
  color: #1c1f20;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.voice--info .voiceInfo__time {
  color: #0638e7;
  font-size: 14px;
}
.ca--group {
  border: 1px solid #bcbab8;
  padding: 13px;
  min-height: 84px;
  border-radius: 10px;
}
.ca--group .ca__para {
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.ca--group-main .comment--group textarea.form-control {
  min-height: 84px;
  height: auto;
}
.ca--cover {
  padding: 4px;
  height: 48px;
  border: 1px solid #bcbab8;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.ca--cover .ca--cover-img {
  height: 38px;
  min-width: 50%;
  width: 50%;
  margin-right: 10px;
}
.ca--cover .ca--cover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ca--cover .ca--cover-name {
  color: #6a7074;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
}
.ca--cover-sm {
  height: 40px;
}
.ca--cover-sm .ca--cover-img {
  height: 30px;
}
.ca--cover-sm .ca--cover-name {
  font-size: 11px;
  line-height: 14px;
}
.ca__btn-sm:not([href]),
.ca__btn-sm {
  min-height: 40px;
  min-width: 50px;
  padding: 8px;
  line-height: 22px;
  width: 100%;
}
.ca__btn.disabled {
  background-color: transparent;
  border: 1px solid #bcbab8;
  color: #bcbab8;
}
.ca--cover,
.ca__btn-canvas {
  width: 50%;
  position: relative;
}
.row--space-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.row--space-5 .col-12,
.row--space-5 .col {
  padding-left: 5px;
  padding-right: 5px;
}
.ca--cover .cover__cross {
  position: absolute;
  top: -7px;
  right: -6px;
  font-size: 15px;
  cursor: pointer;
  line-height: 16px;
  z-index: 5;
}

/*
  Record Voice Over
*/

/*
  Live Tour Style
*/

.liveTour--page {
  display: flex;
  flex-direction: column;
  background-color: #1c1f20;
  height: 100vh;
}
.liveTour--page .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.liveTour--wide {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.liveTour--top {
  box-shadow: 0px 4px 16px rgba(1, 2, 19, 0.18);
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
  background-color: #1c1f20;
  position: relative;
  z-index: 555;
  flex: 1 1;
  width: 100%;
}
.liveTour--video video {
  border: none;
  border-radius: 0px 0px 20px 20px;
}
.img--cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.liveTour--content {
  background-color: #1c1f20;
}
.liveTour--content-broker {
  position: relative;
  min-height: 212px;
}
.users--content {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 10px;
}
.users--row {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 114px;
}
.users--row::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.users--row::-webkit-scrollbar-thumb {
  background-color: #888;
}
.users--row::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
.video__control:not([href]),
.video__control {
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 10px;
  color: #1c1f20;
  background-color: #bcbab8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video__control:not([href]):hover,
.video__control:not([href]):focus,
.video__control:hover,
.video__control:focus {
  color: #1c1f20;
  background-color: #fff;
}
.users--row .users--row-inner {
  display: flex;
  align-items: center;
}
.users--block-md {
  max-width: 104px;
}
.users--block {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 2px;
  padding: 6px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  direction: ltr;
}
.users--block .users--box {
  border: 2px solid transparent;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 11px rgba(1, 2, 19, 0.5);
  margin-left: auto;
  margin-right: auto;
}
.users--block-md .users--box {
  width: 94px;
  height: 94px;
  min-width: 94px;
}
.users--block .users--box.active {
  border-color: #fffbee;
  box-shadow: 0px 0px 5px #fff9e9;
}
.users--row-left .users--block-sm {
  margin-right: 14px;
}
.users--row-right .users--block-sm {
  margin-left: 14px;
}
.users--block-sm .users--box {
  width: 76px;
  height: 76px;
  min-width: 76px;
}
.users--block .users--alt {
  background-color: #4c5052;
}
.users--alt-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2px 4px;
}
.users--alt .usersAlt__heading {
  font-size: 24px;
  color: #1c1f20;
  text-transform: uppercase;
  line-height: 28px;
  margin-bottom: 4px;
  font-weight: normal;
}
.users--alt .usersAlt__subHeading {
  font-size: 11px;
  color: #1c1f20;
  line-height: 13px;
  margin-bottom: 0px;
  white-space: normal;
  word-break: break-word;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.users--ov {
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 57, 55, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: -8px;
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
}
.users--ov .users--ov-name {
  color: #fff;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.users--ov .users--ov-icon {
  color: #fff;
  font-size: 11px;
  line-height: 12px;
  margin-right: 4px;
  min-width: 14px;
}
.users--block .users__name {
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  display: inline-block;
  margin-top: 10px;
  white-space: normal;
}
.users--block .users__voice {
  color: #fff;
  font-size: 11px;
  border-radius: 18px;
  width: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(58, 57, 55, 0.8);
  position: absolute;
  top: -27px;
  left: 0;
  right: 0;
  margin: auto;
}
.users--block .users__voice:hover,
.users--block .users__voice:focus {
  color: #fff;
  background-color: rgba(58, 57, 55, 1);
}
.liveTour__list {
  display: flex;
  align-items: center;
}
.liveTour__list::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.liveTour__list li a {
  margin-left: 15px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 58px;
  min-height: 55px;
  padding: 12px 0;
  border-bottom: 4px solid transparent;
}
.liveTour__list li:first-child a {
  margin-left: 0;
}
.liveTour__list li:last-child a {
  margin-right: 0;
}
.lt__list-box .lt__list-text {
  color: #bcbab8;
  font-size: 14px;
  display: inline-flex;
  line-height: 20px;
  margin-left: 10px;
  white-space: nowrap;
}
.lt__list-box .lt__list-icon {
  color: #bcbab8;
  font-size: 16px;
  display: inline-flex;
  line-height: 20px;
}
.lt__list-box.link__active,
.lt__list-box.active {
  border-bottom-color: #fff;
}
.lt__list-box.link__active .lt__list-icon,
.lt__list-box:hover .lt__list-text,
.lt__list-box:hover .lt__list-icon,
.lt__list-box:focus .lt__list-text,
.lt__list-box:focus .lt__list-icon,
.lt__list-box.active .lt__list-text,
.lt__list-box.active .lt__list-icon {
  color: #fff;
}
.tour__btn {
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  margin-left: 6px;
  white-space: nowrap;
}
.tour__btn:not(:disabled):not(.disabled).active,
.tour__btn:not(:disabled):not(.disabled):active:focus,
.tour__btn:not(:disabled):not(.disabled):active,
.tour__btn:hover,
.tour__btn:focus,
.tour__btn.active {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.tour--btn-canvas {
  max-width: 384px;
  overflow-x: auto;
  align-items: center;
  display: none;
}
.tour__btn .tour__btn-desktop {
  display: none;
}
.lt__list-box .active--content {
  display: none;
}
.lt__list-box.active .active--content {
  display: flex;
}
.tour--btn-canvas::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.lt__list-box.link__active .tour--btn-canvas {
  display: flex;
}
.liveTour--content-tenant .liveTour--options {
  justify-content: center;
  min-width: 200px;
}
.liveTour--options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  position: relative;
}
.liveTour--options li a {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 14px;
  margin-right: 14px;
}
.liveTour--options li:first-child a {
  margin-left: 0;
}
.liveTour--options li:last-child a {
  margin-right: 0;
}
.liveTourOpt__box .liveTourOpt__icon {
  font-size: 16px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liveTourOpt__box .liveTourOpt__icon .icon-share-screen {
  font-size: 18px;
  font-weight: 600;
}
.liveTourOpt__box-gray .liveTourOpt__icon {
  color: #161d28;
  background-color: #d5d9e0;
}
.liveTourOpt__box-red .liveTourOpt__icon {
  color: #fff;
  background-color: #e7062e;
}
.liveTourOpt__box-blue .liveTourOpt__icon {
  color: #fff;
  background-color: #0638e7;
}
.liveTourOpt__box-gray:hover .liveTourOpt__icon,
.liveTourOpt__box-gray:focus .liveTourOpt__icon {
  background-color: #aaabad;
}
.liveTourOpt__box-red:hover .liveTourOpt__icon,
.liveTourOpt__box-red:focus .liveTourOpt__icon {
  background-color: #bd0727;
}
.liveTourOpt__box-blue:hover .liveTourOpt__icon,
.liveTourOpt__box-blue:focus .liveTourOpt__icon {
  background-color: #0f36bf;
}
.liveTourOpt__box .liveTourOpt__text {
  font-size: 10px;
  color: #fff;
  margin-top: 8px;
  white-space: nowrap;
}
.liveTour--mobile {
  background-color: #fff;
  padding-bottom: 24px;
  margin-bottom: 0;
}
.liveTour--mobile .tourMobile-canvas {
  width: 100%;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.tourMobile--block {
  border-bottom: 1px solid #bcbab8;
  padding: 40px 0;
  margin-bottom: 0;
}
.tourMobile--main .tourMobile--block:last-child {
  border-bottom: none;
}
.tourMobile--block .tf__icon {
  min-width: 38px;
  font-size: 34px;
  line-height: 34px;
}
.tourMobile--block .form__para {
  font-size: 14px;
}
.liveTour--placement {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.placement--centered {
  display: flex;
  align-items: center;
}
.placement--centered-h {
  justify-content: center;
}
.placement--block {
  padding: 34px 44px;
  border-radius: 28px;
  background-color: #fff;
  display: inline-block;
  width: 100%;
  max-width: 420px;
}
.placement__logo {
  margin-bottom: 24px;
}
.placement__heading {
  color: #1c1f20;
  font-size: 50px;
  line-height: 64px;
  font-weight: normal;
  margin-bottom: 26px;
}
.placement--location {
  display: flex;
  align-items: center;
}
.placement--location .placementLocation__icon {
  font-size: 22px;
  color: #6a7074;
  display: inline-flex;
  margin-right: 8px;
}
.placement--location .placementLocation__text {
  font-size: 16px;
  color: #6a7074;
  display: inline-block;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.users--content-tenant {
  padding-bottom: 0;
}
.liveTour--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  text-align: center;
  z-index: 5;
  border: none;
  transition: height 0.35s ease;
}
.liveTour--card .card-header,
.liveTour--card .card-body {
  padding: 0;
  background-color: transparent;
  border: none;
}
.liveTour--card .card-body {
  margin-top: 10px;
}
.liveTour--card .card-header .btn-link {
  padding: 4px;
  line-height: 10px;
  color: rgba(255, 255, 2550, 0.3);
  font-size: 9px;
  border: none;
}
.liveTour--card .card-header .btn-link:hover,
.liveTour--card .card-header .btn-link:focus {
  color: rgba(255, 255, 2550, 0.3);
  text-decoration: none;
  box-shadow: none;
  outline: none;
}
.users--content-tenant .users--row {
  padding-top: 26px;
}
.users--broker {
  position: absolute;
  right: 40px;
  top: 40px;
  margin: 0;
  padding: 0;
}
.users--block-lg {
  max-width: 202px;
  width: 100%;
}
.users--block-lg .users--box {
  width: 190px;
  height: 190px;
  min-width: 190px;
}
.users--broker .users--ov {
  top: auto;
  top: initial;
  bottom: -34px;
  max-width: 170px;
}
.row--feature {
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
  justify-content: center;
}
.row--feature .col--feature {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  max-width: 50%;
}
.feature--block {
  padding: 32px 15px;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  min-height: 230px;
}
.feature--block .featureBlock__icon {
  font-size: 34px;
  color: #0638e7;
  display: inline-block;
  margin-bottom: 18px;
}
.feature--block .featureBlock__heading {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0;
  word-break: break-word;
}
.feature--block-address h4.featureBlock__heading {
  margin-top: 24px;
  width: 100%;
}
.liveTour--top-broker .feature--block-address h4.featureBlock__heading {
  overflow: hidden;
}
.featureBlock--sf {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featureBlock--sf .sf__digit {
  font-size: 36px;
  color: #222222;
  font-weight: normal;
  white-space: nowrap;
}
.featureBlock--sf .sf__word {
  font-size: 20px;
  margin-left: 4px;
}
.liveTour--custom.liveTour--gallery {
  background-color: #050505;
}
.liveTour--custom,
.liveTour--placement {
  padding-top: 25px;
  padding-bottom: 25px;
}
.liveTour--custom .liveTour--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.liveTour--head {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.liveTour__heading {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: right;
}
.liveTour--gallery .liveTour--head {
  margin-bottom: 32px;
  display: none;
}
.liveTour--gallery-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.liveTour--gallery-content::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}
.liveTour--gallery-content::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.4);
}
.liveTour--gallery-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1;
  max-height: 100%;
  margin-left: -9px;
  margin-right: -9px;
}
.liveTour--gallery-row .liveTour--gallery-col {
  padding-left: 9px;
  padding-right: 9px;
  width: 25%;
  position: relative;
}
.liveTour--gallery-row .liveTour--gallery-col .gallery__trash {
  position: absolute;
  right: 0;
  top: -8px;
  opacity: 0;
}
.liveTour--gallery-row .liveTour--gallery-col:hover .gallery__trash {
  opacity: 1;
}
.gallery__trash {
  color: #222;
  font-size: 9px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}
.liveTour--gallery-img {
  width: 100%;
  height: 128px;
  margin-bottom: 18px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.gallery__btn-prev,
.gallery__btn-next {
  justify-content: space-between;
}
.gallery__btn .gallery__btn-icon {
  font-size: 9px;
  line-height: 8px;
  display: inline-flex;
}
.gallery__btn .gallery__btn-icon i.icon-chevron-left {
  margin-right: 10px;
}
.gallery__btn .gallery__btn-icon i.icon-chevron-right {
  margin-left: 10px;
}
.liveTour--gallery .gallery--large-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: -25px;
  bottom: -25px;
  height: auto;
}
.liveTour--gallery-large,
.liveTour--gallery-expanded {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: #050505;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liveTour--gallery-large img {
  object-fit: contain;
}
.liveTour--gallery-expanded img {
  object-fit: cover;
}
.gallery__cross {
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 5;
  display: inline-flex;
  cursor: pointer;
  padding: 4px;
}
.liveTour--building-top .dashboard__heading {
  color: #0638e7;
  font-size: 14px;
  margin-bottom: 0;
}
.liveTour__logo {
  min-width: 88px;
  width: 88px;
  height: 23px;
}
.specs__list li {
  color: #bcbab8;
  font-size: 12px;
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
  line-height: 14px;
}
.specs__list li:before {
  content: '';
  width: 1px;
  background-color: #bcbab8;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.building--widget {
  padding: 6px 9px 7px 9px;
  min-height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f7f7f7;
}
.building--widget .bw__icon {
  color: #222222;
  font-size: 13px;
  margin-right: 5px;
  min-width: 14px;
  display: inline-block;
  margin-top: 4px;
}
.building--widget .bw__heading {
  color: #222222;
  font-size: 9px;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
}
.building--widget .bw__para {
  color: #6a7074;
  font-size: 7px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: initial;
  word-break: break-word;
}
.building--widget .bw__arrow {
  font-size: 4px;
  color: #6a7074;
  padding: 3px 2px;
  line-height: 10px;
  display: inline-block;
  margin-top: 4px;
}
.building--widget .bw__detail {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.liveTour--building-top,
.liveTour--building-main {
  margin-top: 16px;
}
.liveTour--building {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.liveTour--building::-webkit-scrollbar {
  width: 0;
}
.certification--widget .bw__heading {
  line-height: 11px;
  margin-bottom: 0;
}
.certification--widget .bw__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
/* .liveTour--details .liveTour--container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 980px;
} */
.amenities--widget {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.amenities--widget .bw__heading {
  white-space: normal;
  overflow: visible;
  overflow: initial;
  text-align: center;
  line-height: 12px;
  margin-bottom: 0;
  font-size: 9px;
}
.liveTour__logo {
  margin-right: 14px;
}
/* .liveTour--listing .container--fixed {
  height: 100%;
  overflow: hidden;
} */
.liveTour--listing {
  background-color: #fcfcfc;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 55;
}
.liveTour--listing .container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.ls--right {
  width: 100%;
}
.ls--top {
  margin: 10px 0px 11px 0px;
  min-height: 42px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 24px;
}
.ls--head {
  display: flex;
  align-items: center;
}
.ls--head .ls__icon {
  font-size: 24px;
  margin-right: 12px;
  display: inline-flex;
}
.ls--head .ls__heading {
  font-size: 24px;
  color: #1c1f20;
  font-weight: normal;
  margin-bottom: 0;
}
.ls--sort {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ls__label {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 10px;
  min-width: 70px;
}
.ls__list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.ls__list li a {
  font-size: 14px;
  color: #bcbab8;
  margin-right: 14px;
  display: inline-block;
  white-space: nowrap;
}
.ls__list li a:hover,
.ls__list li a:focus,
.ls__list li a.active {
  color: #0638e7;
  text-decoration: underline;
}
.ls__list::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.ls__list::-webkit-scrollbar-thumb {
  background: #bcbab8;
  border-radius: 4px;
}
.ls--bottom {
  display: flex;
  align-items: center;
  min-height: 140px;
  position: relative;
}
/* .ls--bottom:after {
  content: '';
  background: linear-gradient(95.75deg, #1C1F20 2.38%, rgba(28, 31, 32, 0) 86.57%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  width: 50px;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
} */
.ls--opt {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
}
.ls--opt .search__cross {
  position: absolute;
  right: -8px;
  top: 0;
  bottom: 0;
  font-size: 18px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.ls__search-icon {
  font-size: 30px;
  color: #0638e7;
  min-width: 30px;
  display: inline-flex;
  margin-right: 32px;
  cursor: pointer;
}
.ls--opt .ls__search-result {
  font-size: 16px;
  color: #6a7074;
  font-weight: 500;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.ls--listings {
  display: flex;
  padding-bottom: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  cursor: move;
}
.ls--listings::-webkit-scrollbar {
  width: 10px;
  height: 26px;
}
.ls--listings::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 20px;
}
.ls--listings::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}
.ls--row {
  margin-left: -9px;
  margin-right: -9px;
  flex-wrap: nowrap;
}
.ls--row .ls--col {
  padding-left: 9px;
  padding-right: 9px;
}
.ls--box {
  border: 1px solid rgba(188, 186, 184, 0.5);
  box-sizing: border-box;
  border-radius: 12px;
  min-height: 106px;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  max-width: 320px;
}
.ls--box .ls--box-img {
  width: 78px;
  min-width: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
}
.ls--box .ls--box-detail {
  padding: 12px;
  overflow: hidden;
  max-width: 236px;
  width: 100%;
}
.ls--box .ls--box-detail .ls__listing-heading {
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 12px;
}
.ls--box .al--listing .al__icon {
  font-size: 14px;
  display: inline-flex;
  margin-right: 8px;
}
.ls--box .al--listing .al__text {
  font-size: 12px;
  min-width: 10px;
  margin-right: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ls--box .al--listing-combine .al--listing .al__text {
  font-size: 16px;
}
.ls--box .al--listing {
  margin-bottom: 10px;
}
.ls--box .al--listing-combine .al--listing {
  margin-bottom: 0;
  width: 100%;
}
.ls__cross:not([href]),
.ls__cross {
  font-size: 16px;
  color: #222222;
  display: inline-flex;
  cursor: pointer;
}
.ls--top .ls__cross {
  position: absolute;
  right: 0;
  top: 14px;
}
.search__block {
  display: flex;
  margin-bottom: 14px;
}
.listing--group {
  position: relative;
  margin-bottom: 10px;
}
.listing--group .form-control {
  border: 1px solid #6a7074;
  border-radius: 11px;
  opacity: 0.5;
  color: #6a7074;
  font-size: 18px;
  font-weight: normal;
  padding: 8px 80px 8px 20px;
  height: calc(1.89em + 0.75rem + 2px);
  min-height: 48px;
}
.listing--group .enter__btn {
  position: absolute;
  right: 10px;
  top: 11px;
  background-color: #6a7074;
  border-radius: 4px;
  opacity: 0.2;
  font-size: 14px;
  color: #fcfcfc;
  padding: 3px 8px;
  min-width: 66px;
  min-height: 26px;
  border: none;
}
.listing--group .form-control:focus ~ .enter__btn {
  opacity: 1;
  background-color: #0638e7;
  color: #fff;
}
.listing--group .form-control:focus {
  opacity: 1;
}
.cancel__link:not([href]),
.cancel__link {
  color: #0638e7;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}
.cancel__link:not([href]):hover,
.cancel__link:not([href]):hover,
.cancel__link:hover,
.cancel__link:focus {
  color: #0638e7;
  text-decoration: none;
}
.search__block .ls__search-icon {
  margin-top: 9px;
}
.liveTour--combine {
  display: flex;
  flex: 1 1;
  height: 100%;
}
.liveTour--combine .tour-tile {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.liveTour--combine-even .tour-tile {
  margin-left: -16px;
}
.liveTour--combine-odd .tour-tile {
  margin-right: -16px;
}
.location--head .liveTour__logo {
  width: 151px;
  height: 39px;
  flex-shrink: 0;
}
.liveTour--feature {
  background-color: #fcfcfc;
  box-shadow: 5px 4px 20px rgba(132, 131, 131, 0.25);
  width: 50%;
  display: flex;
  align-items: flex-start;
  position: static;
  z-index: 5;
  flex-grow: 1;
  flex-basis: auto;
}
.liveTour--combine-even .liveTour--feature {
  border-radius: 0px 20px 20px 0px;
}
.liveTour--combine-odd .liveTour--feature {
  border-radius: 20px 0px 0px 20px;
}
.tourPre--top .liveTour--feature {
  padding-bottom: 20px !important;
}
.liveTour--feature .liveTour--container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.liveTourFeature--component {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1;
  min-height: 100%;
  max-height: 100%;
}
.location--head {
  display: flex;
  align-items: center;
}
.location--head .location__destination {
  display: inline-flex;
  overflow: hidden;
  padding-left: 14px;
  border-left: 1px solid #bcbab8;
  flex-shrink: 0;
}
.location--head .location__destination img {
  object-fit: contain;
}
.location--content {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.locationContent__heading {
  color: #1c1f20;
  font-size: 36px;
  font-weight: 600;
}
.location--box {
  display: flex;
  margin-bottom: 16px;
}
.location--box .locationBox__img {
  width: 138px;
  height: 172px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 20px;
}
.location--box .locationBox--detail {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.location--box .locationBox__heading {
  color: #1c1f20;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 18px;
  word-break: break-word;
}
.location--box .locationBox--badge {
  display: flex;
  flex-wrap: wrap;
}
.location__badge {
  color: #1c1f20;
  font-size: 16px;
  min-height: 34px;
  border-radius: 20px;
  padding: 5px 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(6, 56, 231, 0.06);
  margin: 0px 10px 10px 0px;
}
.locationContent__list {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.scroll--custom::-webkit-scrollbar,
.locationContent__list::-webkit-scrollbar {
  width: 4px;
}
.scroll--custom::-webkit-scrollbar-thumb,
.locationContent__list::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.locationContent__list li:last-child .location--box {
  margin-bottom: 0;
}
.featureComponent--home-lg .location--box,
.featureComponent--home-md .location--box {
  margin-bottom: 32px;
}
.featureComponent--home-lg .location--box .locationBox__img,
.featureComponent--home-md .location--box .locationBox__img {
  width: 60px;
  height: 76px;
  border-radius: 12px;
  margin-right: 14px;
}
.featureComponent--home-lg .location--box .locationBox__heading,
.featureComponent--home-md .location--box .locationBox__heading {
  font-size: 20px;
  margin-bottom: 14px;
}
.featureComponent--home-md .location--content {
  margin-top: 60px;
}
.featureComponent--home-md .locationContent__list {
  margin-top: 28px;
}
.featureComponent--home-sm .location--box {
  margin-bottom: 24px;
}
.featureComponent--home-sm .location--box .locationBox__img {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 14px;
}
.featureComponent--home-sm .location--box .locationBox__heading {
  font-size: 16px;
  margin-bottom: 12px;
}
.featureComponent--home-sm .location--content {
  margin-top: 34px;
}
.featureComponent--home-sm .locationContent__list {
  margin-top: 26px;
}
.featureComponent--home-sm .location__badge {
  font-size: 14px;
  min-height: 26px;
  padding: 4px 12px;
}
.liveTour--combine-even .liveTour--feature .liveTour--container {
  padding-left: 24% !important;
  padding-right: 32px !important;
}
.liveTour--combine-odd .liveTour--feature .liveTour--container {
  padding-right: 24% !important;
  padding-left: 7% !important;
}
.liveTour--combine .liveTour--feature .liveTour--container {
  min-height: 100%;
  max-height: 100%;
}
.featureComponent--main {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.detailComp__list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1;
  margin-top: 34px;
}
.details__heading {
  color: #1c1f20;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.detailComp--box {
  display: flex;
  margin-bottom: 32px;
}
.detailComp--box .detailCompBox__icon {
  color: #0638e7;
  font-size: 40px;
  width: 40px;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  margin-right: 24px;
}
.detailComp--box .detailCompBox__text {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.detailComp--box .detailCompBox__text .text__md-gray {
  line-height: 20px;
}
.detailComp--box .detailCompBox__text .heading__medium {
  line-height: 30px;
  margin-bottom: 0;
  word-break: break-word;
}
.detailComp__list li:last-child .detailComp--box {
  margin-bottom: 0;
}
.detailComp__list li:last-child .detailComp--box .heading__medium {
  line-height: 34px;
}
.text__md-gray {
  font-size: 16px;
  color: #6a7074;
}
.featureComponent--detail {
  padding: 40px 0;
}
.ls--box.ls--nationLanding .ls--box-img {
  font-size: 20px;
  color: #fff;
  background-color: #fd9142;
}
.ls--box.ls--nationLanding .ls--box-detail {
  display: flex;
  align-items: center;
  min-width: 104px;
}
.ls--box.ls--nationLanding .ls--box-detail .ls__listing-heading {
  white-space: normal;
  line-height: 20px;
  margin-bottom: 0;
}

/*
  Live Tour Style
*/

/*
  Virtual Tour Share Style
*/

.vt--share {
  display: flex;
  justify-content: center;
}
.vt--share.share--realistic {
  align-items: flex-start;
}
.share--content {
  padding: 70px 0;
}
.share--middle {
  margin-left: auto;
  margin-right: auto;
}
.share__img {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  display: inline-block;
  width: 148px;
  height: 173px;
  margin-bottom: 40px;
  overflow: hidden;
}
.share--options {
  position: relative;
  width: 100%;
  max-width: 238px;
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}
.share--options:before {
  content: '';
  background: url(/static/media/lines.b18deb19.svg) left top no-repeat;
  position: absolute;
  left: -16px;
  top: -26px;
  display: inline-block;
  width: 40px;
  height: 330px;
  pointer-events: none;
}
.share--options .share--row:nth-of-type(1) {
  min-height: 144px;
}
.share--options .share--row:nth-of-type(2) {
  min-height: 164px;
}
.share--row {
  display: flex;
  position: relative;
}
.share--row .share--detail {
  padding-left: 12px;
  padding-top: 6px;
}
.share--row .share__heading {
  font-size: 16px;
  color: #222222;
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 4px;
}
.share--row .share__paragraph {
  font-size: 12px;
  color: #bcbab8;
}
.digit__box {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0638e7;
}

/*
  Virtual Tour Share Style
*/

/*
  Notfound Link Expired Style
*/

.sec--vertical {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.notfound--content {
  padding: 40px 0;
}
.notfound__img {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  display: inline-block;
  width: 368px;
  height: 260px;
  margin-bottom: 60px;
  overflow: hidden;
}
.linkEx__img {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  display: inline-block;
  width: 168px;
  height: 175px;
  margin-bottom: 40px;
  overflow: hidden;
}
.linkEx--content {
  padding-top: 40px;
  padding-bottom: 40px;
}
.linkEx--content-tenant {
  padding-bottom: 20px;
}
.md--canvas {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}
.sec--vertical-permanent {
  min-height: 100vh;
}
.sec--vertical-permanent .sec--vertical-middle {
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
}

/*
  Notfound Link Expired Style
*/

/*
  Right Sidebar Style
*/

.drawer--right {
  width: 428px;
  padding: 90px 68px 20px 68px;
  background: linear-gradient(89.65deg, #173cdf 18.24%, #3273e9 91.95%);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  min-height: 100vh;
  overflow-y: auto;
  margin-right: -428px;
  transition: all 0.2s ease;
}
.drawer--right-show {
  margin-right: 0;
}
.drawer--right::-webkit-scrollbar {
  width: 6px;
}
.drawer--right::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}
.drawer__cross {
  color: #fff;
  font-size: 18px;
  padding: 0;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 30px;
}
.drawer__cross:not(:disabled):not(.disabled):active,
.drawer__cross:hover,
.drawer__cross:focus,
.drawer__cross:active {
  color: #fff;
  background-color: transparent;
  border: none;
}
.drawer--card {
  border: none;
  background-color: transparent;
}
.accordian--first {
  border-top: 1px solid #fff;
}
.drawer--card .card-header {
  padding: 0;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  position: relative;
  margin-bottom: 0;
}
.accordion > .drawer--card > .card-header {
  margin-bottom: 0;
}
.card--icon > .card-header:after {
  content: '';
  background: url(/static/media/checvon-bottom.f739ebc8.svg) right center no-repeat;
  display: inline-block;
  background-size: 14px;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 14px;
  transition: transform 0.3s ease;
  pointer-events: none;
}
.card--active-first > .card-header:after,
.card--active-second > .card-header:after,
.card--active-third > .card-header:after {
  background: url(/static/media/checvon-top.f76c3723.svg) right center no-repeat;
  display: inline-block;
  background-size: 14px;
  width: 14px;
  height: 14px;
  transition: transform 0.3s ease;
  pointer-events: none;
}
.drawer--card .card-header .btn-link {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 18px 8px 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 24px;
  text-align: left;
}
.drawer--card .card-body {
  padding: 0;
}
.drawer--inner {
  padding-left: 15px;
}
.agent-icon {
  max-width: 41px;
  max-height: 41px;
}
/*
  Right Sidebar Style
*/

/*
  Buildings New Style
*/
.font-w-400 {
  font-weight: 400;
}
.font-w-500 {
  font-weight: 400;
}
.font-w-600 {
  font-weight: 400;
}
.font-w-700 {
  font-weight: 400;
}
.dashboard--content-new {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 100vh;
  overflow-y: auto;
}
.dashboard--content-new .dashboard--off {
  min-height: calc(100vh - 40px);
}
.min-h-calculated {
  min-height: calc(100vh - 40px);
}
.bg-primary-new {
  background: #071033;
}
.text-primary-new {
  color: #071033;
}
.dashboard--content-new .dashboard--canvas {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.dashboard--off {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
}
.bg--gray {
  background-color: #f5f5f5;
}
.white--rounded-box {
  background-color: #fff;
  border-radius: 20px;
  padding: 32px 42px;
}
.dashboard--bar {
  min-height: 88px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-shrink: 0;
}
.dashbaord--opt {
  display: flex;
  align-items: center;
}
.form--group-alt {
  position: relative;
}
.form--group-alt .form-control {
  color: #1c1f20;
  font-size: 16px;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px 13px;
  height: auto;
  min-height: 48px;
  line-height: 19px;
}
.form--group-alt .search__icon {
  color: #6a7074;
  font-size: 16px;
  position: absolute;
  left: 16px;
  top: 16px;
  pointer-events: none;
  display: inline-flex;
}
.form--group-alt .form-control:focus {
  border-color: #0638e7;
}
.form--group-alt .form-control::-webkit-input-placeholder {
  color: #1c1f20;
}
.form--group-alt .form-control::-ms-input-placeholder {
  color: #1c1f20;
}
.form--group-alt .form-control::placeholder {
  color: #1c1f20;
}
.search--group-alt .form-control {
  padding-left: 44px;
}
.button__radius:not([href]),
.button__radius {
  border-radius: 10px;
}
.dashbaord--opt .admin__button {
  margin-left: 20px;
}
.dropdown--radius .dropdown-toggle,
.select-radius .form-control {
  border-radius: 10px;
}
.dropdown--radius .dropdown-toggle .dropdown-menu {
  border-radius: 10px;
}
.dashbord__heading-md {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}
.al--sm-alt,
.al--lg-alt,
.al--block-alt {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #bcbab8;
  cursor: pointer;
  position: relative;
}
.al--sm-alt:hover,
.al--lg-alt:hover,
.al--block-alt:hover {
  border-color: #0638e7;
}
.al--sm-alt .al--block-img,
.al--lg-alt .al--block-img,
.al--block-alt .al--block-img {
  width: 226px;
  height: 128px;
  min-width: 226px;
  border-radius: 10px;
}
.al--sm-alt .al--block-detail,
.al--lg-alt .al--block-detail,
.al--block-alt .al--block-detail {
  border: none;
  padding: 0px 0px 0px 20px;
  margin-left: 0;
}
.al--sm-alt .al__address,
.al--lg-alt .al__address,
.al--block-alt .al__address {
  cursor: pointer;
}
.al--sm-alt .al--adress-component,
.al--lg-alt .al--adress-component,
.al--block-alt .al--adress-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.al--sm-alt .al__address,
.al--lg-alt .al__address,
.al--block-alt .al__address {
  line-height: 26px;
}
.al--block-alt .buildings__next {
  font-size: 18px;
  color: #6a7074;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 20px;
  margin-left: 15px;
  margin-right: 8px;
}
.building__btn:not([href]),
.building__btn {
  font-size: 14px;
  font-weight: normal;
  padding: 2px 4px;
  min-width: 90px;
  min-height: 24px;
  border-radius: 12px;
  text-align: center;
  margin: 5px 0px;
}
.building__btn-draft:not([href]),
.building__btn-draft {
  color: #6a7074;
  background-color: rgba(188, 186, 184, 0.2);
}
.building__btn-review:not([href]),
.building__btn-review {
  color: #ff8d39;
  background-color: rgba(255, 141, 57, 0.2);
}
.building__btn-published:not([href]),
.building__btn-published {
  color: #fff;
  background-color: rgba(34, 34, 34, 0.8);
}
.building__btn-draft:not([href]):hover,
.building__btn-draft:hover {
  color: #fff;
  background-color: #bcbab8;
}
.building__btn-review:not([href]):hover,
.building__btn-review:hover {
  color: #fff;
  background-color: #ff8d39;
}
.building__btn-published:not([href]):hover,
.building__btn-published:hover {
  color: #fff;
  background-color: #222222;
}
.al--block-alt .al--btn-component {
  flex-direction: column;
  justify-content: center;
}
.al--sm-alt .al--listings-component,
.al--lg-alt .al--listings-component,
.al--block-alt .al--listings-component {
  display: flex;
  align-items: center;
}
.dashbaord--opt-head {
  justify-content: flex-end;
}
.btn__lg-width {
  width: 100%;
  max-width: 290px;
}

/*
  Buildings New Style
*/

/*
  Dashbaord New Style
*/
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.p-20 {
  padding: 20px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-48 {
  margin-top: 48px;
}
.gap {
  grid-gap: 10px;
  gap: 10px;
}
.ml-16 {
  margin-left: 16px;
}
.border-neutral-500 {
  border: 1px solid #bcbab8;
}
.rounded-8 {
  border-radius: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-10p {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.para__lg {
  font-size: 18px;
  color: #6a7074;
  font-weight: normal;
}
.para__lg-dark {
  color: #222222;
}
.para__lg-black {
  color: #1c1f20;
}
.dashboard__btn:not(:disabled),
.dashboard__btn {
  color: #0638e7;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #0638e7;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 12px;
}
.dashboard__btn .dashboard__btn-icon {
  display: inline-flex;
  margin-left: 10px;
  font-size: 20px;
}
.dashboard__btn .dashboard__btn-icon .icon-file {
  font-size: 22px;
}
.dashboard__btn:not([href]):not(:disabled):not(.disabled).active,
.dashboard__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.dashboard__btn:not([href]):not(:disabled):not(.disabled):active,
.dashboard__btn:not([href]):hover,
.dashboard__btn:not([href]):focus,
.dashboard__btn:not([href]).active .dashboard__btn:not(:disabled):not(.disabled).active,
.dashboard__btn:not(:disabled):not(.disabled):active:focus,
.dashboard__btn:not(:disabled):not(.disabled):active,
.dashboard__btn:hover,
.dashboard__btn:focus,
.dashboard__btn.active {
  color: #fff;
  border-color: #0638e7;
  background-color: #0638e7;
  outline: none;
  box-shadow: none;
}
.dashboard--card {
  padding: 18px 44px;
  border-radius: 20px;
  background-color: #fff;
  border: none;
  margin-bottom: 20px;
}
.accordion > .dashboard--card:not(:last-of-type) {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.accordion > .dashboard--card:not(:first-of-type) {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.dashboard--card > .card-header {
  background-color: transparent;
  border: none;
  padding: 10px 0px;
}
.dashboard--card > .card-header > .btn-link {
  width: 100%;
  padding: 0;
  text-align: left;
  color: #222222;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard--card > .card-header > .btn-link .icon-chevron-down-solid {
  color: #bcbab8;
  font-size: 9px;
  margin-left: 10px;
}
.dashboard--card > .card-header > .btn-link .icon-chevron-right {
  color: #bcbab8;
  font-size: 12px;
  margin-left: 10px;
}
.dashboard--card > .card-header > .btn-link:hover,
.dashboard--card > .card-header > .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
.dashboard--card .card-body {
  padding: 0;
}
.upcomingTour--block {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #bcbab8;
  display: flex;
  align-items: center;
  min-height: 140px;
  cursor: pointer;
}
.upcomingTour--block:hover {
  border-color: #0638e7;
}
.upcomingTour--block .upcomingTour--left {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  max-width: 190px;
  padding-right: 10px;
  border-right: 1px solid #bcbab8;
  margin-right: 30px;
}
.upcomingTour--block .upComTour__icon {
  font-size: 24px;
  color: #1c1f20;
  display: inline-flex;
  margin-bottom: 10px;
}
.upcomingTour--block .upComTour__name {
  font-size: 16px;
  color: #1c1f20;
  font-weight: normal;
  display: inline-flex;
  line-height: 28px;
}
.upcomingTour--block .upcomingTour--main {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.upcomingTour--block .upcomingTour--right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  margin-bottom: auto;
}
.upComTour__list {
  padding: 10px 10px 4px 0px;
}
.upComTour__list li {
  color: #6a7074;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  padding-left: 26px;
  margin-bottom: 6px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
.upComTour__list li .upComTour__list-text {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.upComTour__list li .upComTour__list-icon {
  position: absolute;
  left: 0;
  top: 1px;
  color: #6a7074;
  font-size: 16px;
  display: inline-flex;
}
.upComTour__list li:last-child {
  margin-bottom: 0;
}
.link__dark:not([href]),
.link__dark {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}
.link__dark:not([href]):hover,
.link__dark:not([href]):focus,
.link__dark:hover,
.link__dark:focus {
  color: #222222;
  text-decoration: underline;
}
.link__blue:not([href]),
.link__blue {
  font-size: 16px;
  color: #0638e7;
  font-weight: normal;
}
.link__blue:not([href]):hover,
.link__blue:not([href]):focus,
.link__blue:hover,
.link__blue:focus {
  color: #0638e7;
  text-decoration: underline;
}
.link__blue-sm:not([href]),
.link__blue-sm {
  font-size: 14px;
  color: #0638e7;
  font-weight: normal;
}
.link__blue-sm:not([href]):hover,
.link__blue-sm:not([href]):focus,
.link__blue-sm:hover,
.link__blue-sm:focus {
  color: #0638e7;
  text-decoration: underline;
}
.upComTour--btn-canvas {
  display: inline-flex;
  flex-direction: column;
}
.link__btn {
  font-size: 16px;
  font-weight: normal;
  min-height: 34px;
  border: 1px solid transparent;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  min-width: 200px;
  line-height: 24px;
}
.link__btn i {
  margin-left: 10px;
}
.link__btn-sm {
  min-width: 180px;
}
.link__btn-blue {
  color: #fff;
  background-color: #0638e7;
  border-color: #0638e7;
}
.link__btn-blue:not(:disabled):not(.disabled).active,
.link__btn-blue:not(:disabled):not(.disabled):active:focus,
.link__btn-blue:not(:disabled):not(.disabled):active,
.link__btn-blue:hover,
.link__btn-blue:focus,
.link__btn-blue.active {
  color: #fff;
  background-color: #0c30af;
  border-color: #0c30af;
  outline: none;
  box-shadow: none;
}
.link__btn-gray {
  color: #1c1f20;
  background-color: #fff;
  border-color: #1c1f20;
}
.link__btn-gray:not(:disabled):not(.disabled).active,
.link__btn-gray:not(:disabled):not(.disabled):active:focus,
.link__btn-gray:not(:disabled):not(.disabled):active,
.link__btn-gray:hover,
.link__btn-gray:focus,
.link__btn-gray.active {
  color: #fff;
  background-color: #1c1f20;
  border-color: #1c1f20;
  outline: none;
  box-shadow: none;
}
.link__btn-yellow {
  color: #fff;
  background-color: #ff8d39;
  border-color: #ff8d39;
}
.link__btn-yellow:not(:disabled):not(.disabled).active,
.link__btn-yellow:not(:disabled):not(.disabled):active:focus,
.link__btn-yellow:not(:disabled):not(.disabled):active,
.link__btn-yellow:hover,
.link__btn-yellow:focus,
.link__btn-yellow.active {
  color: #fff;
  background-color: #f37c25;
  border-color: #f37c25;
  outline: none;
  box-shadow: none;
}
.link__btn-red {
  color: #fff;
  background-color: #e7063c;
  border-color: #e7063c;
}
.link__btn-red:not(:disabled):not(.disabled).active,
.link__btn-red:not(:disabled):not(.disabled):active:focus,
.link__btn-red:not(:disabled):not(.disabled):active,
.link__btn-red:hover,
.link__btn-red:focus,
.link__btn-red.active {
  color: #fff;
  background-color: #d20a3a;
  border-color: #d20a3a;
  outline: none;
  box-shadow: none;
}
.link__btn-outlineBlue {
  color: #0638e7;
  background-color: #fff;
  border-color: #0638e7;
}
.link__btn-outline-secondary {
  border: 1px solid #071033;
}
.link__btn-outlineBlue:not(:disabled):not(.disabled).active,
.link__btn-outlineBlue:not(:disabled):not(.disabled):active:focus,
.link__btn-outlineBlue:not(:disabled):not(.disabled):active,
.link__btn-outlineBlue:hover,
.link__btn-outlineBlue:focus,
.link__btn-outlineBlue.active {
  color: #fff;
  background-color: #0638e7;
  border-color: #0638e7;
  outline: none;
  box-shadow: none;
}
.link__blue-lg:not([href]),
.link__blue-lg {
  font-size: 16px;
  color: #0638e7;
  font-weight: normal;
}
.link__blue-lg:not([href]):hover,
.link__blue-lg:not([href]):focus,
.link__blue-lg:hover,
.link__blue-lg:focus {
  color: #0638e7;
  text-decoration: underline;
}
.upComTour--btn-canvas .link__btn-blue {
  margin-bottom: 10px;
}
.upComTour--btn-canvas .link__btn-gray {
  margin-top: 10px;
}
.para__large-dark {
  font-size: 18px;
  color: #1c1f20;
}
.para__large-gray {
  font-size: 18px;
  color: #6a7074;
}
.para__large-white {
  font-size: 18px;
  color: #fff;
}
.para__md-dark {
  font-size: 16px;
  color: #1c1f20;
}
.para__md-gray {
  font-size: 16px;
  color: #6a7074;
}
.para__md-white {
  font-size: 16px;
  color: #fff;
}
.para__sm-dark {
  font-size: 14px;
  color: #1c1f20;
}
.para__sm-gray {
  font-size: 14px;
  color: #6a7074;
}
.para__sm-white {
  font-size: 14px;
  color: #fff;
}
.dashboard--notfound {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.dashboard--notfound .notfound__result-img {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  display: inline-block;
  width: 204px;
  height: 170px;
  margin-bottom: 40px;
  overflow: hidden;
}
.price-zillow {
  padding: 14px 32px 14px 13px;

  width: 170px;
  height: 49px;

  /* B&W/White */

  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #bcbab8;
  border-radius: 10px;
}

.delete-building {
  padding: 14px 32px 14px 13px;

  /* width: 170px; */
  height: 49px;

  /* B&W/White */

  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #bcbab8;
  border-radius: 10px;
}
.dashboard--notfound .notfound__listing-img {
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  display: inline-block;
  width: 200px;
  height: 161px;
  margin-bottom: 40px;
  overflow: hidden;
}
.dashboard--newAccount {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
}
.dashboard--sticky .dashboard--canvas {
  display: flex;
  flex-direction: column;
}
.dashboard--sticky .dashbaord--sec {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.dashboard--sticky .dashbaord--sec::-webkit-scrollbar {
  width: 4px;
}
.dashboard--sticky .dashbaord--sec::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
/* .tab-pane .row .col:nth-last-child(-n+3) .text-center.mb-4 {
  background-color: red;
} */
.dashbaord--banner {
  height: 198px;
  border-radius: 16px;
  background: url(/static/media/dashboard-banner-full.e98d145c.png) center center no-repeat;
  background-size: cover;
  overflow: hidden;
}
.heading__block {
  font-size: 24px;
  font-weight: 600;
}
.heading__block-black {
  color: #1c1f20;
}
.heading__block-gray {
  color: #6a7074;
}
.rounded--block {
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
}
.rounded-xl {
  padding: 24px;
  border-radius: 16px;
}
.account--block {
  display: flex;
  align-items: center;
}
.account--block .accountBlock--l {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.account--block .accountBlock--r {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}
.updateAccount__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.updateAccount__list li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  padding: 0 22px;
  border-right: 1px solid #bcbab8;
}
.updateAccount__list li:first-child {
  padding-left: 0;
}
.updateAccount__list li:last-child {
  border-right: 0;
  padding-right: 0;
}
.updateAccount--box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.updateAccount--box .updateAccount__icon {
  font-size: 30px;
  display: inline-flex;
  margin-right: 10px;
}
.updateAccount--box-listed .updateAccount__icon {
  color: #0930ba;
}
.updateAccount--box-tour .updateAccount__icon {
  color: #ff8d39;
}
.updateAccount--box-listing .updateAccount__icon {
  color: #524ef1;
}
.updateAccount--box-building .updateAccount__icon {
  color: #23a293;
}
.updateAccount--box .updateAccount__heading {
  font-size: 20px;
  color: #1c1f20;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 3px;
}
.updateAccount--box .para__md-gray {
  line-height: 18px;
}
.spotlight--block .spotlight--head {
  display: flex;
  margin-bottom: 16px;
}
.spotlight--block .spotlight--head .spotlight--icon {
  font-size: 24px;
  color: #0638e7;
  margin-right: 12px;
  display: inline-flex;
}
.dashboard--card-alt {
  padding: 24px;
  border-radius: 16px;
}
.dashboard--card-alt > .card-header {
  padding: 0;
}
.dashboard--card-alt .btn-link .dashCard__arrow {
  font-size: 18px;
  color: #bcbab8;
  display: inline-flex;
}
.dashboard--card-alt .dashCard__icon {
  font-size: 30px;
  color: #0099ff;
  margin-right: 12px;
  display: inline-flex;
}
.heading__md-dark {
  font-size: 18px;
  color: #1c1f20;
}
.heading__md-gray {
  font-size: 18px;
  color: #6a7074;
}
.heading__md-white {
  font-size: 18px;
  color: #fff;
}
.heading__small-dark {
  font-size: 16px;
  color: #1c1f20;
}
.heading__small-gray {
  font-size: 16px;
  color: #6a7074;
}
.heading__small-white {
  font-size: 16px;
  color: #fff;
}
.heading__small-blue {
  font-size: 16px;
  color: #0638e7;
}
.tourPulse--block {
  padding: 18px 0;
  border-bottom: 1px solid rgba(188, 186, 184, 0.5);
}
.tourPulse--off .tourPulse--block:last-child {
  border-bottom: 0;
}
.tourPulse--block .tourPulse--row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
.tourPulse--block .tourPulse--col {
  display: flex;
  border-right: 1px solid rgba(188, 186, 184, 0.5);
  padding-left: 20px;
  padding-right: 20px;
}
.tourPulse--block .tourPulse--col:last-child {
  border-right: 0;
}
.tourPulse--block .tourPulse--col-info {
  flex: 0 0 25%;
  max-width: 25%;
}
.tourPulse--block .tourPulse--col-detail {
  flex: 0 0 42%;
  max-width: 42%;
}
.tourPulse--block .tourPulse--col-link {
  flex: 0 0 33%;
  max-width: 33%;
}
.tourPulse--block .tourPulse--info {
  display: flex;
  width: 100%;
}
.tourPulse--block .tourPulse--info .tourPulseInfo__icon {
  font-size: 16px;
  color: #1c1f20;
  display: inline-flex;
  margin-right: 14px;
  margin-top: 2px;
}
.tourPulse--block .tourPulse--info .para__sm-gray {
  line-height: 16px;
}
.tourPulse--block .tourPulse--detail {
  display: flex;
  width: 100%;
}
.tourPulse--block .tourPulse--detail .tourPulseDetail__icon {
  font-size: 16px;
  color: #6a7074;
  display: inline-flex;
  margin-right: 12px;
  margin-top: 4px;
}
.tourPulse--block .tourPulse--link {
  display: flex;
  width: 100%;
}
.tourPulse--block .tourPulse--link .tourPulseLink__icon {
  font-size: 16px;
  color: #6a7074;
  display: inline-flex;
  margin-right: 12px;
}
.tourPulse--block .tourPulse--info .tourPulseInfo--detail,
.tourPulse--block .tourPulse--link .tourPulseLink--detail {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding-right: 4px;
}
.tourPulse--block .tour__link {
  font-size: 14px;
  color: #bcbab8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 18px;
}
.tourPulse--block .tourPulseLink__arrow {
  color: #0638e7;
  font-size: 16px;
  display: flex;
  margin-top: 8px;
}
.listing--dropdown-alt .dropdown-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 3px 32px 3px 13px;
  background-color: #fff;
  position: relative;
  z-index: 5;
}
.listing--dropdown-alt .dropdown-toggle .ld__sub {
  color: #bcbab8;
  font-size: 12px;
}
.listing--dropdown-alt .dropdown-toggle .select__caret-down {
  font-size: 13px;
  display: inline-flex;
}
.listing--dropdown-alt .dropdown-menu {
  margin-top: -12px;
  padding-top: 22px;
  z-index: 4;
  border-radius: 0px 0px 10px 10px;
}
.listing--dropdown-alt .dropdown-item {
  border-radius: 0;
  border-bottom: 1px solid #bcbab8;
  padding: 10px 4px;
}
.listing--dropdown-alt.show > .dropdown-toggle,
.listing--dropdown-alt.show > .dropdown-toggle:focus,
.listing--dropdown-alt .dropdown-toggle:not(:disabled):not(.disabled):active,
.listing--dropdown-alt .dropdown-toggle:focus,
.listing--dropdown-alt .dropdown-toggle:active {
  background-color: #fff;
}
.listing--dropdown-alt .dropdown-item:hover,
.listing--dropdown-alt .dropdown-item:focus,
.listing--dropdown-alt .dropdown-item.active {
  border-radius: 4px;
}
.dashboard--table-canvas {
  overflow-x: auto;
  padding-left: 2px;
  padding-right: 2px;
}
.dashboard--table-canvas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.dashboard--table-canvas::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}
.dashboard--table {
  min-width: 1080px;
  margin-bottom: 0;
}
.dashboard--table thead th {
  background-color: #f5f5f5;
  border-top: 0;
  border-bottom: 3px solid #fff;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
}
.dashboard--table thead tr th:first-child {
  border-radius: 5px 0px 0px 5px;
  padding-left: 20px;
}
.dashboard--table thead tr th:last-child {
  border-radius: 0px 5px 5px 0px;
}
.dashboard--table tbody td {
  padding: 0;
  border: 1px solid #fff;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  border-radius: 1px;
  min-width: 114px;
}
.dashboard--table tbody tr td:first-child {
  padding-left: 12px;
  text-align: left;
}
.dashboard--table-f tbody tr td:first-child {
  min-width: 290px;
  max-width: 290px;
}
.dashboard--table tbody tr td:last-child {
  min-width: 44px;
}
.listings--check {
  display: flex;
  align-items: center;
}
.checkbox--dashboard {
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 28px;
}
.checkbox--dashboard.checkbox--set {
  min-width: 10px;
}
.checkbox--dashboard .checkmark {
  width: 16px;
  height: 16px;
  border-color: #1c1f20;
  border-radius: 4px;
  display: flex;
  position: static;
}
.th--box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.th--box .thBox__icon {
  font-size: 14px;
  color: #1c1f20;
  display: inline-flex;
  margin-right: 10px;
}
.listings--check .thBox__text,
.th--box .thBox__text {
  font-size: 14px;
  color: #1c1f20;
  font-weight: normal;
}
.dashboard--table thead th:first-child .th--box {
  justify-content: flex-start;
}
.dashTable--listing {
  display: flex;
  align-items: center;
  padding-right: 4px;
}
.dashTable--listing .dashTableListing__img {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}
.dashTable--listing .dashTableListing__link {
  font-size: 14px;
  color: #6a7074;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dashTable--listing .dashTableListing__link:hover,
.dashTable--listing .dashTableListing__link:focus {
  text-decoration: none;
}

/*
  Dashbaord New Style
*/

/*
  Tour Analytics Style
*/

.listingTour--block .listingTour--head {
  margin-top: 30px;
}
.back__link:not([href]),
.back__link {
  color: #0638e7;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  line-height: 16px;
}
.back__link .back__link-icon {
  font-size: 18px;
  margin-right: 10px;
  display: inline-flex;
}
.back__link:not([href]):hover,
.back__link:not([href]):focus,
.back__link:hover,
.back__link:focus {
  color: #0a32bd;
}
.listingTour--t .para__md-dark {
  margin-right: 10px;
}
.listingTour__btn {
  font-size: 14px;
  color: #0638e7;
  min-height: 24px;
  padding: 2px 10px;
  background-color: transparent;
  border: 1px solid #0638e7;
  border-radius: 12px;
}
.listingTour__btn:not(:disabled):not(.disabled):active:focus,
.listingTour__btn:not(:disabled):not(.disabled):active,
.listingTour__btn:hover,
.listingTour__btn:focus,
.listingTour__btn:active {
  background-color: #0638e7;
  border-color: #0638e7;
  box-shadow: none;
  color: #fff;
}
.canvas--head {
  display: flex;
}
.canvas--head .canvasHead--icon {
  font-size: 24px;
  color: #1c1f20;
  margin-right: 12px;
  display: inline-flex;
}
.looked--block .hl--top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listingTour--nav {
  border: 1px solid #bcbab8;
  border-radius: 30px;
  flex-shrink: 0;
  margin-bottom: auto;
}
.listingTour--nav .nav-link {
  font-size: 14px;
  color: #6a7074;
  border-radius: 24px;
  padding: 4px 12px;
  min-height: 34px;
  min-width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.listingTour--nav .nav-link.active {
  background-color: #0638e7;
  color: #fff;
  margin: -1px;
  min-height: 36px;
}
.dashTable--listing .dashTableListing__icon {
  font-size: 7px;
  color: #fff;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #0638e7;
  margin-right: 10px;
}
.dashTable--listing .dashTableListing__video {
  width: 56px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.dashTable--listing .dashTableListing__pdf {
  font-size: 29px;
  color: #6a7074;
  margin-right: 16px;
  min-width: 32px;
  display: inline-flex;
}
.dashboard--table-alt tbody tr td {
  height: 32px;
}
.dashboard--table-alt tbody tr td:first-child {
  padding-left: 18px;
}
.mediaUsed--canvas {
  margin-top: 40px;
}
.dashTable--listing-alt {
  margin-left: 6px;
}
.dashTable--listing-alt .dashTableListing__img {
  border-radius: 4px;
  margin-right: 16px;
}
.dashboard--table-se tbody tr td:first-child {
  min-width: 190px;
  max-width: 190px;
}
.dashboard--table-th tbody tr td:first-child {
  min-width: 620px;
  max-width: 620px;
}
.video--content .videoContent--place {
  height: 408px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 22px;
}
.video--content .videoContent--bar {
  display: flex;
  width: 100%;
  height: 5px;
  background-color: #bcbab8;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 22px;
}
.video--content .videoContent--bar .videoBar__compete {
  background-color: #0638e7;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.videoContent--options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.videoContent--options .videoContent--inner {
  display: flex;
  align-items: center;
}
.videoContent--options .videoContent__play {
  font-size: 20px;
  color: #fff;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0638e7;
  border-radius: 50%;
  margin-right: 16px;
}
.videoContent--options .videoContent__play .icon-play {
  margin-left: 2px;
}
.videoContent--options .videoContent__clock {
  display: flex;
  align-items: center;
  line-height: 19px;
}
.videoContent--options .videoContent__clock .videoContent--time {
  font-size: 14px;
  color: #6a7074;
  display: inline-flex;
}
.videoContent--options .videoContent__clock .videoContent--separator {
  margin: 0 4px;
  display: inline-flex;
}
.videoContent--options .videoContent__speed-btn {
  color: #fff;
  font-size: 16px;
  min-width: 48px;
  min-height: 30px;
  padding: 4px;
  background-color: #0638e7;
  border-color: #0638e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoContent--options .videoContent__play:hover,
.videoContent--options .videoContent__play:focus,
.videoContent--options .videoContent__play:active,
.videoContent--options .videoContent__speed-btn:hover,
.videoContent--options .videoContent__speed-btn:focus,
.videoContent--options .videoContent__speed-btn:active {
  background-color: #0c30af;
  color: #fff;
}
.videoContent--options .videoContent__screen {
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
}
.videoContent--options .videoContent__screen .videoContent__screen-text {
  color: #0638e7;
  font-size: 14px;
  display: inline-flex;
  margin-right: 10px;
}
.videoContent--options .videoContent__screen .videoContent__screen-size {
  color: #0638e7;
  font-size: 16px;
  display: inline-flex;
  cursor: pointer;
}
.comment--block {
  border: 1px solid #bcbab8;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 505px;
}
.comment--block .commentBlock--top {
  padding: 24px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.comment--block .commentBlock--bottom {
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.comment--block .comment__para {
  font-size: 12px;
  color: #6a7074;
}
.comment--block .commentBlock--scroll {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  margin-right: -8px;
  padding-right: 8px;
}
.comment--block .commentBlock--scroll::-webkit-scrollbar {
  width: 2px;
}
.comments--group {
  position: relative;
  margin-bottom: 0;
}
.comments--group .form-control {
  font-size: 14px;
  color: #1c1f20;
  padding: 12px;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  height: 48px;
  transition: all 0.2s ease;
}
.comments--group .form-control::-webkit-input-placeholder {
  color: #bcbab8;
}
.comments--group .form-control::-ms-input-placeholder {
  color: #bcbab8;
}
.comments--group .form-control::placeholder {
  color: #bcbab8;
}
.comments--group .form-control:focus {
  border-color: #0638e7;
  height: 100px;
}
.comments--group .comments--options {
  position: absolute;
  right: 3px;
  bottom: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.commentOpt__btn {
  font-size: 16px;
  color: #bcbab8;
  width: 34px;
  height: 34px;
  padding: 2px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}
.comments--group .form-control:focus ~ .commentOpt__btn {
  background-color: #0638e7;
  color: #fff;
}
.comment--box {
  display: flex;
  margin-bottom: 20px;
}
.comment--box .commentBox--img {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.comment--box .commentBox__heading {
  font-size: 14px;
  color: #6a7074;
  font-weight: 600;
  margin-bottom: 8px;
  display: inline-flex;
  flex-wrap: wrap;
}
.comment--box .commentBox__date {
  font-size: 14px;
  color: #bcbab8;
  display: inline-flex;
  margin-left: 14px;
  font-weight: normal;
}
.comment--box .commentBox__para {
  font-size: 14px;
  color: #6a7074;
}
.comment--box .commentBox__para > a {
  color: #1c1f20;
  font-weight: 700;
}
.comment--box .commentBoxOpt__btn {
  font-size: 16px;
  color: #6a7074;
  display: inline-flex;
  margin-right: 18px;
}
.comment--box .commentBoxOpt__btn:hover,
.comment--box .commentBoxOpt__btn:focus {
  color: #0638e7;
}
.comment--block .commentBlock--scroll .comment--box:last-child {
  margin-bottom: 0;
}

/*
  Tour Analytics Style
*/

/*
  Create Tour Style
*/

.dashboard--tour .dashboard--off {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.dashboard--tour .tour--canvas {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.dashboard--tour .white--rounded-box {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.tour--canvas {
  width: 100%;
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.ct--head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.steps__text {
  color: #6a7074;
  font-size: 16px;
  font-weight: normal;
  white-space: nowrap;
}
.ct--radio-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding: 12px 16px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 150px;
}
.ct--radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ct--radio-btn .checkmark {
  position: relative;
  display: inline-flex;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border: 1px solid #6a7074;
  margin-right: 22px;
  border-radius: 30px;
}
.ct--radio-btn input:checked ~ .checkmark {
  background-color: #0638e7;
  border-color: #0638e7;
}
.ct--radio-btn .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.ct--radio-btn input:checked ~ .checkmark:after {
  display: block;
}
.ct--radio-btn .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.ct--radio-btn.active {
  border-color: #0638e7;
}
.ctRadio--content .ctRadio--head {
  display: flex;
  align-items: center;
}
.ctRadio--content .ctRadio__icon {
  color: #1c1f20;
  font-size: 17px;
  margin-right: 10px;
  display: inline-flex;
}
.ctRadio--content .para__md-dark,
.ctRadio--content .small__paragraph {
  word-break: break-word;
  white-space: normal;
}
.forms--group-radius .form-control,
.forms--group-radius .ant-picker {
  border-radius: 10px;
}
.outline__btn-gray:not([href]),
.outline__btn-gray {
  border: 1px solid #222222;
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  padding: 10px 20px;
  min-height: 48px;
  border-radius: 0px;
  min-width: 200px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.outline__btn-gray-sm:not([href]),
.outline__btn-gray-sm {
  min-width: 160px;
}
.outline__btn-gray-md:not([href]),
.outline__btn-gray-md {
  min-width: 188px;
}
.outline__btn-gray:not([href]):not(:disabled):not(.disabled):active,
.outline__btn-gray:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__btn-gray:not([href]):hover,
.outline__btn-gray:not([href]):focus,
.outline__btn-gray:not(:disabled):not(.disabled):active,
.outline__btn-gray:not(:disabled):not(.disabled):active:focus,
.outline__btn-gray:hover,
.outline__btn-gray:focus {
  background-color: #222222;
  color: #fff;
  border-color: #222222;
  box-shadow: none;
}
.outline__btn-gray.disabled {
  color: #bcbab8 !important;
  border-color: #bcbab8 !important;
}
.outline__btn-radius:not([href]),
.outline__btn-radius {
  border-radius: 10px;
}
.ct__btn-canvas {
  padding-top: 24px;
  margin-top: auto;
}
.listing--dropdown-update .dropdown-toggle {
  background-color: #fff;
  position: relative;
  z-index: 5;
}
.listing--dropdown-update .dropdown-menu {
  margin-top: -12px;
  padding-top: 22px;
  z-index: 4;
  border-radius: 0px 0px 10px 10px;
}
.listing--dropdown-update.show > .dropdown-toggle,
.listing--dropdown-update.show > .dropdown-toggle:focus,
.listing--dropdown-update .dropdown-toggle:not(:disabled):not(.disabled):active,
.listing--dropdown-update .dropdown-toggle:focus,
.listing--dropdown-update .dropdown-toggle:active {
  background-color: #fff;
}
.listing--dropdown-update .dropdown-item {
  border-radius: 0;
  border-bottom: 1px solid #bcbab8;
  padding: 10px 4px;
}
.listing--dropdown-update .dropdown-item:hover,
.listing--dropdown-update .dropdown-item:focus,
.listing--dropdown-update .dropdown-item.active {
  border-radius: 4px;
}
.listing--dropdown-update .dropdown-menu .dropdown-item:last-child {
  border: none;
}

/*
  Create Tour Style
*/

/*
  Create Tour Listing Style
*/

.badge--row {
  margin-left: -10px;
  margin-right: -10px;
}
.badge--row .badge--col {
  padding-left: 10px;
  padding-right: 10px;
}
.tour__badge {
  background-color: #f5f5f5;
  border-radius: 12px;
  min-height: 26px;
  padding: 4px 10px;
  color: #1c1f20;
  font-size: 14px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tour__badge .tour__badge-cross {
  cursor: pointer;
  color: #1c1f20;
  font-size: 10px;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 10px;
  padding: 3px 4px;
}
.tourListing--content {
  border-top: 1px solid rgba(188, 186, 184, 0.5);
  padding-top: 30px;
  margin-top: 10px;
}
.add__btn:not([href]),
.add__btn {
  font-size: 16px;
  line-height: 18px;
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
}
.add__btn-blue:not([href]),
.add__btn-blue {
  color: #fff;
  background-color: #0638e7;
  border: 1px solid #0638e7;
}
.add__btn-blue:not([href]):not(:disabled):not(.disabled):active:focus,
.add__btn-blue:not([href]):not(:disabled):not(.disabled):active,
.add__btn-blue:not([href]):hover,
.add__btn-blue:not([href]):focus,
.add__btn-blue:not(:disabled):not(.disabled):active:focus,
.add__btn-blue:not(:disabled):not(.disabled):active,
.add__btn-blue:hover,
.add__btn-blue:focus {
  color: #fff;
  background-color: #0c30af;
  box-shadow: none;
  outline: none;
  text-decoration: none;
}
.tourListing--block {
  display: flex;
  align-items: center;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tourListing--block .tourListing__img {
  width: 52px;
  height: 52px;
  min-width: 52px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourListing--block .tourListing--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  padding: 8px 20px;
  overflow: hidden;
}
.tourListing--block .tourListing--row .para__md-dark {
  margin-right: auto;
  margin-bottom: 0px;
}
.tourListing--scroll .tourListing--block:last-child {
  margin-bottom: 0;
}
.tourListing--canvas {
  padding-top: 10px;
  position: relative;
}
.dashboard--tourListing .tour--canvas {
  display: flex;
  flex-direction: column;
}
.dashboard--tourListing .white--rounded-box {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.dashboard--tourListing .tourListing--content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.dashboard--tourListing .tour--canvas::-webkit-scrollbar {
  width: 0;
}
.createTour--accordian {
  margin-top: 16px;
  min-height: 100%;
  max-height: 100%;
}
.createTour--card {
  border: none;
  min-height: 100%;
  max-height: 100%;
}
.accordion > .createTour--card > .card-header,
.createTour--card .card-header {
  margin-bottom: 22px;
  padding: 0;
  border: none;
  background-color: transparent;
}
.createTour--card .card-header .btn-link {
  color: #1c1f20;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border: none;
}
.createTour--card .card-header .btn-link:hover,
.createTour--card .card-header .btn-link:focus {
  color: #1c1f20;
  text-decoration: none;
  box-shadow: none;
}
.createTour--card .card-header .createTour--card-icon {
  color: #bcbab8;
  font-size: 9px;
  display: inline-flex;
}
.createTour--card .card-body {
  padding: 0;
}
.createListing--row .tourListing--block:last-child,
.createListing--row .tourListing--block {
  margin-bottom: 20px;
}

/*
  Create Tour Listing Style
*/

/*
  Tour Edit Style
*/

.ml-10 {
  margin-left: 10px;
}
.subDashboard--bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.breadcrumb--custom .breadcrumb {
  padding: 0;
  margin-bottom: 10px;
  background-color: transparent;
}
.breadcrumb--custom .breadcrumb-item {
  font-size: 14px;
  color: #bcbab8;
}
.breadcrumb--custom .breadcrumb-item a {
  font-size: 14px;
  color: #6a7074;
}
.breadcrumb--custom .breadcrumb-item + .breadcrumb-item::before {
  color: #bcbab8;
}
.preview__link i {
  font-size: 20px;
}
.tourEdit--top {
  height: 490px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.listing--dropdown-n .dropdown-toggle {
  padding-left: 44px;
}
.listing--dropdown-n .select__integrate {
  position: absolute;
  left: 16px;
  top: 14px;
  color: #1c1f20;
  font-size: 16px;
  pointer-events: none;
  display: inline-flex;
}
.tourEdit--listing-off {
  position: relative;
}
.tourEdit--listing-off.tourEdit--listing-shadow-effect:after {
  content: '';
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 78px;
  pointer-events: none;
  margin-bottom: 8px;
}
.tourEdit--listing-canvas {
  margin-top: 34px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 12px;
  background-color: #fff;
}
.tourEdit--listing-canvas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.tourEdit--listing-canvas::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}
.tourEdit--listing {
  border: 1px solid #bcbab8;
  border-radius: 10px;
  display: inline-flex;
  margin-right: 20px;
  min-height: 104px;
}
.tourEdit--listing .tel--cursor {
  border-right: 1px solid #bcbab8;
  min-width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tourEdit--listing .tel--cursor i {
  color: #bcbab8;
  font-size: 16px;
}
.tourEdit--listing .tel--control {
  border-left: 1px solid #bcbab8;
  min-width: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tourEdit--listing .tel--control i {
  color: #bcbab8;
  font-size: 14px;
}
.tourEdit--listing .tel--inner {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 244px;
}
.tourEdit--listing .tel--top {
  display: flex;
  align-items: center;
  padding: 4px 24px;
  border-bottom: 1px solid #bcbab8;
  min-height: 30px;
  flex: 1 1;
}
.tourEdit--listing .tel--top .small__paragraph {
  line-height: 20px;
  padding-left: 22px;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.tourEdit--listing .tel--top .small__paragraph i {
  position: absolute;
  left: 0;
  top: 3px;
}
.tourEdit--listing .tel--bottom {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  transition: width 2s ease;
}
.tel--btns-row {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}
.tel--btns-row .tel--btns-col {
  padding-left: 10px;
  padding-right: 10px;
}
.tel__btn:not([href]),
.tel__btn {
  color: #6a7074;
  font-size: 14px;
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  white-space: nowrap;
}
.tel__btn .tel__btn-icon {
  font-size: 16px;
  display: inline-flex;
}
.tel__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.tel__btn:not([href]):not(:disabled):not(.disabled):active,
.tel__btn:not([href]):hover,
.tel__btn:not([href]):focus,
.tel__btn:not([href]):active,
.tel__btn:not([href]).active,
.tel__btn:not(:disabled):not(.disabled).active,
.tel__btn:not(:disabled):not(.disabled):active:focus,
.tel__btn:not(:disabled):not(.disabled):active,
.tel__btn:hover,
.tel__btn:focus,
.tel__btn:active,
.tel__btn.active {
  color: #0638e7;
  border-color: #0638e7;
  background-color: transparent;
  box-shadow: none;
  outline: none;
}
.tourEdit--listing-minimize .tel--top {
  border-bottom: 0;
  padding-left: 14px;
  padding-right: 14px;
}
.tourEdit--listing-minimize .tel--bottom,
.tourEdit--listing-minimize .tel--top .small__paragraph .telTop__span em {
  display: none;
}
.tourEdit--listing-minimize .tel--top .small__paragraph {
  flex-direction: column;
  align-items: flex-start;
}
.tourEdit--listing-minimize .tel--control i {
  transform: rotate(180deg);
}
.team--block-alt {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.team--block-alt .teamBlock--img {
  margin: 0px 16px 0px 0px;
}
.team--block-alt .teamBlock__user {
  margin-bottom: 2px;
}
.preview__link:not([href]),
.preview__link {
  color: #0638e7;
  font-size: 16px;
}
.preview__link:not([href]):hover,
.preview__link:not([href]):focus,
.preview__link:hover,
.preview__link:focus {
  color: #0c30af;
  text-decoration: none;
}
.upload--gallery-img {
  color: #bcbab8;
  font-size: 14px;
  border: 1px dashed #bcbab8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2px;
}
.upload--gallery-img i {
  font-size: 16px;
  margin-bottom: 14px;
}
.upload--gallery-img input[type='file'] {
  display: none;
}
.tourEdit--top .liveTour--container {
  max-width: 800px;
}
.feature--block-alt {
  padding: 18px 15px;
  min-height: 218px;
}
.feature--block-alt .featureBlock__icon {
  font-size: 34px;
}
.feature--block-alt .featureBlock__heading {
  font-size: 20px;
}
.feature--block-alt .featureBlock--sf {
  margin-top: 22px;
  flex-wrap: wrap;
}
.feature--block-alt .featureBlock--sf .sf__digit {
  font-size: 36px;
  white-space: normal;
}
.feature--block-alt .featureBlock--sf .sf__word {
  font-size: 20px;
}
.feature--block-alt.feature--block-address h4.featureBlock__heading {
  height: 64px;
}
.tourEdit--specs .liveTour--building-main .row--space-5 {
  margin-left: -5px;
  margin-right: -5px;
}
.tourEdit--specs .liveTour--building-main .row--space-5 .col {
  padding-left: 5px;
  padding-right: 5px;
}
.component--edit-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 555;
  background-color: rgba(34, 34, 34, 0.7);
}
.component--edit {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  max-width: 570px;
  margin-left: auto;
  border-radius: 20px 0px 0px 20px;
  height: 100vh;
}
.component--edit .componentEdit--head {
  padding: 40px 70px 0px 70px;
  display: flex;
  flex-direction: column;
}
.component--edit .componentEdit--scrollable {
  padding: 24px 70px 40px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.component--edit .componentEdit--scrollable::-webkit-scrollbar {
  width: 0px;
}
.badge--row-alt {
  margin-left: -5px;
  margin-right: -5px;
}
.badge--row-alt .badge--col {
  padding-left: 5px;
  padding-bottom: 5px;
}
.tour__badge-alt {
  margin-bottom: 10px;
}
.tourListing--canvas-alt {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.tourListing--canvas-alt .tourListing--scroll {
  max-height: 352px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 3px;
}
.component--edit-overlay .tourListing--canvas-alt .tourListing--scroll {
  max-height: none;
  max-height: initial;
}
.plus__btn {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #0638e7;
  border: 1px solid #0638e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.plus__btn:not([href]):not(:disabled):not(.disabled):active,
.plus__btn:not([href]):hover,
.plus__btn:not([href]):focus,
.plus__btn:not(:disabled):not(.disabled):active:focus,
.plus__btn:not(:disabled):not(.disabled):active,
.plus__btn:hover,
.plus__btn:focus {
  background-color: #0c30af;
  border-color: #0c30af;
  color: #fff;
  box-shadow: none;
}
.tourListing--block-plus {
  margin-bottom: 15px;
  min-height: 52px;
}
.tourListing--block-plus .tourListing--row {
  padding: 8px 12px;
}
.tourListing--block-alt {
  padding: 6px 12px 6px 8px;
}
.tourListing--block-alt .tourListing__img {
  width: 38px;
  height: 38px;
  min-width: 38px;
  border-radius: 50%;
  margin: 0;
}
.tourListing--block-alt .tourListing--row {
  padding: 0px 0px 0px 16px;
}
.tourEdit--specs .specs--widget-alt {
  padding: 10px 14px;
  min-height: 52px;
  margin-bottom: 10px;
}
.tourEdit--specs .specs--widget-alt .bw__heading,
.tourEdit--specs .specs--widget-alt .bw__para {
  font-size: 12px;
}
.tourEdit--top .location--box .locationBox__img {
  width: 60px;
  height: 78px;
  margin-right: 14px;
  border-radius: 10px;
}
.tourEdit--top .location--box .locationBox__heading {
  font-size: 20px;
  margin-bottom: 10px;
}
.tourEdit--top .tourLocation--canvas {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100%;
}
.tourEdit--top .locationContent__list {
  margin-top: 16px;
}
.tourEdit--top .location--content {
  margin-top: 24px;
}
.tourEdit--top .locationContent__heading {
  font-size: 26px;
}
.tourEdit--top .detailComp--box .detailCompBox__icon {
  font-size: 38px;
  width: 40px;
  margin-right: 16px;
}
.tourEdit--top .detailComp--box .text__md-gray {
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 2px;
}
.tourEdit--top .detailComp--box .heading__medium {
  font-size: 19px;
  line-height: 24px;
}
.tourEdit--top.liveTour--combine-odd .liveTour--feature {
  padding: 28px 34px;
}
.tourEdit--top .detailComp--box {
  margin-bottom: 24px;
}
.tourEdit--top .featureComponent--main .location__badge {
  font-size: 14px;
}
.tourEdit--top .featureComponent--detail {
  padding: 0;
}
.nationalLanding--box {
  align-items: center;
}
.nationalLanding--box .locationBox__img {
  font-size: 20px;
  color: #fff;
  width: 60px;
  height: 56px;
  background-color: #fd9142;
}
.nationalLanding--box .locationBox__heading {
  margin-bottom: 0;
}
.tourEdit--integrate {
  display: flex;
  flex-wrap: wrap;
}
.tourEdit--integrate .tei__icon {
  font-size: 16px;
  color: #1c1f20;
  display: inline-flex;
  margin-right: 10px;
}
.tourEdit--top-box {
  padding: 16px 42px;
}
.tourEdit--top-box .tourEdit--top-start {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.tourEdit--top-box .sd--main {
  margin-right: auto;
}
.tourEdit--logo {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 20px;
}
.tourEdit--logo .tourEdit--logo-canvas {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.tourEdit--logo .tourEdit__cross {
  font-size: 10px;
  color: #1c1f20;
  width: 22px;
  height: 22px;
  background-color: #f5f5f5;
  border-radius: 50%;
  flex-shrink: 0;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
}
.tourEdit--logo .tourEdit__cross:hover,
.tourEdit--logo .tourEdit__cross:focus {
  color: #f5f5f5;
  background-color: #1c1f20;
}
.tourEdit--logo:hover .tourEdit__cross {
  display: flex;
}
.tours--tooltip {
  width: 80px;
  height: 80px;
  padding: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  border-radius: 10px;
  position: relative;
}
.tours--tooltip:hover,
.tours--tooltip:focus,
.tours--tooltip:active,
.tours--tooltip:not(:disabled):not(.disabled):active:focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: none;
  outline: none;
}
.custom-file-upload {
  font-size: 16px;
  color: #0638e7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
}
.custom-file-upload input {
  display: none;
}

/*
  Tour Edit Style
*/

/*
  Tour Presentaion Style
*/

.tourPre--page {
  min-height: 100vh;
}
.tourPre--content {
  min-height: 84px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.tourPre--content .liveTour--options {
  min-width: 150px;
}
.tourPre--top .liveTour--container {
  max-width: 1140px;
}
.tourPre--top .liveTour--head {
  display: flex;
}
.tourPre--top .liveTour--gallery-img {
  height: 140px;
  margin-bottom: 18px;
}
.tourPre--top .liveTour--gallery-row {
  margin-left: -9px;
  margin-right: -9px;
}
.tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
  padding-left: 9px;
  padding-right: 9px;
}
.tourPre--content-listing {
  min-height: 212px;
}
.tourPre--content .liveTour--listing {
  margin-top: -20px;
  padding-top: 20px;
}
.liveTour--gallery-img.upload--pdf {
  height: 72px;
  margin-bottom: 14px;
}
.liveTour--gallery-img.upload--pdf .icon-upload {
  margin-bottom: 8px;
}
.pdf-show-main {
  display: flex;
  flex: 1 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  max-width: 85%;
}
.pdf-show-main .close-btn {
  color: white;
  cursor: pointer;
  margin: 4px 0px 0px 14px;
  display: inline-flex;
  font-size: 14px;
  width: 16px;
  height: 16px;
}
.pdf-show-main .pdf-frame {
  border-radius: 15px;
  border: none;
}
.tourPre--top .feature--block {
  padding: 25px 15px;
  min-height: 220px;
}
.tourPre--top .feature--block-address h4.featureBlock__heading {
  height: 50px;
}
.liveTour--video-row .liveTour--gallery-col {
  width: auto;
}
.tourPre--top .liveTour--gallery-video {
  width: 258px;
  height: 146px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
}
.tourPre--top .liveTour--gallery-video .video__play {
  font-size: 14px;
  color: #fff;
  width: 50px;
  height: 32px;
  background-color: rgba(6, 56, 231, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video--expanded {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
  position: relative;
}
.video--expanded .videoExp--canvas {
  display: flex;
  justify-content: center;
}
.video--expanded .videoExp--box {
  width: 750px;
  height: 422px;
  border-radius: 20px;
  overflow: hidden;
}
.video--expanded .video__cross {
  font-size: 16px;
  color: #fff;
  display: inline-flex;
  cursor: pointer;
  padding: 4px;
  margin-left: 20px;
}
.tourPre--top .location--head .location__destination {
  width: 130px;
  height: 39px;
  min-width: 10px;
}
.tourPre--top .location--head .location__destination img {
  width: auto;
  height: inherit;
}
.tourPre--top .liveTour--combine-odd .liveTour--feature .liveTour--container,
.tourPre--top .liveTour--combine-even .liveTour--feature .liveTour--container {
  padding-left: 7% !important;
  padding-right: 7% !important;
}
.tourPre--top .detailComp--box .detailCompBox__icon {
  font-size: 38px;
  width: 40px;
  margin-right: 22px;
}
.tourPre--top .detailComp--box .text__md-gray {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
}
.tourPre--top .detailComp--box .heading__medium {
  font-size: 20px;
  line-height: 24px;
}
.tourPre--top .featureComponent--detail {
  padding: 40px 0px 20px 0px;
}
.tourPre--top .detailComp--box {
  margin-bottom: 28px;
}

/*
  Tour Presentaion Style
*/

/*
  Admin Home Search & Results Style
*/

.search--group-lg .form-control {
  height: calc(3.1112em + 0.75rem + 2px);
  font-size: 18px;
  line-height: 22px;
  padding: 16px 24px 16px 64px;
}
.search--group-lg .search__icon {
  font-size: 24px;
  left: 24px;
  top: 24px;
}
.search--group-lg .search__icon-cross {
  color: #6a7074;
  font-size: 22px;
  position: absolute;
  right: 16px;
  top: 24px;
  display: inline-flex;
  cursor: pointer;
}
.search--group-lg .form-control::-webkit-input-placeholder {
  color: #bcbab8;
}
.search--group-lg .form-control::-ms-input-placeholder {
  color: #bcbab8;
}
.search--group-lg .form-control::placeholder {
  color: #bcbab8;
}
.heading__sm {
  font-size: 18px;
  word-break: break-word;
}
.heading__sm-black {
  color: #222222;
}
.heading__sm-dark {
  color: #1c1f20;
}
.bs__heading {
  font-size: 20px;
  word-break: break-word;
}
.bs__heading-black {
  color: #222222;
}
.bs__heading-dark {
  color: #1c1f20;
}
.searchResults--block {
  margin-bottom: 32px;
}
.search--bs {
  display: flex;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  padding: 14px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  min-height: 94px;
}
.search--bs .search--bs-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 20px;
}
.search--bs .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.search--bs .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.search--bs .bs__next {
  margin: 0px 8px 0px 16px;
}
.bs__next {
  font-size: 16px;
  color: #6a7074;
}
.search--bs-alt {
  min-height: 55px;
}
.search--bs-alt .bs__heading {
  margin-bottom: 0;
  margin-right: 30px;
}
.search--bs-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.search--bs-alt .listing__badge {
  margin-left: 8px;
  margin-right: 8px;
}
.listing__badge {
  font-size: 14px;
  min-width: 90px;
  min-height: 24px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
}
.listing__badge-draft {
  color: #6a7074;
  background-color: rgba(188, 186, 184, 0.2);
}
.listing__badge-draft:hover {
  color: #fff;
  background-color: #bcbab8;
}
.listing__badge-review {
  color: #ff8d39;
  background-color: rgba(255, 141, 57, 0.2);
}
.listing__badge-review:hover {
  color: #fff;
  background-color: #ff8d39;
}
.listing__badge-published {
  color: #fff;
  background-color: rgba(34, 34, 34, 0.8);
}
.listing__badge-published:hover {
  color: #fff;
  background-color: #222222;
}
.block-flex {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1 1;
}
.block--scroll {
  overflow-x: auto;
}
.block--scroll::-webkit-scrollbar {
  height: 4px;
  width: 10px;
}
.block--scroll::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 20px;
}
.photos--box {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  margin-bottom: 14px;
}
.block--search-row {
  display: flex;
  flex: 1 1;
  margin-left: -7px;
  margin-right: -7px;
}
.photos--search-row .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.block--search-row .block--search-col {
  padding-left: 7px;
  padding-right: 7px;
}
.block--search-row .photos--box {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.videos--search-row .block--search-col {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.video--box,
.video--iframe {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 14px;
}
.videos--search-row .video--box,
.videos--search-row .video--iframe {
  width: 100%;
  min-width: 10px;
}
.video--box .video--box-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 102, 178, 0.21);
}
.pdf__btn:not([href]),
.pdf__btn {
  color: #1c1f20;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  padding: 11px 16px;
  word-break: break-word;
  width: 100%;
  text-align: left;
  min-height: 72px;
  margin-bottom: 14px;
}
.pdf__btn .pdf__btn-icon {
  color: #6a7074;
  font-size: 38px;
  display: inline-flex;
  margin-right: 18px;
  min-width: 32px;
}
.pdf__btn:not([href]):not(:disabled):not(.disabled):active:focus,
.pdf__btn:not([href]):not(:disabled):not(.disabled):active,
.pdf__btn:not([href]):hover,
.pdf__btn:not([href]):focus,
.pdf__btn:not(:disabled):not(.disabled):active:focus,
.pdf__btn:not(:disabled):not(.disabled):active,
.pdf__btn:hover,
.pdf__btn:focus {
  background-color: #dcdbdb;
  border-color: #dcdbdb;
  color: #1c1f20;
  box-shadow: none;
}
.pdf__btn .pdf__btn-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.psf--search-row {
  margin-left: -7px;
  margin-right: -7px;
}
.psf--search-row .col {
  padding-left: 7px;
  padding-right: 7px;
}
.dashbaord--search .searchResults--block:last-child {
  margin-bottom: 0;
}

/*
  Admin Home Search & Results Style
*/

/*
  Subdomain Listing Style
*/

.subdomain--listing-content {
  margin-top: 80px;
}
.key--box-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.key--box-alt .keyBox--head {
  margin-bottom: 10px;
}
.key--box-alt .keyBox--head .small__heading {
  font-size: 16px;
}
.key--box-alt .keyBox--head i {
  font-size: 16px;
  margin-right: 10px;
}
.key--box-alt .keyBox--head i.icon-size {
  font-size: 15px;
}
.key--box-alt .heading__medium {
  line-height: 24px;
  margin-bottom: 8px;
}
.key--box-alt .small__paragraph {
  font-size: 16px;
  display: inline-flex;
  line-height: 20px;
}
.subListing--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}
.subListing--video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play__btn-blue:not([href]),
.play__btn-blue {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background-color: #0638e7;
  border: none;
}
.play__btn-blue:not([href]):not(:disabled):not(.disabled):active:focus,
.play__btn-blue:not([href]):not(:disabled):not(.disabled):active,
.play__btn-blue:not([href]):hover,
.play__btn-blue:not([href]):focus,
.play__btn-blue:not(:disabled):not(.disabled):active:focus,
.play__btn-blue:not(:disabled):not(.disabled):active,
.play__btn-blue:hover,
.play__btn-blue:focus {
  background-color: #0c30af;
  color: #fff;
  box-shadow: none;
  border: none;
}
.photos--off {
  margin-top: 110px;
  margin-bottom: 110px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 36px;
  padding-right: 36px;
}
.photos--row-lg .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.photos--box-lg {
  width: 200px;
  height: 200px;
  min-width: 200px;
}
.photos--box-md {
  width: 180px;
  height: 180px;
  min-width: 180px;
}
.photos__control {
  color: #bcbab8;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #bcbab8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}
.photos__control:hover,
.photos__control:focus {
  color: #fff;
  background-color: #bcbab8;
}
.photos__control-sm {
  font-size: 8px;
  width: 18px;
  height: 18px;
}
.photos--off .photos__control {
  position: absolute;
  top: 0;
  bottom: 14px;
  margin-top: auto;
  margin-bottom: auto;
}
.photos--off .photos__control-prev {
  left: 0;
}
.photos--off .photos__control-next {
  right: 0;
}
.sdl--combine {
  display: inline-block;
  width: 100%;
  padding: 100px 80px;
}
.descrip--combine .para__md-gray {
  line-height: 28px;
}
.floorplan--img {
  pointer-events: none;
}
.sapces--block {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.spaces--box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.spaces--box .spacesBox__line {
  width: 15px;
  height: 3px;
  border-radius: 10px;
  display: inline-flex;
  margin-right: 16px;
}
.spaces--box-available .spacesBox__line {
  background-color: #0638e7;
}
.spaces--box-common .spacesBox__line {
  background-color: #1c1f20;
}
.map--block {
  padding-top: 100px;
  padding-bottom: 100px;
}
.map--box {
  position: relative;
  width: 100%;
  height: 484px;
  border-radius: 20px;
  overflow: hidden;
}
.specs--box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.specs--box .specsBox__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.specs--box .specsBox__head-icon {
  font-size: 14px;
  color: #1c1f20;
  margin-right: 10px;
  display: inline-flex;
  flex-shrink: 0;
}
.specs--box .specsBox__head-text {
  font-size: 14px;
  color: #1c1f20;
  font-weight: 500;
  display: inline-flex;
  text-align: left;
}
.specs--box .para__sm-gray {
  line-height: 22px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.specs--box .specsBox__head-img,
.tempSpecs--block .tempSpecs__img {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.tempSpecs--block .tempSpecs__img {
  margin-bottom: 16px;
}
.specs--box-center {
  justify-content: center;
}
.specs--box .specs__arrow {
  color: #6a7074;
  font-size: 6px;
  cursor: pointer;
  display: inline-flex;
  margin-top: 2px;
  margin-bottom: -4px;
}
.heading--usual {
  display: flex;
  align-items: center;
}
.heading--usual .headingUsual__logo {
  width: 34px;
  height: 38px;
  display: flex;
  flex-shrink: 0;
  margin-right: 24px;
}
.heading--usual .headingUsual__text {
  color: #1c1f20;
  font-size: 18px;
  font-weight: 500;
}
.subdomain--btns-fixed {
  padding: 12px 0;
  box-shadow: 0px 1px 4px rgba(87, 86, 86, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
}
.tour__btn-gray {
  color: #6a7074;
  border-color: #bcbab8;
}
.tour__btn-gray:not(:disabled):not(.disabled).active,
.tour__btn-gray:not(:disabled):not(.disabled):active:focus,
.tour__btn-gray:not(:disabled):not(.disabled):active,
.tour__btn-gray:hover,
.tour__btn-gray:focus,
.tour__btn-gray.active {
  color: #0638e7;
  border-color: #0638e7;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.tel__btn.link__active .tour--btn-canvas {
  display: flex;
}
.video--modal .modal-dialog {
  max-width: 100%;
  margin: 0;
}
.video--modal .modal-content {
  background-color: transparent;
}
.video--modal .modal-body {
  padding: 0;
}
.video--canvas-expand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  margin: 60px 200px;
}
.video--canvas-expand .video--box-off {
  display: flex;
}
.video--canvas-expand .video--box-expand {
  width: 1220px;
  height: 686px;
  border-radius: 32px;
  overflow: hidden;
  background-color: #f4f4f4;
}
.video--canvas-expand .video--box-expand video {
  width: 100%;
  height: 100%;
}
.video--modal .cross__circle-modal {
  width: auto;
  height: auto;
  position: static;
  font-size: 16px;
  line-height: 16px;
  min-width: 20px;
  padding: 4px;
  margin-left: 24px;
  margin-bottom: auto;
  display: flex;
}
.subTours--block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.subTours--video {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background-color: #f6f6f6;
  margin-bottom: 16px;
}
.subTours--video iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.subTours--video .subToursVideo--overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.subTours--video .subToursVideo__play {
  color: #fff;
  font-size: 16px;
  display: inline-flex;
}
.play__btn-highlight:not([href]),
.play__btn-highlight {
  min-width: 200px;
  min-height: 48px;
  border-radius: 10px;
  color: #fff;
  background-color: #0638e7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-size: 16px;
  opacity: 0.9;
}
.play__btn-highlight:not([href]):not(:disabled):not(.disabled):active:focus,
.play__btn-highlight:not([href]):not(:disabled):not(.disabled):active,
.play__btn-highlight:not([href]):hover,
.play__btn-highlight:not([href]):focus,
.play__btn-highlight:not(:disabled):not(.disabled):active:focus,
.play__btn-highlight:not(:disabled):not(.disabled):active,
.play__btn-highlight:hover,
.play__btn-highlight:focus {
  background-color: #0c30af;
  color: #fff;
  box-shadow: none;
  border: none;
  opacity: 1;
}

/*
  Subdomain Listing Style
*/

/*
  Buildings Style
*/
.max-w-140p {
  max-width: 140px;
}
.mb-16p-important {
  margin-bottom: 16px !important;
}
.border-bottom-tabs {
  border-bottom: 1px solid #e5e5e4;
}
.tabBuilding--nav {
  /* border-bottom: 1px solid #e5e5e4; */
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.tabBuilding--nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tabBuilding--nav .nav-item {
  text-align: center;
  margin-bottom: -1px;
}
.tabBuilding--nav-fill .nav-item {
  flex: 1 1 auto;
}
.tabBuilding--nav .nav-link {
  color: #6a7074;
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 60px;
}
.tabBuilding--nav .nav-link .tb__link-icon {
  color: #6a7074;
  font-size: 16px;
  display: inline-flex;
}
.tabBuilding--nav .nav-link .tb__link-text {
  margin-left: 10px;
}
.tabBuilding--nav .nav-link.active {
  color: #0638e7;
  border-bottom-color: #0638e7;
}
.tabBuilding--nav .nav-link.active .tb__link-icon {
  color: #0638e7;
}
.shadowScroll--horizontal {
  position: relative;
}
.shadowScroll--horizontal:after {
  content: '';
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 78px;
  pointer-events: none;
  margin-bottom: 8px;
}
.tab--building,
.tab--building .tabBuilding--content,
.tab--building .tabBuildingContent--canvas,
.tab--building .tabBuildingContent--inner {
  display: flex;
  flex-direction: column;
}
.scroll--content::-webkit-scrollbar {
  width: 4px;
}
.scroll--content::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.tabBuilding--content {
  margin-top: 48px;
}
.custom__radioBtn-alt {
  font-size: 14px;
  color: #6a7074;
  display: inline-block;
  line-height: 18px;
}
.custom__radioBtn-alt:hover {
  color: #0638e7;
}
.custom__radioBtn-alt:hover .checkmark {
  border-color: #0638e7;
}
.buildings--map {
  height: 430px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.specs--box-alt .specsBox__head-icon {
  color: #6a7074;
  font-size: 16px;
}
.specs--box-alt .specsBox__head-text {
  color: #6a7074;
  font-size: 16px;
  font-weight: normal;
}
.specs--box-alt .specs__label {
  color: #1c1f20;
  font-size: 24px;
  display: inline-flex;
  line-height: 24px;
  text-decoration: underline;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.specs--box .specs__input {
  color: #1c1f20;
  font-size: 24px;
  line-height: 24px;
  padding: 0;
  height: calc(0.51em + 0.75rem + 2px);
  border: none;
  background-color: transparent;
  text-align: center;
}
.specs--box .specs__input::-webkit-input-placeholder {
  color: #1c1f20;
}
.specs--box .specs__input::-ms-input-placeholder {
  color: #1c1f20;
}
.specs--box .specs__input::placeholder {
  color: #1c1f20;
}
.amenities--box .specs__input {
  font-size: 16px;
}
.specs--box-alt .para__md-gray {
  color: #1c1f20;
  margin-left: 6px;
}
.specs--box-alt .specsBox__link {
  color: #1c1f20;
  font-size: 14px;
  text-decoration: underline;
  word-break: break-word;
}
.specs--box-alt .specsBox__head-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
}
.specs--box-alt .specs--box-base {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.amenities--box,
.specs--box-alt {
  padding: 15px 18px;
}
.amenities--box {
  justify-content: center;
}
.amenities--box .specs__label {
  color: #1c1f20;
  font-size: 16px;
  display: inline-flex;
  line-height: 24px;
  text-decoration: underline;
}
.specsCertificate--alt .specsBox__head {
  margin-bottom: 0;
}
.specsCertificate--alt .specsBox__head-text {
  color: #1c1f20;
  word-break: break-word;
  white-space: normal;
}
.specsCertificate--alt {
  justify-content: center;
}
.specs--box .specsBox__caret {
  color: #222222;
  font-size: 12px;
  display: inline-flex;
  cursor: pointer;
  margin-left: 4px;
}
.specs--box-alt .specsBox__line {
  height: 1px;
  width: 70px;
  background-color: #1c1f20;
  display: inline-flex;
}
.specs--box-options .ct--radio-alt,
.ct--radio-alt {
  padding: 0;
  min-height: 10px;
  margin-bottom: 0;
  border: 0;
}
.ct--radio-alt .checkmark {
  margin-right: 8px;
}
.specs--box-options {
  display: flex;
  align-items: center;
  width: 100%;
}
.specs--box-options .ct--radio-alt {
  width: 50%;
}
.specs--box-options .specsBox__head {
  margin-right: auto;
}
.specs--box-alt .specsBox__ec-img {
  width: 18px;
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-mb {
  margin-bottom: 12px;
}
.addAmenities__btn {
  padding: 15px 18px;
  color: #1c1f20;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-decoration: underline;
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: transparent;
  min-height: 104px;
}
.addAmenities__btn:not(:disabled):not(.disabled).active,
.addAmenities__btn:not(:disabled):not(.disabled):active:focus,
.addAmenities__btn:not(:disabled):not(.disabled):active,
.addAmenities__btn:hover,
.addAmenities__btn:focus,
.addAmenities__btn.active {
  color: #fff;
  border-color: #1c1f20;
  background-color: #1c1f20;
  outline: none;
  box-shadow: none;
  text-decoration: underline;
}
.epecs__edit {
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a7074;
  cursor: pointer;
  transition: all 0.2s ease;
}
.epecs__edit:hover {
  background-color: #1c1f20;
}
.specs__trash {
  color: #6a7074;
  font-size: 16px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.specs--box .specs__trash,
.specs--box .epecs__edit {
  position: absolute;
  right: 8px;
  top: 8px;
  display: none;
}
.specs--box:hover .specs__trash,
.specs--box:hover .epecs__edit {
  display: flex;
}
.timeBox--row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.timeBox--row .timeBox--col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeBox--row .timeBox--options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeBox--row .timeBox__separator {
  color: #1c1f20;
  font-size: 18px;
  font-weight: normal;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.time--box {
  width: 100%;
}
.time--bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.time--bar .time--dropdown {
  width: 50%;
  display: flex;
  align-items: center;
}
.time--bar .time--dropdown:first-child {
  justify-content: flex-end;
}
.time--bar .time--dropdown:last-child {
  justify-content: flex-start;
}
.time--dropdown .dropdown-toggle {
  color: #1c1f20;
  font-size: 18px;
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}
.time--dropdown .dropdown-toggle::after {
  margin-left: 6px;
  border-top: 0.24em solid;
  border-right: 0.22em solid transparent;
  border-left: 0.22em solid transparent;
  margin-top: 4px;
}
.time--dropdown.show > .dropdown-toggle,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.time--dropdown .dropdown-toggle:not(:disabled):not(.disabled):active:focus {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #1c1f20;
}
.time--dropdown .dropdown-menu {
  right: 0 !important;
  left: -3px !important;
  padding: 0;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #f6f6f6;
  margin: 0;
  color: #1c1f20;
  font-size: 14px;
  overflow-y: auto;
  max-height: 194px;
}
.time--dropdown .dropdown-menu::-webkit-scrollbar {
  width: 4px;
}
.time--dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background: #f6f6f6;
}
.time--dropdown .dropdown-menu .dropdown-item {
  padding: 6px 10px;
}
.time--dropdown .dropdown-menu .dropdown-item:hover,
.time--dropdown .dropdown-menu .dropdown-item:focus,
.time--dropdown .dropdown-menu .dropdown-item:active {
  background-color: #f8f9fa;
  color: #1c1f20;
}
.specs--options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
}
.specsOption--box {
  display: inline-flex;
  align-items: center;
}
.specsOption--box .specsOpt__icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #1c1f20;
  font-size: 8px;
  color: #fff;
  cursor: pointer;
  margin-right: 8px;
}
.specsOption--box .specsOpt__icon .icon-check {
  font-size: 12px;
}
.specsOption--box .specsOpt__text {
  color: #6a7074;
  font-size: 12px;
  font-weight: 500;
}

/*
  Buildings Style
*/

/*
  Listings Pages Style
*/

.off--canvas,
.availabelListing--sticky {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.off--canvas .off--canvas-content,
.availabelListing--sticky .off--canvas-content {
  flex: 1 0 auto;
}
.listing__btn {
  border: 1px solid #0638e7;
  background-color: #fff;
  border-radius: 10px;
  min-height: 144px;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
}
.listing__btn .listing__btn-bar {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.listing__btn .listing__btn-icon {
  color: #0638e7;
  font-size: 24px;
  display: inline-flex;
  margin-right: 16px;
}
.listing__btn .listing__btn-heading {
  color: #0638e7;
  font-size: 18px;
  display: inline-flex;
}
.listing__btn .para__sm-gray {
  text-align: left;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.listing__btn:not(:disabled):not(.disabled).active,
.listing__btn:not(:disabled):not(.disabled):active:focus,
.listing__btn:not(:disabled):not(.disabled):active,
.listing__btn:hover,
.listing__btn:focus,
.listing__btn.active {
  color: #fff;
  border-color: #0638e7;
  background-color: #0638e7;
  outline: none;
  box-shadow: none;
}
.listing__btn:hover .listing__btn-icon,
.listing__btn:focus .listing__btn-icon,
.listing__btn:hover .listing__btn-heading,
.listing__btn:focus .listing__btn-heading,
.listing__btn:hover .para__sm-gray,
.listing__btn:focus .para__sm-gray {
  color: #fff;
}
.listing__btn-unpublish {
  border-color: #bcbab8;
}
.listing__btn-unpublish .listing__btn-icon,
.listing__btn-unpublish .listing__btn-heading {
  color: #6a7074;
}
.listing__btn-unpublish:not(:disabled):not(.disabled).active,
.listing__btn-unpublish:not(:disabled):not(.disabled):active:focus,
.listing__btn-unpublish:not(:disabled):not(.disabled):active,
.listing__btn-unpublish:hover,
.listing__btn-unpublish:focus,
.listing__btn-unpublish.active {
  color: #fff;
  border-color: #222;
  background-color: #222;
  outline: none;
  box-shadow: none;
}
.specs--box-alt .specs__smText {
  color: #1c1f20;
  font-size: 14px;
  text-decoration: underline;
  display: inline-flex;
}
.listingMedia--block {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #bcbab8;
}
.listingMedia--content .listingMedia--block:first-child {
  padding-top: 0;
}
.ls--block-head {
  margin-bottom: 40px;
}
.photos--listings {
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
}
.photos--listings .photos__control-prev {
  left: 0;
}
.photos--listings .photos__control-next {
  right: 0;
}
.photos--listings .photos__control {
  position: absolute;
  top: 80px;
}
.photos--listing-row {
  display: flex;
  flex: 1 1;
  margin-left: -7px;
  margin-right: -7px;
}
.photos--listing-row .block--search-col {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}
.photos--listings .block--scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.render--detail {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.render--detail .link__gray-sm {
  margin-top: 14px;
}
.link__gray-sm:not([href]),
.link__gray-sm {
  color: #6a7074;
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
}
.link__gray-sm .link__icon {
  color: #6a7074;
  font-size: 16px;
  margin-left: 10px;
}
.link__gray-sm:not([href]):not(:disabled):not(.disabled).active,
.link__gray-sm:not([href]):not(:disabled):not(.disabled):active:focus,
.link__gray-sm:not([href]):not(:disabled):not(.disabled):active,
.link__gray-sm:not([href]):hover,
.link__gray-sm:not([href]):focus,
.link__gray-sm:not([href]).active,
.link__gray-sm:not(:disabled):not(.disabled).active,
.link__gray-sm:not(:disabled):not(.disabled):active:focus,
.link__gray-sm:not(:disabled):not(.disabled):active,
.link__gray-sm:hover,
.link__gray-sm:focus,
.link__gray-sm.active {
  color: #0638e7;
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.link__gray-sm:hover .link__icon,
.link__gray-sm:focus .link__icon {
  color: #0638e7;
}
.render--component {
  margin-bottom: 20px;
}
.listingPhotos--block {
  padding-bottom: 100px;
}
.media--video-block {
  display: flex;
  margin-bottom: 20px;
}
.media--video-block .media--video {
  width: 330px;
  height: 190px;
  overflow: hidden;
  background: #c4c4c4;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 14px;
}
.media--video-block .media--detail {
  min-width: 170px;
  flex: 1 1;
}
.mediaContent--combine {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.mediaContent--combine .mc--box {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.mediaContent--combine .mc--box .mc__icon {
  color: #6a7074;
  font-size: 14px;
  display: inline-flex;
  margin-right: 6px;
}
.mediaContent--combine .mc--box .para__sm-dark,
.mediaContent--combine .mc--box .para__sm-gray {
  line-height: 22px;
}
.pdf__btn-lg:not([href]),
.pdf__btn-lg {
  flex-direction: column;
  align-items: flex-start;
  min-height: 114px;
}
.floorplan--box {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 28px;
}
.floorplan--box .floorplanBox--img {
  width: 100%;
  max-width: 760px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  border-radius: 15px;
  overflow: hidden;
}
.textArea--sm textarea.form-control {
  height: 100px;
}
.attach__file {
  color: #0638e7;
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
}
.attach__file input[type='file'] {
  display: none;
}
.attach__file .attach__file-icon {
  margin-left: 12px;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.rl--group {
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}
.tourListing--canvas-alt .rl--group:last-child {
  margin-bottom: 0;
}
.lm--video-block {
  position: relative;
}
.lm--video-block .video--box {
  width: 100%;
  min-width: 10px;
  height: 80px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.lm--video-block .lmv__cross {
  color: #222;
  font-size: 9px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}
.lm--video-block .lmv__cross:hover,
.lm--video-block .lmv__cross:focus {
  color: #fff;
  background-color: #222;
}

/* .row--space-10 .col-12:nth-of-type(5n) .text-center.mb-4 {
  margin-bottom: 0;
} */

/*
  Listings Page Style
*/

/*
  Virtual Tour Before Started Style
*/

.started--content {
  width: 100%;
  max-width: 400px;
}
.started--canvas {
  display: flex;
}
.started--content .started--icon {
  color: #0638e7;
  font-size: 36px;
  margin-bottom: 24px;
  display: inline-flex;
}
.blue__heading {
  font-size: 34px;
  color: #0638e7;
  font-weight: 500;
  line-height: 40px;
}
.started--imgs {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: self-start;
  pointer-events: none;
  width: 100%;
  max-width: 580px;
}
.started--imgs .change__browser {
  display: inline-flex;
  padding-top: 84px;
  pointer-events: none;
}
.started--imgs .browser__direction {
  display: inline-flex;
  pointer-events: none;
}

/*
  Virtual Tour Before Started Style
*/

/*
  Live tour/tour presentation pdf Style
*/

.liveTour--custom.liveTour--pdf {
  background-color: #050505;
}
.liveTour--pdf-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 8px 1px 8px;
}
.liveTour--pdf-content::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}
.liveTour--pdf-content::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.4);
}
.liveTour--pdf-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1 1;
  max-height: 100%;
  margin-left: -7px;
  margin-right: -7px;
}
.liveTour--pdf-row .liveTour--pdf-col {
  padding-left: 7px;
  padding-right: 7px;
  width: 25%;
  position: relative;
}
.liveTour--pdf-row .liveTour--pdf-col .gallery__trash {
  position: absolute;
  right: 0;
  top: -8px;
  opacity: 0;
  background-color: #ddd;
}
.liveTour--pdf-row .liveTour--pdf-col:hover .gallery__trash {
  opacity: 1;
}
.liveTour--pdf .liveTour--head {
  display: flex;
}
.pdf__btn-liveTour:not([href]),
.pdf__btn-liveTour {
  min-height: 82px;
  line-height: 20px;
  margin-bottom: 14px;
}
.tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
  width: 25%;
}
.liveTour--pdf-canvas {
  display: flex;
  flex: 1 1;
  overflow-y: auto;
}
.liveTour--pdf-content .liveTour--pdf-document {
  overflow-y: auto;
  border-radius: 15px;
  background-color: #fff;
}
.liveTour--pdf-document::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}
.liveTour--pdf-document::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.4);
}
.pdf-show-main .pdf--reader {
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  padding: 10px;
}
.liveTour--pdf-canvas .close-btn {
  cursor: pointer;
}
.liveTour--top .liveTour--pdf-canvas {
  margin-top: 32px;
}
.tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
  width: 25%;
}
.liveTour--custom.liveTour--pdf-n {
  background-color: #535659;
}
.liveTour--custom.liveTour--pdf-n .liveTour--pdf-canvas {
  margin-top: 0;
}
.tourPre--top .liveTour--pdf,
.tourEdit--top .liveTour--pdf,
.liveTour--top-broker .liveTour--pdf {
  padding-bottom: 0 !important;
}

/*
  Live tour/tour presentation pdf Style
*/

/*
  Ratio Screen Style
*/

.ratio--content {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 40px 20px;
}
.ratio--block {
  width: 100%;
  max-width: 372px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ratio--block .ratio__icon {
  color: #fff;
  font-size: 42px;
  display: inline-flex;
  margin-bottom: 24px;
}
.ratio--block .ratio__text {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: normal;
}

/*
  Ratio Screen Style
*/

/*
  Tour Presentation New Style
*/

.presentation--page {
  min-height: 100vh;
  overflow-y: hidden;
  padding-top: 36px;
  padding-bottom: 48px;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  position: relative;
}
.presentation--navbar {
  margin-bottom: 28px;
  padding: 0;
  min-height: 48px;
  justify-content: center;
  position: static;
  position: initial;
}
.presentation--container {
  padding-left: 70px;
  padding-right: 70px;
}
.presentation--navbar .presentation--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.presentation--navbar .preNavbar--content {
  margin-left: auto;
}
.suite--component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 30px;
}
.suite--component .heading__md-dark {
  text-align: right;
}
.suite__badge {
  font-size: 12px;
  color: #0638e7;
  display: inline-block;
  background-color: rgba(6, 56, 231, 0.1);
  padding: 4px 10px;
  border-radius: 20px;
  min-height: 22px;
}
.presentation--navbar .app__button-blue {
  min-width: 160px;
}
.dropdown-toggle.no-caret:after {
  content: none;
}
.preNavbar--content .app__button-blue .itinerary__icon {
  display: inline-flex;
  margin-left: 10px;
}
.presentation--welcome {
  padding: 70px 40px 36px 70px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.25);
  border-radius: 20px;
  margin-bottom: 22px;
  width: 100%;
  max-width: 814px;
  margin: auto;
  overflow-y: hidden;
}
.presentation--welcome .preWelcome--main {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.preWelcome--main::-webkit-scrollbar {
  width: 4px;
}
.presentation--welcome .preWelcome--main::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.heading__lg-dark {
  font-size: 36px;
  color: #1c1f20;
}
.heading__lg-gray {
  font-size: 36px;
  color: #6a7074;
}
.heading__lg-white {
  font-size: 36px;
  color: #fff;
}
.preWelcome--head {
  display: flex;
  margin-bottom: 22px;
}
.preWelcome--head .preWelcome__icon {
  font-size: 40px;
  color: #1c1f20;
  display: inline-flex;
  margin-right: 16px;
}
.preWelcomeForm--row {
  margin-left: -5px;
  margin-right: -5px;
}
.preWelcomeForm--row .col {
  padding-left: 5px;
  padding-right: 5px;
}
.forms--group-alt .form-control {
  border-radius: 10px;
}
.forms--group-alt .form-control::-webkit-input-placeholder {
  color: #bcbab8;
}
.forms--group-alt .form-control::-ms-input-placeholder {
  color: #bcbab8;
}
.forms--group-alt .form-control::placeholder {
  color: #bcbab8;
}
.w-80 {
  width: 80%;
}
.presentation--off {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: hidden;
  position: relative;
}
.presentation--off .presentation--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  overflow-y: hidden;
}
.presentation--content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.25);
  border-radius: 20px;
  width: calc(100% - 156px);
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  overflow-y: hidden;
  position: relative;
}
.presentation--content .presentation--main {
  display: flex;
  align-items: stretch;
  flex: 1 1;
  overflow-y: hidden;
}
.presentation--content .preContent--detail {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1 1;
  width: 54%;
  overflow-y: hidden;
  max-height: 100%;
}
.presentation--main-odd .preContent--detail {
  padding: 50px 10px 20px 60px;
}
.presentation--main-even .preContent--detail {
  padding: 50px 0px 20px 70px;
}
.presentation--content .preContent--box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 46%;
  border-radius: 20px;
  overflow: hidden;
}
.presentation--content .preContent--box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.locationBox--badge .location__badge-link {
  margin: 0px 10px 10px 0px;
}
.location__badge-link {
  color: #0638e7;
  font-size: 14px;
  padding: 4px 12px;
  border: 1px solid #0638e7;
  border-radius: 20px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.location__badge-link:hover,
.location__badge-link:focus {
  color: #fff;
  background-color: #0638e7;
}
.preControl--outer {
  position: absolute;
  top: 50px;
  bottom: 50px;
  display: flex;
  align-items: center;
}
.preControl--outer.preControl--outer-prev {
  left: 0;
}
.preControl--outer.preControl--outer-next {
  right: 0;
}
.preControl--outer.preControl--outer-prev:before,
.preControl--outer.preControl--outer-next:after {
  content: '';
  width: 36px;
  height: 100%;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.25);
  display: inline-block;
}
.preControl--outer.preControl--outer-prev:before {
  border-radius: 0px 20px 20px 0px;
}
.preControl--outer.preControl--outer-next:after {
  border-radius: 20px 0px 0px 20px;
}
.presentation--control {
  width: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-shrink: 0;
  margin: 0 !important;
}
.presentation--control-prev {
  padding-left: 2px;
}
.presentation--control-next {
  padding-right: 2px;
}
.preControl--box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preControl--box .preControlBox__icon {
  font-size: 16px;
  display: inline-flex;
  margin-bottom: 18px;
}
.preControl--box .preControlBox__text {
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  word-break: break-word;
  margin-bottom: 14px;
}
.preControl--box .preControlBox__arrow {
  font-size: 16px;
  display: inline-flex;
}
.presentation--control-prev .preControl--box {
  color: #bcbab8;
}
.presentation--control-prev .preControl--box:hover,
.presentation--control-next .preControl--box {
  color: #6a7074;
}
.presentation--content .expand__btn {
  position: absolute;
  top: 24px;
  right: 28px;
}
.expand__btn {
  font-size: 14px;
  color: #6a7074;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(188, 186, 184, 0.5);
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
}
.expand__btn:hover,
.expand__btn:focus {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.presentation--menu {
  margin-left: 20px;
}
.preMenu__btn {
  color: #1c1f20;
  font-size: 14px;
  padding: 1px;
  border-radius: 10px;
  border: 1px solid #1c1f20;
  background-color: transparent;
  width: 58px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preMenu__btn .preMenu__btn-span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.preMenu__btn .preMenu__btn-span .icon-cross-alt {
  font-size: 16px;
}
.preMenu__btn:not(:disabled):not(.disabled):active:focus,
.preMenu__btn:not(:disabled):not(.disabled):active,
.preMenu__btn:hover,
.preMenu__btn:focus,
.preMenu__btn:active {
  background-color: transparent;
  border-color: #1c1f20;
  color: #1c1f20;
  box-shadow: none;
}
.presentation--menu .preMenu--main {
  position: absolute;
  right: 0px;
  top: 86px;
  bottom: 0;
  flex: 1 1;
  min-width: 146px;
  padding-right: 44px;
  padding-top: 34px;
  padding-bottom: 48px;
  text-align: center;
  background-color: #e5e5e5;
  z-index: 5;
  overflow-y: auto;
  display: none;
}
.presentation--menu .preMenu--main-show {
  display: block;
}
.preMenu__list {
  overflow-y: auto;
  max-height: 100%;
}
.preMenu__list li .preControl--box {
  color: #bcbab8;
  margin-bottom: 38px;
}
.preMenu__list li .preControl--box.active {
  color: #1c1f20;
}
.preMenu__list li:last-child .preControl--box {
  margin-bottom: 0;
}
.preMenu__list li .preControl--box .preControlBox__icon {
  margin-bottom: 14px;
}
.preMenu__list li .preControl--box .preControlBox__text {
  margin-bottom: 0;
  line-height: normal;
  line-height: initial;
}
.preMenu__list::-webkit-scrollbar {
  width: 4px;
}
.preMenu__list::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.presentation--content .preContent--head {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  width: 100%;
  padding-right: 40px;
}
.presentation--content .preContent--off {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.component--scroll::-webkit-scrollbar,
.presentation--content .preContent--off::-webkit-scrollbar {
  width: 4px;
}
.component--scroll::-webkit-scrollbar-thumb,
.presentation--content .preContent--off::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.preGallery__ul .preGallery__listItem {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.preGallery--col {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.preGallery--col .preGallery--col-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  min-height: 50%;
  max-height: 50%;
  width: 100%;
}
.preGallery--col .preGallery--box {
  border-radius: 10px;
  margin: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  cursor: pointer;
  height: calc(100% - 10px);
  width: calc(25% - 12px);
  max-width: 25%;
}
.preGallery--carousel {
  margin-top: -5px;
  margin-left: -5px;
  margin-right: -5px;
  /* flex: 1; */
}
.preGallery--carousel .react-multi-carousel-track {
  max-height: 100%;
  min-height: 100%;
  flex: 1 1;
}
.preGallery__list li button {
  width: 10px;
  height: 10px;
  border: none;
  background-color: #c4c4c4;
  flex-shrink: 0;
}
.preGallery__list li.react-multi-carousel-dot--active button {
  background-color: #0638e7;
}
.presentation--footer {
  display: flex;
  justify-content: space-around;
  padding: 10px 20px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 84px;
  background-color: #e5e5e5;
}
.presentation--footer .preControl--box {
  color: #6a7074;
}
.presentation--footer .preControl--box .preControlBox__icon {
  margin-bottom: 10px;
  line-height: 14px;
}
.presentation--footer .preControl--box .preControlBox__text {
  margin-bottom: 10px;
  line-height: 14px;
}
.accordion > .preFooter--card,
.preFooter--card {
  background-color: transparent;
  border: none;
}
.accordion > .preFooter--card .card-header,
.preFooter--card .card-header {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}
.preFooter--card .card-header .btn-link {
  color: #1c1f20;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
}
.preFooter--card .card-header .btn-link .preControlBox__icon {
  font-size: 14px;
  display: inline-flex;
  margin-bottom: 8px;
}
.preFooter--card .card-header .btn-link .preControlBox__text {
  font-size: 13px;
  display: inline-flex;
  margin-bottom: 8px;
  line-height: 14px;
}
.preFooter--card .collapsing,
.preFooter--card .collapse {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.preFooter--card .card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
  min-height: 100%;
}
.preFooter--card .card-body .preFooter--inner {
  padding: 12px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  width: 100%;
}
.preFooter--card .card-body .collapse__btn {
  width: 40px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 10px;
  display: inline-flex;
  padding: 0;
}
.preFooter--card .card-body .collapse__btn:hover,
.preFooter--card .card-body .collapse__btn:focus {
  box-shadow: none;
}
.preFooter--card .preMenu__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  width: 100%;
}
.preFooter--card .preMenu__list li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.preFooter--card .preMenu__list li:nth-last-child(-n + 3) .preControl--box {
  margin-bottom: 0;
}
.preFooter--card-show .collapse.show {
  display: none;
}
.preContent--location {
  display: flex;
  flex: 1 1;
  border-radius: 20px;
  overflow: hidden;
  min-height: 240px;
}
.preContent--floorplan {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 20px;
  border-radius: 20px;
  max-width: 76%;
  margin-left: auto;
  margin-right: auto;
}
.preContent--floorplan.preContent--floorplan-expand {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  max-width: 100%;
}
.preContent--floorplan .preFloorplan--inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  border-radius: 10px;
  overflow: hidden;
}
.preContent--floorplan .preFloorplan--inner img {
  object-fit: contain;
  border-radius: 10px;
}
.presentation--content .specs__list li {
  font-size: 20px;
  line-height: 24px;
}
.preAmenities--carousel,
.preSpecs--carousel {
  flex: 1 1;
  padding-left: 80px;
  padding-right: 80px;
  overflow-y: auto;
}
.preSpecs__list {
  max-height: 100%;
}
.preAmenities--carousel::-webkit-scrollbar,
.preSpecs--carousel::-webkit-scrollbar,
.preAmenities__list::-webkit-scrollbar,
.preSpecs__list::-webkit-scrollbar {
  width: 4px;
}
.preAmenities--carousel::-webkit-scrollbar-thumb,
.preSpecs--carousel::-webkit-scrollbar-thumb,
.preAmenities__list::-webkit-scrollbar-thumb,
.preSpecs__list::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.presentation--content .building--widget {
  min-height: 58px;
  padding: 8px 14px;
  margin-bottom: 20px;
}
.presentation--content .building--widget .bw__icon {
  font-size: 20px;
  margin-right: 10px;
  min-width: 20px;
}
.presentation--content .building--widget .bw__heading,
.presentation--content .building--widget .bw__para {
  font-size: 14px;
}
.presentation--content .preSpecs--col {
  align-items: flex-start;
}
.presentation--content .certification--widget .bw__icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.presentation--content .certification--widget .bw__heading {
  line-height: normal;
  line-height: initial;
}
.presentation--content .building--widget .bw__arrow {
  font-size: 6px;
}
.presentation--content .preAmenities--col {
  display: flex;
  align-items: flex-start;
  flex: 1 1;
  margin-left: -6px;
  margin-right: -6px;
}
.presentation--content .preAmenities--col .amenities--widget {
  width: calc(25% - 12px);
  margin-left: 6px;
  margin-right: 6px;
}
.presentation--content .amenities--widget .bw__heading {
  line-height: 18px;
}
.preAmenities__list {
  max-height: 100%;
}
.presentation--content .prePdf--col {
  display: flex;
}
.presentation--content .prePdf--col .pdf__btn {
  width: calc(25% - 12px);
  max-width: calc(25% - 12px);
  min-width: 10px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 20px;
}
.preVideo--canvas {
  padding-top: 48%;
  position: relative;
  overflow: hidden;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 14.8%);
  margin: auto;
}
.presentation--content-video .preContent--off {
  width: 100%;
}
.preVideo--canvas .static-video-player {
  border: none;
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presentation--content.presentation--content-video {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.presentation--content.presentation--content-video .expand__btn {
  background-color: #fff;
  border-color: #fff;
}
.presentation--content.preVideo--content {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  justify-content: center;
}
.presentation--navbar .location--head .location__destination {
  width: 130px;
  height: 39px;
}
.presentation--navbar .location--head .location__destination img {
  width: auto;
  height: inherit;
}
.presentation--content .preContent--off-pdf {
  background-color: #535659;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: center;
  flex: 1 1;
  overflow-y: hidden;
}
.presentation--content .preContent--off-pdf .pdf--reader {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
.presentation--content .preContent--off-pdf .pdf--reader::-webkit-scrollbar {
  height: 4px;
}
.presentation--content .minimize__pdf {
  border: none;
  color: #fff;
  font-size: 14px;
  right: 24px;
  background-color: #535659;
}
.presentation--content .minimize__pdf:hover,
.presentation--content .minimize__pdf:focus,
.presentation--content .minimize__pdf:active {
  color: #fff;
  background-color: #535659;
}
.preContent--off-pdf .pdf--options {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.preContent--off-pdf .pdf--options .pdfOtion__btn {
  font-size: 16px;
  color: #535659;
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border: none;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.2);
  margin-top: 12px;
}
.preContent--off-pdf .pdf--options .pdfOtion__btn:first-child {
  margin-bottom: 10px;
}
.preContent--off-pdf .pdf--options .pdfOtion__btn:hover,
.preContent--off-pdf .pdf--options .pdfOtion__btn:focus {
  color: #535659;
  background-color: #f2f2f2;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.2);
}
.presentation--content .minimize__pdf:hover,
.presentation--content .minimize__pdf:focus,
.presentation--content .minimize__pdf:active {
  background-color: transparent;
  border: none;
  color: #fff;
}
.preVideo--col .preGallery--col-inner {
  flex: initial;
  min-height: 10px;
}
.preVideo--col .static-video-player {
  width: 100%;
  padding-top: 56.25%;
  height: auto;
  margin: 0px;
}
.preVideo--col .static-video-player > div {
  object-fit: initial;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.presentation--content.preTour--content {
  padding: 0;
}
.preTour--content .preContent--off {
  overflow: hidden;
}
.preTour--content .preContent--tour {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.preTour--content .preContent--tour iframe {
  min-height: 280px;
}
.preTour--content .tour--btn-canvas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  flex: 1 1;
  width: calc(100% - 350px);
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.preTour--content .tour--btn-canvas .tour__btn {
  margin: 3px;
  min-width: 82px;
}
.presentation--content .preContent--gallery {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: hidden;
}
.presentation--content .preContent--gallery .preFloorplan--inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  max-height: 100%;
  min-height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.presentation--content .preContent--gallery .preFloorplan--inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
  Tour Presentation New Style
*/

/*
  Template Style
*/

.app--container {
  max-width: 1340px;
  padding-left: 20px;
  padding-right: 20px;
}
.template--head {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
}
.template--navbar {
  padding: 24px 0;
}
.menu__btn {
  font-size: 16px;
  background-color: transparent;
  padding: 0;
  border: none;
  display: inline-flex;
  margin-left: auto;
}
.menu__btn:not(:disabled):not(.disabled):active:focus,
.menu__btn:hover,
.menu__btn:focus,
.menu__btn:active {
  background-color: transparent;
  color: #fff;
  border: none;
  box-shadow: none;
}
.template--navbar .navbar-brand img {
  width: auto;
  max-width: 150px;
  height: 39px;
  object-fit: contain;
}
.template--banner {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.template--banner .app--container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.template--banner .tempBanner--detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  position: relative;
}
.template--banner .tempBanner--detail .para__md-white {
  line-height: 28px;
}
.template--carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
}
.template--carousel .carousel-item {
  height: 800px;
}
.template--carousel .carousel-item:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(28, 31, 32, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.common--carousel .carousel-indicators li {
  width: 32px;
  height: 8px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  margin: 4px;
}
.common--carousel .carousel-indicators li.active {
  background-color: #fff;
  margin: 4px;
}
.template--carousel .carousel-indicators {
  margin-bottom: 24px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.heading__tem-white {
  font-size: 24px;
  color: #fff;
}
.heading__tem-dark {
  font-size: 24px;
  color: #1c1f20;
}
.heading__tem-blue {
  font-size: 24px;
  color: #0638e7;
}
.add--box {
  display: flex;
}
.add--box .addBox__icon {
  font-size: 18px;
  color: #fff;
  display: inline-flex;
  margin-right: 8px;
}
.add--box .addBox__text {
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  display: inline-flex;
  line-height: 20px;
}
.temp--btn-canvas .btn {
  min-width: 220px;
}
.temp--about {
  position: relative;
  min-height: 800px;
}
.temp--about > .row > .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.temp--about-1 .tempAbout--img:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #f9f9f9;
}
.temp--about-2 .tempAbout--img:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(196, 196, 196, 0.1);
}
.temp--about .tempAbout--col {
  padding: 88px 44px;
  position: relative;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  width: 100%;
}
.temp--about .tempAbout--img-box {
  width: 100%;
  max-width: 420px;
  height: 624px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: auto;
}
.tempAbout__marker {
  color: #0638e7;
  font-size: 32px;
  width: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.temp--about .tempAbout--content {
  background-color: #1c1f20;
  z-index: 1;
  justify-content: space-between;
}
.temp--about .tempAbout--content .heading__small-white {
  color: #c4c4c4;
  margin-bottom: 80px;
}
.temp--about .tempAbout--content .para__md-white {
  line-height: 28px;
}
.temp--about .tempAbout--btn-canvas {
  margin-top: 20px;
  z-index: 5;
}
.temp--specification .tempAbout--content {
  padding-top: 70px;
  padding-bottom: 70px;
}
.block--carousel {
  margin-top: 50px;
  display: flex;
  flex-direction: column-reverse;
}
.block--carousel .carousel-indicators {
  position: static;
  margin: 0;
  justify-content: flex-start;
}
.block--carousel .carousel-indicators li {
  width: 32px;
  height: 8px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin: 4px;
}
.block--carousel .carousel-indicators li.active {
  background-color: #0638e7;
  border-color: #0638e7;
  margin: 4px;
}
.info--carousel-block .tempAbout--content {
  background-color: transparent;
  justify-content: center;
}
.info--carousel-block .tempAbout--col-inner {
  margin-bottom: 78px;
  min-height: 168px;
}
.info--carousel-block:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 50%;
  background-color: #1c1f20;
}
.info--carousel .carousel-item {
  z-index: 2;
}
.info--carousel-block .info--carousel .carousel-indicators {
  position: absolute;
  bottom: 32%;
  max-width: 50%;
  padding-left: 40px;
}
.info--carousel-block .ic--overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 88px 44px;
}
.info--carousel-block .ic--overlay .heading__small-white {
  color: #c4c4c4;
  margin-bottom: 80px;
}
.specs--carousel .carousel-inner {
  min-height: 248px;
  margin-bottom: 24px;
}
.heading__th-dark {
  font-size: 30px;
  color: #1c1f20;
}
.temp--combine {
  padding: 80px 0;
}
.suite--block {
  margin-top: 100px;
}
.suite--block .suite--img {
  height: 386px;
  border-radius: 20px;
  border-color: brown;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
}

.suite--block .suiteBtn--canvas {
  margin-top: 18px;
}
.key--box-temp {
  border: 1px solid #0638e7;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 114px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.key--box-temp .keyBox--head {
  margin-bottom: 8px;
}
.key--box-temp .keyBox--head i {
  font-size: 16px;
}
.key--box-temp .keyBox--head i.icon-size {
  font-size: 14px;
}
.key--box-temp .small__heading {
  font-size: 16px;
  line-height: 18px;
}
.key--box-temp .heading__medium-dark {
  font-size: 18px;
  line-height: 22px;
}
.key--box-temp .small__paragraph {
  font-size: 16px;
  color: #1c1f20;
  line-height: 18px;
}
.specs--box-temp {
  background-color: transparent;
  border: 1px solid #f5f5f5;
  justify-content: flex-start;
}
.specs--box-temp .specsBox__head {
  margin-top: 8px;
  margin-bottom: 6px;
}
.specs--box-temp .specsBox__head-icon,
.specs--box-temp .specsBox__head-text {
  color: #c4c4c4;
}
.specs--box-temp .para__sm-gray {
  color: #f5f5f5;
  margin: 0;
  line-height: 19px;
}
.specs--box-temp.specs--box-center {
  justify-content: center;
}
.temp--sustain {
  padding-top: 100px;
  padding-bottom: 80px;
}
.sustain--box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  background-color: rgba(196, 196, 196, 0.1);
  border-radius: 20px;
  min-height: 216px;
  margin-bottom: 20px;
}
.sustain--box .sustainBox__icon {
  font-size: 40px;
  color: #1c1f20;
  display: inline-flex;
}
.sustain--box .sustainBox__img {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
}
.dashbaord--opt-head .admin__button-sm .icon-xl,
.outline__button-blue .icon-xl,
.admin__button .icon-xl {
  font-size: 22px;
}
.template--radio {
  flex-direction: column;
  padding: 16px;
}
.template--radio .checkmark {
  margin-right: 8px;
}
.template--radio .template--radio-frame {
  height: 366px;
  background-color: #c4c4c4;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.template--radio .templateRadio--overlay {
  background-color: rgba(34, 34, 34, 0.75);
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.template--radio .templateRadio--overlay-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.template--radio .templateRadio--overlay-box .btn {
  min-width: 170px;
}
.template--radio .template--radio-frame:hover .templateRadio--overlay {
  display: flex;
}
.template--radio .template--radio-frame img {
  height: inherit;
  object-fit: cover;
}
.template--radio.active .heading__md-dark {
  color: #0638e7;
}
.mt-80 {
  margin-top: 80px;
}
.mb-80 {
  margin-bottom: 80px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.bg--light {
  background-color: rgba(196, 196, 196, 0.1);
}
.template--head-two .template--banner {
  position: static;
}
.template--head-two .menu__btn {
  color: #222222;
}
.add--box-dark .addBox__text,
.add--box-dark .addBox__icon {
  color: #1c1f20;
}
.add--box-gray .addBox__text,
.add--box-gray .addBox__icon {
  color: #6a7074;
}
.template--head-two .template--banner .tempBanner--detail {
  align-items: center;
  text-align: center;
  margin-top: 100px;
}
.tempBanner--carousel {
  margin-top: 44px;
}
.tempBanner--carousel .carousel-indicators {
  margin-bottom: 20px;
}
.template--head-two .tempBanner--img {
  width: 100%;
  max-width: 1080px;
  height: 600px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}
.link__semibold:not([href]),
.link__semibold {
  font-weight: 600;
}
.tempTwo--about .container--xs {
  max-width: 640px;
}
.temp--map {
  height: 484px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin-top: 80px;
}
.tempListing--row {
  margin-top: 60px;
  margin-bottom: 40px;
}
.tempListing--block {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 5px rgba(247, 247, 247, 0.06);
  display: flex;
  flex-direction: column;
  min-height: 640px;
  margin-bottom: 20px;
}
.tempListing--block .tempListing--img {
  width: 100%;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tempListing--block .tempListing--detail {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.info--item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.info--item .infoItem__icon {
  font-size: 16px;
  color: #222;
  display: inline-flex;
  margin-right: 10px;
  margin-top: 2px;
  line-height: 16px;
}
.info--item .infoItem__text {
  font-size: 16px;
  color: #222;
  display: inline-flex;
  line-height: 20px;
}
.info--item .infoItem__para {
  font-size: 24px;
  color: #222;
  display: inline-flex;
  line-height: 26px;
}
.info--item .infoItem--col {
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
}
.info--item .infoItem--r {
  justify-content: flex-end;
  align-self: center;
  text-align: right;
}
.info--item .infoItem--r .infoItem__text {
  margin-left: 8px;
  align-items: center;
}
.info__list {
  margin-top: 25px;
}
.info__list li:last-child .info--item {
  margin-bottom: 0;
}
.temp--specifications {
  padding-bottom: 44px;
}
.tempSpecs--block {
  margin-bottom: 36px;
}
.tempSpecs--block .tempSpecs__icon {
  font-size: 32px;
  color: #1c1f20;
  display: inline-flex;
  margin-bottom: 16px;
}
.tempSpecs--block .heading__md-dark {
  margin-bottom: 10px;
}
.tempSpecs--block .specs__arrow {
  color: #6a7074;
  font-size: 6px;
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
}
.temp--contacts {
  padding-bottom: 60px;
}
.tempContact--box {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.tempContact--box img {
  transition: all 0.3s ease-in;
  object-position: top;
}
.tempContact--box .tempContact--mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 600px;
  padding: 60px;
  height: 600px;
  opacity: 0;
  transform: translate(1265px, 1145px) rotate(45deg);
  transition: all 0.3s ease-in-out;
}
.tempContact--box .tempContact--overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tempContact--box .heading__tem-white {
  transform: translate(1200px, -1200px);
  transition: all 0.3s ease-in-out;
}
.tempContact--box .heading__md-white {
  transform: translate(-1200px, 1200px);
  transition: all 0.3s ease-in-out;
}
.tempContact--box .tempContact__text {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 40px;
  transform: translate(0px, 1125px);
  transition: all 0.3s 0.1s ease-in-out;
}
.tempContact--box .outline__button-white {
  transform: translate(0px, 1125px);
  transition: all 0.3s 0.1s ease-in-out;
}
.tempContact--box:hover .tempContact--mask {
  opacity: 1;
  transform: translate(-92px, -100px) rotate(45deg);
}
.tempContact--box:hover .heading__tem-white {
  transform: translate(0px, 0px);
  transition-delay: 0.2s;
}
.tempContact--box:hover .heading__md-white {
  transform: translate(0px, 0px);
  transition-delay: 0.3s;
}
.tempContact--box:hover .tempContact__text.mb-4 {
  transform: translate(0px, 0px);
  transition-delay: 0.4s;
}
.tempContact--box:hover .tempContact__text {
  transform: translate(0px, 0px);
  transition-delay: 0.5s;
}
.tempContact--box:hover .outline__button-white {
  transform: translate(0px, 0px);
  transition-delay: 0.6s;
}
.template--head-three .template--banner .tempBannerTh--row {
  align-items: center;
  flex: 1 1;
}
.temp--sustain-alt {
  text-align: center;
}
.temp--sustain-alt .sustain--box .sustainBox__icon,
.temp--sustain-alt .sustain--box .sustainBox__img {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.temp--sustain-alt .sustain--box .heading__md-dark {
  font-size: 32px;
}
.template--head-three .template--carousel {
  width: 50%;
  margin-left: auto;
}
.template--head-three .template--banner .app--container {
  position: relative;
  z-index: 1;
}
.link__blue-lg .link__icon-forward {
  font-size: 22px;
}
.tempLink--canvas {
  margin-top: 54px;
}
.tempLink--canvas .link__dark,
.tempLink--canvas .link__blue-lg {
  margin-right: 38px;
  margin-bottom: 10px;
}
.tempLink--canvas .link__dark {
  font-size: 16px;
}
.temp--about-alt .tempAbout--content {
  background-color: transparent;
  justify-content: flex-start;
}
.temp--about-alt .row .col:first-child {
  border-top: 1px solid rgba(196, 196, 196, 0.4);
  border-right: 1px solid rgba(196, 196, 196, 0.4);
}
.link__dark.hover-none:hover,
.link__dark.hover-none:focus,
.link__blue-lg.hover-none:hover,
.link__blue-lg.hover-none:focus {
  text-decoration: none;
}
.tempContact--th .row .col {
  border: 1px solid rgba(196, 196, 196, 0.4);
  margin-left: -1px;
  margin-top: -1px;
}
.tempContact--th .row .col:nth-child(3n + 1) {
  margin-left: 0;
}
.tempConTh--block {
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 20px;
}
.tempConTh--block .tempConTh--img {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
}
.tempConTh--block .tempConTh--img img {
  object-position: top;
}
.tempConTh--block .tempConTh--detail .heading__md-gray {
  margin-bottom: 24px;
}
.tempConTh--block .tempConTh--detail .heading__md-gray span:first-child {
  margin-bottom: 10px;
}
.tempConTh--block .tempCon__link {
  font-size: 18px;
}
.tempListing--th {
  padding-top: 160px;
  padding-bottom: 160px;
}
.tempListingTh--canvas {
  padding-top: 40px;
  border-top: 1px solid rgba(196, 196, 196, 0.4);
}
.tempListingTh--block {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}
.tempListingTh--block .tempListingTh--overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px 24px 24px 24px;
  background-color: rgba(28, 31, 32, 0.4);
  display: flex;
  align-items: flex-end;
}
.tempListingTh--block .tempListingTh--overlay-inner {
  display: flex;
  flex-direction: column;
}
.tempSpecs--th {
  padding-bottom: 160px;
}
.tempSpecs--block-alt {
  padding: 30px;
  min-height: 260px;
  border: 1px solid rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -1px 0px 0px -1px;
}
.tempSpecs--block-alt .tempSpecs__icon {
  color: #0638e7;
}
.sustain--row-th .col:first-child .sustain--box-th {
  margin-left: 0;
}
.temp--sustain-th {
  padding-top: 0;
  padding-bottom: 160px;
}
.sustain--box-th {
  border: 1px solid rgba(196, 196, 196, 0.4);
  background-color: transparent;
  border-radius: 0;
  margin: -1px 0px 0px -1px;
}
.sustain--box-th .sustainBox__icon {
  color: #0638e7;
}
.template--header {
  padding: 26px 0;
  min-height: 100px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 55;
  flex-shrink: 0;
}
.template--header.header--shadow {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
}
.template--header .tempHeader--canvas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.template--header .tempHeader--col {
  display: flex;
  align-items: center;
}
.template--header .tempHeader--left {
  justify-content: flex-start;
}
.template--header .tempHeader--right {
  justify-content: flex-end;
}
.template--header .back__btn {
  padding: 2px;
}
.template--header .template__name {
  font-size: 16px;
  color: #1c1f20;
  font-weight: 600;
  margin: 0px 40px 0px 10px;
  white-space: nowrap;
}
.tempView__btn {
  font-size: 24px;
  color: #6a7074;
  width: 48px;
  height: 48px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tempView__btn:not(:disabled):not(.disabled):active:focus,
.tempView__btn:not(:disabled):not(.disabled).active,
.tempView__btn:hover,
.tempView__btn:focus {
  color: #0638e7;
  background-color: #fff;
  border-color: #0638e7;
}
.temp--view {
  display: flex;
  align-items: center;
}
.temp--view .tempView__btn {
  margin-right: 16px;
}
.temp--view .tempView__btn:last-child {
  margin-right: 0;
}
.temp--options {
  display: flex;
  width: 100%;
}
.temp--options .btn {
  margin-left: 16px;
  min-width: 180px;
}
.template--header .cross__link {
  margin-left: 40px;
}
.cross__link {
  font-size: 24px;
  color: #6a7074;
  display: inline-flex;
}
.tempParaCombine--canvas {
  height: 310px;
  overflow: hidden;
  transition: height 0.35s ease;
}
.tempParaCombine--canvas-expand {
  height: auto;
  transition: height 0.35s ease;
}
.temp__edit-icon {
  font-size: 16px;
  margin-left: 20px;
  display: inline-flex;
  cursor: pointer;
}
.temp--group {
  margin-bottom: 0;
}
.temp--group textarea.form-control {
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  background-color: transparent;
  border: 1px dashed #fff;
  border-radius: 0;
  padding: 14px;
  height: 304px;
}
.template--mobile {
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
}
.template--mobile .template--banner .tempBanner--detail .row > .col,
.template--mobile .temp--about .row .col,
.template--mobile .row--space-10.suite--block .col,
.template--mobile .sustain--row .col,
.template--mobile .subdomain__footer .row .col-12,
.template--mobile .tempListing--row .col,
.template--mobile .temp--contacts .row .col,
.template--mobile .template--head-three .template--banner .tempBannerTh--row .col,
.template--mobile .tempListing--th .row .col,
.template--mobile .temp--sustain-th .sustain--row-th .col {
  flex: 0 0 100%;
  max-width: 100%;
}
.template--mobile .heading__lg-dark,
.template--mobile .heading__lg-white {
  font-size: 22px;
}
.template--mobile .heading__tem-white {
  font-size: 16px;
}
.template--mobile .template--head .add--box {
  margin-top: 20px;
}
.template--mobile .add--box .addBox__text,
.template--mobile .temp--about .para__large-white,
.template--mobile .temp--about .para__md-white,
.template--mobile .temp--combine .link__blue-lg {
  font-size: 14px;
}
.template--mobile .tempBanner--btn-canvas {
  padding-top: 100px;
}
.template--mobile .tempBanner--btn-canvas .btn {
  width: 100%;
}
.template--mobile .btn {
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.template--mobile .tempBanner--btn-canvas .admin__button {
  margin: 0 !important;
}
.template--mobile .tempBanner--btn-canvas .outline__button {
  margin: 16px 0px 0px 0px !important;
}
.template--mobile .temp--about .tempAbout--col {
  min-height: 10px;
  padding: 50px 20px;
}
.template--mobile .temp--about-1 .tempAbout--img:after {
  bottom: auto;
  top: 0;
}
.template--mobile .temp--about .tempAbout--img-box,
.template--mobile .template--head-two .tempBanner--img {
  height: 500px;
}
.template--mobile .info--carousel-block .info--carousel .carousel-indicators {
  bottom: 15%;
  padding-left: 20px;
}
.template--mobile .info--carousel-block .tempAbout--img-box {
  height: 300px;
}
.template--mobile .info--carousel-block .tempAbout--content {
  min-height: 640px;
}
.template--mobile .info--carousel-block .tempAbout--col-inner {
  margin-bottom: 0;
  margin-top: 10px;
}
.template--mobile .temp--about .heading__tem-white {
  font-size: 18px;
}
.template--mobile .info--carousel-block .ic--overlay {
  max-width: 100%;
  min-height: 640px;
  background-color: #1c1f20;
  padding: 50px 20px;
  top: 400px;
}
.template--mobile .info--carousel-block:before {
  max-width: 100%;
  bottom: auto;
  min-height: 700px;
  background-color: transparent;
}
.template--mobile .info--carousel-block:after {
  content: none;
}
.template--mobile .temp--about-2 .tempAbout--img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background-color: hsla(0, 0%, 76.9%, 0.1);
}
.template--mobile .temp--combine {
  padding-bottom: 160px;
}
.template--mobile .suite--block {
  margin-top: 60px;
}
.template--mobile .suite--block .suite--img {
  height: 200px;
  margin-bottom: 24px;
}
.template--mobile .suite--block .heading__th-dark {
  font-size: 20px;
  margin-bottom: 16px !important;
}
.template--mobile .temp--specification .tempAbout--img {
  padding-top: 160px;
}
.template--mobile .temp--specification .tempAbout--img-box {
  height: 300px;
}
.template--mobile .specs--carousel .carousel-inner {
  min-height: 496px;
}
.template--mobile .specs--carousel .carousel-indicators {
  margin-bottom: 40px;
}
.template--mobile .sustain--row.mt-4.mt-sm-5 {
  padding-top: 16px !important;
  margin-top: 24px !important;
}
.template--mobile .sustain--box {
  padding: 20px;
}
.template--mobile .subdomain__footer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.template--mobile .subdomain__footer .container--large {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
}
.template--mobile .sd__footer-list {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  padding-bottom: 8px !important;
}
.template--mobile .subdomain__footer .container--large > .sd__footer-para {
  margin-top: 16px !important;
}
.template--mobile .template--head-two .tempBanner--btn-canvas {
  padding-top: 32px;
}
.template--mobile .tempBanner--carousel {
  margin-top: 80px;
}
.template--mobile .temp--map {
  height: 300px;
  margin-top: 110px;
  margin-bottom: 30px;
}
.template--mobile .tempListing--row {
  margin-bottom: 28px;
}
.template--mobile .tempListing--block {
  padding: 16px;
  min-height: 10px;
  margin-bottom: 32px;
}
.template--mobile .tempListing--block .tempListing--img {
  height: 160px;
}
.template--mobile .tempListing--block .tempListing--detail {
  margin-top: 20px;
}
.template--mobile .tempListing--block .add--box {
  margin-top: 8px;
}
.template--mobile .info--item {
  margin-bottom: 18px;
}
.template--mobile .info--item .infoItem__para {
  font-size: 18px;
  line-height: 20px;
}
.template--mobile .info--item .infoItem--r .infoItem__text {
  font-size: 12px;
  line-height: 14px;
}
.template--mobile .tempSpecs--row .col,
.template--mobile .tempSpecsTh--row .col {
  flex: 0 0 50%;
  max-width: 50%;
}
.template--mobile .tempSpecs--block .heading__md-dark {
  font-size: 16px;
  margin-bottom: 8px;
}
.template--mobile .tempContact--box {
  height: 350px;
  margin-bottom: 24px;
}
.template--mobile .tempContact--box .heading__tem-white {
  font-size: 24px;
}
.template--mobile .temp--contacts .row.mt-80 {
  margin-top: 60px;
}
.template--mobile .temp--sustain-alt .sustain--box .heading__md-dark {
  font-size: 18px;
}
.template--mobile .sustain--row .col:last-child .sustain--box {
  margin-bottom: 0;
}
.template--mobile .template--head-three .template--banner {
  position: static;
  min-height: 100px;
}
.template--mobile .template--head-three .template--banner .tempBannerTh--row {
  padding-top: 94px;
  padding-bottom: 40px;
}
.template--mobile .tempLink--canvas {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.template--mobile .tempLink--canvas .link__dark,
.template--mobile .tempLink--canvas .link__blue-lg {
  margin: 30px auto 0 0;
}
.template--mobile .template--head-three .template--carousel {
  width: 100%;
}
.template--mobile .template--head-three .template--carousel .carousel-item {
  height: 500px;
}
.template--mobile .temp--about-alt .tempAbout--col {
  padding-top: 80px;
  padding-bottom: 80px;
}
.template--mobile .temp--about-alt .row .col:first-child {
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
}
.template--mobile .tempContact--th .row .col {
  flex: 0 0 100%;
  max-width: 100%;
  border-right: 0;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
}
.template--mobile .tempContact--th .row .col:last-child {
  border-bottom: 0;
}
.template--mobile .tempConTh--block {
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.template--mobile .tempConTh--block .tempConTh--detail .heading__md-gray {
  margin-bottom: 16px;
}
.template--mobile .tempConTh--block .tempConTh--detail .heading__md-gray span {
  margin-bottom: 8px;
}
.template--mobile .temp--sustain-th,
.template--mobile .tempListing--th,
.template--mobile .tempSpecs--th {
  padding-bottom: 80px;
}
.template--mobile .tempListing--th {
  padding-top: 80px;
}
.template--mobile .heading__tem-blue {
  font-size: 14px;
}
.template--mobile .tempListingTh--block {
  height: 200px;
  margin-bottom: 24px;
}
.template--mobile .tempListingTh--block .tempListingTh--overlay {
  padding: 16px;
}
.template--mobile .tempListingTh--block .tempListingTh--overlay .heading__tem-white,
.template--mobile .tempListingTh--block .tempListingTh--overlay .heading__tem-white {
  font-size: 24px;
}
.template--mobile .tempListing--th .mt-4.mt-lg-3.pt-2 {
  margin-top: 24px !important;
  padding-top: 8px !important;
}
.template--mobile .temp--sustain-th,
.template--mobile .tempSpecs--th {
  padding-top: 70px;
}
.template--mobile .tempSpecs--block-alt {
  min-height: 192px;
  padding: 22px 10px 22px 16px;
}
.template--mobile .tempSpecs--block-alt .tempSpecs__icon {
  font-size: 24px;
}
.template--mobile .tempSpecsTh--row .col:nth-child(odd) .tempSpecs--block-alt {
  margin-left: 0;
}
.template--mobile .tempContact--th .row .col {
  border: none;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  margin: 0;
}
.template--mobile .tempContact--th .row .col:nth-child(3n + 1) {
  margin-left: 0;
}
.tempShimmer--mobile .tempBanner--btn-canvas {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tempShimmer--mobile .template--banner-shimmer .tempBanner--btn-canvas .db__btns {
  width: 100%;
  margin: 0 !important;
}
.tempShimmer--mobile .template--banner-shimmer .tempBanner--btn-canvas .db__btns:first-child {
  margin-bottom: 16px !important;
}
.tempShimmer--mobile .template--banner-shimmer .shimmer__heading-lg {
  height: 20px;
  max-width: 80%;
  margin-bottom: 4px !important;
}
.tempShimmer--mobile .template--banner-shimmer .shimmer__heading-md {
  height: 16px;
}
.tempShimmer--mobile .template--head .add--box {
  margin-top: 12px;
}
.tempShimmer--mobile .temp--about-shimmer .heading__lg-white {
  height: 22px;
}
.tempShimmer--mobile .temp--about .tempAbout--btn-canvas {
  display: flex;
  margin-top: 10px;
}
.tempShimmer--mobile .infoCarousel--shimmer .tempAbout--img-box {
  height: 300px;
}
.tempShimmer--mobile .infoCarousel--shimmer .tempAbout--btn-canvas {
  margin-top: 42px;
}
.tempShimmer--mobile .tempCombine--head-shimmer .tch--heading {
  height: 22px;
}
.tempShimmer--mobile .suite--block-shimmer .suite--detail .heading__th-dark {
  height: 20px;
}
.tempShimmer--mobile .temp--specification .tempAbout--btn-canvas {
  margin-top: 60px;
}
.tempShimmer--mobile .subdomain__footer-shimmer .fl--shimmer {
  margin-top: 24px !important;
  margin-bottom: 32px !important;
}
.tempShimmer--mobile .subdomain__footer-shimmer .fp__box .fp__box-span {
  margin-bottom: 6px;
}
.tempShimmer--mobile .subdomain__footer-shimmer .fp__box .fp__box-span:last-child {
  margin-bottom: 4px;
}
.tempShimmer--mobile .tempBanner--btn-canva .db__btns {
  width: 100%;
  margin: 0 !important;
}
.tempShimmer--mobile .template--head-three .tempBanner--detail .row {
  width: 100%;
  margin: 0;
}
.tempShimmer--mobile .template--head-three .tempBanner--detail .row > .col {
  padding-left: 0;
  padding-right: 0;
}
.tempShimmer--mobile .tempBanner--btn-canva .db__btns:first-child {
  margin-bottom: 16px !important;
}
.tempShimmer--mobile .sustainBox--shimmer-alt .sbs--text .heading__md-dark {
  height: 24px;
}
.tempShimmer--mobile .template--head-three .template--banner {
  min-height: 10px;
  position: static;
}
.tempShimmer--mobile .template--head-three .tempHeadTh--bg-shimmer {
  position: static;
  width: 100%;
  height: 500px;
}
.tempShimmer--mobile .template--head-three .template--banner-shimmer .tempBanner--detail {
  padding-top: 94px;
  padding-bottom: 40px;
}
.tempShimmer--mobile .template--head-three .template--banner-shimmer .tempLink--canvas {
  align-items: flex-start;
}
.tempShimmer--mobile .tempLink--canvas .link--shimmer {
  margin: 30px 0px 0px 0px;
}
.tempShimmer--mobile .tempContact--th .row .col {
  border: none;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  margin: 0;
}
.template--mobile .drawer--right {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  width: 325px;
  padding: 70px 30px 20px 30px;
  margin-left: auto;
  margin-right: -375px;
  left: 0;
}
.template--mobile .drawer--right-show {
  margin-right: auto;
  right: -3.4%;
  right: -50px;
}
.template--mobile .drawer--right .drawer__cross {
  right: 20px;
}

/*
  Template Style
*/

/*
  Mobile Nav & Footer Style
*/

.mobile--nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-height: 50px;
  padding: 9px 20px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile--nav .mobileNav__logo {
  width: 120px;
  height: 32px;
  overflow: hidden;
}
.mobileNav--card .mobileNav--user {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}
.mobileNav--card .mobileNav--user:hover,
.mobileNav--card .mobileNav--user:focus {
  box-shadow: none;
}
.mobileNav--card .collapse.show,
.mobileNav--card .collapsing {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}
.mobileNav--card .collapse.show:before,
.mobileNav--card .collapsing:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.7);
  width: 100%;
  height: 100%;
}
.mobileNav--card .collapse:not(.show) {
  display: none;
}
.mobileNav--card .card-body {
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 555;
}
.mobileNav--content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 32px 20px 20px 20px;
  width: 100%;
  overflow-y: auto;
  max-height: 92vh;
}
.mobileNav--content .mobileNavMenu--head .heading__small-dark {
  font-size: 20px;
}
.cross__link-gray {
  font-size: 16px;
  color: #6a7074;
}
.mobileNav--menu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.mobileNav--menu .mobileNav--item {
  color: #6a7074;
  display: flex;
  align-items: center;
  padding: 14px 0;
  min-height: 48px;
  border-top: 1px solid #f5f5f5;
}
.mobileNav--menu .mobileNav--item:first-child {
  border-top: none;
}
.mobileNav--menu .mobileNav__icon {
  font-size: 16px;
  display: inline-flex;
  margin-right: 10px;
}
.mobileNav--menu .mobileNav__text {
  font-size: 14px;
  line-height: 18px;
  display: inline-flex;
}
.mobile--footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 10px;
  min-height: 60px;
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobileNav--menu-f {
  flex-direction: row;
  justify-content: space-between;
  overflow-y: visible;
  overflow-y: initial;
}
.mobileNav--menu-f .mobileNav--item {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  border: none;
  min-height: 10px;
}
.mobileNav--menu-f .mobileNav--item.active {
  color: #0930ba;
}
.mobileNav--menu-f .mobileNav__icon {
  margin: 0px 0px 6px 0px;
}
.mobileNav--menu-f .mobileNav__text {
  line-height: 16px;
}

/*
  Mobile Nav & Footer Style
*/

/*
  Wework Style
*/

.app__btn:not([href]),
.app__btn {
  font-size: 16px;
  font-weight: normal;
  min-width: 180px;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  border-radius: 10px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.app__btn-green:not([href]),
.app__btn-green {
  background-color: #006e51;
  border-color: #006e51;
  color: #fff;
}
.app__btn-green:not([href]):not(:disabled):not(.disabled):active:focus,
.app__btn-green:not([href]):not(:disabled):not(.disabled):active,
.app__btn-green:not([href]):hover,
.app__btn-green:not([href]):focus,
.app__btn-green:not(:disabled):not(.disabled):active:focus,
.app__btn-green:not(:disabled):not(.disabled):active,
.app__btn-green:hover,
.app__btn-green:focus {
  background-color: #045842;
  border-color: #045842;
  color: #fff;
  box-shadow: none;
}
.outline__button .app__btn-icon,
.app__btn .app__btn-icon {
  margin-left: 10px;
  display: inline-flex;
}
.app__btn-lg:not([href]),
.app__btn-lg {
  min-width: 200px;
}
.outline__button-green:not([href]),
.outline__button-green {
  border-color: #006e51;
  color: #006e51;
}
.outline__button-green:not([href]):not(:disabled):not(.disabled):active,
.outline__button-green:not([href]):not(:disabled):not(.disabled):active:focus,
.outline__button-green:not([href]):hover,
.outline__button-green:not([href]):focus,
.outline__button-green:not(:disabled):not(.disabled):active,
.outline__button-green:not(:disabled):not(.disabled):active:focus,
.outline__button-green:hover,
.outline__button-green:focus {
  background-color: #006e51;
  color: #fff;
  border-color: #006e51;
  box-shadow: none;
}
.viewBy--alt .grid__view.active {
  color: #006e51;
}
.bulding--block {
  padding: 16px;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  min-height: 402px;
  margin-bottom: 20px;
}
.bulding--block .buldingBlock--img {
  width: 100%;
  height: 128px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 20px;
}
.bulding--block .buldingBlock--detail {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.add--box-md .addBox__icon,
.add--box-md .addBox__text {
  font-size: 16px;
}
.alm--alt {
  border-radius: 20px;
}

/*
  Wework Style
*/

/* Available Listing Footer for mobile */

.available--listing--menu--container {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.available--listing--mbl--menu .listing--mbl--menu--card {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  box-shadow: 0px 5px 20px 0px rgba(6, 56, 231, 0.07);
}
.available--listing--mbl--menu .card-header {
  background-color: #ffffff;
  padding: 10px 20px;
}
.available--listing--mbl--menu .mbl--menu--acrd--btn {
  font-size: 12px;
  line-height: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #1c1f20;
  text-decoration: none;
  padding: 6px 3px;
  cursor: pointer;
}
.available--listing--mbl--menu .mbl--menu--acrd--btn:hover {
  text-decoration: none;
}
.available--listing--mbl--menu .mbl--menu--acrd--btn:focus {
  box-shadow: none;
}
.available--listing--mbl--menu .mbl--menu--icon {
  margin-left: 11px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.available--listing--mbl--menu .listing--mbl--menu--border {
  height: 40px;
  width: 1px;
  background: linear-gradient(to bottom, #f6f6f6, #bcbab8, #f6f6f6);
  margin: 0px 3px 0px 3px;
}
.available--listing--mbl--menu .collapse {
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(6, 56, 231, 0.07);
}
.available--listing--mbl--menu .card-body {
  padding: 0px 20px;
  justify-content: flex-end;
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.available--listing--mbl--menu .nav {
  position: relative;
  padding: 10px 0px;
}
.available--listing--mbl--menu .nav::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(to right, #f6f6f6, #bcbab8, #f6f6f6);
}
.available--listing--mbl--menu .card-header-pills {
  margin: 0px;
}
.available--listing--mbl--menu .nav-item {
  padding: 8px 3px;
}
.available--listing--mbl--menu .nav-pills .nav-link {
  font-size: 12px;
  line-height: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #1c1f20;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.available--listing--mbl--menu .nav-pills .nav-link.active {
  color: #0638e7;
  background-color: transparent;
  border: none;
}
.available--listing--mbl--menu .tab-content {
  padding: 24px 0px 16px 0px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.available--listing--mbl--menu .tab-content::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.available--listing--mbl--menu .menu--bordered--btn {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: transparent;
  margin-bottom: 10px;
  min-height: 48px;
}
.available--listing--mbl--menu .menu--bordered--btn:last-child {
  margin-bottom: 0px;
}
.available--listing--mbl--menu .blue--outline--btn {
  color: #0638e7;
  border: 1px solid #0638e7;
}
.available--listing--mbl--menu .blue--outline--btn:hover,
.available--listing--mbl--menu .blue--outline--btn:focus,
.available--listing--mbl--menu .blue--outline--btn:not(:disabled):not(.disabled):active {
  color: #0638e7;
  border: 1px solid #0638e7;
  background-color: transparent;
}
.available--listing--mbl--menu .gray--outline--btn {
  color: #bcbab8;
  border: 1px solid #bcbab8;
}
.available--listing--mbl--menu .gray--outline--btn:hover,
.available--listing--mbl--menu .gray--outline--btn:focus,
.available--listing--mbl--menu .gray--outline--btn:not(:disabled):not(.disabled):active {
  color: #bcbab8;
  border: 1px solid #bcbab8;
  background-color: transparent;
}
/*Available Listing Footer for mobile*/

/* Pricing landing page */
.fluid--expand--payment {
  padding-left: 70px;
  padding-right: 70px;
}
.pricing--top--section {
  background-color: #f2e9d0;
  margin-bottom: -20px;
}
.navbar__list--center {
  margin-left: auto;
}
.schedule__btn-outline--curved:not([href]),
.schedule__btn-outline--curved {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.navbar__header__payment {
  padding-top: 36px;
  padding-bottom: 36px;
}
.navbar__header__payment .navbar__list-n .nav-link {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 6px 15px;
}
.bottom--header--component {
  padding-top: 176px;
  padding-bottom: 60px;
}
.pricing__main__heading {
  color: #15235c;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
  margin-bottom: 40px;
}
.pricing__btm__hdr__para {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  color: #1c1f20;
}
.pricing--choose--plan--section {
  padding-top: 67px;
  padding-bottom: 86px;
  background-color: #ffffff;
  border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -ms-border-radius: 20px 20px 0px 0px;
  -o-border-radius: 20px 20px 0px 0px;
}
.pricing--choose--plan--inner {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.pricing__choose__hd {
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
  color: #15235c;
}
.pricing--choose--plan--form {
  width: 100%;
}
.pricing--choose--plan--form {
  margin-top: 40px;
}
.pricing--choose--card--container {
  padding-top: 50px;
}
.pricing--choose--card--container .col {
  margin-bottom: 24px;
}
.pricing--choose--card--container .col:last-child {
  margin-bottom: 0px;
}
.pricing--choose--card {
  background: #ffffff;
  border: 1px solid rgba(188, 186, 184, 0.48);
  box-shadow: 0px 4px 10px rgba(131, 131, 131, 0.14);
  border-radius: 20px;
}
.pricing--choose--card .card-body {
  padding: 30px 28px 38px 28px;
}
.pricing--choose--card .pricing--card--plan--title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #15235c;
}
.pricing--choose--card .pricing--card--plan--price {
  color: #1c1f20;
  text-align: right;
}
.pricing--choose--card .pricing--card--plan--building {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #1c1f20;
  text-align: right;
}
.pricing--choose--card .card-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #15235c;
  margin-bottom: 26px;
}
.pricing--choose--card .pricing--card--listGrp--container {
  height: 320px;
  overflow-y: auto;
  padding: 0px 22px 0px 22px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}
.pricing--choose--card .pricing--card--listGrp--container::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.pricing--choose--card .price--choose--card--border {
  background-color: #1c1f20;
  height: 1px;
  width: 100%;
}
.pricing--choose--card .list-group {
  margin-bottom: 58px;
}
.pricing--choose--card .list-group:last-child {
  margin-bottom: 18px;
}
.pricing--choose--card .list-group-item {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  background-color: inherit;
  border: none;
  margin-bottom: 18px;
}
.pricing--choose--card .list-group-item:last-child {
  margin-bottom: 0px;
}
.pricing--choose--card .pricing--card--tick {
  background-color: #15235c;
  border: 1px solid #15235c;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px 0px 2px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 3px;
}
.pricing--choose--card .pricing--card--li--text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: #6a7074;
  margin-left: 9px;
}
.pricing--choose--card .pricing--card--total--container {
  margin: 22px 0px 34px 0px;
}
.pricing--choose--card .pricing--card--total {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #1c1f20;
}
.pricing--choose--card .pricing__card__btn__link {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #15235c;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 4px;
  min-height: 52px;
  border: none;
  background-color: transparent;
  min-width: 200px;
  margin-top: 14px;
}
.pricing--choose--card .pricing__card__link__icon {
  font-size: 16px;
  color: #15235c;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricing--inquiry--section {
  padding: 108px 0px 103px 0px;
  background-color: #15235c;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: -20px;
}
.pricing--inquiry--hd--container {
  margin-top: 155px;
}
.pricing--inquiry--section .pricing__inquiry__hd {
  color: #f2e9d0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.pricing--inqury--form .forms--group {
  margin-bottom: 27px;
}
.pricing--inqury--form .forms--group:nth-child(1) {
  padding-bottom: 27px;
}
.pricing--inqury--form .forms--group:nth-child(2) {
  padding-bottom: 18px;
}
.pricing--inqury--form .forms--group:nth-child(3) {
  padding-bottom: 21px;
}
.pricing--inqury--form .forms--group:nth-child(4) {
  padding-bottom: 31px;
}
.pricing--inqury--form .forms--group:last-child {
  margin-bottom: 0px;
}
.pricing--inqury--form .form-label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  margin-bottom: 12px;
}
.pricing--inqury--form .form-control {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  min-height: 48px;
  width: 100%;
  border: 1px solid #f2e9d0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  padding: 4px 11px;
  vertical-align: middle;
}
.pricing--inqury--form .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.pricing--inqury--form .form-control::-ms-input-placeholder {
  color: #ffffff;
}
.pricing--inqury--form .form-control::placeholder {
  color: #ffffff;
}
.pricing--inqury--form .forms--group .pricing--inqury--sm--input {
  font-size: 45px;
  line-height: 50px;
  font-weight: 500;
  min-height: 79px;
  width: 198px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  padding: 12px 30px;
}
.pricing--inqury--form .pricing--default--input--text {
  position: absolute;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: #ffffff;
  top: 47px;
  left: 12px;
}
.pricing--inqury--form .pricing--inqury--currency--container {
  margin-left: 5px;
}
.pricing--inqury--form .pricing--inqury--currency--container .pricing--inqury--currency--name {
  min-width: 34px;
  min-height: 26px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #f2e9d0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.pricing--inqury--form
  .pricing--inqury--currency--container
  .pricing--inqury--currency--name:nth-child(1) {
  background-color: #f2e9d0;
  color: #15235c;
  border-radius: 6px 0px 0px 6px;
  -webkit-border-radius: 6px 0px 0px 6px;
  -moz-border-radius: 6px 0px 0px 6px;
  -ms-border-radius: 6px 0px 0px 6px;
  -o-border-radius: 6px 0px 0px 6px;
}
.pricing--inqury--form
  .pricing--inqury--currency--container
  .pricing--inqury--currency--name:nth-child(2) {
  background-color: transparent;
  color: #ffffff;
  border-radius: 0px 6px 6px 0px;
  -webkit-border-radius: 0px 6px 6px 0px;
  -moz-border-radius: 0px 6px 6px 0px;
  -ms-border-radius: 0px 6px 6px 0px;
  -o-border-radius: 0px 6px 6px 0px;
}
.pricing--inquiry--btn--container {
  margin-top: 42px;
}
.pricing--inquiry--form--btn {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  min-width: 198px;
  min-height: 52px;
  padding: 8px 12px;
  background-color: #f2e9d0;
  border: 1px solid #f2e9d0;
  color: #15235c;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.footer--payment--pricing {
  padding-top: 162px;
  padding-bottom: 80px;
  border-radius: 20px 20px 0px 0px;
}
.footer--payment--pricing .f__list li a {
  color: #f2e9d0;
  margin-right: 40px;
}
.footer--payment--pricing .f__list li:last-child a {
  margin-right: 0px;
}
.footer--payment--pricing .f__list li a:hover {
  color: #ffffff;
}
/* Pricing landing page */

/* Payment signup page*/
.payment--sigup--bg {
  background: #15235c;
}
.payment--signup--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.payment--signup--spacing {
  padding: 40px;
}
.payment--signup--form {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}
.payment--signup--main--hd {
  margin-bottom: 28px;
}
.form--group--pmnt--signup .form-control {
  border: 1px solid #f2e9d0;
  color: #ffffff;
}
.form--group--pmnt--signup .form-control:focus {
  border: 1px solid #f2e9d0;
  box-shadow: none;
  outline: none;
}
.form--group--pmnt--signup .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.form--group--pmnt--signup .form-control::-ms-input-placeholder {
  color: #ffffff;
}
.form--group--pmnt--signup .form-control::placeholder {
  color: #ffffff;
}
/* Payment signup page*/

/* Pricing payment page */
/* credit card payment page */
.price--payment--bg {
  background-color: #f2e9d0;
}
.payment--main--section--spacing {
  padding-top: 165px;
  padding-bottom: 50px;
  display: flex;
  flex-grow: 1;
}
.payment--main--section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.payment--method--container {
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
  flex-direction: column;
  height: auto;
  background: #ffffff;
  padding: 25px 24px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-right: 0px;
  margin-bottom: 10px;
}
.payment--billing--card {
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
  flex-direction: column;
  height: auto;
  background: #15235c;
  padding: 32px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-left: 0px;
  margin-top: 10px;
}
.payment--method--container .tab-content,
.payment--method--container .tab-content > .active,
.payment--method--container .credit--card--payment--container,
.payment--method--container .credit--card--payment--form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.payment--method--container .nav {
  border: 1px solid #15235c;
  border-radius: 16px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 6px 14px;
  align-items: center;
}
.payment--method--container .nav-pills .nav-link {
  font-size: 16px;
  color: #15235c;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  min-width: 165px;
  min-height: 40px;
  padding: 8px 12px;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment--method--container .nav-pills .nav-link.active,
.payment--method--container .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #15235c;
  border: 1px solid #15235c;
}
.credit--card--payment--top {
  margin-top: 54px;
  margin-bottom: 25px;
}
.crdCard--payment--top--icon {
  font-size: 24px;
}
.crdCard--payment--top--icon:last-child {
  margin-right: 0px;
}
.credit--card--payment--form .forms--group {
  margin-bottom: 0px;
}
.credit--card--payment--form .forms--group--col {
  margin-bottom: 16px;
}
.credit--card--payment--form .forms--group--col:last-child {
  margin-bottom: 0px;
}
.credit--card--payment--form .forms--group .form-control {
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: #1c1f20;
}
.credit--card--payment--form .forms--group .form-control:focus {
  border: 1px solid #bcbab8;
}
.credit--card--payment--form .select--group .form-control {
  padding-right: 30px;
}
.credit--card--payment--form .select--group .select__caret-down {
  top: 18px;
  font-size: 14px;
}
.card--payment--btn--container {
  padding-top: 40px;
  margin-top: auto;
  margin-bottom: 7px;
}
.card--payment--btn--container .btn {
  margin-right: 24px;
}
.card--payment--btn--container .btn:last-child {
  margin-right: 0px;
}
.card--payment--btn--container .btn-link {
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: #15235c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card--payment--btn--container .btn-link:hover,
.card--payment--btn--container .btn-link:focus,
.card--payment--btn--container .btn-link.active {
  color: #15235c;
  box-shadow: none;
}
.payment--billing--card .card-body {
  padding: 0px;
}
.payment--billing--card .payment--billing--card--list ul {
  max-height: 320px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.payment--billing--card .payment--billing--card--list ul::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.payment--billing--card .payment--billing--card--list ul li {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.payment--billing--card .payment--billing--card--list ul li div:nth-child(1) p {
  margin-right: 6px;
  text-align: left;
}
.payment--billing--card .payment--billing--card--list ul li div:nth-child(2) p {
  margin-left: 6px;
  text-align: right;
}
.payment--billing--card .payment__billing__card__listTxt {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0px;
}
.payment--billing--card .payment__billing__card__listSbTxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  margin-bottom: 11px;
}
.payment--billing--card .payment--billing--card--subTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0px 46px 0px;
}
.payment--billing--card .payment--billing--card--subTotal .payment__bill__subTotal__txt {
  font-size: 24px;
  line-height: 36px;
}
.payment--billing--card .payment--billing--card--border {
  height: 1px;
  width: 100%;
  background-color: #ffffff;
}
.payment--billing--card .payment--billing--card--total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;
}
.payment--billing--card .payment--billing--card--total .payment__bill__totalTxt {
  font-size: 18px;
}
.payment--billing--card .payment--billing--card--total p {
  margin-bottom: 18px;
}
.payment--billing--card .payment--billing--card--total p:last-child {
  margin-bottom: 0px;
  font-weight: 600;
}
.payment__billing__card__lgTxt {
  font-size: 32px;
  line-height: 46px;
}
/* credit card payment page */
/* bank payment page */
.bank--payment--container .credit--card--payment--top {
  margin-top: 21px;
  margin-bottom: 45px;
}
.bank--payment--container .bank--payment--sub--top {
  margin-top: 7px;
}
.bank--payment--container .bank--payment--sub--top .bank__payment__top__para {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #6a7074;
}
/* bank payment page */
/* Pricing payment page */

/* Payment completed  page*/

.payment--completed--banner {
  background: #15235c;
  border-radius: 0px 20px 20px 0px;
}
.payment--completed--banner-canvas {
  justify-content: center;
  align-items: center;
}
.payment--completed--hd {
  font-size: 32px;
  font-weight: 600;
  margin: 112px 0px 8px 0px;
  text-align: center;
}
.payment--completed--para {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0px;
}
.form--text--pearl {
  color: #f2e9d0;
}
.checkbox__link__underline:not([href]),
.checkbox__link__underline {
  text-decoration: underline;
}
.form--hd--pmnt--completed {
  color: #15235c;
  font-weight: 700;
  margin-bottom: 22px;
}
.payment__completed__button:not([href]),
.payment__completed__button {
  background-color: #15235c;
  min-height: 52px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.payment--chat--box {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
.payment--chat--box--btn {
  width: 60px;
  height: 60px;
  background-color: #222222;
  border: 1px solid #222222;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 14px;
  font-size: 28px;
}
.payment--chat--box--btn img {
  width: 28px;
  height: 33px;
}
.mt-fifty-five {
  margin-top: 55px;
}
.payment--formOpt {
  padding-top: 12px;
}

/* Payment completed  page*/

/* Marketing pages */

.marketing--home--top--section {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 620px;
  padding-top: 140px;
}
.marketing--home--top--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.marketing--home--top--content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mktHome--top--content--inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 420px;
}
.marketing--home--top--content .pricing__main__heading {
  text-align: center;
}
.simple__paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
}
.marketing--home--top--content .simple__paragraph {
  color: #1c1f20;
  margin-top: 9px;
  text-align: center;
}
.marketing--home--top--content .mktHome--top--content--btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.marketing--home--top--content .schedule__btn {
  min-height: 48px;
}
.marketing--home--top--video {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-right: 8%;
}
.mktHome--top--video--container {
  position: relative;
  max-width: 310px;
  min-width: 310px;
  min-height: 156px;
  max-height: 156px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 16px 16px 16px 0px;
  -webkit-border-radius: 16px 16px 16px 0px;
  -moz-border-radius: 16px 16px 16px 0px;
  -ms-border-radius: 16px 16px 16px 0px;
  -o-border-radius: 16px 16px 16px 0px;
  display: flex;
  justify-content: space-between;
}
.mktHome--top--video--img,
.mktHome--top--video--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
}
.mktHome--top--video--text {
  padding: 6px;
}
.mktHome--top--video--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mktHome--video--text--inner {
  max-width: 80px;
}
.para__xs {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.mktHome--top--video--text .para__xs {
  color: #bcbab8;
  margin-bottom: 9px;
}
.mktHome--top--video--text .para__lg {
  color: #15235c;
  font-weight: 600;
  margin-bottom: 0px;
}
.mktHome--top--video--btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
.mktHome--top--video--btn .btn {
  min-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #15235c;
  border: 1px solid #15235c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  padding: 2px;
  margin: 0px;
}
.mktHome--top--video--btn .btn:focus {
  outline: none;
  box-shadow: none;
}
.marketing--toggle--div {
  border: 1px solid #15235c;
  border-radius: 14.5px;
  width: 29px;
  height: 44px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.marketing--toggle--div--dot {
  width: 3px;
  height: 6px;
  background: #15235c;
  border-radius: 14.5px;
  margin-top: 7px;
}
.marketing--home--personalize--component {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: -30px;
  min-height: 600px;
}
.marketing--home--personalize--component .mktHome--personalize--container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
}
.marketing--home--personalize--component .mktHome--personalize--canvas {
  display: none;
}
.marketing--home--personalize--component .mktHome--personalize--img {
  width: 100%;
  min-height: 320px;
}
.marketing--home--personalize--component .mktHome--personalize--img img {
  width: 100%;
  height: 100%;
}
.marketing--home--personalize--component .mktHome--personalize--form--container {
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
}
.marketing--home--personalize--component .personalize--block {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-top: auto;
  padding: 40px;
}
.marketing--home--personalize--component .personalize--block .forms--group {
  margin-bottom: 24px;
}
.marketing--home--personalize--component .personalize--block .schedule__btn {
  margin-top: 16px;
}
.marketing--home--personalize--component .personalize--block .heading__component {
  font-size: 24px;
  color: #000000;
  text-align: center;
  font-weight: 600;
}
.footer--marketing--home {
  padding-top: 330px;
}
.marketing--home--opinion--component {
  background-color: #ffffff;
  min-height: 504px;
  padding-top: 100px;
  padding-bottom: 90px;
}
.marketing--home--opinion--component .opinion--canvas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
}
.marketing--home--opinion--component .marketing--opinion--heading--block {
  max-width: 100%;
  display: flex;
  flex: 1 1 100%;
}
.marketing--home--opinion--component .marketing--home--opinion--hd {
  font-size: 36px;
  line-height: 50px;
  font-weight: 700;
  color: #15235c;
  margin-bottom: 30px;
  text-align: center;
}
.marketing--home--opinion--component .opinion--content {
  margin-top: 0px;
  max-width: 100%;
  display: flex;
  flex: 1 1 100%;
}
.marketing--home--opinion--component .opinion--block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.marketing--home--opinion--component .marketing--opinion--img--block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.marketing--home--opinion--component .opinion--block .nav-item {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  padding: 0px;
  margin-left: 0px;
  margin-right: 20px;
  margin-bottom: 24px;
}
.marketing--home--opinion--component .opinion--block .nav-item:last-child {
  margin-right: 0px;
}
.marketing--home--opinion--component .opinion--block .nav-link {
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;
  margin: 0px;
  padding: 10px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.marketing--home--opinion--component .opinion--block .nav-link.active {
  border: 2px solid #15235c;
}
.marketing--home--opinion--component .opinion--block .nav--link--img--container {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  margin: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.marketing--home--opinion--component .opinion--block .nav--link--img--container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.marketing--home--opinion--component .op--info {
  margin-top: 40px;
  margin-bottom: 24px;
}
.marketing--home--opinion--component .op__logo__container {
  display: flex;
  justify-content: center;
}
.marketing--home--opinion--component .opinion__para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1c1f20;
  text-align: center;
  margin-bottom: 0px;
}
.marketing--home--opinion--component .opinion__para .opinion__para-text {
  position: relative;
  z-index: 1;
}
.marketing--home--opinion--component .op--name {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1c1f20;
  text-align: center;
  margin-bottom: 0px;
}
.marketing--home--opinion--component .opinion__para:before {
  content: '';
  background: url(/static/media/quote-left-lg.c0df9cf6.svg) center center no-repeat;
  background-size: 42px;
  width: 42px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: -10px;
  color: #fff;
  display: inline-block;
}
.marketing--home--opinion--component .opinion__para:after {
  background: none;
}
.marketing--home--presentation--section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #15235c;
  min-height: 900px;
  border-radius: 20px 20px 0px 0px;
  padding: 50px 0;
}
.marketing--home--slider {
  flex-grow: 1;
}
.marketing--home--presentation--container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 1620px;
}
.marketing--presentation--slider--content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.marketing--presentation--slider--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 550px;
}
.marketing--slider--img--container {
  margin-right: 0px;
  margin-bottom: 30px;
}
.marketing--slider--img--inner {
  max-width: 337px;
  max-height: 431px;
  overflow: hidden;
}
.marketing--slider--txt--container {
  max-width: 640px;
}
.marketing--slider--top--txt {
  margin-left: 0px;
}
.marketing--presentation--slider--inner .pricing__main__heading {
  text-align: center;
  margin-bottom: 0px;
}
/* .marketing--presentation--btns--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
} */
.marketing--presentation--slider--btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 40px;
  min-height: 48px;
}
.marketing--presentation--btn--container .schedule__btn {
  border: 1px solid #f2e9d0;
  background-color: #f2e9d0;
  color: #15235c;
  font-size: 16px;
  padding: 8px 12px;
  min-height: 48px;
}
.marketing--presentation--btn--container .schedule__btn-outline {
  border: 1px solid #f2e9d0;
  background-color: transparent;
  color: #f2e9d0;
  font-size: 16px;
  padding: 8px 12px;
  min-height: 48px;
}
.marketing--presentation--btn--container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}
.marketing--presentation--tog--div {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.marketing--home--presentation--section .marketing--toggle--div {
  border: 1px solid #f2e9d0;
}
.marketing--home--presentation--section .marketing--toggle--div--dot {
  background: #f2e9d0;
}
.market--home-pre-last .marketing--home--slider--item {
  display: flex;
  align-items: initial;
}
.market--home-pre-last .marketing--presentation--btn--container {
  display: none;
}
.market--home-pre-last
  .marketing--home--slider
  .marketing--home--slider--item:last-child
  .marketing--presentation--btn--container {
  display: flex;
}
.market--home-pre-last
  .marketing--presentation--btn--container
  .market--home-pre-last
  .marketing--slider--top--txt {
  margin: -60px 0px 0px 0px;
}
.market--home-pre-last
  .marketing--home--slider
  .marketing--home--slider--item:last-child
  .marketing--presentation--slider--btn {
  margin-top: 40px;
}
.marketing--get--started--slider .marketing--presentation--tog--div {
  margin-top: 40px;
  min-height: 44px;
  margin-left: auto;
  position: relative;
}
.marketing--slider--list--img {
  max-width: 90px;
  max-height: 115px;
  overflow: hidden;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--img {
  max-width: 90px;
  min-width: 90px;
  max-height: 115px;
  min-height: 115px;
  overflow: hidden;
}
.marketing--presentation--carousal--canvas .marketing--slider--txt--list {
  display: flex;
  align-items: flex-start;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--txt {
  margin-left: 20px;
  max-width: 238px;
  margin-top: 20px;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--hd {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 0px;
  color: #ffffff;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--txt ul {
  margin: 29px 0px 0px 16px;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--txt ul li {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 12px;
  color: #f2e9d0;
  list-style: disc;
  margin-left: 16px;
}
/* .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
  max-width: 855px;
  max-height: 544px;
  position: relative;
} */
.marketing--presentation--carousal--canvas .marketing--slider--canvas--inner {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
}
.marketing--video-canvas {
  padding-top: 56.25%;
  overflow: hidden;
}
.marketing--video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  overflow: hidden;
}
.marketing--presentation--carousal--canvas .mktSlider--immerse--container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  right: -14px;
}
.marketing--presentation--carousal--canvas .mktSlider--track--container {
  position: absolute;
  top: auto;
  bottom: -50px;
  left: 19px;
}
/* .marketing--presentation--carousal--canvas .marketing--presentation--btn--container {
  margin-left: 0px;
} */
.marketing--presentation--carousal--canvas .mktSlider-canvas-second {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 30px;
}
.mktSlider--video--link .mktHome--top--video--container {
  position: relative;
  max-width: 240px;
  min-width: 240px;
  min-height: 112px;
  max-height: 112px;
  background-color: #f2e9d0;
}
.mktSlider--video--link .mktHome--top--video--img {
  flex: 1 1 40%;
}
.mktSlider--video--link .mktHome--top--video--text {
  flex: 1 1 60%;
}
.mktSlider--video--link .mktHome--top--video--btn {
  left: 30%;
  right: auto;
  right: initial;
}
.mktSlider--tour--link--position {
  position: absolute;
  bottom: auto;
  top: -62px;
  right: -31px;
}
.mktSlider--tour--link--box {
  background: radial-gradient(
    95.54% 506.99% at 2.91% 7.59%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 20px 20px 20px 0px;
  max-width: 258px;
  min-width: 258px;
  max-height: 112px;
  min-height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mktSlider--tour--link--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #1c1f20;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1c1f20;
  min-width: 188px;
  min-height: 48px;
  padding: 6px 8px;
}
.mktSlider--tour--link--btn .mktTour--link--btn--icon {
  font-size: 16px;
  color: #1c1f20;
  margin-left: 10px;
}
.marketing--carousal--canvas--reverse .marketing--presentation--slider--inner {
  flex-direction: column-reverse;
}
.marketing--carousal--canvas--reverse .mktSlider-canvas-second {
  margin-top: 0px;
  margin-bottom: 30px;
}
.marketing--presentation--carousal--canvas .marketing--slider--list--txt ul.remove--circle li {
  list-style-type: none;
}
.market__btn-outline:not([href]):not(:disabled):not(.disabled):active:focus,
.market__btn-outline:not([href]):not(:disabled):not(.disabled):active,
.market__btn-outline:not([href]):hover,
.market__btn-outline:not([href]):focus,
.market__btn-outline:not([href]):active,
.market__btn-outline:not(:disabled):not(.disabled):active:focus,
.market__btn-outline:not(:disabled):not(.disabled):active,
.market__btn-outline:hover,
.market__btn-outline:focus,
.market__btn-outline:active {
  color: #15235c;
  border-color: #f2e9d0;
  background-color: #f2e9d0;
  box-shadow: none;
}
.marketing--sharedTour {
  background-color: #e5e5e5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
}
.marketing--sharedTour .container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 1820px;
}
.marketSt__logo {
  width: 188px;
  height: 30px;
}
.marketSt__content {
  padding-top: 82px;
  padding-bottom: 18px;
}
.market__heading {
  font-size: 46px;
  color: #15235c;
}
.marketSt__row .col {
  display: flex;
}
.marketSt__component-type {
  margin-bottom: 36px;
}
.marketSt__radio {
  padding: 16px 24px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(131, 131, 131, 0.14);
  border-radius: 20px;
  border: 1px solid rgba(188, 186, 184, 0.48);
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 10px;
}
.marketSt__radio.active {
  border-color: #15235c;
}
.marketSt__radio .checkmark {
  margin-left: auto;
  width: 24px;
  height: 24px;
  border: none;
  flex-shrink: 0;
}
.marketSt__radio input:checked ~ .checkmark {
  background-color: #15235c;
}
.marketSt__radio .checkmark:after {
  left: 9px;
  top: 2px;
  width: 8px;
  height: 16px;
}
.marketSt__radio-use {
  padding: 20px;
  align-items: flex-start;
}
.marketSt__pickSpace {
  display: flex;
  align-items: center;
}
.marketSt__pickSpace .marketSt__pick-img {
  width: 66px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  pointer-events: none;
  margin-right: 20px;
}
.marketSt__pickUse {
  align-items: flex-start;
}
.marketSt__pickUse .marketSt__pick-img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.market__heading-sm {
  font-size: 18px;
  color: #1c1f20;
}
.marketSt__btn.disabled {
  opacity: 0.45;
}
.video--profile-img-canvas {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video--profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #ffffff;
  margin-bottom: 9px;
}
.video--profile-img:last-child {
  margin-bottom: 0px;
}
.navbar-light .toggler--new,
.toggler--new {
  font-size: 28px;
  color: #15235c;
  padding: 2px;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(21, 35, 92, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.editable-text-container {
  height: auto;
  min-height: 33px;
  display: flex;
  align-items: center;
}

.editable-text-description {
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  line-height: 20px;
  display: inline-block;
  word-break: break-word;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

/* Marketing pages */

.listing-card-container {
  border-radius: 16px;
  display: flex;
  padding: 16px;
  cursor: pointer;
  border: 1px solid #bcbab8;
  margin-bottom: 20px;
}
.building-card-container {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  width: 100%;
  border-top: 1px solid #cccccc;
}

.listing-card-container:hover {
  border-color: rgb(24, 16, 60);
}

.listing-card-details-container {
  display: flex;
  flex-direction: row;
  flex: 0.9 1;
}

.listing-card-image-container {
  flex: 0.2 1;
  height: 130px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-shrink: 0px;
}

.listing-card-description {
  flex: 1.2 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.listing-card-details {
  flex: 1.2 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  padding-left: 20px;
}
.listing-status-button {
  padding: 2px 20px;
  border-radius: 30px;
}
.listing-card-status-container {
  display: flex;
  flex: 0.8 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}
.building-card-status-container {
  display: flex;
  flex: 0.8 1;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.listing-card-subtitle {
  color: #6a7074;
  font-size: 15px;
  margin-right: 8px;
  display: inline-block;
  min-width: 46px;
}

.mobile-hidden {
  display: inherit;
}

.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.bg--transparent {
  background-color: #00000000;
}

.signup-team-card-header {
  border-color: #e1e1e1;
  border-style: solid;
  border-radius: 10px 10px 0px 0px;
  border-width: 2px;
  padding: 15px;
}

.signup-team-card-heading {
  color: #1c1f20;
  font-weight: 600;
  font-size: 18px;
  margin: 0px;
}

.signup-team-card-subheading {
  color: #5e5e5e;
  font-size: 14px;
  margin: 0px;
}

.signup-team-card-body {
  border-color: #e1e1e1;
  border-style: solid;
  border-radius: 0px 0px 10px 10px;
  border-width: 0px 2px 2px 2px;
  padding: 15px;
}

.signup-team-logo {
  width: 150px;
  max-height: 100px;
  object-fit: contain;
}

.brokerSeats {
  height: 600px;
  max-height: 700px;
}
.select-broker {
  border: 1px solid #eeeeee;
  border-radius: 3px;
  height: 78px;
  width: 100%;
}
.remove-seat-button {
  background-color: transparent;
  color: #575c60;
  border: 0px;
}

.color-gray {
  color: gray;
}

.backgroundColor-0A1B40 {
  background-color: #0a1b40 !important;
}

.width-95 {
  width: 95%;
}
.mr-20p {
  margin-right: 20px;
}
.max-w-760p {
  max-width: 760px;
}
.w-90 {
  width: 90%;
}
.ml-10p {
  margin-left: 10px;
}
.gap-10p {
  grid-gap: 10px;
  gap: 10px;
}
.width-5 {
  width: 5%;
}
.height-78px {
  height: 78px;
}

.broker-card {
  height: 74px;
  border-color: #eeeeee;
  border-radius: 3px;
  border: 1px solid #eeeeee;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 0px;
}
.bg-transparent {
  background-color: transparent;
}

.avatar-width-height {
  width: 45px;
  height: 40px;
}

.avatar-radius {
  border-radius: 50%;
}

.seat-avatar-container {
  width: 45px;
  height: 45px;
}
.seat-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 45px;
}

.text-align-right {
  text-align: right;
}

.font-weight-normal {
  font-weight: normal;
}

.admin-account-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ad-create-note-spacing {
  padding-left: 50px;
  padding-right: 50px;
}

.ad-note-text-view {
  width: 100%;
  height: 100px;
  background-color: #f5f3f3;
  padding: 20px;
}

.ad-create-note-heading {
  padding: 30px;
}

.blue-text {
  color: #0930ba;
}
.bg-notification {
  background-color: rgba(255, 191, 0, 0.2);
}
.card-credit-status {
  color: white;
  background-color: #d88b43;
}
.text-dark-blue {
  color: #4b7e8f;
}
.font-60 {
  font-size: 60px;
}
.font-20 {
  font-size: 20px;
}
.right {
  float: right;
}
.border-top-light-gray {
  border-top: 2px solid #eeeeee;
}
.plan-details-popup {
  background-color: white;
  padding: 20px;
  margin-left: 50px;
  z-index: 999;
  border-radius: 2px;
  box-shadow: -2px 8px 12px 3px rgba(198, 198, 198, 0.74);
  -webkit-box-shadow: -2px 8px 12px 3px rgba(198, 198, 198, 0.74);
  -moz-box-shadow: -2px 8px 12px 3px rgba(198, 198, 198, 0.74);
}
.bandwidth-notify {
  background-color: rgb(255, 255, 255);
  width: 20%;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  left: 40%;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.bg-light-yellow {
  background-color: #fff4ce;
  margin-right: 20px;
  width: 15%;
}
.cross-bandwidth {
  position: absolute;
  right: 20px;
  top: 8px;
}
.text-neutral-500 {
  color: #bcbab8;
}
.text-neutral-700 {
  color: #6a7074;
}
.text-main-secondary {
  color: #071033;
}
.text-neutral-900 {
  color: #1c1f20;
}
.bg-neutral-900 {
  color: #1c1f20;
}
.bg-main {
  background: #071033;
}
.flex-1 {
  flex: 1 1;
}
.line-height-heading {
  line-height: 150%;
}
.bg-solid-blue {
  background-color: #0638e7;
}
.border-gray-100 {
  border: 1px solid #bcbab8;
}
.rounded-20 {
  border-radius: 20px;
}
.rounded-10 {
  border-radius: 10px;
}
.transition {
  transition: all;
}
.duration-700 {
  transition-duration: 0.7s;
}
.bg-plans {
  background-image: url(/static/media/bg-plan.e0fa1a5e.png);
  object-fit: cover;
}
.border-t-gray {
  border-top: 1px solid #bcbab8;
}

.rounded-checkbox {
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.bg-template-item {
  background-color: #f6f6f6;
}
.template-button {
  padding: 10px 20px;
}
.border-b-template {
  border-bottom: 1px solid #dcdcdc;
}
.template-active-link {
  border-bottom: 2px solid white !important;
  color: white !important;
}
.template-border-left-green {
  border-left: 4px solid #1c7469 !important;
}
.bg-transparen-gray {
  background-color: rgba(0, 0, 0, 0.6);
}
.text-gray-light {
  color: rgba(255, 255, 255, 0.8);
}
.right-1 {
  right: 2px;
}
.outline-button-new {
  outline: #006e51;
}
.rounded-checkbox:checked {
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
  background-color: blue;
}
.ml-24p {
  margin-left: 24px;
}
.ml-20p {
  margin-left: 24px;
}
.h-250p {
  max-height: 250px;
}

.px-16p {
  padding-left: 16px;
  padding-right: 16px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.border-red {
  border: 1px solid red !important;
}
.border-none {
  border: none;
}
.max-w-200 {
  max-width: 200px;
}
.rounded-20p {
  border-radius: 20px;
}
.w-30p {
  width: 30px;
}
.h-30p {
  height: 30px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ml-20p {
  margin-left: 20px;
}
.mt-20p {
  margin-top: 20px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-16p {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.font-30 {
  font-size: 30px;
}
.px-40p {
  padding-left: 40px;
  padding-right: 40px;
}
.bg-notification-ended {
  background-color: rgba(255, 0, 0, 0.2);
}
.py-12p {
  padding-top: 12px;
  padding-bottom: 12px;
}
.rounded-10p {
  border-radius: 10px !important;
}
.mt-12p {
  margin-top: 12px;
}
.mt-24p {
  margin-top: 24px;
}
.upgrade--plan--min-height {
  min-height: calc(100vh - 172px) !important;
}

.upgrade-plan--rm-pb {
  padding-bottom: 0 !important;
}

.upgrade-plan--badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 83px;
  height: 25px;
  background: #fc8080;
  border-radius: 20px;
}

.rounded-6p {
  border-radius: 6px;
}

hr {
  margin: 0;
}

.py-20p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-32p {
  padding: 32px;
}

.pb-2p {
  padding-bottom: 2px;
}

.mt-38p {
  margin-top: 38px;
}

.min-w-41p {
  width: 41px;
}

.px-30p {
  padding-left: 30px;
  padding-right: 30px;
}

.mt-65p {
  margin-top: 65px;
}

.p-6p {
  padding: 6px !important;
}

.pt-1p {
  padding-top: 1px;
}

/* Drop Down */
.upgrade--plan-dropdown {
  border-radius: 10px;
  border: 1px solid #bcbab8;
  background-color: white;
}

.upgrade--plan-dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade--plan-dropdown-body {
  border-radius: 10px !important;
  border: 1px solid #bcbab8;
  display: none;
  position: absolute;
  z-index: 999;
  background: white;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 4px;
}

.upgrade--plan-dropdown-body.open {
  display: block;
}

.upgrade--plan-dropdown-item {
  padding-left: 10px;
  padding-right: 10px;
}

.upgrade--plan-dropdown-item:hover {
  cursor: pointer;
}
.upgrade--plan-dropdown-alert {
  border-color: #e7063c !important;
}
.button-full-alert {
  background: #e7063c;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}
.button-full-alert:disabled {
  background: #f87171;
}
.button-full-alert:hover {
  background: #e7063c;
  color: #ffffff;
}

.custom--domain-logo {
  max-width: 215px !important;
  max-height: 136px !important;
  object-fit: cover;
}
.info-icon:hover .tool-tip {
  display: block;
}
.tool-tip {
  width: 171px;
  height: 76px;
  color: white;
  background-color: #000000;
  left: 15px;
  top: 0;
  z-index: 99;
  display: none;
  border-radius: 10px;
}
.selected-type {
  border: 2px solid black;
}
.w-128p {
  width: 128px;
}
.max-h-210p {
  max-height: 210px;
}
.rounded-8p-important {
  border-radius: 8px !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #cccccc;
}
input[type='checkbox'] {
  accent-color: #071033;
}
input[type='radio'] {
  accent-color: #071033;
}
.h-70screen {
  height: 50vh;
  overflow: auto;
}
.ml-20p {
  margin-left: 20px;
}
.bg-occupied {
  background-color: #b80832;
}
.py-16p {
  padding-top: 16px;
  padding-bottom: 16px;
}
.custom-select {
  /* margin: 50px; */
  width: 100%;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
  background-color: white;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  background: url(/static/media/DropDown.64c30f4c.svg) 98% / 12px no-repeat #fff;
}
.custom-select:active {
  border: 1px solid #ccc;
  height: 34px;
  background-color: white;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  background: url(/static/media/DropDown.64c30f4c.svg) 98% / 12px no-repeat #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 28px;
  width: 28px;
  left: -2px;
  bottom: -3px;
  background-color: #e5e5e4;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
  background-color: #008b16;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-radio[type='radio'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid #d3d3d3;
  width: 15px;
  height: 15px;
  content: none;
  outline: none;
  border-radius: 100%;
  margin: 0;
}

.custom-radio[type='radio']:checked {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 100%;
  outline: none;
  padding: 0;
  content: none;
  border: none;
  width: 15px;
  height: 15px;
}

.custom-radio[type='radio']:checked::before {
  position: absolute;
  background: #0c1332;
  accent-color: blue;
  border-radius: 100%;
  color: white !important;
  width: 15px;
  height: 15px;
  content: '\2713' !important;
  /* border: 1px solid #d3d3d3; */
  font-weight: bolder;
  font-size: 12px;
}

.max-width-100 {
  max-width: 100% !important;
}
.ml-20p-imp {
  margin-left: 20px !important;
}

.text-available {
  color: #0a893d;
}
.text-not-available {
  color: #b80832;
}
.font-40p {
  font-size: 40px;
}
.py-40 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.h-75p {
  height: 75px;
}
.object-cover {
  object-fit: cover;
}
.max-w-380p {
  max-width: 380px;
}
.w-70 {
  width: 70%;
}

.max-w-50 {
  max-width: 50%;
}
.button-outline {
  border: 1px solid #050505;
}

.plan-img-tile {
  width: 100px;
  height: 40px;
  object-fit: cover;
}

.plan-name {
  align-items: center;
  padding: 14px 12px 14px 13px;
}

/*
  Media Queries
 */

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .listing--schedule-col {
    flex-direction: column;
  }
  .week--time {
    padding-right: 0;
    margin-bottom: 16px;
  }
  .hours--time,
  .listing--schedule-col .select--group,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .ca--cover-large {
    width: 55%;
  }
  .group-input .outline__button {
    min-width: 150px;
  }
}
@media screen and (min-width: 576px) {
  .flex-row-sm-reverse {
    flex-direction: row-reverse;
  }
  .modal--uploadPhotos .modal-dialog {
    max-width: 860px;
  }
  .modal-settings .modal-dialog {
    max-width: 700px;
  }
  .listing--modal .modal-dialog,
  .office-modal .modal-dialog {
    max-width: 640px;
  }
  .footer-n .footer__logo {
    margin-right: 20px;
  }
  .modal--video .modal-dialog {
    max-width: 100%;
  }
  .users--content-tenant {
    margin-bottom: -23px;
  }
  .liveTour--amenities-main {
    margin-top: 32px;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .tourListing--block .tourListing__img {
    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .presentation--footer,
  .suite--component-mobile {
    display: none;
  }
  .carouselPre--ul {
    max-height: 100%;
    min-height: 100%;
  }
  .carouselPre--li {
    display: flex;
    flex-direction: column;
  }
  .itinerary--back-canvas {
    padding-right: 78px;
  }
  .presentation--content {
    flex: 1 1;
    width: 90%;
  }

  /* Pricing payment page media query*/
  /* credit card payment page media query */
  .crdCard--payment--top--icon {
    margin-right: 12px;
  }
  /* credit card payment page media query */
  /* Pricing payment page media query*/
  .marketing--presentation--btn--container .btn:first-child {
    margin-right: 10px;
  }
  .marketing--presentation--btn--container .btn:last-child {
    margin-left: 10px;
  }
  .video--profile-img-canvas {
    left: -14px;
  }
  .video--profile-img {
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
  }
}
@media screen and (max-width: 786px) {
  .dashTable--listing .dashTableListing__img {
    width: 100%;
  }
  .presentation--off {
    padding: 20px;
    height: 100%;
    /* flex: 0; */
    width: 100%;
  }
  .dashTable--listing .dashTableListing__video {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .steps--content .row .col-12 {
    border-right: 1px solid rgba(188, 186, 184, 0.5);
  }
  .steps--content .row .col-12:last-child {
    border-right: 0;
  }
  .modal--video-set {
    max-width: 640px;
    height: 360px !important;
  }
  .users--content-tenant {
    margin-top: -90px;
  }
  .liveTour--mobile {
    display: none;
  }
  .sec--vertical-md {
    min-height: 100vh;
  }
  .sec--vertical-md .sec--vertical-middle {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .vt--share {
    min-height: 100vh;
    align-items: center;
  }
  .liveTour--options {
    min-width: 360px;
  }
  .liveTour--content-broker .liveTour--options.liveTour--shadow-effect:before {
    content: '';
    width: 21px;
    background: linear-gradient(95.75deg, #1c1f20 2.38%, rgba(28, 31, 32, 0) 86.57%);
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    pointer-events: none;
  }
  .liveTour--options li:last-child .liveTourOpt__box {
    padding-left: 20px;
    border-left: 1px solid rgba(213, 217, 224, 0.3);
  }
  .tourListing--canvas {
    overflow: hidden;
  }
  .tourListing--canvas.tourListing--canvas-shadow-effect:after {
    content: '';
    box-shadow: 0px -54px 10px rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: -58px;
    left: 0;
    right: 0;
    height: 27px;
  }
  .tourListing--scroll::-webkit-scrollbar {
    width: 3px;
  }
  .tourListing--scroll::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
  .dashboard--newAccount .admin__button {
    min-width: 230px;
  }
  .tourEdit--nl {
    margin-top: 140px;
  }
  .tourPre--content .liveTour--options {
    min-width: 150px;
  }
  .presentation--page {
    height: 100vh;
  }
  .presentation--welcome .preWelcome--main {
    overflow-y: auto;
    max-height: 100%;
    padding-right: 30px;
  }
  .preSpecs__list {
    flex-wrap: wrap;
    overflow-y: auto !important;
    flex: 1 1;
  }
  .preSpecs__listItem {
    width: 100% !important;
    flex-grow: initial !important;
  }
  .preSpecs__list li button {
    opacity: 0;
  }
  .preAmenities__list {
    flex-wrap: wrap;
    flex: 1 1;
  }
  .preAmenities__listItem {
    width: 100% !important;
  }
  .presentation--content .preSpecs--col {
    display: flex;
  }
  .presentation--content .specs--widget {
    width: calc(25% - 12px);
    margin-left: 6px;
    margin-right: 6px;
  }
  .carousel--presentation {
    flex: 1 1;
  }
  .presentation--content {
    transition: width 1s;
  }
  .carousel--presentation {
    transition: width 1s, height 1s;
    transition: margin 400ms;
  }
  .presentation--off .presentation--container {
    transition: all 0.2s ease;
  }
  .preControl--box .preControlBox__icon,
  .preControl--box .preControlBox__text,
  .presentation--navbar {
    opacity: 1;
    transition: all 0.3s ease;
    animation-name: fadeInOpacity;
    animation-iteration-count: 2;
    animation-timing-function: ease-in;
    animation-duration: 3s;
  }
  .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    transition: width 1s;
  }
  .preControl--box .preControlBox__arrow {
    transition: margin 400ms;
  }
  .presentation--page.full--screen {
    padding: 24px 44px;
  }
  .presentation--page.full--screen .preControl--box .preControlBox__icon,
  .presentation--page.full--screen .preControl--box .preControlBox__text {
    opacity: 0;
  }
  .presentation--page.full--screen .presentation--navbar {
    opacity: 0;
  }
  .presentation--page.full--screen .preControl--outer.preControl--outer-prev:before,
  .presentation--page.full--screen .preControl--outer.preControl--outer-next:after {
    width: 0;
  }
  .presentation--page.full--screen .presentation--content {
    width: 100%;
  }
  .presentation--page.full--screen .carousel--presentation {
    margin-top: -78px;
  }
  .presentation--page.full--screen .preControl--box .preControlBox__arrow {
    margin-top: -78px;
  }
  .presentation--page.full--screen .presentation--container {
    padding-left: 0;
    padding-right: 0;
  }
  .presentation--page.full--screen .preControl--outer {
    position: fixed;
  }
  .presentation--page.full--screen .presentation--control {
    width: 44px;
  }
  .mobile--nav,
  .mobile--footer {
    display: none;
  }

  /* Pricing landing page media query */
  .pricing--choose--plan--inner {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
  }
  .pricing--choose--card--container .col {
    margin-bottom: 0px;
  }
  /* Pricing landing page media query */

  /* Pricing payment page media query*/
  /* credit card payment page media query */
  .credit--card--payment--form .forms--group--col:nth-last-child(2) {
    margin-bottom: 0px;
  }
  /* credit card payment page media query */
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .marketing--home--top--container {
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
  }
  .marketing--home--top--content .pricing__main__heading,
  .marketing--home--top--content .simple__paragraph {
    text-align: left;
  }
  .marketing--home--top--content .mktHome--top--content--btn {
    justify-content: flex-start;
    margin-top: 40px;
  }
  .marketing--home--opinion--component .opinion--canvas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    max-width: 50%;
    flex: 1 1 50%;
    margin-right: 15px;
  }
  .marketing--home--opinion--component .opinion--content {
    max-width: 50%;
    flex: 1 1 50%;
    margin-left: 15px;
  }
  .marketing--home--opinion--component .opinion--block {
    align-items: flex-start;
  }
  .marketing--home--opinion--component .marketing--opinion--heading {
    max-width: 480px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    margin-bottom: 0px;
    text-align: left;
  }
  .marketing--home--opinion--component .opinion__para,
  .marketing--home--opinion--component .op--name {
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .marketing--home--opinion--component .opinion__para:before {
    background-size: 52px;
    width: 52px;
    height: 40px;
    left: -37px;
    top: -19px;
  }
  .marketing--presentation--carousal--canvas .mktSlider--immerse--container {
    right: -29px;
  }
  .marketing--home--opinion--component .op__logo__container {
    justify-content: flex-start;
  }
  .marketing--home--presentation--section .marketing--presentation--tog--div {
    position: absolute;
    right: 20px;
    top: 0;
  }
  .video--profile-img-canvas {
    left: -20px;
  }
  .video--profile-img {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
  }
  /* Marketing homepage */
}
@media screen and (min-width: 992px) {
  .key--box-temp {
    border-color: #000;
  }
  .border-color {
    border-color: transparent !important;
  }

  .navbar-light .navbar__list .nav-link,
  .navbar__list .nav-link {
    margin: 4px 25px;
  }

  .row--space-wide {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row--space-wide > .col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .form--content .navbar__header .navbar-brand,
  .menu__toggle {
    display: none;
  }
  .vc--mobile {
    display: none;
  }
  .about--block:nth-child(odd) .about--detail,
  .about--block:nth-child(even) .about--img {
    padding-right: 120px;
  }
  .about--block:nth-child(even) .about--detail,
  .about--block:nth-child(odd) .about--img {
    padding-left: 120px;
  }
  .form--content .navbar__list {
    margin-left: 0;
  }
  .form--banner .form__brand {
    display: block;
  }
  .form--page,
  .dashboard--page {
    overflow: hidden;
  }
  .form--banner,
  .form--content,
  .dashboard--content {
    height: 100vh;
    overflow-y: auto;
  }
  .form--content--payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form--content--company--setup {
    display: flex;
    flex-direction: column;
  }
  .dashboard--container {
    padding-left: 70px;
    padding-right: 70px;
  }
  .settings-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .al--block .al--listings-component {
    padding: 0px 10px;
  }
  .listingFinal--content {
    display: flex;
    align-items: center;
  }
  .virtual--video-block {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-right: 0;
  }
  .empty--box {
    height: calc(100vh - 107px);
    margin-top: 0;
  }
  .empty--listings {
    height: calc(100vh - 152px);
  }
  .standby--content {
    margin-top: 0;
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
  }
  .info--block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .row--gif .col-lg-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .al__address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .available--listing-cols .scroll--listing-cols {
    max-height: 890px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .liveTour--amenities-main {
    margin-top: 44px;
  }
  .voiceRecord--block {
    max-height: 180px;
    margin-bottom: 16px;
    overflow-y: auto;
  }
  .voiceRecord--block::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  .voiceRecord--block::-webkit-scrollbar-thumb {
    background-color: #bcbab8;
  }
  .sec--vertical-lg,
  .virtualTour--combine {
    min-height: 100vh;
  }
  .sec--vertical-lg .sec--vertical-middle,
  .vt--combine-alt {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .share--realistic .share--content {
    padding-left: 240px;
  }
  .al--block-lg .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-lg .al--listings-component {
    min-width: 42%;
    max-width: 42%;
  }
  .al--block-sm .al--adress-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-sm .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al--adress-component {
    min-width: 40%;
    max-width: 40%;
  }
  .al--block-md .al--listings-component {
    min-width: 44%;
    max-width: 44%;
  }
  .al--block .al--btn-component {
    width: auto;
    min-width: 10px;
    max-width: 100%;
  }
  .al--block.buildings--block .bb-sf {
    min-width: 60%;
    max-width: 60%;
  }
  .available--listing-map {
    height: 890px;
    overflow: hidden;
  }
  .unpublished--col {
    margin-top: -60px;
  }
  .toggle__btn {
    display: none;
  }
  .dashboard--bar .dashboard__heading {
    line-height: 48px;
  }
  .al--lg-alt .al--btn-component {
    min-width: 24%;
    max-width: 24%;
  }
  .show--col-list .admin__button {
    min-width: 94px;
  }
  .tourListing--scroll {
    max-height: 324px;
    overflow-y: auto;
    padding-right: 3px;
  }
  .dashboard--sticky {
    overflow-y: hidden;
  }
  .dashboard--sticky .box--expand {
    overflow-y: auto;
  }
  .dashboard--sticky .dashboard--canvas {
    height: calc(100vh - 40px);
  }
  .tab--building-sticky,
  .tab--building-sticky .tabBuilding--content,
  .tab--building-sticky .tabBuildingContent--canvas,
  .tab--building-sticky .tabBuildingContent--inner {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab--building-sticky .tab-pane {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab--building-sticky .tabBuildingContent--canvas {
    max-height: 100%;
  }
  .started--canvas {
    padding-left: 70px;
  }
  .info--carousel-block .tempAbout--img:before {
    content: none;
  }
  .info--carousel-block:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%;
    background-color: #f9f9f9;
  }
  .listings--grid-change > .row > .col-lg-12 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .listings--grid-change > .row > .col-lg-12 .bulding--block-row .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sustain--row .col {
    max-width: 50%;
  }
  .al--block-alt.buildingsBlock--update .al--adress-component {
    min-width: 25%;
    max-width: 25%;
  }
  .al--block-alt.buildingsBlock--update .bb-sf {
    min-width: 44%;
    max-width: 44%;
    margin-right: auto;
  }
  .al--block-alt.buildingsBlock--update .buildingsBtn--canvas {
    min-width: 23%;
    max-width: 23%;
  }
  .al--block-alt.buildingsBlock--sm .buildingsBtn--canvas {
    min-width: 20%;
    max-width: 20%;
  }
  .al--block-alt.buildingsBlock--sm .bb-sf {
    min-width: 50%;
    max-width: 50%;
  }

  /* Pricing landing page media query */
  .navbar__header__payment .navbar__list .nav-link {
    margin-right: 45px;
    margin-left: 0px;
  }
  .navbar__header__payment .navbar__list-n .nav-link.active {
    color: #1c1f20;
    /* border-bottom: 1px solid #000000; */
  }
  .navbar__header__payment .navbar__list .nav-link:last-child {
    margin-right: 0px;
  }
  /* Pricing landing page media query */

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--main--section {
    justify-content: space-between;
    flex-direction: row;
  }
  .payment--method--container {
    flex: 1 1 62%;
    max-width: 62%;
    height: 100%;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .payment--billing--card {
    flex: 1 1 38%;
    max-width: 38%;
    height: 100%;
    margin-left: 10px;
    margin-top: 0px;
  }
  .crdCard--payment--top--icon {
    margin-right: 16px;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .marketing--home--top--section {
    background: url(/static/media/marketing-bg-lady.79a89ac5.png) center bottom no-repeat;
    background-size: 68%;
  }
  .marketing--home--personalize--component .mktHome--personalize--canvas {
    display: flex;
    flex: 1 1 auto;
    margin-right: -13%;
    margin-top: 30px;
  }
  .marketing--home--personalize--component .mktHome--personalize--form--container {
    display: flex;
    flex: 1 1 auto;
  }
  .marketing--home--personalize--component .personalize--block {
    min-width: 485px;
    margin-right: 0;
    margin-right: initial;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    margin-right: 20px;
  }
  .marketing--home--opinion--component .opinion--content {
    margin-left: 20px;
  }
  .marketing--home--presentation--section .marketing--presentation--tog--div {
    right: 70px;
  }
  .video--profile-img {
    min-width: 58px;
    max-width: 58px;
    min-height: 58px;
    max-height: 58px;
  }
  /* Marketing homepage */
}
@media screen and (min-width: 1200px) {
  .container--expand {
    max-width: 1170px;
  }
  .row--space-price,
  .row--space-price .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--space-price .col-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-availableListings > .col-lg-4 {
    flex: 0 0 34.5%;
    max-width: 34.5%;
  }
  .row-availableListings > .col-lg-8 {
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }
  .broker--accordian {
    min-height: 420px;
  }
  .al--block-md .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al__address {
    -webkit-line-clamp: 1;
  }
  .ls--left {
    min-width: 204px;
    max-width: 204px;
    margin-right: 20px;
  }
  .ls--bottom .ls--left {
    margin-bottom: 20px;
  }
  .al--block-alt .al__address {
    -webkit-line-clamp: 2;
  }
  .al--block-alt .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 36%;
    max-width: 36%;
  }
  .al--block-alt .al--btn-component {
    min-width: 12%;
    max-width: 12%;
  }
  .photos--listings {
    margin-left: -32px;
    margin-right: -32px;
  }
  .media--video-block .media--video {
    width: 220px;
  }
  .tourPre--top .gallery__cross {
    right: -70px;
  }
  .tourPre--top .magnifier-tools {
    right: -80px;
  }
  .listingTourBtns--row {
    margin-left: 2px;
    margin-right: 2px;
    flex-grow: 1;
  }
  .listingTourBtns--row .col {
    padding-left: 2px;
    padding-right: 2px;
  }
  .listingTour--t {
    display: flex;
    align-items: center;
  }
  .tempSpecsTh--row .col-xl {
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* Pricing payment page media query  */
  /* credit card payment page media query*/
  .payment--method--container {
    flex: 1 1 67%;
    max-width: 67%;
  }
  .payment--billing--card {
    flex: 1 1 33%;
    max-width: 33%;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query  */

  /* Marketing homepage media query */
  .marketing--home--personalize--component .personalize--block {
    min-width: 620px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    font-size: 54px;
    line-height: 75px;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    margin-right: 40px;
  }
  .marketing--home--opinion--component .opinion--content {
    margin-left: 45px;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 45px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 40px;
  }

  .marketing--presentation--slider--inner {
    flex-direction: row;
  }
  .marketing--get--started--slider .marketing--presentation--slider--inner {
    flex-direction: column;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 15px;
  }
  .marketing--presentation--slider--inner .pricing__main__heading {
    text-align: left;
  }
  .marketing--get--started--slider .marketing--presentation--slider--inner .pricing__main__heading {
    text-align: center;
  }
  .marketing--presentation--carousal--canvas .marketing--presentation--slider--inner {
    justify-content: space-between;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 32%;
    max-width: 32%;
    padding-right: 40px;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--txt--list {
    padding-left: 50px;
    padding-right: 0;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    margin-top: 4px;
    flex: 0 0 68%;
    max-width: 68%;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--canvas--inner {
    margin-left: auto;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 30px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 30px;
  }
  .marketing--carousal--canvas--reverse .marketing--presentation--slider--inner {
    flex-direction: row;
  }
  .marketing--carousal--canvas--reverse .mktSlider-canvas-second {
    margin-bottom: 0px;
  }
  .marketing--home--personalize--component .personalize--block .heading__component {
    text-align: left;
  }
  .market--home-pre-last .marketing--slider--top--txt {
    margin: -60px 0px 0px 0px;
  }
  .marketing--presentation--slider--btn {
    margin-top: -40px;
  }
  .marketing--home--carousel {
    margin-top: -80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  /* Marketing homepage media query */
}
@media screen and (min-width: 1280px) {
  .team--main-sbl {
    padding-left: 30px;
    padding-right: 30px;
  }
  /* Marketing homepage media query */
  .marketing--home--top--section {
    background-size: 1062px;
    min-height: 762px;
  }
  /* Marketing homepage media query */
}
@media screen and (min-width: 1367px) {
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 34%;
    max-width: 34%;
    padding-right: 90px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    flex: 0 0 66%;
    max-width: 66%;
  }
}
@media screen and (min-width: 1440px) {
  .container--expand {
    max-width: 1270px;
  }
  .fluid--expand {
    max-width: 1440px;
  }
  .container--xs {
    max-width: 800px;
  }
  .md--canvas {
    max-width: 960px;
  }
  .vp__box {
    right: -11px;
    top: 132px;
  }
  .vp__box:after {
    height: 31px;
    left: 2px;
    bottom: -22px;
  }
  .vp__logo:before {
    height: 26px;
    right: 45px;
    top: -25px;
  }
  .vt--demand-content {
    min-height: 450px;
  }
  .vt--combine {
    margin: 50px 0;
  }
  .vt--combine .adminSettings--head {
    margin-bottom: 20px;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 480px;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    font-size: 18px;
    padding: 12px 16px;
  }
  .al--block-alt .al--adress-component {
    min-width: 32%;
    max-width: 32%;
  }
  .al--block-alt .al--listings-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-alt .al--btn-component {
    min-width: 15%;
    max-width: 15%;
  }
  .tourPre--top .liveTour--placement,
  .tourPre--top .liveTour--gallery,
  .tourPre--top .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .photos--off {
    margin-left: -36px;
    margin-right: -36px;
  }
  .photos--off .photos__control-prev {
    margin-right: 22px;
  }
  .photos--off .photos__control-next {
    margin-left: 22px;
  }
  .media--video-block .media--video {
    width: 330px;
  }
  .media--video-block .media--detail {
    min-width: 190px;
  }
  .dashboard--table thead tr th:first-child {
    padding-left: 38px;
  }
  .dashboard--table thead tr th:last-child {
    padding-right: 38px;
  }
  .dashboard--table tbody tr td:first-child {
    padding-left: 30px;
  }
  .dashboard--table tbody tr td:last-child {
    padding-right: 20px;
  }
  .listingTourBtns--row {
    margin-left: 10px;
    margin-right: 10px;
  }
  .listingTourBtns--row .col {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashboard--table-f tbody tr td:first-child {
    min-width: 310px;
    max-width: 310px;
  }
  .dashboard--table-se tbody tr td:first-child {
    min-width: 232px;
    max-width: 232px;
  }
  .dashboard--table-th tbody tr td:first-child {
    min-width: 750px;
    max-width: 750px;
  }
  .navbar__header__payment .navbar__list-n .nav-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 1620px) {
  .container--expand {
    max-width: 1400px;
  }
  .fluid--expand {
    max-width: 1600px;
  }
  .container--xs {
    max-width: 960px;
  }
  .tour--col {
    min-width: 20%;
  }
  .liveTour--building-top {
    margin-top: 60px;
  }
  .liveTour--specs-main {
    margin-top: 44px;
  }
  .building--widget .bw__arrow {
    font-size: 11px;
    line-height: 12px;
  }
  .vt--container {
    max-width: 960px;
  }
  .listing__btn {
    min-height: 130px;
  }
  .listing__btn .para__sm-gray {
    font-size: 14px;
  }
  .time--dropdown .dropdown-toggle {
    font-size: 18px;
  }
  .time--dropdown .dropdown-menu {
    font-size: 16px;
  }
  .video--content .videoContent--place {
    height: 450px;
  }
  .comment--block {
    height: 546px;
  }
  .dashboard--tourListing .tour--canvas-listing {
    height: calc(100vh - 40px);
  }
  .tourListing--canvas-alt .tourListing--scroll {
    max-height: none;
    max-height: initial;
  }
  /* .tourListing--canvas-alt .tourListing--scroll {
    max-height: 400px;
  } */
  .fluid--expand--payment {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pricing--choose--plan--inner {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
  .payment--billing--card .payment__billing__card__listTxt {
    font-size: 18px;
  }
  .payment--billing--card .payment--billing--card--total .payment__bill__totalTxt {
    font-size: 20px;
  }
  .bank--payment--container .bank--payment--sub--top .bank__payment__top__para {
    font-size: 18px;
  }
  .payment--signup--container .container--xs {
    max-width: 75%;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--txt--list {
    padding-left: 90px;
    padding-right: 0;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 1440px) {
  .tourPre--top .liveTour--container {
    max-width: 960px;
  }

  .fluid--expand {
    padding-left: 60px;
    padding-right: 60px;
  }
  .container--large,
  .vt--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdf__btn:not([href]),
  .pdf__btn {
    font-size: 15px;
    padding: 10px 12px;
  }
  .pdf__btn .pdf__btn-icon {
    font-size: 24px;
    margin-right: 8px;
    min-width: 22px;
  }
  .block--search-row .photos--box {
    height: 160px;
  }
  .photos--listings .photos__control {
    top: 70px;
  }
  .started--imgs {
    max-width: 480px;
  }
  .tourPre--top .liveTour--gallery .liveTour--container {
    max-width: 960px;
  }
  .video--canvas-expand .video--box-expand {
    width: 950px;
    height: 535px;
  }
  .presentation--content .preContent--head .heading__lg-dark,
  .locationContent__heading {
    font-size: 30px;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 34px;
    width: 34px;
    margin-right: 20px;
  }
  .presentation--content .detailComp--box .text__md-gray {
    font-size: 14px;
    line-height: 15px;
  }
  .presentation--content .detailComp--box .heading__medium {
    font-size: 20px;
    line-height: 24px;
  }
  .preVideo--canvas {
    padding-top: 44%;
    width: calc(100% - 22%);
  }
  .template--radio .heading__md-dark {
    font-size: 16px;
  }
}
@media screen and (max-width: 1300px) {
  .vp__logo {
    left: -20px;
  }
  .vp__logo:before {
    height: 22px;
    left: 80px;
    right: inherit;
    top: -21px;
  }
  .vp__box:after {
    height: 28px;
    left: 0;
    bottom: -18px;
  }
}
@media screen and (max-width: 1280px) {
  .tourEdit--top {
    height: 380px;
  }
  .video--canvas-expand {
    margin-left: 50px;
    margin-right: 50px;
  }

  /* Marketing homepage media query */
  .mktHome--top--content--inner {
    max-width: 360px;
  }
  .marketing--home--top--video {
    margin-top: 60px;
    margin-right: 3%;
  }
  /* Marketing homepage media query */
}
@media screen and (max-width: 1200px) {
  .tourPre--top .liveTour--container {
    max-width: 720px;
  }
  .form--banner {
    width: 400px;
    min-width: 400px;
  }
  .form--content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal--listing .modal-dialog {
    max-width: 100%;
  }
  .al--block {
    flex-direction: column;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
  }
  .al--block .al--block-detail {
    margin-left: 0;
  }
  .landing__heading {
    font-size: 52px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 14px;
    margin-right: 14px;
  }
  .tour--col {
    min-width: 34%;
  }
  .broker--video {
    margin-bottom: 48px;
  }
  .ls--top,
  .ls--bottom {
    flex-direction: column;
    align-items: flex-start;
    min-height: 10px;
  }
  .ls--top {
    min-height: 90px;
    padding-right: 0;
    margin-top: 20px;
  }
  .liveTour--listing-search .ls--top {
    min-height: 10px;
  }
  .ls--sort {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .ls--opt {
    width: 100%;
    margin-bottom: 20px;
  }
  .ls--top .ls__cross {
    top: 6px;
  }
  .liveTour--listing {
    overflow-y: auto;
  }
  .liveTour--listing::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .search__block {
    margin-bottom: 32px;
  }
  .white--rounded-box {
    padding: 24px;
  }
  .dashboard--card {
    padding-left: 24px;
    padding-right: 24px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    margin-bottom: 20px;
    height: 400px;
    min-width: 10px;
  }
  .dashboard__btn {
    margin-top: 20px;
  }
  .upcomingTour--block .upcomingTour--left {
    min-width: 160px;
    max-width: 160px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .subDashboard--bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .subDashboard--bar .subDashboard--top-btns {
    width: 100%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .listing__btn .listing__btn-icon {
    font-size: 20px;
    margin-right: 12px;
  }
  .listing__btn .listing__btn-heading {
    font-size: 16px;
  }
  .video--canvas-expand .video--box-expand {
    width: 720px;
    height: 405px;
    border-radius: 26px;
  }
  .account--block {
    flex-direction: column;
    align-items: flex-start;
  }
  .account--block .accountBlock--col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .updateAccount__list li {
    padding: 0 12px;
    margin-top: 20px;
  }
  .updateAccount--box {
    justify-content: center;
  }
  .updateAccount__icon {
    flex: 1 1;
    justify-content: flex-end;
    padding-right: 5px;
  }
  .updateAccount__detail {
    flex: 1 1;
  }
  .updateAccount--box .updateAccount__heading {
    font-size: 16px;
  }
  .updateAccount--box .para__md-gray {
    font-size: 14px;
  }
  .presentation--main-even .preContent--detail {
    padding-left: 40px;
  }
  .presentation--main-odd .preContent--detail {
    padding-left: 30px;
  }
  .preAmenities--carousel,
  .preSpecs--carousel {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar__header__payment .navbar__list-n .nav-link {
    padding: 6px 8px;
  }
  .pricing__main__heading {
    font-size: 50px;
    line-height: 65px;
  }
  .marketing--home--slider--item {
    display: flex;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    width: 100%;
  }
  .marketing--home--presentation--section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt {
    max-width: 550px;
  }
  .listing-card-container {
    padding: 0px;
    justify-content: center;
  }
  .listing-card-details-container {
    flex-direction: column;
    flex: 0.65 1;
    padding: 5px;
  }
  .listing-card-image-container {
    flex: 0.35 1;
    border-radius: 10px 0px 0px 10px;
    height: auto;
    max-height: 160px;
  }
  .listing-card-details {
    display: none;
  }
  .listing-card-description {
    padding-left: 5px;
  }
  .listing-card-status-container {
    justify-content: center;
    flex: 0.5 1;
  }
  .listing-card-title {
    font-size: 14px;
  }
  .listing-card-subtitle {
    font-size: 13px;
  }
  .mobile-hidden {
    display: none;
  }
  .w-md-100 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 33.33%;
  }
  .tourPre--page {
    height: auto;
  }
  .tourPre--top {
    height: 480px;
  }
  .preVideo--canvas {
    padding-top: 56.25%;
    width: 100%;
  }
  .marketing--home--top--video {
    margin-right: 0%;
  }
}
@media screen and (max-width: 560px) {
  .suite--block .img-height {
    height: 205px;
  }
}
@media screen and (max-width: 991px) {
  /* .tourPre--top .liveTour--container {
    max-width: 540px;
  } */
  .listing--border-style {
    border: 2px solid #c4c4c4;

    border-style: solid;
    border-radius: 22px;
  }
  .suite--block .suite--img {
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .key--box-temp .listing--font-size {
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
  }
  .heading-font-weight {
    font-weight: normal;
  }
  .entry-height {
    min-height: 20px;
  }
  .listing--adjust-direction {
    display: flex;
    flex: 85% 1;
  }
  .entry-flex-start {
    display: flex;
    flex-direction: row-reverse;
  }
  .suite--block-margin {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .suite--block .img-height {
    height: 250px;
    align-items: flex-start;
  }
  .justify-content-start {
    display: flex;
    justify-content: flex-start;
  }
  .listing--adjust-location {
    align-items: initial;
    max-width: 100%;
  }

  .entry-size {
    display: flex;
    flex: 25% 1;
  }
  .display-none {
    display: none;
  }

  .key--box-temp {
    flex-direction: row;
    padding: 0%;
    align-items: unset;
    padding-left: 0px;
  }

  .key--box-temp {
    justify-content: space-between;
    border-color: transparent;
    text-align: start;
  }
  .container--expand,
  .fluid--expand,
  .navbar-expand-lg > .fluid--expand {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container--large,
  .container--expand {
    max-width: 100%;
  }
  .navbar__list {
    padding: 15px;
  }
  .navbar--btns {
    padding: 0px 15px 15px 15px;
  }
  .navbar__header .navbar-collapse {
    background-color: #28313c;
    margin-top: 14px;
    box-shadow: 2px 0px 11px rgba(0, 0, 0, 0.15);
  }
  .nav__signin-button {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar__header .outline__button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .lists__heading {
    font-size: 26px;
  }
  .lists__subHeading {
    font-size: 22px;
  }
  .lists-info li {
    font-size: 20px;
  }
  .fields__icon {
    right: 20px;
    top: 13px;
    font-size: 22px;
  }
  .fields--group {
    margin-bottom: 16px;
  }
  .tour--status {
    font-size: 16px;
    right: 15px;
    top: 15px;
  }
  .heading__landing {
    font-size: 42px;
  }
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 26px;
  }
  .steps__paragraph {
    font-size: 16px;
  }
  .about--block .about--detail {
    margin-bottom: 40px;
    text-align: center;
  }
  .about--block .about--img {
    text-align: center;
  }
  .about__heading {
    margin-bottom: 16px;
  }
  .footer {
    margin-top: 40px;
  }
  .company__about {
    font-size: 16px;
    line-height: 28px;
  }
  .listing--map {
    height: auto;
    min-height: 320px;
  }
  .form--page {
    flex-direction: column-reverse;
  }
  .form--banner {
    display: none;
  }
  .app__button-sm {
    min-height: 48px;
  }
  .sidebar {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .sidebar.sidebar--hide {
    margin-left: 0;
    margin-right: 0;
  }
  .dashboard--select-n {
    width: 100%;
  }
  .broker__table {
    min-width: 880px;
  }
  .container--xs {
    max-width: 100%;
  }
  .listing--schedule {
    flex-direction: column;
  }
  .listing--schedule-col {
    padding-left: 0;
    padding-right: 0;
  }
  .hours__change {
    margin: 10px 0;
  }
  .al--block .al--block-detail {
    flex-direction: column;
  }
  .al--listing-box .al--listing,
  .al--listing-box .al--listing:last-child {
    margin-bottom: 16px;
  }
  .customer--block {
    margin: 0px 0px 16px 0px;
  }
  .buildings--block .bb-sf {
    width: 100%;
    justify-content: space-between;
    margin-top: 6px;
  }

  .heading--group .heading__edit {
    margin-top: 4px;
  }
  .vb--positioning {
    margin-right: 0;
  }
  .hours__del {
    margin: 20px 0px 20px auto;
  }
  .opinion__para {
    font-size: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .opinion__para:before {
    background-size: 18px;
    left: 0;
    top: -10px;
  }
  .opinion__para:after {
    background-size: 18px;
    right: 0;
    bottom: -14px;
  }
  .landing__header-n .navbar-collapse {
    background-color: #15235c;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link,
  .navSignin__btn {
    color: #fff;
  }
  .landing__header-n .schedule__btn-outline {
    border-color: #fff;
    color: #fff;
  }
  .landing__heading {
    font-size: 42px;
  }
  .landing__para {
    font-size: 18px;
  }
  .info--block {
    margin-bottom: 48px;
  }
  .info--content .row .col-12:last-child .info--block {
    margin-bottom: 0;
  }
  .info--component {
    padding-top: 140px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 0;
    margin-right: 0;
  }
  .tour--col {
    min-width: 43%;
  }
  .vp__box:after,
  .vp__logo:before,
  .vp__secure:after {
    content: none;
  }
  .approve--video {
    margin-bottom: 32px;
  }
  .vt--combine .adminSettings--head .adminSettings__icon {
    font-size: 20px;
  }
  .vt--combine .adminSettings--head .adminSettings__text {
    font-size: 24px;
    line-height: 30px;
  }
  .share--options {
    display: none;
  }
  .buildings--block .bb--inner {
    width: auto;
  }
  .available--listing-map {
    height: 400px;
    overflow: hidden;
    margin-top: 32px;
  }
  .al--listing-combine {
    justify-content: space-between;
  }
  .ls--box .ls--box-detail {
    max-width: 260px;
    min-width: 260px;
  }
  .dashbaord--opt {
    margin-top: 16px;
  }
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .al--block-alt .buildings__next {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .upcomingTour--block {
    flex-direction: column;
  }
  .upComTour--btn-canvas {
    width: 100%;
  }
  .upComTour__list {
    padding-right: 0;
  }
  .upcomingTour--block .upcomingTour--main {
    width: 100%;
    margin-bottom: 24px;
  }
  .al--block-alt .al--btn-component {
    flex-direction: row;
  }
  .building__btn:not([href]),
  .building__btn {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sidebar--user-canvas {
    padding: 20px 20px 24px 20px;
  }
  .sidebar--hide .sidebar--user-canvas {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar--popover {
    left: -8px !important;
    margin-top: -20px !important;
  }
  .tabBuilding--nav .nav-link .tb__link-text,
  .tel__btn .tel__btn-text {
    display: none;
  }
  .tel__btn:not([href]),
  .tel__btn {
    min-width: 52px;
  }
  .tel--btns-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tel--btns-row .tel--btns-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-img {
    margin-bottom: 10px;
  }
  .map--block,
  .sdl--combine {
    padding: 60px 0px;
  }
  .video--canvas-expand {
    height: calc(100vh - 20px);
    margin: 10px;
  }
  .video--modal .cross__circle-modal {
    line-height: 20px;
    min-width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
    color: #6a7074;
    position: absolute;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .listing--btns-top .listing__btn {
    margin-bottom: 20px;
  }
  .listing--btns-top .row .col-12:last-child .listing__btn {
    margin-bottom: 0;
  }
  .lsBlock--btns-single .link__btn {
    margin-left: auto;
  }
  .listing__btn {
    min-height: 10px;
  }
  .listing__btn .para__sm-gray {
    -webkit-line-clamp: inherit;
  }
  .navbar-light .navbar__list-n .nav-link:hover,
  .navbar__list-n .nav-link:hover,
  .navbar-light .navbar__list-n .nav-link.active,
  .navbar__list-n .nav-link.active,
  .navSignin__btn:not(:disabled):not(.disabled).active,
  .navSignin__btn:not(:disabled):not(.disabled):active,
  .navSignin__btn:hover,
  .navSignin__btn:focus,
  .navSignin__btn.active {
    color: #fff;
  }
  .started--imgs {
    max-width: 320px;
  }
  .tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 33.33%;
  }
  .tourPre--top .liveTour--gallery .liveTour--container {
    max-width: 860px;
  }
  .updateAccount__list li {
    display: flex;
    flex: 0 0 50%;
    width: 50%;
    padding: 0px;
  }
  .updateAccount__list li:nth-of-type(2) {
    border-right: none;
  }
  .presentation--navbar .navbar-nav .app__button-blue {
    width: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .presentation--navbar .navbar-nav .app__button-blue .itinerary__icon {
    margin-left: 0;
  }
  .presentation--navbar .navbar-nav .app__button-blue .itinerary__text {
    display: none;
  }
  .presentation--container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .presentation--navbar .navbar-nav {
    flex-direction: row;
  }
  .suite--component {
    margin-right: 20px;
  }
  .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    width: 8px;
  }
  .presentation--control {
    width: 88px;
  }
  .presentation--menu .preMenu--main {
    padding-right: 4px;
    min-width: 88px;
  }
  .presentation--main-even .preContent--detail {
    padding: 0px 0px 0px 20px;
  }
  .presentation--main-odd .preContent--detail {
    padding: 0px 20px 0px 0px;
  }
  .presentation--content .detailComp__list {
    margin-top: 26px;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 30px;
    width: auto;
    margin-right: 16px;
  }
  .presentation--content .detailComp--box .heading__medium {
    font-size: 20px;
  }
  .presentation--content .preAmenities--col {
    flex-wrap: wrap;
  }
  .presentation--content .preAmenities--col .amenities--widget {
    width: calc(50% - 12px);
  }
  .presentation--content .prePdf--col {
    flex-wrap: wrap;
  }
  .presentation--content .prePdf--col .pdf__btn {
    width: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  /* .preVideo--canvas {
    width: calc(100% - 21%);
  }
  .presentation--content.presentation--content-video .preVideo--canvas {
    padding-top: calc(55.5% - 11%);
  } */
  .presentation--content.presentation--content-video .preVideo--canvas .static-video-player {
    border-radius: 14px;
  }
  .presentation--content-video .expand__btn {
    top: 12px;
    right: 12px;
  }
  /* .suite--block .suite--img {
    margin-bottom: 24px;
  } */
  .suite--block {
    margin-top: 60px;
  }
  .temp--about .tempAbout--col {
    min-height: 10px;
  }
  /* .temp--about {
    min-height: 10px;
    margin-top: 50px;
  } */
  .info--carousel-block .tempAbout--content {
    min-height: 640px;
  }

  .info--carousel-block:before {
    max-width: 100%;
    bottom: auto;
    bottom: initial;
    min-height: 700px;
    background-color: transparent;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    bottom: 12%;
  }
  .info--carousel-block .ic--overlay {
    max-width: 100%;
    top: 800px;
    min-height: 640px;
    background-color: #1c1f20;
  }
  .info--carousel-block .tempAbout--col-inner {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .tempHeader--canvas {
    flex-direction: column;
  }
  .template--header .tempHeader--col {
    width: 100%;
  }
  .template--header .temp--view {
    margin-left: auto;
  }
  .template--header .tempHeader--right {
    margin-top: 20px;
  }
  .temp--about-alt .row .col:first-child {
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  }
  .temp--options .btn {
    width: calc(100% - 16px);
  }
  .temp--options .outline__button {
    margin: 0px 8px 0px 0px;
  }
  .temp--options .admin__button {
    margin: 0px 0px 0px 8px;
  }
  .buildingsBlock--update .buildingsBtn--canvas {
    margin-top: 20px;
  }
  .buildingsBlock--update .bb-sf {
    justify-content: space-between;
  }
  .buildingsBlock--update .bb--inner {
    width: auto;
  }
  .app--modal .modal-dialog.modal-lg {
    max-width: 100%;
    margin: 8px;
  }
  .template--mobile .drawer--right {
    margin-top: 168px;
    min-height: calc(100vh - 168px);
  }

  /* Pricing landing page media query */
  .fluid--expand--payment,
  .navbar-expand-lg > .fluid--expand--payment {
    padding-left: 32px;
    padding-right: 32px;
  }
  .navbar__header__payment .navbar__list-n .nav-link.active {
    color: #f2e9d0;
  }
  .pricing__main__heading {
    font-size: 40px;
    line-height: 52px;
  }
  /* Pricing landing page media query */

  /* Marketing homepage media query */
  .marketing--home--top--section {
    padding-top: 100px;
  }
  /* Marketing homepage media query */
}
@media screen and (max-width: 767px) {
  .magnifier-tools {
    right: 10px;
  }
  .vt--container,
  .container--expand,
  .container--large,
  .fluid--expand,
  .navbar-expand-lg > .fluid--expand {
    padding-left: 20px;
    padding-right: 20px;
  }
  .schedule__button {
    margin-top: 40px;
  }
  .social__list {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .social__list li a {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gallery--box {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .schedule--content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .chat__app {
    bottom: 15px;
    right: 15px;
  }
  .dashboard--head,
  .listing--head {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard__heading,
  .heading--group {
    margin-bottom: 16px;
  }
  .editListing--head-btn {
    width: 100%;
  }
  .editListing--head-btn .outline__button {
    width: 100%;
    margin: 0px 5px 0px 0px;
  }
  .editListing--head-btn .admin__button {
    width: 100%;
    margin: 0px 0px 0px 5px;
  }
  .steps--content,
  .about--content {
    margin-top: 70px;
  }
  .empty--box {
    margin-top: 80px;
  }
  .f__list {
    justify-content: center;
  }
  .f__list li a {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .vp__box {
    right: -10px;
    top: 78px;
    width: 52px;
    height: 52px;
    min-width: 52px;
  }
  .vp__box:before {
    width: 36px;
    height: 36px;
  }
  .vp__secure {
    font-size: 18px;
    padding: 4px 20px;
    top: -58px;
    left: 50px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 18px;
    line-height: 18px;
  }
  .vp__logo {
    left: -10px;
    width: 154px;
    height: 48px;
  }
  .vp__logo img {
    width: 126px;
    height: 33px;
  }
  .tour--row {
    justify-content: flex-start;
  }
  .tour--col {
    min-width: 100%;
  }
  .floorplan--box .floorplanBox--img,
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 300px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 420px;
  }
  .tenant--video {
    margin-top: -210px;
    margin-bottom: 175px;
  }
  .vt--combine-right {
    padding-top: 40px;
  }
  .suite--box {
    justify-content: space-between;
    padding: 12px 15px;
  }
  .team--main .row .col-12:last-child .team--block {
    margin-bottom: 0;
  }
  .vt--options:after {
    right: auto;
    left: auto;
    top: auto;
    top: initial;
    bottom: -18px;
    width: 200px;
    background-size: 200px;
    height: 74px;
  }
  .liveTour--options {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ct--radio-btn {
    min-height: 130px;
  }
  .ct--radio-alt {
    min-height: 10px;
  }
  .ct--radio-btn .checkmark {
    margin-right: 14px;
  }
  .render--component .ct--radio-btn .checkmark {
    margin-right: 5px;
  }
  .component--edit-overlay {
    padding-left: 30px;
  }
  .component--edit .componentEdit--head {
    padding: 20px 24px 10px 24px;
  }
  .component--edit .componentEdit--scrollable {
    padding: 20px 24px 20px 24px;
  }
  .componentEdit--scrollable {
    overflow: visible;
    overflow: initial;
  }
  .search--bs {
    flex-direction: column;
  }
  .search--bs .search--bs-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .search--bs .search--bs-detail {
    flex-direction: column;
  }
  .search--bs .search--bs-inner {
    align-items: center;
    margin-bottom: 20px;
  }
  .search--bs .bs__next {
    margin-left: auto;
  }
  .search--bs-alt .search--bs-inner {
    width: 100%;
    flex-direction: column;
  }
  .search--bs-alt .bs__heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--box {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box,
  .video--iframe {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .search--group-lg .form-control {
    height: calc(2.8em + 0.75rem + 2px);
    font-size: 15px;
    line-height: 20px;
    padding: 10px 16px 10px 40px;
  }
  .search--group-lg .search__icon {
    font-size: 16px;
    left: 15px;
    top: 22px;
  }
  .search--group-lg .search__icon-cross {
    font-size: 12px;
    right: 15px;
    top: 23px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 300px;
  }
  .team--block-alt {
    flex-direction: column;
  }
  .team--block-alt .teamBlock--img {
    margin: 0px 0px 20px 0px;
  }
  .team--block-alt .teamBlock__user {
    margin-bottom: 6px;
  }
  .buildings--map,
  .subListing--video,
  .map--box {
    height: 320px;
  }
  .map--block,
  .sdl--combine {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--off {
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .viewBy {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .videos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .media--video-block {
    flex-direction: column;
  }
  .media--video-block .media--video {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .mediaContent--combine {
    margin-bottom: 10px;
  }
  .mediaContent--combine .mc--box {
    margin-bottom: 8px;
  }
  .timeBox--row .timeBox--col {
    flex-direction: column;
  }
  .started--imgs {
    max-width: 280px;
  }
  .started--canvas {
    padding-top: 320px;
  }
  .liveTour--page {
    height: auto;
  }
  .video--canvas-expand .video--box-expand {
    width: 520px;
    height: 293px;
    border-radius: 14px;
    border-radius: 20px;
  }
  .tourPulse--block .tourPulse--row {
    flex-direction: column;
  }
  .tourPulse--block .tourPulse--col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    border: none;
  }
  .tourPulse--block .tourPulse--col-detail {
    margin: 20px 0;
  }
  .looked--block .hl--top {
    flex-direction: column;
    align-items: flex-start;
  }
  .presentation--container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .presentation--content .preContent--box {
    display: none;
  }
  .presentation--content .preContent--detail {
    width: 100%;
    padding: 0;
  }
  .presentation--navbar .location--head {
    margin-right: 10px;
  }
  .presentation--navbar .location--head .liveTour__logo {
    width: 120px;
    height: auto;
    min-width: 10px;
    margin-right: 10px;
  }
  .presentation--navbar .location--head .location__destination {
    height: 30px;
    width: 100px;
    padding-left: 10px;
  }
  .suite--component {
    margin-right: 10px;
  }
  .presentation--menu {
    margin-left: 10px;
  }
  .presentation--navbar {
    min-height: 22px;
  }
  .presentation--content .expand__btn {
    display: none;
  }
  .presentation--content .building--widget {
    margin-bottom: 10px;
  }
  .presentation--content .preAmenities--col .amenities--widget {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .presentation--content .preAmenities--col {
    margin-left: 0;
    margin-right: 0;
  }
  .presentation--content .prePdf--col .pdf__btn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .preContent--floorplan {
    padding: 0;
    max-width: 100%;
  }
  .itinerary--back-canvas .app__button-blue {
    padding: 6px;
    min-width: 58px;
    font-size: 14px;
  }
  .presentation--content .detailComp__list {
    margin-top: 16px;
  }
  .presentation--content .minimize__pdf,
  .presentation--content.presentation--content-video .expand__btn {
    display: flex;
  }
  .preTour--content .tour--btn-canvas {
    bottom: 60px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    padding: 0px 0px 4px 0px;
    left: 10px;
    right: 10px;
  }
  .preTour--content .tour--btn-canvas .tour__btn:first-child {
    margin-left: 0;
  }
  .preTour--content .tour--btn-canvas .tour__btn:last-child {
    margin-right: 0;
  }
  .preTour--content .tour--btn-canvas::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .preTour--content .tour--btn-canvas::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
  }
  .presentation--content.presentation--content-video .expand__btn {
    width: 22px;
    height: 22px;
    font-size: 10px;
    border-radius: 5px;
  }
  .info--carousel-block .ic--overlay,
  .temp--about .tempAbout--col {
    padding: 50px 20px;
  }
  .template--head-three .template--banner {
    min-height: 100px;
  }
  .template--head-three .template--banner:after {
    bottom: auto;
    bottom: initial;
    left: 0;
    width: 100%;
    height: 220px;
  }
  .template--head-three .template--banner .tempBannerTh--row {
    padding-top: 94px;
    padding-bottom: 40px;
  }
  .tempContact--th .row .col {
    border: none;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
    margin: 0;
  }
  .tempContact--th .row .col:last-child {
    border-bottom: 0;
  }
  .tempListing--th {
    padding-top: 80px;
  }
  .tempListing--th,
  .tempSpecs--th,
  .temp--sustain-th {
    padding-bottom: 80px;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    padding-left: 20px;
  }
  .info--carousel-block .ic--overlay {
    top: 724px;
  }
  .template--head-three .template--banner {
    position: static;
  }
  .template--head-three .template--carousel {
    width: 100%;
  }
  .template--head-three .template--carousel .carousel-item {
    height: 500px;
  }
  .temp--sustain-th,
  .tempSpecs--th {
    padding-top: 70px;
  }
  .tempSpecs--block-alt {
    min-height: 192px;
    padding: 22px 10px 22px 16px;
  }
  .tempSpecs--block-alt .tempSpecs__icon {
    font-size: 24px;
  }
  .tempSpecsTh--row .col:nth-child(odd) .tempSpecs--block-alt {
    margin-left: 0;
  }
  #main-app .sidebar,
  #main-app .toggle__btn,
  #main-app .menu__toggle {
    display: none;
  }
  .dashboard--opt {
    min-height: 1px;
  }
  .listingTour--nav {
    margin-left: auto;
  }
  #main-app {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  .tourListing--canvas-alt .tourListing--scroll {
    max-height: 580px;
  }

  /* Pricing landing page media query */
  .fluid--expand--payment,
  .navbar-expand-lg > .fluid--expand--payment {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pricing__main__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 32px;
  }
  .pricing__choose__hd {
    font-size: 24px;
    line-height: 32px;
  }
  .pricing--choose--plan--form {
    margin-top: 24px;
  }
  /* .pricing--choose--plan--inner {
    max-width: 450px;
  } */
  .pricing--inquiry--hd--container {
    margin-top: 0px;
  }
  .footer--payment--pricing {
    padding-top: 130px;
  }
  /* Pricing landing page media query */

  /* Marketing homepage */
  .marketing--home--top--section {
    padding-top: 130px;
  }
  .marketing--home--top--video {
    margin-right: 0%;
  }
  .footer--marketing--home {
    padding-top: 200px;
  }
  .marketSt__radio {
    padding: 16px;
    min-height: 10px;
  }
  .marketSt__pickUse {
    flex-direction: column;
  }
  .marketSt__pickUse .marketSt__pick-img {
    margin-bottom: 20px;
  }
  .marketing--presentation--slider--btn {
    flex-direction: column;
  }
  .marketing--presentation--tog--div {
    margin-top: 20px;
    margin-left: auto;
  }
  .comment--block {
    height: auto;
    max-height: 505px;
  }
  /* Marketing homepage */
}
@media screen and (max-width: 575px) {
  .navbar--btns {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar--btns .app__button {
    width: 100%;
    margin-top: 2px;
  }
  .office--component {
    margin-top: 50px;
  }
  .detail--text {
    margin-top: 36px;
  }
  .gradient--component {
    padding-top: 120px;
  }
  .heading__landing {
    font-size: 32px;
  }
  .heading--group .form-control,
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 24px;
    line-height: 34px;
  }
  .heading__medium {
    font-size: 22px;
    line-height: inherit;
  }
  .lists__heading {
    font-size: 24px;
  }
  .lists__subHeading {
    font-size: 20px;
  }
  .lists-info li {
    font-size: 18px;
  }
  .form--option {
    flex-direction: column;
    align-items: flex-start;
  }
  .form--opt-r {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
  }
  .form__link {
    margin-left: auto;
  }
  .signin--canvas {
    max-width: 100%;
  }
  .signin--btn-canvas,
  .btn-top {
    margin-top: 40px;
  }
  .btn-top {
    width: 100%;
  }
  .forms--btn-canvas .app__button,
  .forms--btn-canvas .admin__button,
  .adminSettings-btn .outline__button {
    width: 100%;
  }
  .form--content {
    padding-top: 40px;
    padding-bottom: 48px;
  }
  .signup--inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .stepBtn-canvas-1 {
    padding-top: 0px;
    margin-top: 24px;
  }
  .stepBtn-canvas .admin__button,
  .al--btn-component .admin__button,
  .annex--card-btn,
  .modal--btn-canvas .admin__button {
    width: 100%;
  }
  .al--btn-component {
    margin-top: 10px;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .al__heading-medium {
    font-size: 20px;
  }
  .annex--card {
    flex-direction: column;
    align-items: flex-start;
  }
  .annex--card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .listing--video,
  .virtualTour--video {
    height: 320px;
  }
  .listing--video video,
  .virtualTour--video video {
    height: 320px;
  }
  .listing__button {
    margin-top: 10px;
  }
  .bl--head-btn {
    width: 100%;
  }
  .b--sf-heading {
    font-size: 32px;
    line-height: 30px;
  }
  .al--block .al--block-detail {
    padding: 15px;
    margin-top: -1px;
  }
  .heading--group .heading__edit {
    margin-top: 6px;
  }
  .virtual--carousel .carousel-item {
    height: 400px;
  }
  .notfound__heading {
    font-size: 136px;
    line-height: 124px;
  }
  .nav__signin-button {
    margin-bottom: 10px;
  }
  .empty--box .admin__button {
    width: 100%;
  }
  .office-modal .modal-header,
  .office-modal .modal-body {
    padding-left: 28px;
    padding-right: 28px;
  }
  .personalize--block {
    padding: 32px 20px 20px 20px;
  }
  .landing__header-n .schedule__btn-outline {
    width: 100%;
  }
  .landing__header-n .schedule__btn-outline:hover,
  .landing__header-n .schedule__btn-outline:focus,
  .landing__header-n .schedule__btn-outline:active {
    border-color: #fff;
  }
  .floorplan--box .floorplanBox--img,
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 250px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 320px;
  }
  .footer-n {
    padding-top: 240px;
  }
  .vp__secure {
    font-size: 10px;
    padding: 3px 12px;
    top: -42px;
    left: 37px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 10px;
    line-height: 12px;
  }
  .personalize--block-about {
    margin-bottom: 112px;
  }
  .tenant--video {
    margin-top: -160px;
    margin-bottom: 112px;
  }
  .vp__box {
    width: 30px;
    height: 30px;
    min-width: 30px;
    top: 54px;
    right: -5px;
  }
  .vp__box:before {
    width: 20px;
    height: 20px;
  }
  .vp__logo {
    width: 106px;
    height: 36px;
    top: 16px;
  }
  .vp__logo img {
    width: 78px;
    height: 22px;
  }
  .vt--btns,
  .group-input {
    margin-bottom: 40px;
  }
  .group-input {
    flex-direction: column;
  }
  .group-input .outline__button {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
  .vt--combine .dashboard__heading {
    padding-bottom: 20px;
  }
  .recording__heading {
    font-size: 24px;
  }
  .recording__timer {
    font-size: 100px;
    line-height: 100px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(odd) {
    justify-content: flex-start;
    padding-right: 10px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(even) {
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 0;
  }
  .voiceRecord--box {
    width: 50%;
  }
  .record__voice {
    width: 42px;
    height: 42px;
    min-width: 42px;
  }
  .modal--video-set {
    height: 250px !important;
  }
  .mv__cross {
    right: 4px;
  }
  .c--post-canvas .admin__button,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .liveTour--top-custom {
    height: auto;
  }
  .users--content .row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .users--row-right {
    border-bottom: 1px solid rgba(213, 217, 224, 0.3);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .liveTour--options {
    margin: 0;
  }
  .liveTour--options li a {
    margin-bottom: 16px;
  }
  .tour--btn-canvas {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .tourMobile--block {
    padding: 24px 0;
  }
  .users--content-tenant .users--row-left {
    padding-top: 0px;
  }
  .notfound__img {
    width: 268px;
    height: 189px;
    margin-bottom: 40px;
  }
  .available--listing-map {
    height: 250px;
    margin-top: 20px;
  }
  .search__block .ls__search-icon {
    display: none;
  }
  .listing--group .form-control {
    font-size: 13px;
    padding: 6px 56px 6px 10px;
    height: calc(1.7em + 0.75rem + 2px);
    min-height: 40px;
  }
  .listing--group .enter__btn {
    font-size: 10px;
    padding: 3px 6px;
    min-width: 44px;
    min-height: 20px;
    top: 10px;
  }
  .cancel__link {
    font-size: 12px;
  }
  .liveTour--listing .container-fluid {
    justify-content: start;
  }
  .ls--sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .ls__label {
    margin: 10px 0px 12px 0px;
  }
  .ls__list {
    width: 100%;
  }
  .ls--listings::-webkit-scrollbar {
    height: 14px;
    width: 10px;
  }
  .ls__search-icon {
    margin-right: 10px;
    font-size: 24px;
  }
  .sec--vertical .sec--vertical-middle .heading__medium {
    margin-bottom: 18px;
  }
  .dashbaord--opt {
    flex-direction: column;
  }
  .dashbaord--opt .admin__button {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .white--rounded-box,
  .dashboard--card {
    padding-left: 16px;
    padding-right: 16px;
  }
  .upcomingTour--block .upcomingTour--main {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upcomingTour--block .upcomingTour--left {
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    border-right: 0;
  }
  .upcomingTour--block .upcomingTour--right {
    border-left: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 8px;
  }
  .dashboard--card .card-header {
    padding: 5px 0px;
  }
  .dashboard--card .card-header .btn-link {
    font-size: 20px;
  }
  .listing--card .card-header .btn-link {
    font-size: 14px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding: 0;
  }
  .ct__btn-canvas {
    padding-top: 0px;
  }
  .tour__btn-canvas .btn,
  .btns-mobile-expand .btn {
    width: 100%;
  }
  .tourListing--block {
    flex-direction: column;
    padding: 16px;
  }
  .tourListing--block .tourListing--row {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    margin-right: 0;
    margin-right: initial;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    min-height: 60px;
  }
  .subDashboard--bar .heading__medium {
    width: 100%;
  }
  .tourEdit--top {
    height: 250px;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 58px;
    margin-bottom: 10px;
  }
  .pdf__btn-liveTour:not([href]),
  .pdf__btn-liveTour {
    min-height: 72px;
  }
  .liveTour--gallery-img.upload--pdf {
    height: 72px;
  }
  .upload--gallery-img {
    font-size: 9px;
  }
  .upload--gallery-img i {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .tourEdit__btn-canvas .btn {
    width: 100%;
  }
  .component--edit .form--group-alt .form-control {
    font-size: 12px;
  }
  .component--edit .search--group-alt .form-control {
    padding-left: 38px;
  }
  .component--edit .search--group-alt .search__icon {
    font-size: 14px;
    top: 18px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 180px;
  }
  .dashboard__heading-alt {
    font-size: 26px;
  }
  .subdomain__footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--box-lg {
    width: 100%;
    min-width: 10px;
  }
  .listingModal--request {
    min-height: 350px;
  }
  .tour--btn-nowrap {
    flex-wrap: nowrap;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .btn-sm-100 .btn,
  .lsBlock--btns .link__btn {
    width: 100%;
  }
  .floorplan--box {
    padding: 15px;
  }
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col,
  .tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 50%;
  }
  .liveTour--gallery-img.upload--pdf {
    font-size: 14px;
  }
  .liveTour--gallery-img.upload--pdf i {
    font-size: 16px;
  }
  .tourEdit--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourEdit--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
    width: 33.33%;
  }
  .tourEdit--top .liveTour--gallery-content {
    margin: -5px -10px;
  }
  .tourEdit--top .liveTour--pdf-content {
    margin-top: -5px;
  }
  .video--canvas-expand .video--box-expand {
    width: 420px;
    height: 236px;
  }
  .presentation--page {
    padding: 0;
  }
  .presentation--navbar {
    margin-top: 24px;
  }
  .presentation--welcome {
    padding: 48px 24px;
    margin-top: 0;
  }
  .preWelcome--head {
    margin-bottom: 16px;
  }
  .preWelcome--head .preWelcome__icon {
    font-size: 24px;
    margin-right: 10px;
  }
  .preWelcome--head .heading__lg-dark {
    font-size: 22px;
    font-weight: normal;
  }
  .preWelcome--main .para__large-gray {
    font-size: 16px;
    line-height: 24px;
  }
  .preWelcome--main .forms--group-alt {
    margin-bottom: 16px;
  }
  .preWelcome--main .admin__button {
    margin-top: 24px;
    width: 100%;
  }
  .carouselPre--li {
    height: calc(100vh - 184px);
    padding-bottom: 24px;
  }
  .presentation--content {
    flex-grow: 1;
    padding: 20px;
    width: 100%;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .tour-itinerary {
    margin-bottom: 20px;
    height: auto;
  }
  .presentation--content .presentation--main {
    flex-direction: column;
    overflow-y: auto;
  }
  .presentation--content .preContent--detail {
    overflow-y: auto;
  }
  .preAmenities--carousel,
  .preSpecs--carousel {
    align-items: initial !important;
    overflow-y: auto !important;
  }
  .preVideo--col {
    height: auto;
  }
  .preGallery--carousel {
    flex-grow: initial;
    overflow-y: visible;
    overflow-y: initial;
    background-color: rgba(255, 255, 255, 0.7);
  }
  /* .presentation--content:before,
  .presentation--content:after {
    content: '';
    width: 8px;
    flex-shrink: 0;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.25);
    display: inline-block;
    position: absolute;
    top: 24px;
    bottom: 30px;
  }
  .presentation--content:before {
    left: -20px;
    border-radius: 0px 20px 20px 0px;
  }
  .presentation--content:after {
    right: -20px;
    border-radius: 20px 0px 0px 20px;
  } */
  .preControl--outer {
    top: 22%;
    bottom: 15%;
  }
  .presentation--navbar .presentation--menu {
    display: none;
  }
  .presentation--navbar .suite--component {
    display: none;
  }
  .suite--component-mobile {
    margin: 10px 0px 20px 0px;
    justify-content: center;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 20px;
    margin-right: 12px;
  }
  .presentation--content .detailComp--box .text__md-gray {
    font-size: 12px;
    line-height: 14px;
  }
  .presentation--content .detailComp__list li:last-child .detailComp--box .heading__medium,
  .presentation--content .detailComp--box .heading__medium {
    font-size: 16px;
    line-height: normal;
    line-height: initial;
  }
  .presentation--content .detailComp--box {
    margin-bottom: 20px;
  }
  .presentation--content .preContent--head {
    padding-right: 0;
  }
  .presentation--content .preContent--head .heading__lg-dark {
    font-size: 22px;
  }
  .presentation--content .location--box .locationBox__heading {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .presentation--content .location--box .locationBox__img {
    margin-right: 12px;
  }
  .presentation--content .locationBox--badge .location__badge-link {
    padding: 4px 10px;
    font-size: 13px;
  }
  .presentation--off.preOff--space {
    /* padding-bottom: 82px; */
    min-height: 100%;
    max-height: 100%;
  }
  .presentation--off .presentation--container {
    justify-content: flex-start;
  }
  .presentation--content.presentation--content-video {
    margin-top: auto;
    margin-bottom: auto;
  }
  .presentation--control .preControl--box {
    color: #6a7074;
  }
  .presentation--control .preControl--canvas {
    position: fixed;
    bottom: 9px;
    z-index: 5;
    min-width: 88px;
    max-width: 120px;
  }
  .presentation--control-prev .preControl--canvas {
    left: 10%;
  }
  .presentation--control-next .preControl--canvas {
    right: 12%;
  }
  .presentation--control {
    padding: 0;
    width: auto;
  }
  .preControl--box .preControlBox__icon {
    margin-bottom: 8px;
    line-height: 14px;
    font-size: 14px;
  }
  .preControl--box .preControlBox__text {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 12px;
  }
  .preGallery--col .preGallery--box {
    height: 132px;
    width: calc(50% - 12px);
    max-width: 50%;
  }
  .preFooter--card .card-header .btn-link {
    margin-top: 8px;
  }
  .presentation--content .specs__list li {
    font-size: 16px;
    line-height: 18px;
  }
  .presentation--navbar .location--head .liveTour__logo {
    width: 90px;
  }
  .presentation--navbar .location--head .location__destination {
    height: 20px;
    width: 78px;
  }
  .tour--fixed .preTour--content .preContent--tour,
  .floorplan--fixed .preContent--floorplan,
  .location--fixed .preContent--location {
    /* position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    height: 100%;
    z-index: 5;
    border-radius: 0;
    background-color: #e5e5e5;
  }
  .preContent--floorplan {
    background-color: #fff;
  }
  .preContent--floorplan .preFloorplan--inner {
    max-width: 70%;
    max-height: 68%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .preContent--floorplan .preFloorplan--inner img {
    object-fit: contain;
    border-radius: 10px;
  }
  .preVideo--canvas video {
    border-radius: 14px;
  }
  .video--content .videoContent--place {
    height: 180px;
    border-radius: 12px;
  }
  .videoContent--options .videoContent__play {
    font-size: 14px;
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }
  .videoContent--options .videoContent__clock .videoContent--time {
    font-size: 13px;
  }
  .videoContent--options .videoContent__speed-btn {
    font-size: 14px;
    min-width: 38px;
    min-height: 30px;
    padding: 2px;
  }
  .videoContent--options .videoContent__screen {
    margin-right: 14px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text,
  .videoContent--options .videoContent__screen .videoContent__screen-size {
    font-size: 14px;
  }
  .comment--block .commentBlock--top {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .presentation--content.presentation--content-video {
    padding: 10px;
  } */
  .presentation--container .suite--component .heading__md-dark {
    text-align: center;
    margin: 0px 4px 4px 0px !important;
  }
  .presentation--container .suite--component .suite__badge {
    margin-bottom: 4px;
  }
  /* .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    content: none;
  } */
  .presentation--content .prePdf--col .pdf__btn,
  .presentation--content .building--widget {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .presentation--expanded {
    padding-top: 0;
  }
  .presentation--expanded .presentation--navbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
  }
  .presentation--expanded .presentation--navbar .location--head {
    display: none;
  }
  .presentation--expanded .presentation--navbar .navbar-nav .app__button-blue {
    position: relative;
    z-index: 2;
  }
  .presentation--expanded .carousel--presentation .carouselPre--ul .carouselPre--li {
    height: calc(100vh - 84px);
  }
  .presentation--expanded .preControl--outer.preControl--outer-prev:before,
  .presentation--expanded .preControl--outer.preControl--outer-next:after {
    content: none;
  }
  .preVideo--col .preGallery--box {
    height: auto;
  }
  .preContent--off-pdf .pdf--options {
    right: 15px;
    bottom: 60px;
  }
  .preContent--off-pdf .pdf--options .pdfOtion__btn {
    width: 30px;
    height: 30px;
  }
  .pdf--fixed .presentation--content .preContent--off-pdf {
    padding: 10px 10px 0px 10px;
    position: fixed;
    bottom: 84px;
  }
  .preOff--pdf .presentation--content {
    position: static;
  }
  .presentation--content .minimize__pdf {
    right: 15px;
    top: 15px;
  }
  .dashTable--listing-alt {
    margin-left: 0;
  }
  .dashboard--table-th {
    min-width: 700px;
  }
  .dashboard--table-th tbody tr td:first-child {
    min-width: 280px;
    max-width: 280px;
  }
  .combine--dropdown .col:last-child .listing--dropdown .dropdown-toggle {
    z-index: 3;
  }
  .combine--dropdown .col:last-child .listing--dropdown .dropdown-menu {
    z-index: 2;
  }
  .sustain--box {
    padding: 20px;
  }
  .specs--carousel .carousel-inner {
    min-height: 496px;
  }

  .suite--block .suite--img {
    display: flex;
    flex-direction: flex-end;
    float: left;
    size: 2rem;
    margin-bottom: 0px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  .tempBanner--btn-canvas .btn {
    width: 100%;
  }
  .tempBanner--btn-canvas {
    padding-top: 100px;
  }
  .temp--about .tempAbout--img-box,
  .template--head-two .tempBanner--img {
    height: 500px;
  }
  .temp--about .tempAbout--col {
    min-height: 10px;
  }
  .dashbaord--opt-head .outline__button-blue {
    max-width: 100%;
  }
  .admin__button:not([href]),
  .admin__button-outline:not([href]),
  .admin__button,
  .admin__button-outline,
  .outline__button:not([href]),
  .outline__button {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashbaord--opt-head .admin__button-sm .icon-eye-alt,
  .outline__button-blue .icon-user-question,
  .outline__button-blue .icon-edit-custom,
  .outline__button-blue .icon-globe {
    font-size: 18px;
  }
  .template--radio .template--radio-frame {
    height: 280px;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    bottom: 15%;
  }
  .info--carousel-block .tempAbout--img-box {
    height: 300px;
  }
  .info--carousel-block .tempAbout--content {
    min-height: 640px;
  }
  .temp--options,
  .template--header .tempHeader--right {
    flex-direction: column;
  }
  .temp--options .btn {
    width: 100%;
  }
  .temp--options .admin__button {
    margin: 0;
  }
  .temp--options .outline__button {
    margin: 0px 0px 16px 0px;
  }
  .template--header .cross__link {
    font-size: 20px;
    margin-left: auto;
  }
  .template--header .template__name {
    margin-right: 16px;
  }
  .temp--view .tempView__btn {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .info--carousel-block .ic--overlay {
    top: 400px;
  }
  .template--page .heading__lg-white {
    font-size: 24px;
  }
  .template--page .heading__tem-white {
    font-size: 16px;
  }
  .template--page .add--box .addBox__text {
    font-size: 14px;
  }
  .template--page .heading__lg-dark,
  .template--page .heading__lg-white {
    font-size: 22px;
  }
  .temp--about .heading__tem-white {
    font-size: 18px;
  }
  .temp--combine .link__blue-lg,
  .temp--about .para__large-white,
  .temp--about .para__md-white {
    font-size: 14px;
  }
  .suite--block .heading__th-dark {
    font-size: 20px;
  }
  .temp--specification .tempAbout--img-box {
    height: 300px;
  }
  .temp--specification .tempAbout--img {
    padding-top: 160px;
  }
  .specs--carousel .carousel-indicators {
    margin-bottom: 40px;
  }
  .temp--combine {
    padding-bottom: 160px;
  }
  .tempBanner--carousel {
    margin-top: 80px;
  }
  .template--head-two .tempBanner--btn-canvas {
    padding-top: 32px;
  }
  .temp--map {
    height: 300px;
    margin-top: 110px;
    margin-bottom: 30px;
  }
  .tempListing--row {
    margin-bottom: 28px;
  }
  .tempListing--block {
    padding: 16px;
    min-height: 10px;
    margin-bottom: 32px;
  }
  .tempListing--block .tempListing--img {
    height: 160px;
  }
  .tempListing--block .tempListing--detail {
    margin-top: 20px;
  }
  .tempListing--block .add--box {
    margin-top: 8px;
  }
  .info--item {
    margin-bottom: 18px;
  }
  .info--item .infoItem--col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .info--item .infoItem__para {
    font-size: 18px;
    line-height: 20px;
  }
  .info--item .infoItem--r .infoItem__text {
    font-size: 12px;
    line-height: 14px;
  }
  .tempSpecs--block .heading__md-dark {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .tempContact--box {
    height: 350px;
    margin-bottom: 24px;
  }
  .temp--contacts .row.mt-80 {
    margin-top: 60px;
  }
  .tempContact--box .heading__tem-white {
    font-size: 24px;
  }
  .temp--sustain-alt .sustain--box .heading__md-dark {
    font-size: 18px;
  }
  .sustain--row .col:last-child .sustain--box {
    margin-bottom: 0;
  }
  .template--head .add--box {
    margin-top: 20px;
  }
  .tempLink--canvas {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .tempLink--canvas .link__dark,
  .tempLink--canvas .link__blue-lg {
    margin: 30px auto 0px 0px;
  }
  .temp--about-alt .tempAbout--col {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .tempConTh--block {
    max-width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tempConTh--block .tempConTh--detail .heading__md-gray {
    margin-bottom: 16px;
  }
  .tempConTh--block .tempConTh--detail .heading__md-gray span {
    margin-bottom: 8px;
  }
  .tempListingTh--block {
    height: 200px;
    margin-bottom: 24px;
  }
  .tempListingTh--block .tempListingTh--overlay {
    padding: 16px;
  }
  .tempListingTh--block .tempListingTh--overlay .heading__tem-white {
    font-size: 24px;
  }
  .template--page .heading__tem-blue {
    font-size: 14px;
  }
  .app--modal .modal-content {
    padding: 24px;
  }
  .app--modal .modal-title {
    font-size: 24px;
    line-height: 34px;
  }
  .app--modal .modal__cross {
    font-size: 20px;
    margin-top: 6px;
  }
  .template--mobile .drawer--right {
    margin-top: 224px;
    min-height: calc(100vh - 224px);
  }
  .listingTour--nav {
    width: 100%;
  }
  .listingTour--nav .nav-item {
    flex: 1 1;
  }
  .listingTour--nav .nav-link {
    width: 100%;
  }
  .tempConTh--block .tempConTh--img img {
    object-fit: contain;
  }
  .tourEdit--top-box .tourEdit--top-start {
    flex-direction: column;
    align-items: flex-start;
  }
  .tourEdit--top-box .sd--main {
    margin-top: 20px;
  }
  .createTour--card .card-header .btn-link {
    font-size: 20px;
  }

  /* Pricing landing page media query */
  .bottom--header--component {
    padding-top: 120px;
  }
  .pricing__main__heading {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 25px;
  }
  .pricing__btm__hdr__para {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing__choose__hd {
    font-size: 20px;
    line-height: 30px;
  }
  .pricing--choose--card .pricing--card--plan--title {
    font-size: 17px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing--card--plan--building {
    font-size: 14px;
    line-height: 18px;
  }
  .pricing--choose--card .card-subtitle {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 18px;
  }
  .pricing--choose--card .pricing--card--listGrp--container {
    height: 250px;
    padding: 0px 4px 0px 14px;
    margin-bottom: 12px;
  }
  .pricing--choose--card .list-group {
    margin-bottom: 36px;
  }
  .pricing--choose--card .list-group:last-child {
    margin-bottom: 12px;
  }
  .pricing--choose--card .list-group-item {
    margin-bottom: 12px;
  }
  .pricing--choose--card .pricing--card--tick {
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    padding: 0px 0px 2px 2px;
  }
  .pricing--choose--card .pricing--card--li--text {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing--card--total--container {
    margin: 18px 0px 24px 0px;
  }
  .pricing--choose--card .pricing--card--total {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing__card__btn,
  .pricing--choose--card .pricing__card__btn__link {
    min-height: 42px;
    font-size: 14px;
  }
  .footer--payment--pricing {
    padding-top: 100px;
  }
  .pricing--inqury--form .forms--group {
    margin-bottom: 16px;
  }
  .pricing--inqury--form .forms--group:nth-child(1) {
    padding-bottom: 18px;
  }
  .pricing--inqury--form .forms--group:nth-child(2) {
    padding-bottom: 9px;
  }
  .pricing--inqury--form .forms--group:nth-child(3) {
    padding-bottom: 12px;
  }
  .pricing--inqury--form .forms--group:nth-child(4) {
    padding-bottom: 22px;
  }
  .pricing--inqury--form .forms--group:nth-child(5) {
    padding-bottom: 0px;
  }
  .pricing--inqury--form .forms--group:last-child {
    margin-bottom: 0px;
  }
  .pricing--inqury--form .form-label {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--inqury--form .form-control {
    min-height: 40px;
  }
  .pricing--inqury--form .forms--group .pricing--inqury--sm--input {
    font-size: 28px;
    line-height: 36px;
    min-height: 60px;
    padding: 8px 25px;
  }
  .pricing--inqury--form .pricing--default--input--text {
    font-size: 20px;
    line-height: 26px;
    top: 42px;
    left: 10px;
  }
  .pricing--inqury--form .pricing--inqury--currency--container .pricing--inqury--currency--name {
    min-width: 28px;
    min-height: 24px;
    font-size: 14px;
    line-height: 20px;
    padding: 3px;
  }
  .pricing--inquiry--form--btn {
    min-height: 42px;
  }
  .button__xs__full {
    width: 100%;
  }
  /* Pricing landing page media query */

  /* Payment signup page media query*/
  .payment--signup--spacing {
    padding: 20px;
  }
  /* Payment signup page media query*/

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--main--section--spacing {
    padding-top: 120px;
  }
  .payment--method--container .nav {
    padding: 4px 8px;
  }
  .payment--method--container .nav-pills .nav-link {
    font-size: 14px;
    line-height: 18px;
    min-width: 130px;
    min-height: 32px;
    padding: 6px 8px;
  }
  .credit--card--payment--top {
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .credit--card--payment--container .card--payment--hd--container .heading__medium {
    font-size: 18px;
  }
  .crdCard--payment--top--icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .credit--card--payment--form .forms--group--col {
    margin-bottom: 14px;
  }
  .credit--card--payment--form .forms--group .form-control {
    min-height: 40px;
    font-size: 14px;
  }
  .credit--card--payment--form .select--group .select__caret-down {
    top: 15px;
    font-size: 12px;
  }
  .card--payment--btn--container {
    padding-top: 26px;
  }
  .card--payment--btn--container .payment__completed__button:not([href]),
  .card--payment--btn--container .payment__completed__button {
    min-height: 42px;
  }
  .card--payment--btn--container .btn {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  .card--payment--btn--container .btn:last-child {
    margin-bottom: 0px;
  }
  .payment--billing--card {
    padding: 28px 24px;
  }
  .payment--billing--card .payment__billing__card__listTxt {
    font-size: 14px;
    line-height: 20px;
  }
  .payment--billing--card .payment--billing--card--subTotal {
    margin: 40px 0px 28px 0px;
  }
  .payment--billing--card .payment--billing--card--subTotal .payment__bill__subTotal__txt {
    font-size: 20px;
    line-height: 26px;
  }
  .payment--billing--card .payment--billing--card--total {
    margin-top: 34px;
  }
  .payment--billing--card .payment--billing--card--total .payment__bill__totalTxt {
    font-size: 16px;
  }
  .payment__billing__card__lgTxt {
    font-size: 24px;
    line-height: 30px;
  }
  /* credit card payment page media query*/
  /* bank payment page */
  .bank--payment--container .credit--card--payment--top {
    margin-bottom: 26px;
  }
  .bank--payment--container .bank--payment--sub--top .bank__payment__top__para {
    font-size: 14px;
    line-height: 20px;
  }
  /* bank payment page */
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .mktHome--top--video--container {
    max-width: 280px;
    min-width: 280px;
    min-height: 145px;
    max-height: 145px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 16px 16px 16px 0px;
    -webkit-border-radius: 16px 16px 16px 0px;
    -moz-border-radius: 16px 16px 16px 0px;
    -ms-border-radius: 16px 16px 16px 0px;
    -o-border-radius: 16px 16px 16px 0px;
    display: flex;
    justify-content: space-between;
  }
  .simple__paragraph {
    font-size: 14px;
    line-height: 22px;
  }
  .mktHome--top--video--btn .btn {
    min-height: 40px;
    min-width: 40px;
    font-size: 16px;
  }
  .marketing--home--personalize--component .personalize--block {
    padding: 20px;
  }
  .marketing--home--personalize--component .personalize--block .heading__component {
    margin-bottom: 32px;
  }
  .marketing--home--personalize--component .personalize--block .forms--group {
    margin-bottom: 20px;
  }
  .footer--marketing--home {
    padding-top: 180px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  .marketing--home--opinion--component .op--info {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .marketing--home--opinion--component .opinion__para {
    font-size: 14px;
    line-height: 22px;
  }
  .marketing--home--opinion--component .op--name {
    font-size: 16px;
    line-height: 24px;
  }
  .marketing--presentation--btn--container {
    flex-direction: column;
    width: 100%;
  }
  .marketing--presentation--btn--container .schedule__btn,
  .marketing--presentation--btn--container .schedule__btn-outline {
    font-size: 14px;
    min-height: 40px;
  }
  .marketing--presentation--btn--container .btn {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .marketing--presentation--btn--container .btn:last-child {
    margin-bottom: 0px;
  }
  .marketing--get--started--slider .marketing--presentation--slider--btn {
    width: 100%;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--img {
    max-width: 65px;
    min-width: 65px;
    max-height: 100px;
    min-height: 100px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt {
    margin-left: 16px;
    margin-top: 10px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--hd {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0px;
    color: #ffffff;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt ul {
    margin: 18px 0px 0px 6px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt ul li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    margin-left: 12px;
  }
  .mktSlider--video--link .mktHome--top--video--container {
    max-width: 180px;
    min-width: 180px;
    min-height: 80px;
    max-height: 80px;
  }
  .mktSlider--video--link .mktHome--top--video--text .para__xs {
    margin-bottom: 4px;
  }
  .mktSlider--video--link .mktHome--top--video--text .para__lg {
    font-size: 14px;
  }
  .mktSlider--video--link .mktHome--top--video--btn .btn {
    min-height: 30px;
    min-width: 30px;
    font-size: 13px;
    padding: 1px 1px 1px 2px;
  }
  .marketing--presentation--carousal--canvas .mktSlider--track--container {
    bottom: -36px;
  }
  .mktSlider--tour--link--position {
    top: -36px;
    right: -9px;
  }
  .mktSlider--tour--link--box {
    max-width: 220px;
    min-width: 220px;
    max-height: 80px;
    min-height: 80px;
  }
  .mktSlider--tour--link--btn {
    font-size: 14px;
    line-height: 20px;
    min-width: 168px;
    min-height: 40px;
    padding: 4px 8px;
  }
  .mktSlider--tour--link--btn .mktTour--link--btn--icon {
    font-size: 14px;
    margin-left: 8px;
  }
  .marketing--home--opinion--component .opinion--block .nav-item {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    margin-right: 12px;
    margin-bottom: 18px;
  }
  .marketing--home--opinion--component .opinion--block .nav-link {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    padding: 10px;
  }
  .marketing--home--opinion--component .opinion--block .nav--link--img--container {
    max-width: 45px;
    min-width: 45px;
    max-height: 45px;
    min-height: 45px;
  }
  /* Marketing homepage */

  .component--edit-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 0px;
  }
  .component--edit-overlay .component--edit {
    max-height: 86vh;
    border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -ms-border-radius: 20px 20px 0px 0px;
    -o-border-radius: 20px 20px 0px 0px;
  }
  .component--edit-overlay .component--edit .componentEdit--scrollable {
    padding-bottom: 0px;
  }
  .component--edit-overlay .component--edit .btn-sm-100 .admin__button {
    margin-bottom: 20px;
  }
  .marketing--presentation--btn--container .btn:first-child {
    margin-bottom: 20px;
  }
  .video--profile-img-canvas {
    left: -12px;
  }
  .video--profile-img {
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    margin-bottom: 7px;
  }
  .market--home-pre-last .marketing--home--slider .marketing--home--slider--item .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .marketing--slider--top--txt {
    width: 100%;
  }
  .navbar__header__payment {
    padding-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .key--details .row .col-6,
  .listings .row .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gallery--box {
    height: auto;
  }
  .listing--schedule-col {
    flex-direction: column;
  }
  .listing--schedule-col .select--group {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }
  .hours__change {
    margin: 6px 0px 16px 0px;
  }
  .week--time {
    padding-right: 0;
  }
  .hours--time {
    width: 100%;
  }
  .editListing--head-btn {
    flex-direction: column;
  }
  .editListing--head-btn .sm__button {
    margin: 0px 0px 20px 0px;
  }
  .virtual--carousel .carousel-item {
    height: 300px;
  }
  .hours__del {
    margin-top: 0;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 180px;
  }
  .media--video-block .media--video,
  .buildings--map,
  .map--box,
  .subListing--video,
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 220px;
  }
  .tenant--video {
    margin-top: -110px;
  }
  .vp__secure {
    top: -34px;
    left: 32px;
  }
  .form-label {
    font-size: 11px;
  }
  .modal--video-set {
    height: 200px !important;
  }
  .drawer--right {
    width: 90%;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: -100%;
  }
  .drawer--right-show {
    margin-right: 0;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col,
  .videos--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .video--box,
  .video--iframe {
    width: 100%;
    min-width: 10px;
  }
  .timeBox--row .timeBox--options {
    justify-content: space-between;
  }
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 100%;
  }
  .video--canvas-expand .video--box-expand {
    width: 320px;
    height: 180px;
  }
  .updateAccount__icon {
    flex: 0.5 1;
    justify-content: flex-end;
    padding-right: 5px;
  }
  .updateAccount__detail {
    flex: 1 1;
  }
  .presentation--control-prev .preControl--canvas {
    left: 8%;
  }
  .presentation--control-next .preControl--canvas {
    right: 8%;
  }
  .videoContent--options .videoContent__play {
    font-size: 12px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .videoContent--options .videoContent__clock .videoContent--time {
    font-size: 11px;
  }
  .videoContent--options .videoContent__speed-btn {
    font-size: 12px;
    min-width: 24px;
    min-height: 20px;
    line-height: 12px;
  }
  .videoContent--options .videoContent__screen {
    margin-right: 8px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text,
  .videoContent--options .videoContent__screen .videoContent__screen-size {
    font-size: 11px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text {
    margin-right: 6px;
  }

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--method--container .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query*/

  .video--profile-img-canvas {
    left: -7px;
  }
  .video--profile-img {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 375px) {
  .mobileNav--menu .mobileNav__icon {
    font-size: 14px;
  }
  .mobileNav--menu .mobileNav__text {
    font-size: 12px;
  }
  .video--profile-img-canvas {
    left: -8px;
  }
  .video--profile-img {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }
}
@media screen and (max-width: 350px) {
  .presentation--content .preContent--head .heading__lg-dark {
    font-size: 18px;
  }
  .presentation--content .specs__list li {
    font-size: 13px;
    line-height: 14px;
  }
  .presentation--content.presentation--content-video {
    margin-top: 20%;
  }
}

/*
  Media Queries
 */

.bb--alt-shimmer .al--block-img,
.alb--alt-shimmer .al--block-img {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.bb--alt-shimmer .al__address,
.alb--alt-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
}
.bb--alt-shimmer .al__address:hover,
.alb--alt-shimmer .al__address:hover {
  cursor: auto;
  cursor: initial;
}
.al--listing-box-sh .al--listing .al__icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.al--listing-box-sh .al--listing .al__text {
  width: 120px;
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-right: 0;
}
.alb--alt-shimmer .buildingBtn__shimmer {
  margin: 5px 0;
}
.badge--off {
  display: inline-flex;
  align-items: center;
}
.buildingBtn__shimmer {
  width: 90px;
  min-width: 90px;
  height: 24px;
  background-color: #d2d2d2;
  border-radius: 12px;
  display: inline-flex;
}
.alb--alt-shimmer .buildings__next {
  color: #f5f5f5;
}
.bb--alt-shimmer,
.alb--alt-shimmer,
.upcomingTour--shimmer {
  cursor: auto;
  cursor: initial;
}
.bb--alt-shimmer:hover,
.alb--alt-shimmer:hover,
.upcomingTour--shimmer:hover {
  border-color: #bcbab8;
}
.upcomingTour--shimmer .upComTour__icon {
  background-color: #ededed;
  border-radius: 4px;
  height: 24px;
  width: 28px;
  margin-bottom: 4px;
}
.upcomingTour--shimmer .upComTour__name {
  background-color: #ededed;
  border-radius: 4px;
  width: 126px;
  height: 18px;
  margin-top: 8px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-icon {
  background-color: #ededed;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-text {
  background-color: #ededed;
  border-radius: 4px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(1) .upComTour__list-text {
  width: 80%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(2) .upComTour__list-text {
  width: 60%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(3) .upComTour__list-text {
  width: 50%;
}
.upcomingTour--shimmer .btn__span {
  background-color: #ededed;
  border-radius: 10px;
  height: 32px;
  width: 100%;
  min-width: 200px;
  display: inline-flex;
}
.tl--shimmer .tourListing__img,
.tl--plus-shimmer .tourListing__img {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.tl--shimmer .tourListing--row .para__md-dark,
.tl--plus-shimmer .tourListing--row .para__md-dark,
.tl--alt-shimmer .tourListing--row .para__md-dark {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 80%;
}
.tl--plus-shimmer .plus__shimmer,
.tl--alt-shimmer .plus__shimmer {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #ededed;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tl--alt-shimmer .tourListing__img {
  background-color: #ededed;
}
.addBtn__shimmer {
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
  display: inline-flex;
  background-color: #ededed;
}
.breadcrumb--shimmer {
  margin-bottom: 10px;
  display: flex;
}
.breadcrumb--shimmer .breadcrumbShimmer__span {
  background-color: #ededed;
  border-radius: 4px;
  height: 16px;
  display: inline-flex;
  margin-right: 8px;
  min-width: 30px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:nth-of-type(1) {
  width: 40px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:nth-of-type(2) {
  width: 158px;
}
.dashboard--bar-shimmer {
  min-height: 105px;
}
.dbPreview--box .db--preview,
.dashboard--bar-shimmer .db--preview {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.dbPreview--box .db--preview-icon {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 70px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.dbPreview--box {
  display: flex;
  align-items: center;
}
.db__btns {
  width: 188px;
  height: 48px;
  background-color: #ededed;
  border-radius: 10px;
  display: inline-flex;
}
.tourEditTop--shimmer {
  background-color: #f5f5f5;
  border-radius: 15px;
}
.placement--shimmer-alt {
  background-color: #ebebeb;
  border-radius: 15px;
  min-height: 327px;
}
.dropdown--shimmer {
  border: 1px solid #bcbab8;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 14px;
}
.dropdown--shimmer .dropdownShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.dropdown--shimmer .dropdownShimmer__text {
  background-color: #ededed;
  border-radius: 4px;
  height: 18px;
  width: 100%;
  max-width: 200px;
  display: inline-flex;
  margin-right: auto;
}
.dropdown--shimmer .dropdownShimmer__arrow {
  display: inline-flex;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  margin-right: 2px;
  margin-left: 8px;
}
.lt__shimmer {
  display: inline-flex;
  min-width: 74px;
  height: 20px;
  background-color: #ededed;
  margin-left: 12px;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 8px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__text {
  height: 18px;
  min-width: 18px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
  width: 100%;
  max-width: 270px;
}
.telBtn__shimmer {
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  padding: 0.375rem 0.65rem;
}
.telBtn__shimmer .telBtn__shimmer-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  background-color: #ededed;
  border-radius: 4px;
}
.telBtn__shimmer .telBtn__shimmer-text {
  height: 18px;
  min-width: 50px;
  background-color: #ededed;
  border-radius: 4px;
  flex: 1 1;
}
.teamBlock--shimmer .teamBlock--img {
  background-color: #ededed;
}
.teamBlock--shimmer .teamBlock__user {
  height: 16px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 6px;
}
.teamBlock--shimmer .teamBlock__email {
  height: 16px;
  width: 150px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.search--shimmer-lg {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 12px 24px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.search--shimmer-lg .search__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 16px;
  display: inline-flex;
}
.search--shimmer-lg .search__text {
  width: 63%;
  height: 24px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.searchBs--shimmer {
  display: flex;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  padding: 14px 16px;
  margin-bottom: 20px;
  min-height: 94px;
}
.searchBs--shimmer .sb__shimmer-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  margin-right: 20px;
  background-color: #ededed;
}
.searchBs--shimmer .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .sb__shimmer-heading {
  height: 20px;
  width: 50%;
  background-color: #ededed;
  border-radius: 4px;
  margin-bottom: 12px;
}
.searchBs--shimmer .bs__next {
  color: #ededed;
  font-size: 16px;
  margin: 0px 8px 0px 16px;
}
.searchBs--shimmer-alt {
  min-height: 55px;
}
.searchBs--shimmer-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.searchBs--shimmer-alt .sb__shimmer-heading {
  margin-bottom: 0;
  margin-right: 30px;
  width: 100%;
  max-width: 232px;
}
.searchBs--shimmer-alt .buildingBtn__shimmer {
  margin-left: 8px;
  margin-right: 8px;
}
.photos--box-shimmer {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  border-radius: 16px;
  position: relative;
  margin-bottom: 14px;
  background-color: #ededed;
}
.block--search-row .photos--box-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.video--box-shimmer {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  position: relative;
  margin-bottom: 14px;
  background-color: #ededed;
}
.block--search-row .video--box-shimmer {
  width: 100%;
  min-width: 10px;
}
.pdf__btn-shimmer {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  padding: 11px 16px;
  width: 100%;
  min-height: 72px;
  margin-bottom: 14px;
  background-color: #f6f6f6;
}
.pdf__btn-shimmer .pdf__btn-icon {
  min-width: 32px;
  height: 38px;
  display: inline-flex;
  margin-right: 18px;
  background-color: #ededed;
  border-radius: 4px;
}
.pdf__btn-shimmer .pdf__btn-text {
  width: 100%;
  height: 18px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
  margin-bottom: 8px;
}
.pdf__btn-shimmer .pdf__btn-off .pdf__btn-text:last-child {
  margin-bottom: 0;
}
.pdf__btn-shimmer .pdf__btn-off {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dh--shimmer .dhShimmer__heading {
  background-color: #ededed;
  width: 100%;
  max-width: 200px;
  height: 28px;
  border-radius: 4px;
  display: inline-flex;
}
.dh--shimmer .dhShimmer__para {
  background-color: #ededed;
  width: 100%;
  max-width: 90%;
  height: 20px;
  border-radius: 4px;
  display: inline-flex;
}
.db--shimmer {
  background-color: #f5f5f5;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
}
.db--shimmer .dbShimmer__text {
  height: 22px;
  background-color: #ededed;
  border-radius: 4px;
  width: 100%;
  max-width: 152px;
}
.db--shimmer .dbShimmer__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background-color: #ededed;
  border-radius: 4px;
  margin-left: 10px;
}
.search--shimmer {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 10px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 100%;
}
.search--shimmer .searchShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.search--shimmer .searchShimmer__text {
  width: 70%;
  height: 16px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.button__shimmer {
  border: none;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button__shimmer .button__shimmer-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
  background-color: #ededed;
  border-radius: 4px;
}
.button__shimmer .button__shimmer-icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  background-color: #ededed;
  border-radius: 4px;
  margin-left: 8px;
}
.button__shimmer-lg {
  min-width: 200px;
}
.button__shimmer-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.dashbaord--opt .button__shimmer-sm {
  margin-left: 20px;
}
.shimmer__style {
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.header__logo-shimmer {
  width: 151px;
  height: 39px;
}
.header__bars-shimmer {
  width: 24px;
  height: 18px;
}
.viewBy--shimmer {
  min-height: 26px;
}
.viewBy--shimmer .viewBy__text {
  height: 18px;
  min-width: 61px;
}
.viewBy--shimmer .viewBy__marker {
  height: 22px;
  min-width: 15px;
}
.grid--shimmer .grid__view {
  width: 20px;
  height: 22px;
}
.shimmer__heading-al {
  width: 50%;
  height: 28px;
}
.alb--lg-shimmer:hover {
  cursor: auto;
  cursor: initial;
  border-color: #bcbab8;
}
.alb--lg-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  margin-bottom: 12px;
}
.sdf__para-shimmer {
  width: 100%;
  max-width: 210px;
  height: 18px;
  margin-bottom: 16px;
}
.fl--shimmer {
  min-height: 24px;
}
.fl--shimmer .fl--shimmer-span {
  height: 18px;
  margin-right: 24px;
}
.fl--shimmer .fl--shimmer-span:last-child {
  margin-right: 0;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(1) {
  min-width: 55px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(2) {
  min-width: 95px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(3) {
  min-width: 136px;
}
.fp__box {
  min-height: 88px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.fp__box .fp__box-span {
  height: 18px;
  width: 100%;
  max-width: 152px;
  margin-bottom: 4px;
}
.fp__box .fp__box-span:nth-of-type(2) {
  max-width: 235px;
}
.shimmer__back {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.shimmer__back .back__btn-icon {
  color: #ededed;
}
.shimmer__back .shimmer__back-text {
  height: 20px;
  width: 40px;
}
.shimmer__heading-lg {
  height: 40px;
  width: 100%;
  max-width: 50%;
}
.shimmer__heading-md {
  height: 24px;
  width: 100%;
  max-width: 25%;
}
.shimmer--keybox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.shimmer--keybox .keyBox--head {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}
.shimmer--keybox .keyBox--head .keyBoxHead__icon {
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
}
.shimmer--keybox .keyBox--head .keyBoxHead__text {
  height: 16px;
  width: 100%;
  max-width: 104px;
}
.shimmer--keybox .shimmer__heading-md {
  height: 18px;
  max-width: 90px;
  margin-bottom: 10px;
}
.shimmer--keybox .small__paragraph {
  height: 16px;
  width: 100%;
  max-width: 90px;
}
.shimmer--keybox-expand .small__paragraph {
  max-width: 275px;
}
.shimmer--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: #f7f7f7;
}
.shimmer__play-btn {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.shimmer__play-highlight {
  min-width: 232px;
  min-height: 48px;
  border-radius: 10px;
  padding: 8px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
}
.shimmer__play-highlight .shimmer__play-text {
  width: 100%;
  max-width: 168px;
  height: 20px;
}
.button__shimmer.button__shimmer-expand .button__shimmer-text {
  max-width: 300px;
}
.photos--lg-shimmer {
  width: 200px;
  height: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 16px;
  margin-bottom: 14px;
}
.block--search-row .photos--lg-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.shimmer--dc .para__md-gray {
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
}
.shimmer--dc .dc--para-column .para__md-gray:last-child {
  margin-bottom: 0;
}
.map--block-shimmer .shimmer__heading-lg,
.sdl--combine .shimmer__heading-lg {
  max-width: 100%;
  width: auto;
  width: initial;
  min-width: 224px;
}
.spaces--box-shimmer {
  min-height: 21px;
}
.spaces--box-shimmer .para__sm-gray {
  min-height: 16px;
  min-width: 112px;
}
.shimmer--floorplan .floorplan--img {
  height: 350px;
  width: 100%;
}
.map--block-shimmer .map--box {
  border-radius: 20px;
}
.specs--box-shimmer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}
.specs--box-shimmer .specsBox__head {
  min-height: 21px;
  width: 100%;
}
.specs--box-shimmer .specsBox__head .specsBox__head-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.specs--box-shimmer .specsBox__head .specsBox__head-text {
  width: 100%;
  max-width: 88px;
  height: 14px;
}
.specs--box-shimmer .para__sm-gray {
  height: 18px;
  width: 100%;
  max-width: 88px;
  margin-top: auto;
  margin-bottom: auto;
}
.specsBox--img-shimmer {
  flex-direction: row;
  justify-content: center;
}
.specs--box-shimmer .specsBox__head-img {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-shimmer .para__sm-gray {
  margin-bottom: 6px;
}
.specs--box-shimmer .para--column {
  min-width: 88px;
}
.specs--box-shimmer .para--column .para__sm-gray:last-child {
  margin-bottom: 0;
}
.specsBox--shimmer-alt {
  justify-content: center;
}
.specsBox--shimmer-alt .para__sm-gray {
  max-width: 150px;
}
.subdomain__footer-shimmer {
  background-color: #757879;
  min-height: 310px;
}
.shimmer__md .shimmer__md-text {
  height: 24px;
  width: 100%;
  max-width: 80%;
}
.shimmer__md .shimmer__md-link {
  height: 24px;
  min-width: 85px;
}
.do--btn-canvas {
  display: inline-flex;
}
.do--btn-canvas .db__btns {
  margin-left: 20px;
}
.tab--shimmer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(188, 186, 184, 0.5);
}
.scroll--hide-x {
  overflow-x: auto;
}
.scroll--hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tab--shimmer-fill .tabShimmer--item {
  flex: 1 1 auto;
}
.tab--shimmer .tabShimmer--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 52px;
  position: relative;
}
.tab--shimmer .tabShimmer--link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0px 20px;
  min-width: 60px;
}
.tab--shimmer .tabShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.tab--shimmer .tabShimmer__text {
  height: 16px;
  width: 100%;
  min-width: 60px;
  margin-left: 16px;
}
.tab--shimmer .tabShimmer__border {
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.subTours--block-shimmer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 152px;
}
.subTours--video-shimmer {
  width: 100%;
  height: 112px;
  border-radius: 10px;
  background-color: #f6f6f6;
  margin-bottom: 16px;
}
.subTours--block-shimmer .para__sm-gray {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.bb--inner-shimmer {
  min-height: 70px;
}
.bb--inner-shimmer .biShimmer__heading {
  height: 30px;
  width: 100%;
  max-width: 90px;
  margin-bottom: 20px;
}
.bb--inner-shimmer .biShimmer__text {
  height: 20px;
  width: 100%;
  max-width: 120px;
}
.bb--alt-shimmer .buildings__next {
  color: #f5f5f5;
}
.dashbord__shimmer-md {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.listing__btn-shimmer {
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 10px;
  min-height: 144px;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
}
.listingBtn--bar-shimmer {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.listingBtn--bar-shimmer .listingBtn__icon-shimmer {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.listingBtn--bar-shimmer .listingBtn__heading-shimmer {
  width: 100%;
  max-width: 125px;
  height: 24px;
}
.listingBtn--para-gr {
  display: flex;
  flex-direction: column;
}
.listingBtn--para-gr .listingBtn__para-shimmer {
  height: 18px;
  width: 100%;
  max-width: 90%;
  margin-bottom: 8px;
}
.listingBtn--para-gr .listingBtn__para-shimmer:last-child {
  margin-bottom: 0;
  max-width: 60%;
}
.specsBox--alt-shimmer {
  justify-content: center;
}
.specsBox--alt-shimmer .specsBox__head {
  min-height: 24px;
  margin-bottom: 8px;
}
.specsBox--alt-shimmer .para__sm-gray {
  margin-top: 0;
}
.sbs--mb {
  margin-bottom: 12px;
}
.radioBtn--shimmer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
}
.radioBtn--shimmer .radioBtn__circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}
.radioBtn--shimmer .radioBtn__text {
  width: 44px;
  height: 16px;
}
.radioBtn--shimmer-alt {
  min-height: 20px;
}
.radioBtn--shimmer-alt .radioBtn__text {
  width: 100%;
  max-width: 90px;
}
.buildings--map.shimmer__style {
  border-radius: 20px;
}
.heading__medium-shimmer {
  height: 28px;
  width: 100%;
  max-width: 252px;
}
.link__btn-shimmer {
  height: 34px;
  width: 200px;
  border-radius: 10px;
}
.render--detail .optLink--shimmer {
  margin-top: 18px;
}
.optLink--shimmer {
  display: flex;
  align-items: center;
}
.optLink--shimmer .link__text {
  width: 56px;
  height: 18px;
}
.optLink--shimmer .link__icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.heading__sm-shimmer {
  height: 18px;
  width: 100%;
  max-width: 120px;
}
.mcBox--shimmer .mc__icon {
  width: 14px;
  height: 14px;
}
.ps__shimmer {
  height: 14px;
  width: 100%;
  max-width: 70px;
}
.mcc--shimmer {
  margin-bottom: 14px;
}
.media--video-block .media--video-shimmer {
  width: 330px;
  height: 190px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-right: 14px;
}
.pdf__lg-shimmer {
  min-height: 114px;
  flex-direction: column;
}
.floorplanBox--shimmer {
  width: 100%;
  border-radius: 15px;
  padding: 28px;
}
.floorplanBox--shimmer .floorplanBox--img {
  width: 100%;
  max-width: 760px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  border-radius: 15px;
  overflow: hidden;
}
.specsOption--box-shimmer {
  display: inline-flex;
  align-items: center;
}
.specsOption--box-shimmer .specsOpt__icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
  display: flex;
}
.specsOption--box-shimmer .specsOpt__text {
  height: 16px;
  width: 46px;
}
.time--box.specs--box-shimmer {
  justify-content: center;
}
.timeBox__separator .timeBox__separator-text {
  height: 18px;
  width: 20px;
}
.radioBtn--shimmer-inverse .radioBtn__circle {
  margin-right: 0;
  margin-left: 10px;
}
.timeBox--options-shimmer .to--box:first-child .radioBtn--shimmer {
  justify-content: flex-end;
}
.timeBox--options-shimmer .to--box:last-child .radioBtn--shimmer {
  justify-content: flex-start;
}
.location--content-shimmer .locationContent__heading {
  height: 28px;
  width: 100%;
  max-width: 200px;
  flex-shrink: 0;
}
.location--box-shimmer .locationBox__heading {
  height: 20px;
  margin-bottom: 14px;
}
.location--box-shimmer .locationBadge__shimmer {
  min-height: 34px;
  width: 100px;
  border-radius: 20px;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0px 10px 10px 0px;
}
.location--head-shimmer .locationHead__logo {
  width: 151px;
  height: 39px;
  flex-shrink: 0;
  margin-right: 14px;
}
.location--head-shimmer .location__destination {
  width: 129px;
  height: 34px;
}
.editor--shimmer {
  display: flex;
  align-items: center;
  min-height: 42px;
  padding-left: 18px;
  position: relative;
}
.editor--shimmer:before {
  content: '';
  width: 1px;
  background-color: #cccccc;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.editor--shimmer .editorOpt__shimmer {
  width: 24px;
  height: 16px;
  margin-right: 18px;
}
.editorBox--shimmer {
  border-radius: 10px;
  height: 300px;
  width: 100%;
}
.tourEdit--shimmer {
  width: 100%;
  background-color: #f7f7f7;
}
.feature--shimmer {
  width: 100%;
  background-color: #ededed;
}
.feature--shimmer .featureBlock__icon {
  width: 34px;
  height: 34px;
  margin: 0px auto 32px auto;
}
.feature--shimmer .featureBlock__heading.text__gray,
.feature--shimmer .sf__digit {
  width: 100%;
  max-width: 140px;
  height: 24px;
}
.feature--shimmer .featureBlock__heading.text__dark {
  width: 100%;
  max-width: 240px;
  height: 20px;
}
.feature--shimmer .featureShimmer--combine {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.tourEdit--shimmer .liveTour--pdf,
.tourEdit--shimmer .liveTour--gallery {
  background-color: #f7f7f7;
}
.tourEdit--shimmer .liveTour--gallery-img {
  border-radius: 10px;
  background-color: #ededed;
  cursor: default;
}
.pdf__shimmer-edit {
  background-color: #ededed;
  min-height: 82px;
}
.pdf__shimmer-edit .pdf__btn-icon {
  width: 30px;
  height: 36px;
}
.specsEdit--shimmer .bw__icon {
  min-width: 14px;
  height: 16px;
}
.tourEdit--specs .specsEdit--shimmer .bw__heading,
.specsEdit--shimmer .bw__heading {
  height: 13px;
  width: 100%;
  max-width: 80px;
  margin-bottom: 8px;
}
.specsEdit--shimmer .bw__para {
  height: 13px;
  width: 100%;
  max-width: 50px;
}
.specsEdit--shimmer .bw__detail {
  display: flex;
  flex-direction: column;
}
.certification--widget.specsEdit--shimmer .bw__icon {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}
.specsTop--shimmer {
  min-height: 28px;
}
.specsTop--shimmer .dashboard__heading-shimmer {
  height: 26px;
  width: 100%;
  max-width: 270px;
}
.specsList__shimmer {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
  min-height: 24px;
  display: flex;
  flex: 1 1;
}
.specsList__shimmer:before {
  content: '';
  width: 1px;
  background-color: #bcbab8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.specsList__shimmer .specsList__shimmer-text {
  height: 22px;
  width: 100%;
  max-width: 120px;
}
.link__blue-shimmer {
  height: 20px;
  width: 98px;
  flex-shrink: 0;
}
.hms__md {
  max-width: 150px;
}
.createListing--row .tl--alt-shimmer .tourListing--row .para__md-dark {
  width: 70%;
}
.intergrate--shimmer {
  display: inline-flex;
  align-items: center;
}
.intergrate--shimmer .intergrateShimmer__icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.intergrate--shimmer .intergrateShimmer__text {
  min-width: 200px;
  height: 20px;
}
.dashbaord--banner-shimmer {
  width: 100%;
  min-height: 198px;
  border-radius: 16px;
  display: flex;
}
.accountBlock--shimmer {
  width: 100%;
  min-height: 100px;
  border-radius: 16px;
  padding: 24px;
  background-color: #fff;
}
.accountBlock--shimmer .accountBlock--col {
  display: flex;
  flex-direction: column;
}
.accountBlock--shimmer .accountBlockCol__heading {
  width: 150px;
  height: 20px;
}
.accountBlock--shimmer .accountBlockCol__para {
  width: 260px;
  height: 18px;
}
.updateAccount--box-shimmer .updateAccount__icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.updateAccount--box-shimmer .updateAccount__detail {
  display: flex;
  flex-direction: column;
}
.updateAccount--box-shimmer .updateAcShimmer__heading {
  width: 70px;
  height: 20px;
  margin-bottom: 6px;
}
.updateAccount--box-shimmer .updateAcShimmer__para {
  width: 70px;
  height: 18px;
}
.spotlight--block-shimmer {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  flex-direction: column;
  background-color: #fff;
}
.spotlight--head-shimmer {
  min-height: 28px;
}
.spotlight--head-shimmer .spotlight--icon {
  width: 24px;
  height: 24px;
}
.spotlight--head-shimmer .spotlight--text {
  width: 160px;
  height: 24px;
}
.shimmerPara--box {
  display: flex;
  flex-direction: column;
}
.shimmerPara--box .shimmer__para {
  width: 80%;
  height: 14px;
}
.card--shimmer {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.cardHead--shimmer {
  display: flex;
  align-items: center;
  min-height: 38px;
  flex: 1 1;
}
.cardHead--shimmer .cdIcon__shimmer {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.cardHead--shimmer .cdText__shimmer {
  width: 100%;
  max-width: 160px;
  height: 28px;
}
.cardHead--shimmer .cdArrow__shimmer {
  font-size: 18px;
  color: #bcbab8;
  display: inline-flex;
  margin-left: auto;
}
.tourPulse--block-shimmer .tourPulse--link .tourPulseLink__icon,
.tourPulse--block-shimmer .tourPulse--detail .tourPulseDetail__icon,
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__heading {
  width: 110px;
  height: 18px;
  margin-bottom: 8px;
}
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__date {
  width: 90px;
  height: 16px;
}
.tourPulseLink--shimmer .tourPulseLink__heading {
  height: 18px;
  width: 50%;
}
.tourPulseLink--shimmer .tourLink__shimmer {
  height: 16px;
  width: 90%;
}
.dropdown--shimmer-new {
  padding: 3px 13px;
}
.dropdown--shimmer-new .dropdownShimmer__sub {
  height: 12px;
  width: 100%;
  max-width: 100px;
  margin-bottom: 6px;
}
.dropdown--shimmer-new .dropdownShimmer__text {
  height: 14px;
}
.dropdown--shimmer-new .dropdownShimmer__arrow {
  font-size: 13px;
  display: inline-flex;
}
.listingCheck--shimmer {
  display: flex;
  align-items: center;
}
.listingCheck--shimmer .thBox__circle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 28px;
}
.th--box-shimmer .thBox__text,
.listingCheck--shimmer .thBox__text {
  height: 18px;
  width: 50px;
}
.th--box-shimmer .thBox__icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.dashTable--listing-shimmer .dashTableListing__link {
  height: 14px;
  width: 100%;
  max-width: 190px;
}
.dashboard--table-shimmer tbody td {
  display: table-cell;
}
.dashboard--table-shimmer.dashboard--table-se .dashTable--listing-shimmer .dashTableListing__link {
  max-width: 110px;
}
.tourAnalyticBlock--shimmer .dt__para-shimmer {
  width: 100%;
  max-width: 180px;
  height: 20px;
}
.dashTable--listing-shimmer .dashTableListing__pdf {
  width: 26px;
  height: 32px;
  min-width: 10px;
}
.back__link-shimmer .back__link-text {
  width: 100%;
  max-width: 150px;
  height: 16px;
}
.listingTour--head-shimmer {
  min-height: 28px;
}
.listingTour--head-shimmer .lts__heading {
  width: 100%;
  max-width: 154px;
  height: 24px;
  margin-right: 20px;
}
.listingTour--head-shimmer .shimmer__date {
  width: 100%;
  max-width: 80px;
  height: 20px;
}
.paraCombine--shimmer {
  min-height: 28px;
  width: 100%;
}
.paraCombine--shimmer .paraCombineSh__icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.paraCombine--shimmer .paraCombineSh__text {
  width: 75%;
  height: 20px;
}
.listingToured--shimmer .para__toured {
  width: 100%;
  max-width: 125px;
  height: 20px;
}
.listingTour__btn-shimmer {
  width: 100%;
  height: 26px;
  border-radius: 12px;
  padding: 2px 12px;
  display: flex;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer .spotlight--icon {
  margin-right: 12px;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer .spotlight--text {
  width: 100%;
  max-width: 250px;
}
.listingToured--nav-shimmer {
  background-color: #f5f5f5;
  min-height: 36px;
  min-width: 180px;
  border-radius: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
.listingToured--nav-shimmer .ltn__item {
  min-width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  min-height: 34px;
}
.listingToured--nav-shimmer .ltn__item-last {
  min-height: 36px;
  margin: -1px;
}
.videoContent--bar-shimmer {
  background-color: #f5f5f5;
}
.videoContent--options-shimmer .videoContent__clock .videoContent--time {
  height: 18px;
  width: 38px;
}
.videoContent--options-shimmer .videoContent__screen .videoContent__screen-text {
  width: 76px;
  height: 20px;
}
.videoContent--options-shimmer .videoContent__speed-btn {
  min-height: 34px;
}
.videoContent--options-shimmer .videoContent__screen .videoContent__screen-size {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.comment--block-shimmer {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.comment--block-shimmer .comment--block-heading {
  width: 100%;
  max-width: 110px;
  height: 20px;
  margin-bottom: 8px;
}
.comment--block-shimmer .shimmerPara--box-xs {
  margin-bottom: 18px;
}
.shimmerPara--box-xs .shimmer__para {
  height: 12px;
}
.commentsGr--shimmer {
  background-color: #fff;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.commentsGr--shimmer .commentsGr__text {
  width: 100%;
  max-width: 44%;
  height: 14px;
}
.commentsGr--shimmer .commentsGr--icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.commentsGr--shimmer .commentsGr--icons .commentsGr__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 18px;
}
.comment--box-shimmer .commentBox--detail {
  width: 100%;
}
.comment--box-shimmer .cbd--top {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
.comment--box-shimmer .cbd--top .cbdt__heading {
  width: 100%;
  max-width: 110px;
  height: 16px;
}
.comment--box-shimmer .cbd--top .cbdt__date {
  width: 100%;
  max-width: 44px;
  height: 16px;
  margin-left: 14px;
}
.comment--box-shimmer .commentBoxOpt__btn {
  width: 18px;
  height: 18px;
}
.paginationShimmer--canvas .dashbord__shimmer-md {
  max-width: 220px;
}
.pagination--shimmer .paginationItem__shimmer {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 10px;
}
.videoTimeline--shimmer {
  display: flex;
  justify-content: space-between;
  min-height: 21px;
  margin-bottom: 22px;
}
.videoTimeline--shimmer .videoTime__shimmer {
  width: 34px;
  height: 18px;
}
.videoTimeline--shimmer .videoProgress__shimmer {
  flex: 1 1;
  height: 5px;
  margin: 7px 20px 0px 20px;
  position: relative;
}
.videoTimeline--shimmer .videoProgress__shimmer .videoHandler__shimmer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
.videoController--shimmer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.videoController--shimmer .vcbIcon__shimmer {
  width: 20px;
  height: 20px;
}
.videoController--shimmer .vcbIcon__shimmer:first-child {
  margin-right: auto;
}
.videoController--shimmer .vcbIcon__shimmer:last-child {
  margin-left: auto;
}
.videoController--shimmer .vcb__shimmer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
}
.videoController--shimmer .vcb--speed {
  display: flex;
  align-items: center;
}
.vcb--switch {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.vcb--switch .switchIcon__shimmer {
  width: 26px;
  height: 14px;
}
.vcb--switch .switchText__shimmer {
  width: 80px;
  height: 18px;
  margin: 0 8px;
}
.video--content .videoContent--place.videoContent--place-shimmer {
  height: 408px;
  border-radius: 20px;
}
.sidebar--shimmer {
  border-radius: 0px 20px 20px 0px;
}
.sl-shimmer {
  margin-bottom: 24px;
}
.sl-shimmer .sl__item {
  padding: 12px 0;
  display: flex;
  align-items: center;
  min-height: 48px;
}
.sl-shimmer .sl__item .sl__item-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.sl-shimmer .sl__item .sl__item-text {
  width: 90px;
  height: 20px;
}
.sl-shimmer-alt {
  margin-top: auto;
  margin-bottom: 10px;
}
.sidebar__toggle-shimmer {
  border-radius: 21px;
}
.sidebar--user-shimmer .sidebar__name {
  width: 100%;
  height: 19px;
}
.sidebar--user-shimmer .dropdown__item-span {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.sidebar--user-shimmer .sidebar__mention {
  width: 100%;
  height: 16px;
}
.sidebar--user-shimmer .sidebar--detail {
  min-height: 39px;
  display: flex;
  flex-direction: column;
}
.db__btns.btn__lg-width {
  width: 100%;
}
.bs--hp {
  min-height: 70px;
}
.bs--hp .headingMd__shimmer {
  width: 100%;
  max-width: 272px;
  height: 20px;
  margin-top: 6px;
}
.bs--hp .para__md-gray {
  width: 100%;
  max-width: 600px;
  height: 18px;
}
.tempCheck--shimmer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tempCheck--shimmer .tempCheck--heading {
  width: 100%;
  max-width: 150px;
  height: 18px;
  margin-top: 2px;
}
.tempCheck--shimmer .radioBtn--shimmer {
  min-height: 20px;
}
.tempCheck--shimmer .radioBtn--shimmer .radioBtn__text {
  width: 94px;
}
.tempCheck--shimmer .tempCheck--frame {
  height: 366px;
  border-radius: 10px;
}
.template--banner-shimmer {
  position: static;
  min-height: 800px;
  background-color: rgba(196, 196, 196, 0.1);
}
.tempHeader--shimmer {
  padding-top: 24px;
  padding-bottom: 24px;
}
.add--box-shimmer {
  min-height: 20px;
}
.add--box-shimmer .addBox__icon {
  width: 18px;
  height: 18px;
}
.add--box-shimmer .addBox__text {
  width: 100%;
  max-width: 215px;
  height: 18px;
}
.temp--btn-canvas .db__btns {
  min-width: 220px;
}
.template--banner-shimmer .shimmer__heading-lg {
  height: 38px;
}
.carouselIndicators--shimmer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  margin: 0px 0px 24px 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.carouselIndicators__case {
  width: 32px;
  height: 10px;
  margin: 4px;
}
.temp--about-shimmer .heading__small-white {
  width: 100%;
  max-width: 266px;
  height: 19px;
}
.temp--about-shimmer .heading__lg-white {
  width: 100%;
  max-width: 370px;
  height: 38px;
}
.headingMd--shimmer {
  display: flex;
  flex-direction: column;
}
.headingMd--shimmer .hm__shimmer {
  width: 100%;
  height: 18px;
  margin-bottom: 10px;
}
.temp--about-shimmer .tempAboutColInner--shimmer .heading__lg-white {
  max-width: 166px;
  height: 36px;
}
.temp--about-shimmer .tempAboutColInner--shimmer .heading__tem-white {
  width: 100%;
  max-width: 166px;
  height: 24px;
}
.headingMd--shimmer-cc .hm__shimmer {
  max-width: 180px;
}
.ci--sh {
  margin-bottom: 72px;
  min-height: 168px;
  position: relative;
}
.cis--static {
  position: static;
  padding: 0;
  margin: 0;
}
.tempCombine--head-shimmer {
  display: flex;
  flex-direction: column;
  min-height: 82px;
}
.tempCombine--head-shimmer .tch--text {
  width: 100%;
  max-width: 220px;
  height: 20px;
}
.tempCombine--head-shimmer .tch--heading {
  width: 100%;
  max-width: 160px;
  height: 38px;
  margin-top: 2px;
}
.temp--combine .tempCombine--head-shimmer {
  min-height: 87px;
}
.suite--block-shimmer .suite--detail .heading__th-dark {
  width: 100%;
  max-width: 160px;
  height: 29px;
}
.keyBox--shimmer {
  background-color: rgba(196, 196, 196, 0.1);
  border-color: rgba(196, 196, 196, 0.1);
}
.keyBox--shimmer .add--box-shimmer {
  margin-bottom: 8px;
}
.keyBox--shimmer .add--box-shimmer .addBox__icon {
  width: 16px;
  height: 16px;
}
.keyBox--shimmer .add--box-shimmer .addBox__text {
  max-width: 100px;
  height: 16px;
}
.keyBox--shimmer .heading__medium {
  width: 100%;
  max-width: 200px;
  height: 20px;
  margin-bottom: 8px;
}
.keyBox--shimmer .small__paragraph {
  width: 100%;
  max-width: 100px;
  height: 16px;
}
.temp--combine .tcl--shimmer-canvas {
  padding-top: 21px;
}
.specsBoxTemp--shimmer .specsBox__head {
  margin-bottom: 14px;
}
.specsBoxTemp--shimmer .specsBox__head-icon {
  width: 14px;
  height: 14px;
}
.specsBoxTemp--shimmer .specsBox__head-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
}
.specsBoxTemp--shimmer .para__sm-gray {
  width: 100%;
  max-width: 160px;
  height: 16px;
}
.temp--about-shimmer.temp--specification .heading__lg-white {
  margin-bottom: 27px;
}
.sbs--text {
  display: flex;
  flex-direction: column;
  min-height: 48px;
}
.sbs--text .heading__md-dark {
  width: 100%;
  max-width: 60px;
  height: 16px;
  margin-bottom: 14px;
}
.sbs--text .para__sm-gray {
  width: 100%;
  max-width: 100px;
  height: 14px;
}
.template--head-two .template--banner-shimmer .tempBanner--detail {
  margin-top: 80px;
}
.tch-shimmer-alt .tch--heading {
  max-width: 320px;
}
.temp--map.shimmer__style {
  border-radius: 20px;
}
.tempListing--block-shimmer .heading__tem-dark {
  width: 100%;
  max-width: 120px;
  height: 24px;
}
.info--item-shimmer {
  min-height: 26px;
}
.info--item-shimmer .infoItem__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.info--item-shimmer .infoItem__text {
  width: 100%;
  max-width: 104px;
  height: 20px;
}
.info--item-shimmer .infoItem--r .infoItem__text {
  width: 18px;
}
.info--item-shimmer .infoItem__para {
  width: 100%;
  max-width: 72px;
  height: 24px;
}
.tempSpecs--block-shimmer {
  display: flex;
  flex-direction: column;
  min-height: 103px;
}
.tempSpecs--block-shimmer .tempSpecs__icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.tempSpecs--block-shimmer .heading__md-dark {
  width: 100%;
  max-width: 120px;
  height: 18px;
  margin-bottom: 13px;
}
.tempSpecs--block-shimmer .para__sm-gray {
  width: 100%;
  max-width: 160px;
  height: 16px;
}
.tempListing--block-shimmer .temp--btn-canvas {
  margin-top: 34px;
}
.tempContact--box-shimmer {
  border-radius: 20px;
}
.sustainBox--shimmer-alt .sbs--text {
  align-items: center;
}
.sustainBox--shimmer-alt .sbs--text .heading__md-dark {
  height: 30px;
}
.template--head-three .template--banner-shimmer {
  background-color: transparent;
}
.template--head-three .tempHeadTh--bg-shimmer {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  margin-left: auto;
  background-color: rgba(196, 196, 196, 0.1);
}
.template--head-three .template--banner-shimmer .shimmer__heading-lg {
  height: 32px;
}
.template--head-three .tempLink--canvas {
  margin-top: 54px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tempLink--canvas .link--shimmer {
  margin-right: 38px;
  margin-bottom: 10px;
}
.link--shimmer {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.link--shimmer .linkShimmer__text {
  width: 108px;
  height: 20px;
}
.link--shimmer .linkShimmer__arrow {
  width: 22px;
  height: 22px;
  margin-left: 16px;
}
.tempConTh--block--shimmer .tempConTh--detail .heading__tem-dark {
  width: 100%;
  max-width: 180px;
  height: 28px;
}
.tempConTh--block--shimmer .headingMd--shimmer .hm__shimmer {
  max-width: 200px;
  height: 16px;
  margin-bottom: 10px;
}
.tempConTh--block--shimmer .link__blue-shimmer {
  height: 16px;
}
.tempConTh--block--shimmer .tempConTh--img {
  border-radius: 0;
}
.tch-shimmer-custom {
  min-height: 103px;
}
.tch-shimmer-custom .tch--heading {
  max-width: 320px;
}
.tempListingTh--block-shimmer {
  background-color: rgba(196, 196, 196, 0.1);
}
.tempListingTh--block-shimmer .heading__temp {
  width: 100%;
  max-width: 120px;
  height: 24px;
}
.tempListingTh--block-shimmer .heading__tem-white {
  width: 66px;
  height: 24px;
}
.tempListingTh--block-shimmer .para__md-white {
  width: 60px;
  height: 20px;
}
.tempListingTh--block-shimmer .tempListingTh--overlay {
  background-color: transparent;
}
.tempSpecs--th .tch-shimmer-custom {
  min-height: 87px;
}
.tempSpecsBlock--shimmer .tempSpecs__icon {
  width: 32px;
  height: 32px;
}
.tempSpecsBlock--shimmer .tempSpecs--detail {
  display: flex;
  flex-direction: column;
}
.tempSpecsBlock--shimmer .tempSpecs--detail .heading__md-dark {
  width: 100%;
  max-width: 150px;
  height: 18px;
  margin-bottom: 16px;
}
.tempSpecsBlock--shimmer .tempSpecs--detail .para__sm-gray {
  width: 100%;
  max-width: 80px;
  height: 16px;
}
.temp--options .db__btns {
  width: 180px;
  margin-left: 16px;
}
.tempView__btn.shimmer__style {
  border-radius: 10px;
}
.template--header-shimmer .template__name {
  width: 125px;
  height: 22px;
  flex-grow: 0;
}
.db--preview-lg {
  width: 100%;
  max-width: 250px;
  height: 34px;
}
.mobile--nav-shimmer .mobileNav__logo {
  width: 120px;
  height: 32px;
}
.mobile--nav-shimmer .mobileNav__user {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.footer--nav-shimmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
}
.footer--nav-shimmer .footerNav--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  min-height: 40px;
}
.footer--nav-shimmer .footerNav--item .footerNav__icon {
  width: 16px;
  height: 16px;
  margin-bottom: 6px;
}
.footer--nav-shimmer .footerNav--item .footerNav__text {
  width: 100%;
  max-width: 70px;
  height: 15px;
}
.ct--shimmer .ct--head {
  min-height: 40px;
  padding-top: 4px;
}
.ct--shimmer .ct--head .dashboard__heading {
  width: 100%;
  max-width: 240px;
  height: 34px;
}
.ct--shimmer .ct--head .steps__text {
  width: 64px;
  height: 20px;
  margin-left: 20px;
}
.ct--shimmer .ct__para-lg {
  width: 100%;
  max-width: 420px;
  height: 24px;
  margin-top: 3px;
}
.ct--shimmer .ct__para-md {
  width: 100%;
  max-width: 380px;
  height: 22px;
  margin-top: 2px;
}
.ct--radio-shimmer {
  align-items: flex-start;
  cursor: auto;
  cursor: initial;
}
.ct--radio-shimmer .checkmark {
  border-color: #ededed;
  margin-top: auto;
  margin-bottom: auto;
}
.ctRadio--head-shimmer {
  width: 100%;
  min-height: 24px;
}
.ctRadio--head-shimmer .ctRadio__icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.ctRadio--head-shimmer .ct__para-md {
  height: 18px;
}
.para--sm-box .small__paragraph {
  width: 100%;
  height: 18px;
  margin-bottom: 6px;
}
.formGr--shimmer {
  display: flex;
}
.formGr--shimmer .formGr--cntl {
  width: 100%;
  height: 48px;
  border-radius: 10px;
}
.tourEdit--top-box .tourEdit--logo-shimmer {
  border-radius: 10px;
}
.ct--shimmer .ct__lg-combine {
  min-height: 54px;
}
.ct--shimmer .ct__lg-combine .ct__para-lg {
  max-width: 100%;
  height: 22px;
  margin-top: 4px;
}
.ct--shimmer .ct__lg-combine .ct__para-lg:last-child {
  max-width: 110px;
}
.tour__badge-shimmer {
  width: 100%;
  min-width: 286px;
}
.tour__badge-shimmer .tour__badge-text {
  width: 100%;
  height: 14px;
}
.tour__badge-shimmer .tour__badge-cross {
  width: 18px;
  height: 16px;
}
.createTour--inner-shimmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;
  padding-top: 4px;
  margin-top: 16px;
  margin-bottom: 22px;
}
.createTour--inner-shimmer .createTour--card-heaing {
  width: 100%;
  max-width: 250px;
  height: 28px;
}
.createTour--inner-shimmer .createTour--card-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  margin-left: 20px;
}
.dashboard--head-shimmer {
  min-height: 48px;
}
.dashboard--head-shimmer .dashboard__heading {
  width: 100%;
  max-width: 140px;
  height: 32px;
}
.db__btns.sm__button {
  min-width: 156px;
  width: auto;
}
.adminSettings--head-shimmer {
  min-height: 36px;
}
.adminSettings--head-shimmer .adminSettings__icon {
  width: 24px;
  height: 24px;
}
.adminSettings--head-shimmer .adminSettings__text {
  width: 100%;
  max-width: 140px;
  height: 24px;
}
.profile--img-shimmer {
  border-radius: 50%;
}
.formGr--shimmer .formLb--shimmer,
.formLb--shimmer {
  width: 70px;
  height: 12px;
  margin-bottom: 10px;
  margin-top: 8px;
}
.db__btns.db__btns-lg {
  min-width: 200px;
}
.formGr--shimmer .adminSettings--upload-del {
  width: 18px;
  height: 18px;
}
.dashHead--shimmer-alt {
  min-height: 40px;
}
.step--head-shimmer .step__text {
  width: 60px;
  height: 18px;
}
.step--head-shimmer .step__info {
  width: 100%;
  max-width: 140px;
  height: 18px;
}
.dashboard__paragraph-shimmer {
  width: 100%;
  max-width: 300px;
  height: 24px;
}
.uploadBlock--shimmer {
  display: flex;
  align-items: center;
  min-height: 28px;
}
.uploadBlock--shimmer .upBl__text {
  width: 100%;
  max-width: 188px;
  height: 22px;
}
.uploadBlock--shimmer .upBl__icon {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
.uploadBlock--shimmer-alt {
  margin-top: 50px;
  margin-bottom: 36px;
  min-height: 72px;
}
.back__shimmer-lh {
  min-height: 25px;
}
.back__shimmer {
  display: flex;
  align-items: center;
}
.back__shimmer .back__shimmer-arrow {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.back__shimmer .back__shimmer-text {
  width: 40px;
  height: 18px;
}
.listing--schedule .hours--time .dropdown--shimmer {
  min-width: 110px;
}
.add__options-shimmer {
  min-width: 220px;
  min-height: 30px;
  border-radius: 0;
}
.form__para-shimmer {
  width: 100%;
  max-width: 500px;
  height: 20px;
  margin-top: 3px;
}
.formGr--shimmer .formGr--textarea {
  width: 100%;
  height: 190px;
  border-radius: 10px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 1000px 100%;
}
.animate--dark {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #ededed 4%, #e2e2e2 25%, #ededed 36%);
  background-size: 1000px 100%;
}
.animate--gray {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@media screen and (min-width: 992px) {
  .shimmer--dc {
    min-height: 520px;
  }
  .shimmer--floorplan {
    min-height: 550px;
  }
}
@media screen and (min-width: 1200px) {
  .media--video-block .media--video-shimmer {
    width: 220px;
  }
}
@media screen and (min-width: 1440px) {
  .media--video-block .media--video-shimmer {
    width: 330px;
  }
}
@media screen and (min-width: 1620px) {
  .listing__btn-shimmer {
    min-height: 130px;
  }
  .video--content .videoContent--place.videoContent--place-shimmer {
    height: 450px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 1440px) {
  .pdf__btn-shimmer {
    padding: 10px 12px;
  }
  .pdf__btn-shimmer .pdf__btn-icon {
    margin-right: 12px;
    min-width: 24px;
    height: 28px;
  }
  .pdf__btn-shimmer .pdf__btn-text {
    height: 16px;
  }
  .block--search-row .photos--box-shimmer,
  .block--search-row .photos--lg-shimmer {
    height: 160px;
  }
  .pdf__shimmer-edit .pdf__btn-icon {
    width: 22px;
    height: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .db--btns-shimmer {
    width: 100%;
  }
  .search--shimmer-lg .search__text {
    width: 80%;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 70%;
  }
  .db--shimmer {
    margin-top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .alb--alt-shimmer .buildingBtn__shimmer {
    margin-left: 6px;
    margin-right: 6px;
  }
  .alb--alt-shimmer .al--listing-box-sh {
    margin-top: 14px;
  }
  .shimmer__heading-al {
    width: 75%;
  }
  .alb--lg-shimmer .al__address {
    margin-bottom: 20px;
  }
  .tab--shimmer .tabShimmer__text {
    display: none;
  }
  .bb--alt-shimmer .bb--inner-shimmer {
    width: 100%;
    margin-top: 12px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:first-child {
    align-items: flex-start;
    padding-right: 8px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:last-child {
    align-items: flex-end;
    padding-left: 8px;
  }
  .feature--shimmer .featureBlock__icon {
    width: 22px;
    height: 22px;
    margin-bottom: 28px;
  }
  .feature--shimmer .featureBlock__heading.text__gray,
  .feature--shimmer .sf__digit {
    height: 20px;
  }
  .feature--shimmer .featureShimmer--combine {
    margin-top: 20px;
  }
  .temp--options .db__btns {
    width: calc(100% - 16px);
  }
  .temp--options .db__btns:first-child {
    margin: 0px 8px 0px 0px;
  }
  .temp--options .db__btns:last-child {
    margin: 0px 0px 0px 8px;
  }
  .db--preview-lg {
    height: 28px;
  }
  .ct--shimmer .ct--head {
    min-height: 32px;
  }
  .ct--shimmer .ct--head .dashboard__heading {
    height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .search--shimmer-lg {
    padding: 4px 15px;
    min-height: 56px;
  }
  .search--shimmer-lg .search__icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
  }
  .search--shimmer-lg .search__text {
    height: 16px;
    width: 100%;
  }
  .searchBs--shimmer .sb__shimmer-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .searchBs--shimmer .search--bs-inner {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 100%;
    max-width: 240px;
  }
  .searchBs--shimmer {
    flex-direction: column;
  }
  .searchBs--shimmer .search--bs-detail {
    flex-direction: column;
  }
  .searchBs--shimmer .bs__next {
    margin-left: auto;
  }
  .searchBs--shimmer-alt .search--bs-inner {
    margin-bottom: 14px;
    width: 100%;
    flex-direction: column;
  }
  .searchBs--shimmer-alt .sb__shimmer-heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--lg-shimmer,
  .photos--box-shimmer {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box-shimmer {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .viewBy--shimmer {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .shimmer__heading-al {
    width: 100%;
  }
  .media--video-block .media--video-shimmer {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .floorplanBox--shimmer .floorplanBox--img {
    height: 300px;
  }
  .location--box-shimmer .locationBox__heading {
    height: 18px;
  }
  .location--head-shimmer .location__destination,
  .location--head-shimmer .locationHead__logo {
    width: 120px;
    height: 30px;
  }
  .feature--shimmer .featureBlock__icon {
    margin-bottom: 22px;
  }
  .feature--block-address.feature--shimmer
    .featureShimmer--combine
    .featureBlock__heading:first-child {
    display: none;
  }
  .tourEdit--specs .specsEdit--shimmer .bw__heading,
  .specsEdit--shimmer .bw__heading,
  .specsEdit--shimmer .bw__para {
    height: 11px;
  }
  .certification--widget.specsEdit--shimmer .bw__icon {
    width: 32px;
    height: 32px;
  }
  .tourAnalyticBlock--shimmer .spotlight--head-shimmer {
    width: 100%;
  }
  .template--head-three .tempHeadTh--bg-shimmer {
    position: static;
    width: 100%;
    height: 500px;
  }
  .template--head-three .template--banner-shimmer .tempBanner--detail {
    padding-top: 94px;
    padding-bottom: 40px;
  }
  .ct--shimmer .ct--head .dashboard__heading {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .btn-expand,
  .do--btn-canvas,
  .tl--shimmer .tourListing--row .para__md-dark,
  .tl--plus-shimmer .tourListing--row .para__md-dark,
  .tl--alt-shimmer .tourListing--row .para__md-dark,
  .db--btns-shimmer .db__btns {
    width: 100%;
  }
  .placement--shimmer-alt {
    min-height: 165px;
  }
  .db--shimmer {
    min-height: 60px;
  }
  .lt__shimmer {
    margin-left: 0;
    max-width: 76px;
  }
  .dashbaord--opt .button__shimmer {
    width: 100%;
    margin-top: 16px;
    margin-left: 0;
  }
  .button__shimmer .button__shimmer-text {
    max-width: 120px;
  }
  .photos--lg-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .subdomain__footer-shimmer {
    min-height: 383px;
  }
  .do--btn-canvas .db__btns {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .do--btn-canvas .db__btns.btn__lg-width {
    max-width: 100%;
  }
  .btnCanvas--column,
  .dbPreview--box {
    flex-direction: column;
    align-items: flex-start;
  }
  .floorplanBox--shimmer .floorplanBox--img {
    height: 250px;
  }
  .location--box-shimmer .locationBadge__shimmer {
    min-height: 30px;
    width: 75px;
    margin: 0px 4px 4px 0px;
  }
  .location--head-shimmer .locationHead__logo {
    margin-right: 10px;
  }
  .location--head-shimmer .location__destination {
    width: 86px;
    height: 24px;
    padding-left: 10px;
  }
  .editorBox--shimmer {
    height: 240px;
  }
  .feature--shimmer .featureBlock__icon {
    width: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .feature--shimmer .featureBlock__heading.text__gray,
  .feature--shimmer .sf__digit {
    height: 14px;
    max-width: 90px;
  }
  .feature--shimmer .featureBlock__heading.text__dark {
    height: 14px;
  }
  .feature--shimmer .featureBlock--sf {
    margin-top: 4px;
  }
  .feature--shimmer .featureShimmer--combine {
    margin-top: 10px;
  }
  .pdf__shimmer-edit {
    min-height: 72px;
  }
  .pagination--shimmer {
    width: 100%;
  }
  .pagination--shimmer .paginationItem__shimmer:first-child {
    margin-left: 0;
  }
  .videoController--shimmer {
    flex-wrap: wrap;
  }
  .vcb--switch {
    flex: 0 0 70%;
    max-width: 70%;
    margin-left: 0;
    margin-top: 22px;
  }
  .videoController--shimmer .vcbIcon__shimmer:last-child {
    margin-top: 22px;
  }
  .video--content .videoContent--place.videoContent--place-shimmer {
    height: 180px;
    border-radius: 12px;
  }
  .tempCheck--shimmer .radioBtn--shimmer {
    justify-content: flex-start;
    margin-bottom: 2px;
  }
  .tempCheck--shimmer .tempCheck--frame {
    height: 280px;
  }
  .temp--about-12.temp--about-shimmer .tempAbout--img-box {
    height: 300px;
  }
  .temp--about-shimmer.temp--specification .tempAbout--btn-canvas {
    margin-top: 56px;
  }
  .template--head-three .template--banner-shimmer .tempLink--canvas {
    align-items: flex-start;
  }
  .tempLink--canvas .link--shimmer {
    margin: 30px 0px 0px 0px;
  }
  .tempCombine--head-shimmer .tch--heading,
  .temp--about-shimmer .heading__lg-white {
    height: 22px;
  }
  .tempCombine--head-shimmer .tch--text {
    height: 16px;
  }
  .template--head-two .template--banner-shimmer .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .template--head-two .template--banner-shimmer .row .col {
    padding-left: 0;
    padding-right: 0;
  }
  .template--banner-shimmer .tempBanner--btn-canvas {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .template--banner-shimmer .tempBanner--btn-canvas .db__btns {
    width: 100%;
  }
  .sustainBox--shimmer-alt .sbs--text .heading__md-dark {
    height: 24px;
  }
  .suite--block-shimmer .suite--detail .heading__th-dark {
    height: 20px;
  }
  .template--banner-shimmer .shimmer__heading-lg {
    height: 22px;
    max-width: 80%;
  }
  .template--banner-shimmer .shimmer__heading-md {
    height: 18px;
  }
  .temp--options .db__btns {
    width: 100%;
  }
  .temp--options .db__btns:first-child {
    margin: 0px 0px 16px 0px;
  }
  .temp--options .db__btns:last-child {
    margin: 0px;
  }
  .db--preview-lg {
    height: 24px;
  }
  .listingToured--nav-shimmer {
    width: 100%;
  }
  .listingToured--nav-shimmer .ltn__item {
    flex: 1 1;
  }
  .createTour--inner-shimmer {
    min-height: 30px;
  }
  .createTour--inner-shimmer .createTour--card-heaing {
    height: 22px;
  }
  .dashboard--head-shimmer .dashboard__heading {
    height: 26px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .photos--box-shimmer,
  .video--box-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .timeBox--options-shimmer .to--box:first-child .radioBtn--shimmer {
    justify-content: flex-start;
  }
  .timeBox--options-shimmer .to--box:last-child .radioBtn--shimmer {
    justify-content: flex-end;
  }
  .editor--shimmer .editorOpt__shimmer {
    margin-right: 10px;
    width: 100%;
  }
  .videoContent--options-shimmer .videoContent__speed-btn {
    min-height: 24px;
  }
  .tour__badge-shimmer {
    min-width: 248px;
  }
}

/* write custom css here */
button:disabled {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
/*
  Loader
*/
.lds-canvas {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}
.lds-canvas .lds-spinner {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-canvas .lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-canvas .lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 25px;
  left: 39px;
  width: 2px;
  height: 10px;
  border-radius: 33%;
  background: #000000;
}
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-spinner div {
  transform-origin: 9px 8px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 1px;
  height: 5px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* white loader */

#loader {
  /* position: absolute;
  left: 50%;
  top: 50%; */
  z-index: 1;
  width: 15px;
  height: 15px;
  /* margin: -76px 0 0 -76px; */
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

#myDiv {
  display: none;
  text-align: center;
}

/*
 Loader
*/
.personalize--block {
  border-radius: 20px;
}
.footer__logo {
  min-width: 32px;
}
.app__button .lds-spinner {
  padding-top: 1px;
  padding-left: 4px;
}
.app__button:hover .lds-spinner div:after,
.app__button:focus .lds-spinner div:after,
.app__button:active .lds-spinner div:after {
  background: #1c1f20;
}
.app__button .lds-spinner div:after {
  background: #fff;
}
.signin--page .app__button .lds-spinner div:after {
  background-color: #1c1f20;
}
.admin__button .lds-spinner,
.schedule__btn .lds-spinner,
.outline__button .lds-spinner {
  padding-left: 4px;
  padding-top: 1px;
}
.admin__button .lds-spinner div:after,
.schedule__btn .lds-spinner div:after,
.profile--img-upload .lds-spinner div:after {
  background: #fff;
}

.img--cover-modern {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.admin__button_listing {
  text-decoration: none;
  color: #fff;
}
admin__button_listing:hover {
  text-decoration: none;
  color: #000000;
}
.schedule__btn:hover .lds-spinner div:after,
.schedule__btn:focus .lds-spinner div:after,
.schedule__btn:active .lds-spinner div:after {
  background: #f2e9d0;
}
.profile--img-upload .lds-spinner {
  padding-left: 3px;
  padding-top: 5px;
}
.adminSettings--upload-item .lds-spinner {
  padding-left: 3px;
}
.forms--group .lds-spinner {
  position: absolute;
  right: 6px;
  bottom: 14px;
}
.outline__button:hover .lds-spinner div:after,
.outline__button:focus .lds-spinner div:after,
.outline__button:active .lds-spinner div:after {
  background: #fff;
}
.form--group-alt .error__msg,
.fields--group .error__msg {
  position: absolute;
  left: 0;
  top: 52px;
  color: #e7063c;
  font-size: 12px;
}
.checkbox--set {
  min-width: 250px;
}
.checkbox--set .error__msg {
  position: absolute;
  left: 0;
  top: 26px;
  color: #e7063c;
  font-size: 12px;
}
.custom__file .error__msg {
  position: absolute;
  left: 0;
  top: 32px;
  color: #e7063c;
  font-size: 12px;
}
.uploadPhoto--progress .uploadPhoto__error {
  background-color: red;
}
.spinner--group .form-control {
  padding-right: 32px;
}
.spinner--group .lds-spinner {
  position: absolute;
  right: 11px;
  top: 16px;
}
.spinner--group .lds-spinner div:after {
  background: #000;
}
.spinner--group.listing--dropdown .lds-spinner {
  right: 30px;
}
.google--suggestions--container {
  padding: 4px 0;
  font-size: 14px;
  border: 1px solid #0638e7;
  position: absolute;
  left: 0;
  right: 0;
  top: 47px;
  background-color: #fff;
  z-index: 5;
  max-height: 240px;
  overflow-y: auto;
}
.google--suggestions--container::-webkit-scrollbar {
  width: 3px;
}
.google--suggestions--container::-webkit-scrollbar-thumb {
  background: #bcbab8;
}
.listing--dropdown-pad .dropdown-toggle {
  padding-right: 48px;
}
.google--suggestions--container .google--places--suggestions {
  line-height: 20px;
  padding: 10px 13px;
  color: #6a7074;
}
.google--suggestions--container .google--places--suggestions:hover {
  background: #0638e7;
  color: #fff;
  cursor: pointer;
}
.step--upload-block .lds-spinner {
  padding-top: 5px;
}
.step--upload-block .lds-spinner div:after {
  background: #000;
}
.listing--dropdown .error {
  border: 1px solid #e7063c;
}
.combine--group .error__msg {
  position: absolute;
  top: 52px;
  left: 0;
  color: #e7063c;
  font-size: 12px;
}

.combine--group .error__msg.edit {
  top: 80px;
}
.spinner--group.select--group .lds-spinner {
  right: 30px;
}
.spinner--group.select--group .form-control {
  padding-right: 52px;
}
/*.dashboard--content .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}
.listing .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}*/
.al--block {
  cursor: pointer;
}
.loading--more {
  text-align: center;
}
.loading--more .lds-spinner {
  padding-top: 5px;
  padding-left: 6px;
}
.loading--more .lds-spinner div:after {
  background: #000000;
}
.options__list li a .lds-spinner {
  padding-left: 5px;
}
.options__list li a:hover .lds-spinner div:after,
.options__list li a:focus .lds-spinner div:after {
  background: #0638e7;
}
/* .listing-video {
  border: 2px solid;
  box-shadow: 2px 2px 10px;
}
.listing-video:before {
  position: relative !important;
} */
.building-single-listing {
  font-weight: normal;
}
div.css-1hb7zxy-IndicatorsContainer > span {
  display: none;
}
div.css-yk16xz-control,
div.css-1pahdxg-control {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #bcbab8;
  min-height: 48px !important;
  padding: 6px 12px !important;
}
div.css-yk16xz-control:hover {
  border-color: #bcbab8 !important;
}
div.css-1pahdxg-control:hover,
div.css-1pahdxg-control {
  border-color: #0638e7 !important;
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
  color: #6a7074 !important;
}
.css-1hwfws3 {
  padding: 2px 10px !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
}
.css-26l3qy-menu {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 0px 1px 2px rgba(34, 39, 44, 0.1) !important;
  z-index: 55 !important;
}
.css-1uccc91-singleValue {
  font-size: 14px;
  color: #222222;
}
.css-9gakcf-option {
  font-size: 14px !important;
  color: #222222 !important;
  word-break: break-word;
  background-color: #deebff !important;
}
.css-1rhbuit-multiValue {
  border-radius: 0px !important;
  background-color: #1c1f20 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
}
.css-12jo7m5 {
  font-size: 14px !important;
  padding-left: 8px !important;
  line-height: 20px !important;
  color: #fff !important;
}
.css-xb97g8 {
  border-radius: 0px !important;
  cursor: pointer;
  color: #fff !important;
}
.css-xb97g8:hover {
  background: transparent !important;
  color: #fff !important;
}
.css-4ljt47-MenuList {
  overflow-x: hidden;
}
.css-gg45go-NoOptionsMessage {
  font-size: 14px !important;
  color: #8e91a0 !important;
}
.css-yt9ioa-option,
.css-1n7v3ny-option,
.css-165luzk-option,
.css-19jh2ze-option {
  font-size: 14px !important;
  color: #8e91a0 !important;
  word-break: break-word;
}
.css-yt9ioa-option:focus,
.css-1n7v3ny-option:focus,
.css-165luzk-option:focus,
.css-19jh2ze-option:focus {
  outline: none !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 5px;
}
.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #e44d43;
  border-radius: 10px;
}
.css-1pahdxg-control:hover {
  border-color: white !important;
}
.css-1hwfws3 > div {
  border-radius: 22px !important;
}
.css-1hwfws3 .css-1wa3eu0-placeholder {
  background-color: transparent;
}
.css-1hwfws3 .css-b8ldur-Input {
  line-height: 18px;
  background-color: transparent;
}
div.css-tlfecz-indicatorContainer > svg.css-6q0nyr-Svg,
div.css-1gtu0rj-indicatorContainer > svg.css-6q0nyr-Svg {
  background: #1c1f20;
  border-radius: 0;
  color: white;
}
.css-a25fsq-multiValue svg.css-6q0nyr-Svg {
  color: white;
  width: 16px;
  height: 16px;
}
select {
  overflow: hidden;
  text-overflow: ellipsis;
}
select option {
  white-space: nowrap;
}
.textarea--group .error__msg,
.errorMsg--set .error__msg {
  top: auto;
  top: initial;
  bottom: -20px;
}
.listing--dropdown .dropdown-item.active {
  background-color: #0638e7;
  color: #fff;
}
.requestTour--box .forms--group .error__msg {
  font-size: 11px;
}
.listing-map-view {
  height: 100%;
  width: 100%;
}
.adminSettings--upload--logo .error__msg {
  position: absolute;
  top: 80px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.adminSettings--row .forms--group .error__msg {
  top: 80px;
}
.adminSettings--row .adminSettings--upload--logo .error__msg {
  padding-left: 12px;
}
.error__msg {
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.virtual--video--active {
  position: relative;
  overflow: visible;
  z-index: 5;
}
@keyframes highlight {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.025);
  }
  from {
    transform: scale(1);
  }
}
.virtual--video--active::after {
  content: '';
  position: absolute;
  transform-origin: center center;
  height: 100%;
  width: 100%;
  animation-name: highlight;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  z-index: -1;
  background-color: #7ff87b;
}
.virtual--video-placeholder {
  background: black;
  height: 100%;
  width: 100%;
}
.bg-white-button {
  background-color: white;
}
.gallery--carousel {
  height: 440px;
}
.join--tour .error__msg {
  top: 56px;
}
.placeholder {
  color: lightgray;
}
.error__msg-white {
  color: white !important;
}
.liveTour--page {
  background: #1c1f20;
}
.ca--group-main .error__msg {
  font-size: 12px;
  color: red;
  position: absolute;
}
.ca--group-main .edited__msg {
  font-size: 12px;
}
.custom__file .lds-spinner {
  padding-left: 4px;
}
.ca--cover {
  cursor: pointer;
}
.outline__button:disabled {
  border: 1px solid #222222;
  background-color: transparent;
  color: #222222;
}
.record__voice .lds-spinner {
  margin-top: 2px;
}
.record__voice .lds-spinner div:after {
  background: #0638e7;
}
.record__voice:hover .lds-spinner div:after {
  background: white;
}
.transparent--overlay {
  bottom: 33px;
  background-color: transparent;
}
.hidden--overlay {
  top: auto;
  top: initial;
  bottom: 0;
  height: 40px;
  background-color: transparent;
}
.status-text {
  text-align: center;
  margin-bottom: 0px;
}
.meeting--block {
  cursor: pointer;
}
.quill--group .ql-container {
  min-height: 150px !important;
  max-height: 300px !important;
  border: 1px solid #bcbab8 !important;
  border-radius: 0 !important;
  color: #222222 !important;
  background-color: transparent !important;
  height: auto !important;
}
.quill--group .ql-editor {
  min-height: 150px !important;
  max-height: 300px !important;
  color: #222222 !important;
  font-size: 14px !important;
  height: auto !important;
}
.quill--group .ql-editor::-webkit-scrollbar {
  width: 4px !important;
}
.quill--group .ql-editor::-webkit-scrollbar-thumb {
  background-color: #bcbab8 !important;
  border-radius: 10px !important;
}
.hide--section {
  position: fixed !important;
  bottom: -10000px !important;
  left: -10000px !important;
  opacity: 0 !important;
  pointer-events: none !important;
}
tourListingLoading {
  position: absolute;
}
.liveTour__list {
  overflow-x: visible;
  overflow-x: initial;
}
.liveTour--listing .container--fixed {
  height: 100%;
  overflow: hidden;
}
.ls--row .loading--more {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 75px;
  text-align: center;
}
.ls--row .loading--more .lds-spinner {
  padding: 0;
}
.ls--row .loading--more span {
  font-size: 14px;
  color: #6a7074;
}

.lt__list_span {
  display: inline-flex;
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.upcomingTour--canvas {
  position: relative;
}
.upcomingTour--canvas .lds-canvas {
  position: absolute;
}
.upcomingTour--canvas .loading--more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upcomingTour--canvas .loading--more .lds-spinner {
  padding-top: 0;
}
.loading-fixed {
  margin-top: -24px;
}
.white--rounded-box {
  position: relative;
}
.white--rounded-box .lds-canvas {
  position: absolute;
}
.dashboard__btn .lds-spinner {
  margin-left: auto;
}
.dashboard__btn .lds-spinner div:after {
  top: 12px;
  bottom: 0;
  left: 8px;
  width: 2px;
  height: 8px;
}
.dashboard__btn:hover .lds-spinner div:after,
.dashboard__btn:focus .lds-spinner div:after {
  background-color: #fff;
}

.empty--photos__control-prev {
  margin-right: 3.2%;
}
.empty--photos__control-next {
  margin-left: 3.2%;
}
.form-option--radius .form-control {
  border-radius: 10px;
}
.no-height-implement {
  height: auto !important;
}
.subListing--video {
  cursor: pointer;
}
.listing-map-view {
  border-radius: 16px;
}
.cap_title:first-letter {
  text-transform: uppercase;
}
.forms--group-radius .ql-toolbar {
  border-radius: 10px 10px 0 0 !important;
}
.forms--group-radius .ql-container {
  border-radius: 0 0 10px 10px !important;
}
.forms--group-radius .ant-input,
.forms--group-radius .google-places-autocomplete,
.forms--group-radius .dropdown-toggle {
  border-radius: 10px !important;
}
.forms--group-radius .error .ant-input,
.forms--group-radius .error .ant-input:hover,
.forms--group-radius .error .ant-input:focus,
.forms--group-radius .error .ant-input:active {
  border-color: #e7063c;
}
.quill.error .ql-toolbar {
  border-top-color: #e7063c !important;
  border-right-color: #e7063c !important;
  border-left-color: #e7063c !important;
}
.quill.error .ql-container {
  border-bottom-color: #e7063c !important;
  border-right-color: #e7063c !important;
  border-left-color: #e7063c !important;
}
.ql-editor-toolbar .ql-toolbar {
  border: none !important;
}
.listing--description-editor .ql-container {
  height: 380px !important;
  border-radius: 10px !important;
  border: none !important;
  background-color: #f6f6f6 !important;
}
.listing--description-editor .ql-editor {
  height: 380px !important;
}
.listing--description-editor .ql-editor::-webkit-scrollbar-thumb {
  background-color: #000 !important;
}
.listing--description-editor .quill.error .ql-container {
  border: 1px solid #e7063c !important;
}
.subdomain--gallery-video video {
  object-fit: cover;
}
.subdomain--gallery-playIcon {
  position: absolute;
  color: white;
  font-size: 30px;
}
.handler-buttons {
  position: relative;
}
.handler-buttons:disabled {
  background-color: white;
}
.handler-buttons:hover:disabled {
  background-color: hsl(227deg 95% 46%);
}
.loading--state {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.22);
}
.listing__btn-row .col {
  max-width: 50%;
}
.google-places-autocomplete .error.form-control {
  border-color: #e7063c;
}
.listing--dropdown-pad .dropdown--button-radius {
  border-radius: 10px;
}
.forms--group .adminSettings--upload-item {
  border-radius: 10px;
}
.forms--group .adminSettings--upload-item.error {
  border-color: #e7063c;
}
.underline__button:after {
  content: unset;
}
.interface--dropdown .dropdown-menu {
  border-radius: 10px 10px 0 0;
}
.listing--dropdown-pad .dropdown--button-radius.opened {
  border-radius: 0 0 10px 10px;
}
.amenities--box .specs__label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.specs--box-alt .specs__smText {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.component--edit .componentEdit--scrollable form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.media--video-block .media--video {
  cursor: pointer;
}
.magnifier-tools {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.magnifier-zoom {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  color: #1c1f20;
  padding: 2px;
  margin: 8px 0;
  box-shadow: 0px 0px 10px rgba(97, 94, 94, 0.3);
}
.magnifier-zoom:not(:disabled):not(.disabled):active,
.magnifier-zoom:hover,
.magnifier-zoom:focus,
.magnifier-zoom:active {
  background-color: #828282;
  border: none;
  box-shadow: 0px 0px 10px rgba(97, 94, 94, 0.3);
  color: #1c1f20;
}
.liveTour--gallery-large .TransformComponent-module_container__3NwNd {
  overflow: visible !important;
}
.sketch-picker {
  max-width: 100% !important;
  width: auto !important;
  width: initial !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 99;
  margin-bottom: 5px !important;
}
.adminSettings--row .color--group .error__msg {
  top: 50px;
}
.react-pdf__message--loading {
  margin: auto;
  display: flex;
  align-items: center;
}
.react-pdf__message--loading .lds-spinner {
  margin-left: 4px;
}
.box-cursor {
  cursor: pointer;
}
.specsOption--box .cancel_spec--icon {
  background-color: #6a7074;
}
.specsOption--box .cancel_spec--text {
  color: #6a7074;
}
.specsOption--box:hover .cancel_spec--icon,
.specsOption--box:focus .cancel_spec--icon {
  background-color: #1c1f20;
}
.specsOption--box:hover .cancel_spec--text,
.specsOption--box:focus .cancel_spec--text {
  color: #1c1f20;
}
.specs--box-alt .specs--box-base-alt {
  height: 48px;
  align-items: initial;
}
.amenities--box.amenities--box-alt {
  height: 104px;
}
.specs--box-alt .specs__label-alt,
.amenities--box .specs__label-alt {
  display: inline-block;
  word-break: break-word;
}
.specs--box-alt .specs--box-base-alt.listing--details {
  height: 40px;
}
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
.react-transform-element {
  width: 100% !important;
  height: 100% !important;
  flex-wrap: initial !important;
  display: inline-block !important;
  text-align: center !important;
}
.react-transform-component .react-transform-element img {
  height: 100% !important;
  width: 100% !important;
}
.uploadPhoto--component {
  position: relative;
}
.uploadPhoto--component .error__msg {
  position: absolute;
  left: 10px;
  top: 315px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.border-radius-10 {
  border-radius: 10px;
}
.draggable--canvas .tourEdit--listing:last-child {
  margin-right: 0;
}

.neighbour-scroll {
  overflow: auto;
}
.neighbour-scroll .accordion {
  min-height: auto;
  min-width: auto;
  max-height: none;
}
.neighbour-scroll .accordion .collapse {
  max-height: none;
}
.heading-shimmer-neighbour {
  width: 30%;
  height: 30px;
}
.hide-after-right {
  margin-right: 40px;
}
.hide-after-left {
  margin-left: 40px;
}
.hide-after-slide::after,
.hide-after-slide::before {
  display: none;
}
.no-max-width {
  max-width: none;
  margin-left: 0px;
  margin-right: 0px;
}
.static-tour-video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  display: flex;
}
.static-video-player div video {
  object-fit: initial;
}
.video--content .videoContent--place {
  height: auto;
  overflow: visible;
  overflow: initial;
  border-radius: 0;
  margin: 0;
}
.video--content .videoContent--place .rr-player {
  width: 100% !important;
  height: 100% !important;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.video--content .videoContent--place .rr-player__frame {
  border: 1px solid #bcbab8;
  width: 100% !important;
  height: 408px !important;
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 20px;
  margin-bottom: 22px;
}
.video--content .videoContent--place .replayer-wrapper {
  transform: scale(0.34) translate(-50%, -50%) !important;
}
.video--content .rr-controller.svelte-dxnc1j.svelte-dxnc1j {
  background: transparent;
  height: auto;
}
.video--content .rr-timeline.svelte-dxnc1j.svelte-dxnc1j {
  width: 100%;
  margin-bottom: 22px;
}
.video--content .rr-timeline__time.svelte-dxnc1j.svelte-dxnc1j {
  font-size: 14px;
  color: #6a7074;
  width: auto;
}
.video--content .rr-progress.svelte-dxnc1j.svelte-dxnc1j {
  margin: 0 20px;
  border: none;
  height: 5px;
  border-radius: 20px;
}
.video--content .rr-controller__btns.svelte-dxnc1j.svelte-dxnc1j {
  width: 100%;
  justify-content: space-between;
}
.video--content .switch.svelte-1mmdovf.svelte-1mmdovf {
  height: auto;
  font-size: 13px;
  color: #6a7074;
  margin-left: 12px;
}
.video--content .switch.svelte-1mmdovf label.svelte-1mmdovf {
  margin-bottom: 0;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j {
  color: #0638e7;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.active.svelte-dxnc1j {
  color: #fff;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j svg {
  fill: #0638e7;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:first-child {
  margin-left: 0;
  margin-right: auto;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:last-child {
  margin-left: auto;
  margin-right: 0;
}
.video--content .rr-progress__handler.svelte-dxnc1j.svelte-dxnc1j {
  display: none;
}
.video--content .videoContent--place.video--fullscreen .rr-player__frame {
  height: calc(100% - 120px) !important;
}
.video--content .videoContent--place.video--fullscreen .replayer-wrapper {
  transform: scale(0.7) translate(-50%, -50%) !important;
}
.video--content .video--fullscreen .rr-controller.svelte-dxnc1j.svelte-dxnc1j {
  padding-left: 20px;
  padding-right: 20px;
}
.dashboard--accordian > .dashboard--card-alt {
  overflow: visible;
  overflow: initial;
}
.ant-message svg {
  vertical-align: baseline;
  vertical-align: initial;
}
.temp--group .ql-editor {
  max-height: 228px !important;
}
.ql-toolbar.ql-snow .cross--icon {
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-right: 6px !important;
  cursor: pointer !important;
}
.tempParaCombine--canvas.para__md-white .small__paragraph {
  color: #fff !important;
}
.tempParaCombine--canvas.para__md-dark .small__paragraph {
  color: #1c1f20 !important;
}
.tempParaCombine--canvas.para__md-gray .small__paragraph {
  color: #6a7074 !important;
}

.para__md-gray-center {
  color: #6a7074 !important;
  text-align: center;
}
.temp--about-1 .ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}
.temp--about-1 .ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}
.temp--about-1 .ql-editor h1,
.temp--about-1 .ql-editor h2,
.temp--about-1 .ql-editor h3,
.temp--about-1 .ql-editor h4,
.temp--about-1 .ql-editor h5,
.temp--about-1 .ql-editor h6,
.temp--about-1 .ql-editor span,
.temp--about-1 .ql-editor b,
.temp--about-1 .ql-editor i,
.temp--about-1 .ql-editor label .temp--about-1 .ql-editor div {
  color: #fff !important;
}
.temp--about-1 .ql-toolbar .ql-picker,
.temp--about-1 .ql-toolbar .ql-picker-options,
.temp--about-1 .quill--group .ql-editor {
  color: #fff !important;
  background-color: #1c1f20 !important;
}

.ls--row .ls--col {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

@media screen and (min-width: 1440px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.46) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.82) translate(-50%, -50%) !important;
  }
}
@media screen and (min-width: 1620px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.54) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place .rr-player__frame {
    height: 450px !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(1.1) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 1200px) {
  .gallery--carousel {
    height: 672px;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.52) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 991px) {
  .gallery--carousel {
    height: 440px;
  }
  .listing-sm-map {
    height: 420px;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.4) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 767px) {
  .gallery--carousel {
    height: 672px;
  }
  .magnifier-tools {
    right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.18) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place .rr-player__frame {
    height: 180px !important;
    border-radius: 12px;
  }
  .video--content .rr-controller__btns.svelte-dxnc1j.svelte-dxnc1j {
    flex-wrap: wrap;
  }
  .video--content .switch.svelte-1mmdovf.svelte-1mmdovf {
    margin-top: 10px;
    margin-left: 0;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:last-child {
    margin-top: 10px;
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    justify-content: flex-end;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.3) translate(-50%, -50%) !important;
  }
  .preContent--location .gm-style .gm-control-active.gm-fullscreen-control {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .gallery--carousel {
    height: auto;
  }
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.15) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.2) translate(-50%, -50%) !important;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-1 {
  bottom: 0.25rem;
}

.right-3 {
  right: 0.75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.bg-blue {
  background-color: blue;
}
.ml-115px {
  margin-left: 115px;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.template-button-hover:hover {
  background-color: #3a4e47;
  transition: 0.2s ease;
  color: white;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  z-index: 99;
  top: 0;
}
.sticky-b-0 {
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  bottom: 0;
}
.max-w-440 {
  max-width: 440px;
}
.sticky-100 {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  z-index: 9;
  top: 100px;
}
.px-5p {
  padding-left: 5px;
  padding-right: 5px;
}
.scale-1:hover {
  scale: 1.1;
  transition: 0.2s ease;
}
.overlay-image:hover {
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
  transition: 0.2s ease;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.gallery-image {
  margin-bottom: 5px;
  width: 178px;
}
.px-20p {
  padding-left: 20px;
  padding-right: 20px;
}
.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}
.width-178 {
  width: 178px;
}
.width-246 {
  width: 246px;
  height: 210px;
}
.main-image {
  width: 646px;
  /* height: 559px; */
}
.main-image-classic {
  /* min-width: 668px; */
  height: 430px;
  /* max-width: 668px;
  max-height: 430px;
  width: 100%; */
}
.template-button-outlined-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  grid-gap: 10px;
  gap: 10px;

  width: 100%;
  height: 48px;

  border: 1px solid #587d71;
  border-radius: 10px;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}
.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}
.border-1p-white {
  border: 1px solid white;
}

.border-1p-gray {
  border: 1px solid #bcbab8;
}
.border-1p {
  border-width: 1px;
  border-style: solid;
}
.template-button-filled-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  grid-gap: 10px;
  gap: 10px;

  width: 100%;
  height: 48px;

  background: #587d71;
  border-radius: 10px;
}
.hidden {
  display: none;
}
.hidden-important {
  display: none !important;
}
.h-60 {
  height: 15rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-96 {
  height: 24rem;
}

.h-72 {
  height: 18rem;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-40 {
  width: 10rem;
}

.max-w-1180p {
  max-width: 1180px;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}
.w-60p {
  width: 100px;
  height: 100px;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}
.bg-none {
  background: none;
}

.border-2 {
  border-width: 2px;
  border-style: solid;
}

.border-4 {
  border-width: 4px;
}

.border-b-2 {
  border-bottom-width: 2px;
  border-color: #f6f6f6 !important;
}

.border-b-new-2 {
  border-bottom-width: 2px !important;
  border-color: #f6f6f6 !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-button-new {
  --tw-border-opacity: 1;
  border-color: rgb(88 125 113 / var(--tw-border-opacity)) !important;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-secondary-new {
  --tw-bg-opacity: 1;
  background-color: rgb(88 112 125 / var(--tw-bg-opacity));
}

.bg-button-new {
  --tw-bg-opacity: 1;
  background-color: rgb(88 125 113 / var(--tw-bg-opacity));
}

.bg-primary-new {
  --tw-bg-opacity: 1;
  background-color: rgb(65 63 84 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-mobile-new {
  --tw-bg-opacity: 1;
  background-color: rgb(65 63 84 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}
.template-white-button {
  padding: 14px 20px;
  grid-gap: 10px;
  gap: 10px;

  width: 350px;
  height: 48px;

  /* B&W/White */

  border: 1px solid #ffffff;
  border-radius: 10px;
}
.template-button-filled-two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  grid-gap: 10px;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 48px;

  background: #587d71;
  border-radius: 10px;
}
.template-button-outlined-two {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  padding: 14px 20px;
  grid-gap: 10px;
  gap: 10px;
  width: 300px;
  height: 48px;
  border: 1px solid #587d71;
  color: #587d71;
  border-radius: 10px;
  flex: none;
  order: 12;
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: black;
}
.template-heading-color {
  color: #413f54;
}
.template-gray-color {
  color: #777777;
}
.font-medium {
  font-weight: 500;
}
.color-none {
  color: none;
}
.font-w-500 {
  font-weight: 500;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-active {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pt-0 {
  padding-top: 0px;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 2.188rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.875rem;
  line-height: 2rem;
}
.template-color-gray-three {
  color: #353535 !important;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-secondary-new {
  --tw-text-opacity: 1;
  color: rgb(88 112 125 / var(--tw-text-opacity));
}

.text-button-new {
  --tw-text-opacity: 1;
  color: rgb(88 125 113 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.import-input-field-full {
  padding: 14px 32px 14px 13px;
  grid-gap: 10px;
  gap: 10px;

  width: 75%;
  height: 49px;

  /* B&W/White */

  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #bcbab8;
  border-radius: 10px 0px 0px 10px;
}

@media (min-width: 768px) {
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:import-input-field {
    padding: 14px 32px 14px 13px;
    grid-gap: 10px;
    gap: 10px;

    width: 654px;
    height: 49px;

    /* B&W/White */

    background: #ffffff;
    /* Neutral/500 */

    border: 1px solid #bcbab8;
    border-radius: 10px 0px 0px 10px;
  }
  .md\:import-input-field-new {
    padding: 14px 32px 14px 13px;
    grid-gap: 10px;
    gap: 10px;
    width: 503px;
    height: 49px;

    /* B&W/White */

    background: #ffffff;
    /* Neutral/500 */

    border: 1px solid #bcbab8;
    border-radius: 10px 0px 0px 10px;
  }
  .md\:w-2\/5 {
    width: 40%;
  }
  /* .md\:mt-0 {
    margin-top: 0px !important;
  } */

  .md\:w-3\/5 {
    width: 60%;
  }
  .md\:px-2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md\:ml-4 {
    margin-left: 50px;
  }
  .md\:text-template-gray-two {
    color: #6a7074 !important;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:w-80p {
    width: 80px;
    height: 80px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }
  .md\:template-button-filled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    grid-gap: 10px;
    gap: 10px;

    width: 143px;
    height: 48px;

    background: #587d71;
    border-radius: 10px;
  }
  .md\:template-button-outlined {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    grid-gap: 10px;
    gap: 10px;

    width: 143px;
    height: 48px;

    border: 1px solid #587d71;
    border-radius: 10px;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:text-black {
    color: black !important;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .md\:border-button-new {
    border: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(88 125 113 / var(--tw-border-opacity)) !important;
  }
  .md\:outline-button-new {
    --tw-border-opacity: 1;
    outline: 2px;
    outline: rgb(88 125 113 / var(--tw-border-opacity)) !important;
  }

  .md\:bg-transparent {
    background-color: transparent;
  }

  .md\:bg-dark-gray-new {
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity));
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:text-left-new {
    text-align: left !important;
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .md\:text-button-new {
    --tw-text-opacity: 1;
    color: rgb(88 125 113 / var(--tw-text-opacity));
  }
}
@media (min-width: 1024px) {
  .lg\:w-380px {
    width: 380px;
  }
}
@media (min-width: 1180px) {
  .main-image-classic {
    min-width: 668px;
  }
}

.header-container {
  height: 91px;
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  align-items: center;
}

.header-subcontainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.header-contact-container {
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
}

.header-logo {
  max-height: 80px;
  max-width: 300px;
  object-fit: contain;
}

.header-contact-number {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #222222;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  line-height: 21px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 500;
}

.button-sm {
  padding: 8px 20px;
  border-radius: 10px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.button-lg {
  padding: 14px 20px;
  border-radius: 10px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.button-primary {
  background: #587d71;
  color: #ffffff;
  border: none;
}

.button-primary-outline {
  box-sizing: border-box;
  border: 1px solid #587d71;
  background: none;
  color: #587d71;
}

.button-white {
  background: #ffffff;
  color: #587d71;
  border: none;
}

.button-white-outline {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  color: white;
  background: none;
}

.button-blue-outline {
  box-sizing: border-box;
  border: 1px solid #071033;
  color: #071033;
  background: none;
}

.website-padding {
  padding: 0px 216px;
}

@media (max-width: 1024px) {
  .website-padding {
    padding: 0px 40px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.content-container {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  align-items: flex-start;
}

.website-tabs-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  padding-bottom: 40px;
}

.hr {
  border: 1px solid #bcbab8;
  height: 0px;
  width: 100%;
}

.building-features-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 166px 20px;
  grid-gap: 80px;
  gap: 80px;

  width: 100%;
  height: 252px;

  background: #343434;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
}

.footer-company-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
}

.footer-company-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.footer-contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 4px;
  gap: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer-address {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
}

.footer-phone-number {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.footer-copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  text-transform: uppercase;
}

.grid-container {
  display: grid;
  grid-gap: 20px 10px;
  gap: 20px 10px;
  grid-template-columns: 25% 25% 25% 25%;
}

.grid-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  grid-gap: 20px;
  gap: 20px;
  border: 1px solid #e5e5e4;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}

.contact-board-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  grid-gap: 12px;
  gap: 12px;
  width: 380px;
  background: #f9f9f9;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-units-container {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #071033;
}

.price-container {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.price-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #071033;
}

.price-value {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #587d71;
}

.price-subtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7074;
}

.request-tour-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #071033;
}

.contact-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #071033;
}

.agent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  flex: none;
  flex-grow: 0;
}

.agent-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 40px;
}

.agent-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #071033;
}

.agent-email {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #6a7074;
}

.hr {
  height: 0px;
  width: 100%;
  border: 1px solid #dcdcdc;
}

.w-100 {
  width: 100%;
}

.tabs-container {
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #bcbab8;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  display: flex;
  flex-direction: row;
}

.tab-item {
  padding: 0px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #6a7074;
}

.tab-item-active {
  color: #071033;
  border-bottom: 3px solid #071033;
}

.dropdown-items-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 12px 14px 13px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  right: 0px;
  top: calc(100% + 10px);
  min-width: 230px;
  cursor: pointer;
}

.dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.dropdown-item-hr {
  border: 1px solid #cacaca;
  height: 0px;
  width: 100%;
}

.units-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 30px;
  gap: 30px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.unit-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e5e5e4;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
}

.unit-primary-image {
  width: 126px;
  height: 126px;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  object-fit: cover;
}

.unit-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  grid-gap: 4px;
  gap: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.unit-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.unit-name {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #000000;
}

.unit-status {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0a893d;
}

.unit-status-unavailable {
  color: #6a7074;
}

.unit-price-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  grid-gap: 4px;
  gap: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.unit-price {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #587d71;
}

.unit-price-duration {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7074;
}

.unit-details-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 28px;
  gap: 28px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.unit-details-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 12px;
  gap: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.unit-details-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}

.unit-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
}

.available-units-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
  cursor: pointer;
}

.units-filter-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222222;
}

.units-filter-dropdown-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 12px 14px 13px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  right: 0px;
  top: calc(100% + 10px);
  min-width: 230px;
  cursor: pointer;
}

.units-filter-dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.basic-gallery-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  height: 430px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.basic-gallery-primary-image-container {
  height: 100%;
  flex-grow: 1;
}

.basic-gallery-secondary-images-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.basic-gallery-secondary-images-col {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  flex-grow: 1;
}

.basic-gallery-secondary-image-container {
  width: 230px;
  height: 50%;
  object-fit: cover;
  position: relative;
}

.basic-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.basic-image-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.basic-building-name-container {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.basic-building-name {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  color: #071033;
}

.basic-building-location-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.basic-building-location {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #071033;
}

.building-intro-container {
  grid-gap: 20px;
  gap: 20px;
}

.building-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  color: #222222;
}

.building-location {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #6a7074;
}

.building-map {
  height: 202px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.exterior-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.exterior-header-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.exterior-properties-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 10px;
  gap: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
}

.exterior-properties-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.exterior-property-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #353535;
}

.exterior-property-item {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.features-main-container {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}

.feature-collapse-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  border: 1px solid #e5e5e4;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.feature-head-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

.feature-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.feature-chevron-inactive {
  transform: rotate(270deg);
}

.feature-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  color: #222222;
}

.feature-images-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  height: 258px;
  width: 100%;
}

.feature-image-row {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}

.feature-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery-container {
  width: 100%;
  height: 318px;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.secondary-images-col {
  width: 121px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}

.primary-image {
  display: flex;
  flex-grow: 1;
  object-fit: cover;
}

.secondary-image-container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 30%;
  position: relative;
}

.secondary-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.secondary-image-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}

.headline-container {
  width: 100%;
  height: 350px;
  background-color: antiquewhite;
  position: relative;
  display: flex;
  align-items: center;
}

.headline-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  background-color: black;
}

.headline-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.headline-content-container {
  z-index: 1000;
}

.headline-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: white;
  display: block;
}

.headline-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ffffff;
}

.headline-action-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

