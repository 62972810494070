/*
  Media Queries
 */

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .listing--schedule-col {
    flex-direction: column;
  }
  .week--time {
    padding-right: 0;
    margin-bottom: 16px;
  }
  .hours--time,
  .listing--schedule-col .select--group,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .ca--cover-large {
    width: 55%;
  }
  .group-input .outline__button {
    min-width: 150px;
  }
}
@media screen and (min-width: 576px) {
  .flex-row-sm-reverse {
    flex-direction: row-reverse;
  }
  .modal--uploadPhotos .modal-dialog {
    max-width: 860px;
  }
  .modal-settings .modal-dialog {
    max-width: 700px;
  }
  .listing--modal .modal-dialog,
  .office-modal .modal-dialog {
    max-width: 640px;
  }
  .footer-n .footer__logo {
    margin-right: 20px;
  }
  .modal--video .modal-dialog {
    max-width: 100%;
  }
  .users--content-tenant {
    margin-bottom: -23px;
  }
  .liveTour--amenities-main {
    margin-top: 32px;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .tourListing--block .tourListing__img {
    margin-left: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .presentation--footer,
  .suite--component-mobile {
    display: none;
  }
  .carouselPre--ul {
    max-height: 100%;
    min-height: 100%;
  }
  .carouselPre--li {
    display: flex;
    flex-direction: column;
  }
  .itinerary--back-canvas {
    padding-right: 78px;
  }
  .presentation--content {
    flex: 1;
    width: 90%;
  }

  /* Pricing payment page media query*/
  /* credit card payment page media query */
  .crdCard--payment--top--icon {
    margin-right: 12px;
  }
  /* credit card payment page media query */
  /* Pricing payment page media query*/
  .marketing--presentation--btn--container .btn:first-child {
    margin-right: 10px;
  }
  .marketing--presentation--btn--container .btn:last-child {
    margin-left: 10px;
  }
  .video--profile-img-canvas {
    left: -14px;
  }
  .video--profile-img {
    min-width: 34px;
    max-width: 34px;
    min-height: 34px;
    max-height: 34px;
  }
}
@media screen and (max-width: 786px) {
  .dashTable--listing .dashTableListing__img {
    width: 100%;
  }
  .presentation--off {
    padding: 20px;
    height: 100%;
    /* flex: 0; */
    width: 100%;
  }
  .dashTable--listing .dashTableListing__video {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .steps--content .row .col-12 {
    border-right: 1px solid rgba(188, 186, 184, 0.5);
  }
  .steps--content .row .col-12:last-child {
    border-right: 0;
  }
  .modal--video-set {
    max-width: 640px;
    height: 360px !important;
  }
  .users--content-tenant {
    margin-top: -90px;
  }
  .liveTour--mobile {
    display: none;
  }
  .sec--vertical-md {
    min-height: 100vh;
  }
  .sec--vertical-md .sec--vertical-middle {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .vt--share {
    min-height: 100vh;
    align-items: center;
  }
  .liveTour--options {
    min-width: 360px;
  }
  .liveTour--content-broker .liveTour--options.liveTour--shadow-effect:before {
    content: '';
    width: 21px;
    background: linear-gradient(95.75deg, #1c1f20 2.38%, rgba(28, 31, 32, 0) 86.57%);
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    transform: rotate(180deg);
    pointer-events: none;
  }
  .liveTour--options li:last-child .liveTourOpt__box {
    padding-left: 20px;
    border-left: 1px solid rgba(213, 217, 224, 0.3);
  }
  .tourListing--canvas {
    overflow: hidden;
  }
  .tourListing--canvas.tourListing--canvas-shadow-effect:after {
    content: '';
    box-shadow: 0px -54px 10px rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: -58px;
    left: 0;
    right: 0;
    height: 27px;
  }
  .tourListing--scroll::-webkit-scrollbar {
    width: 3px;
  }
  .tourListing--scroll::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
  .dashboard--newAccount .admin__button {
    min-width: 230px;
  }
  .tourEdit--nl {
    margin-top: 140px;
  }
  .tourPre--content .liveTour--options {
    min-width: 150px;
  }
  .presentation--page {
    height: 100vh;
  }
  .presentation--welcome .preWelcome--main {
    overflow-y: auto;
    max-height: 100%;
    padding-right: 30px;
  }
  .preSpecs__list {
    flex-wrap: wrap;
    overflow-y: auto !important;
    flex: 1;
  }
  .preSpecs__listItem {
    width: 100% !important;
    flex-grow: initial !important;
  }
  .preSpecs__list li button {
    opacity: 0;
  }
  .preAmenities__list {
    flex-wrap: wrap;
    flex: 1;
  }
  .preAmenities__listItem {
    width: 100% !important;
  }
  .presentation--content .preSpecs--col {
    display: flex;
  }
  .presentation--content .specs--widget {
    width: calc(25% - 12px);
    margin-left: 6px;
    margin-right: 6px;
  }
  .carousel--presentation {
    flex: 1;
  }
  .presentation--content {
    transition: width 1s;
  }
  .carousel--presentation {
    transition: width 1s, height 1s;
    transition: margin 400ms;
  }
  .presentation--off .presentation--container {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .preControl--box .preControlBox__icon,
  .preControl--box .preControlBox__text,
  .presentation--navbar {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    animation-name: fadeInOpacity;
    animation-iteration-count: 2;
    animation-timing-function: ease-in;
    animation-duration: 3s;
  }
  .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    transition: width 1s;
  }
  .preControl--box .preControlBox__arrow {
    transition: margin 400ms;
  }
  .presentation--page.full--screen {
    padding: 24px 44px;
  }
  .presentation--page.full--screen .preControl--box .preControlBox__icon,
  .presentation--page.full--screen .preControl--box .preControlBox__text {
    opacity: 0;
  }
  .presentation--page.full--screen .presentation--navbar {
    opacity: 0;
  }
  .presentation--page.full--screen .preControl--outer.preControl--outer-prev:before,
  .presentation--page.full--screen .preControl--outer.preControl--outer-next:after {
    width: 0;
  }
  .presentation--page.full--screen .presentation--content {
    width: 100%;
  }
  .presentation--page.full--screen .carousel--presentation {
    margin-top: -78px;
  }
  .presentation--page.full--screen .preControl--box .preControlBox__arrow {
    margin-top: -78px;
  }
  .presentation--page.full--screen .presentation--container {
    padding-left: 0;
    padding-right: 0;
  }
  .presentation--page.full--screen .preControl--outer {
    position: fixed;
  }
  .presentation--page.full--screen .presentation--control {
    width: 44px;
  }
  .mobile--nav,
  .mobile--footer {
    display: none;
  }

  /* Pricing landing page media query */
  .pricing--choose--plan--inner {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
  }
  .pricing--choose--card--container .col {
    margin-bottom: 0px;
  }
  /* Pricing landing page media query */

  /* Pricing payment page media query*/
  /* credit card payment page media query */
  .credit--card--payment--form .forms--group--col:nth-last-child(2) {
    margin-bottom: 0px;
  }
  /* credit card payment page media query */
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .marketing--home--top--container {
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
  }
  .marketing--home--top--content .pricing__main__heading,
  .marketing--home--top--content .simple__paragraph {
    text-align: left;
  }
  .marketing--home--top--content .mktHome--top--content--btn {
    justify-content: flex-start;
    margin-top: 40px;
  }
  .marketing--home--opinion--component .opinion--canvas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    max-width: 50%;
    flex: 1 1 50%;
    margin-right: 15px;
  }
  .marketing--home--opinion--component .opinion--content {
    max-width: 50%;
    flex: 1 1 50%;
    margin-left: 15px;
  }
  .marketing--home--opinion--component .opinion--block {
    align-items: flex-start;
  }
  .marketing--home--opinion--component .marketing--opinion--heading {
    max-width: 480px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    margin-bottom: 0px;
    text-align: left;
  }
  .marketing--home--opinion--component .opinion__para,
  .marketing--home--opinion--component .op--name {
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .marketing--home--opinion--component .opinion__para:before {
    background-size: 52px;
    width: 52px;
    height: 40px;
    left: -37px;
    top: -19px;
  }
  .marketing--presentation--carousal--canvas .mktSlider--immerse--container {
    right: -29px;
  }
  .marketing--home--opinion--component .op__logo__container {
    justify-content: flex-start;
  }
  .marketing--home--presentation--section .marketing--presentation--tog--div {
    position: absolute;
    right: 20px;
    top: 0;
  }
  .video--profile-img-canvas {
    left: -20px;
  }
  .video--profile-img {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
  }
  /* Marketing homepage */
}
@media screen and (min-width: 992px) {
  .key--box-temp {
    border-color: #000;
  }
  .border-color {
    border-color: transparent !important;
  }

  .navbar-light .navbar__list .nav-link,
  .navbar__list .nav-link {
    margin: 4px 25px;
  }

  .row--space-wide {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row--space-wide > .col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .form--content .navbar__header .navbar-brand,
  .menu__toggle {
    display: none;
  }
  .vc--mobile {
    display: none;
  }
  .about--block:nth-child(odd) .about--detail,
  .about--block:nth-child(even) .about--img {
    padding-right: 120px;
  }
  .about--block:nth-child(even) .about--detail,
  .about--block:nth-child(odd) .about--img {
    padding-left: 120px;
  }
  .form--content .navbar__list {
    margin-left: 0;
  }
  .form--banner .form__brand {
    display: block;
  }
  .form--page,
  .dashboard--page {
    overflow: hidden;
  }
  .form--banner,
  .form--content,
  .dashboard--content {
    height: 100vh;
    overflow-y: auto;
  }
  .form--content--payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form--content--company--setup {
    display: flex;
    flex-direction: column;
  }
  .dashboard--container {
    padding-left: 70px;
    padding-right: 70px;
  }
  .settings-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .al--block .al--listings-component {
    padding: 0px 10px;
  }
  .listingFinal--content {
    display: flex;
    align-items: center;
  }
  .virtual--video-block {
    position: sticky;
    top: 0;
    margin-right: 0;
  }
  .empty--box {
    height: calc(100vh - 107px);
    margin-top: 0;
  }
  .empty--listings {
    height: calc(100vh - 152px);
  }
  .standby--content {
    margin-top: 0;
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
  }
  .info--block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .row--gif .col-lg-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .al__address {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .available--listing-cols .scroll--listing-cols {
    max-height: 890px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .liveTour--amenities-main {
    margin-top: 44px;
  }
  .voiceRecord--block {
    max-height: 180px;
    margin-bottom: 16px;
    overflow-y: auto;
  }
  .voiceRecord--block::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  .voiceRecord--block::-webkit-scrollbar-thumb {
    background-color: #bcbab8;
  }
  .sec--vertical-lg,
  .virtualTour--combine {
    min-height: 100vh;
  }
  .sec--vertical-lg .sec--vertical-middle,
  .vt--combine-alt {
    min-height: calc(100vh - 65px);
    display: flex;
    align-items: center;
  }
  .share--realistic .share--content {
    padding-left: 240px;
  }
  .al--block-lg .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-lg .al--listings-component {
    min-width: 42%;
    max-width: 42%;
  }
  .al--block-sm .al--adress-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-sm .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al--adress-component {
    min-width: 40%;
    max-width: 40%;
  }
  .al--block-md .al--listings-component {
    min-width: 44%;
    max-width: 44%;
  }
  .al--block .al--btn-component {
    width: auto;
    min-width: 10px;
    max-width: 100%;
  }
  .al--block.buildings--block .bb-sf {
    min-width: 60%;
    max-width: 60%;
  }
  .available--listing-map {
    height: 890px;
    overflow: hidden;
  }
  .unpublished--col {
    margin-top: -60px;
  }
  .toggle__btn {
    display: none;
  }
  .dashboard--bar .dashboard__heading {
    line-height: 48px;
  }
  .al--lg-alt .al--btn-component {
    min-width: 24%;
    max-width: 24%;
  }
  .show--col-list .admin__button {
    min-width: 94px;
  }
  .tourListing--scroll {
    max-height: 324px;
    overflow-y: auto;
    padding-right: 3px;
  }
  .dashboard--sticky {
    overflow-y: hidden;
  }
  .dashboard--sticky .box--expand {
    overflow-y: auto;
  }
  .dashboard--sticky .dashboard--canvas {
    height: calc(100vh - 40px);
  }
  .tab--building-sticky,
  .tab--building-sticky .tabBuilding--content,
  .tab--building-sticky .tabBuildingContent--canvas,
  .tab--building-sticky .tabBuildingContent--inner {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab--building-sticky .tab-pane {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab--building-sticky .tabBuildingContent--canvas {
    max-height: 100%;
  }
  .started--canvas {
    padding-left: 70px;
  }
  .info--carousel-block .tempAbout--img:before {
    content: none;
  }
  .info--carousel-block:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 50%;
    background-color: #f9f9f9;
  }
  .listings--grid-change > .row > .col-lg-12 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .listings--grid-change > .row > .col-lg-12 .bulding--block-row .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sustain--row .col {
    max-width: 50%;
  }
  .al--block-alt.buildingsBlock--update .al--adress-component {
    min-width: 25%;
    max-width: 25%;
  }
  .al--block-alt.buildingsBlock--update .bb-sf {
    min-width: 44%;
    max-width: 44%;
    margin-right: auto;
  }
  .al--block-alt.buildingsBlock--update .buildingsBtn--canvas {
    min-width: 23%;
    max-width: 23%;
  }
  .al--block-alt.buildingsBlock--sm .buildingsBtn--canvas {
    min-width: 20%;
    max-width: 20%;
  }
  .al--block-alt.buildingsBlock--sm .bb-sf {
    min-width: 50%;
    max-width: 50%;
  }

  /* Pricing landing page media query */
  .navbar__header__payment .navbar__list .nav-link {
    margin-right: 45px;
    margin-left: 0px;
  }
  .navbar__header__payment .navbar__list-n .nav-link.active {
    color: #1c1f20;
    /* border-bottom: 1px solid #000000; */
  }
  .navbar__header__payment .navbar__list .nav-link:last-child {
    margin-right: 0px;
  }
  /* Pricing landing page media query */

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--main--section {
    justify-content: space-between;
    flex-direction: row;
  }
  .payment--method--container {
    flex: 1 1 62%;
    max-width: 62%;
    height: 100%;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .payment--billing--card {
    flex: 1 1 38%;
    max-width: 38%;
    height: 100%;
    margin-left: 10px;
    margin-top: 0px;
  }
  .crdCard--payment--top--icon {
    margin-right: 16px;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .marketing--home--top--section {
    background: url('../images/marketing-bg-lady.png') center bottom no-repeat;
    background-size: 68%;
  }
  .marketing--home--personalize--component .mktHome--personalize--canvas {
    display: flex;
    flex: 1 1 auto;
    margin-right: -13%;
    margin-top: 30px;
  }
  .marketing--home--personalize--component .mktHome--personalize--form--container {
    display: flex;
    flex: 1 1 auto;
  }
  .marketing--home--personalize--component .personalize--block {
    min-width: 485px;
    margin-right: initial;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    margin-right: 20px;
  }
  .marketing--home--opinion--component .opinion--content {
    margin-left: 20px;
  }
  .marketing--home--presentation--section .marketing--presentation--tog--div {
    right: 70px;
  }
  .video--profile-img {
    min-width: 58px;
    max-width: 58px;
    min-height: 58px;
    max-height: 58px;
  }
  /* Marketing homepage */
}
@media screen and (min-width: 1200px) {
  .container--expand {
    max-width: 1170px;
  }
  .row--space-price,
  .row--space-price .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row--space-price .col-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-availableListings > .col-lg-4 {
    -ms-flex: 0 0 34.5%;
    flex: 0 0 34.5%;
    max-width: 34.5%;
  }
  .row-availableListings > .col-lg-8 {
    -ms-flex: 0 0 65.5%;
    flex: 0 0 65.5%;
    max-width: 65.5%;
  }
  .broker--accordian {
    min-height: 420px;
  }
  .al--block-md .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 48%;
    max-width: 48%;
  }
  .al--block-md .al__address {
    -webkit-line-clamp: 1;
  }
  .ls--left {
    min-width: 204px;
    max-width: 204px;
    margin-right: 20px;
  }
  .ls--bottom .ls--left {
    margin-bottom: 20px;
  }
  .al--block-alt .al__address {
    -webkit-line-clamp: 2;
  }
  .al--block-alt .al--adress-component {
    min-width: 34%;
    max-width: 34%;
  }
  .al--block-md .al--listings-component {
    min-width: 36%;
    max-width: 36%;
  }
  .al--block-alt .al--btn-component {
    min-width: 12%;
    max-width: 12%;
  }
  .photos--listings {
    margin-left: -32px;
    margin-right: -32px;
  }
  .media--video-block .media--video {
    width: 220px;
  }
  .tourPre--top .gallery__cross {
    right: -70px;
  }
  .tourPre--top .magnifier-tools {
    right: -80px;
  }
  .listingTourBtns--row {
    margin-left: 2px;
    margin-right: 2px;
    flex-grow: 1;
  }
  .listingTourBtns--row .col {
    padding-left: 2px;
    padding-right: 2px;
  }
  .listingTour--t {
    display: flex;
    align-items: center;
  }
  .tempSpecsTh--row .col-xl {
    flex: 0 0 20%;
    max-width: 20%;
  }

  /* Pricing payment page media query  */
  /* credit card payment page media query*/
  .payment--method--container {
    flex: 1 1 67%;
    max-width: 67%;
  }
  .payment--billing--card {
    flex: 1 1 33%;
    max-width: 33%;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query  */

  /* Marketing homepage media query */
  .marketing--home--personalize--component .personalize--block {
    min-width: 620px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    font-size: 54px;
    line-height: 75px;
  }
  .marketing--home--opinion--component .marketing--opinion--heading--block {
    margin-right: 40px;
  }
  .marketing--home--opinion--component .opinion--content {
    margin-left: 45px;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 45px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 40px;
  }

  .marketing--presentation--slider--inner {
    flex-direction: row;
  }
  .marketing--get--started--slider .marketing--presentation--slider--inner {
    flex-direction: column;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 15px;
  }
  .marketing--presentation--slider--inner .pricing__main__heading {
    text-align: left;
  }
  .marketing--get--started--slider .marketing--presentation--slider--inner .pricing__main__heading {
    text-align: center;
  }
  .marketing--presentation--carousal--canvas .marketing--presentation--slider--inner {
    justify-content: space-between;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 32%;
    max-width: 32%;
    padding-right: 40px;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--txt--list {
    padding-left: 50px;
    padding-right: 0;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    margin-top: 4px;
    flex: 0 0 68%;
    max-width: 68%;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--canvas--inner {
    margin-left: auto;
  }
  .marketing--presentation--slider--inner .marketing--slider--img--container {
    margin-right: 30px;
  }
  .marketing--presentation--slider--inner .marketing--slider--top--txt {
    margin-left: 30px;
  }
  .marketing--carousal--canvas--reverse .marketing--presentation--slider--inner {
    flex-direction: row;
  }
  .marketing--carousal--canvas--reverse .mktSlider-canvas-second {
    margin-bottom: 0px;
  }
  .marketing--home--personalize--component .personalize--block .heading__component {
    text-align: left;
  }
  .market--home-pre-last .marketing--slider--top--txt {
    margin: -60px 0px 0px 0px;
  }
  .marketing--presentation--slider--btn {
    margin-top: -40px;
  }
  .marketing--home--carousel {
    margin-top: -80px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  /* Marketing homepage media query */
}
@media screen and (min-width: 1280px) {
  .team--main-sbl {
    padding-left: 30px;
    padding-right: 30px;
  }
  /* Marketing homepage media query */
  .marketing--home--top--section {
    background-size: 1062px;
    min-height: 762px;
  }
  /* Marketing homepage media query */
}
@media screen and (min-width: 1367px) {
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 34%;
    max-width: 34%;
    padding-right: 90px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    flex: 0 0 66%;
    max-width: 66%;
  }
}
@media screen and (min-width: 1440px) {
  .container--expand {
    max-width: 1270px;
  }
  .fluid--expand {
    max-width: 1440px;
  }
  .container--xs {
    max-width: 800px;
  }
  .md--canvas {
    max-width: 960px;
  }
  .vp__box {
    right: -11px;
    top: 132px;
  }
  .vp__box:after {
    height: 31px;
    left: 2px;
    bottom: -22px;
  }
  .vp__logo:before {
    height: 26px;
    right: 45px;
    top: -25px;
  }
  .vt--demand-content {
    min-height: 450px;
  }
  .vt--combine {
    margin: 50px 0;
  }
  .vt--combine .adminSettings--head {
    margin-bottom: 20px;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 480px;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    font-size: 18px;
    padding: 12px 16px;
  }
  .al--block-alt .al--adress-component {
    min-width: 32%;
    max-width: 32%;
  }
  .al--block-alt .al--listings-component {
    min-width: 30%;
    max-width: 30%;
  }
  .al--block-alt .al--btn-component {
    min-width: 15%;
    max-width: 15%;
  }
  .tourPre--top .liveTour--placement,
  .tourPre--top .liveTour--gallery,
  .tourPre--top .liveTour--custom {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .photos--off {
    margin-left: -36px;
    margin-right: -36px;
  }
  .photos--off .photos__control-prev {
    margin-right: 22px;
  }
  .photos--off .photos__control-next {
    margin-left: 22px;
  }
  .media--video-block .media--video {
    width: 330px;
  }
  .media--video-block .media--detail {
    min-width: 190px;
  }
  .dashboard--table thead tr th:first-child {
    padding-left: 38px;
  }
  .dashboard--table thead tr th:last-child {
    padding-right: 38px;
  }
  .dashboard--table tbody tr td:first-child {
    padding-left: 30px;
  }
  .dashboard--table tbody tr td:last-child {
    padding-right: 20px;
  }
  .listingTourBtns--row {
    margin-left: 10px;
    margin-right: 10px;
  }
  .listingTourBtns--row .col {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashboard--table-f tbody tr td:first-child {
    min-width: 310px;
    max-width: 310px;
  }
  .dashboard--table-se tbody tr td:first-child {
    min-width: 232px;
    max-width: 232px;
  }
  .dashboard--table-th tbody tr td:first-child {
    min-width: 750px;
    max-width: 750px;
  }
  .navbar__header__payment .navbar__list-n .nav-link {
    font-size: 16px;
  }
}
@media screen and (min-width: 1620px) {
  .container--expand {
    max-width: 1400px;
  }
  .fluid--expand {
    max-width: 1600px;
  }
  .container--xs {
    max-width: 960px;
  }
  .tour--col {
    min-width: 20%;
  }
  .liveTour--building-top {
    margin-top: 60px;
  }
  .liveTour--specs-main {
    margin-top: 44px;
  }
  .building--widget .bw__arrow {
    font-size: 11px;
    line-height: 12px;
  }
  .vt--container {
    max-width: 960px;
  }
  .listing__btn {
    min-height: 130px;
  }
  .listing__btn .para__sm-gray {
    font-size: 14px;
  }
  .time--dropdown .dropdown-toggle {
    font-size: 18px;
  }
  .time--dropdown .dropdown-menu {
    font-size: 16px;
  }
  .video--content .videoContent--place {
    height: 450px;
  }
  .comment--block {
    height: 546px;
  }
  .dashboard--tourListing .tour--canvas-listing {
    height: calc(100vh - 40px);
  }
  .tourListing--canvas-alt .tourListing--scroll {
    max-height: initial;
  }
  /* .tourListing--canvas-alt .tourListing--scroll {
    max-height: 400px;
  } */
  .fluid--expand--payment {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pricing--choose--plan--inner {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
  .payment--billing--card .payment__billing__card__listTxt {
    font-size: 18px;
  }
  .payment--billing--card .payment--billing--card--total .payment__bill__totalTxt {
    font-size: 20px;
  }
  .bank--payment--container .bank--payment--sub--top .bank__payment__top__para {
    font-size: 18px;
  }
  .payment--signup--container .container--xs {
    max-width: 75%;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--txt--list {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .marketing--carousal--canvas--reverse .marketing--slider--txt--list {
    padding-left: 90px;
    padding-right: 0;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 1440px) {
  .tourPre--top .liveTour--container {
    max-width: 960px;
  }

  .fluid--expand {
    padding-left: 60px;
    padding-right: 60px;
  }
  .container--large,
  .vt--container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .pdf__btn:not([href]),
  .pdf__btn {
    font-size: 15px;
    padding: 10px 12px;
  }
  .pdf__btn .pdf__btn-icon {
    font-size: 24px;
    margin-right: 8px;
    min-width: 22px;
  }
  .block--search-row .photos--box {
    height: 160px;
  }
  .photos--listings .photos__control {
    top: 70px;
  }
  .started--imgs {
    max-width: 480px;
  }
  .tourPre--top .liveTour--gallery .liveTour--container {
    max-width: 960px;
  }
  .video--canvas-expand .video--box-expand {
    width: 950px;
    height: 535px;
  }
  .presentation--content .preContent--head .heading__lg-dark,
  .locationContent__heading {
    font-size: 30px;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 34px;
    width: 34px;
    margin-right: 20px;
  }
  .presentation--content .detailComp--box .text__md-gray {
    font-size: 14px;
    line-height: 15px;
  }
  .presentation--content .detailComp--box .heading__medium {
    font-size: 20px;
    line-height: 24px;
  }
  .preVideo--canvas {
    padding-top: 44%;
    width: calc(100% - 22%);
  }
  .template--radio .heading__md-dark {
    font-size: 16px;
  }
}
@media screen and (max-width: 1300px) {
  .vp__logo {
    left: -20px;
  }
  .vp__logo:before {
    height: 22px;
    left: 80px;
    right: inherit;
    top: -21px;
  }
  .vp__box:after {
    height: 28px;
    left: 0;
    bottom: -18px;
  }
}
@media screen and (max-width: 1280px) {
  .tourEdit--top {
    height: 380px;
  }
  .video--canvas-expand {
    margin-left: 50px;
    margin-right: 50px;
  }

  /* Marketing homepage media query */
  .mktHome--top--content--inner {
    max-width: 360px;
  }
  .marketing--home--top--video {
    margin-top: 60px;
    margin-right: 3%;
  }
  /* Marketing homepage media query */
}
@media screen and (max-width: 1200px) {
  .tourPre--top .liveTour--container {
    max-width: 720px;
  }
  .form--banner {
    width: 400px;
    min-width: 400px;
  }
  .form--content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal--listing .modal-dialog {
    max-width: 100%;
  }
  .al--block {
    flex-direction: column;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
  }
  .al--block .al--block-detail {
    margin-left: 0;
  }
  .landing__heading {
    font-size: 52px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 14px;
    margin-right: 14px;
  }
  .tour--col {
    min-width: 34%;
  }
  .broker--video {
    margin-bottom: 48px;
  }
  .ls--top,
  .ls--bottom {
    flex-direction: column;
    align-items: flex-start;
    min-height: 10px;
  }
  .ls--top {
    min-height: 90px;
    padding-right: 0;
    margin-top: 20px;
  }
  .liveTour--listing-search .ls--top {
    min-height: 10px;
  }
  .ls--sort {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  .ls--opt {
    width: 100%;
    margin-bottom: 20px;
  }
  .ls--top .ls__cross {
    top: 6px;
  }
  .liveTour--listing {
    overflow-y: auto;
  }
  .liveTour--listing::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .search__block {
    margin-bottom: 32px;
  }
  .white--rounded-box {
    padding: 24px;
  }
  .dashboard--card {
    padding-left: 24px;
    padding-right: 24px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    margin-bottom: 20px;
    height: 400px;
    min-width: 10px;
  }
  .dashboard__btn {
    margin-top: 20px;
  }
  .upcomingTour--block .upcomingTour--left {
    min-width: 160px;
    max-width: 160px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .subDashboard--bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .subDashboard--bar .subDashboard--top-btns {
    width: 100%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .listing__btn .listing__btn-icon {
    font-size: 20px;
    margin-right: 12px;
  }
  .listing__btn .listing__btn-heading {
    font-size: 16px;
  }
  .video--canvas-expand .video--box-expand {
    width: 720px;
    height: 405px;
    border-radius: 26px;
  }
  .account--block {
    flex-direction: column;
    align-items: flex-start;
  }
  .account--block .accountBlock--col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .updateAccount__list li {
    padding: 0 12px;
    margin-top: 20px;
  }
  .updateAccount--box {
    justify-content: center;
  }
  .updateAccount__icon {
    flex: 1;
    justify-content: flex-end;
    padding-right: 5px;
  }
  .updateAccount__detail {
    flex: 1;
  }
  .updateAccount--box .updateAccount__heading {
    font-size: 16px;
  }
  .updateAccount--box .para__md-gray {
    font-size: 14px;
  }
  .presentation--main-even .preContent--detail {
    padding-left: 40px;
  }
  .presentation--main-odd .preContent--detail {
    padding-left: 30px;
  }
  .preAmenities--carousel,
  .preSpecs--carousel {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar__header__payment .navbar__list-n .nav-link {
    padding: 6px 8px;
  }
  .pricing__main__heading {
    font-size: 50px;
    line-height: 65px;
  }
  .marketing--home--slider--item {
    display: flex;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--img--canvas {
    width: 100%;
  }
  .marketing--home--presentation--section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt {
    max-width: 550px;
  }
  .listing-card-container {
    padding: 0px;
    justify-content: center;
  }
  .listing-card-details-container {
    flex-direction: column;
    flex: 0.65;
    padding: 5px;
  }
  .listing-card-image-container {
    flex: 0.35;
    border-radius: 10px 0px 0px 10px;
    height: auto;
    max-height: 160px;
  }
  .listing-card-details {
    display: none;
  }
  .listing-card-description {
    padding-left: 5px;
  }
  .listing-card-status-container {
    justify-content: center;
    flex: 0.5;
  }
  .listing-card-title {
    font-size: 14px;
  }
  .listing-card-subtitle {
    font-size: 13px;
  }
  .mobile-hidden {
    display: none;
  }
  .w-md-100 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 33.33%;
  }
  .tourPre--page {
    height: auto;
  }
  .tourPre--top {
    height: 480px;
  }
  .preVideo--canvas {
    padding-top: 56.25%;
    width: 100%;
  }
  .marketing--home--top--video {
    margin-right: 0%;
  }
}
@media screen and (max-width: 560px) {
  .suite--block .img-height {
    height: 205px;
  }
}
@media screen and (max-width: 991px) {
  /* .tourPre--top .liveTour--container {
    max-width: 540px;
  } */
  .listing--border-style {
    border: 2px solid #c4c4c4;

    border-style: solid;
    border-radius: 22px;
  }
  .suite--block .suite--img {
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .key--box-temp .listing--font-size {
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
  }
  .heading-font-weight {
    font-weight: normal;
  }
  .entry-height {
    min-height: 20px;
  }
  .listing--adjust-direction {
    display: flex;
    flex: 85%;
  }
  .entry-flex-start {
    display: flex;
    flex-direction: row-reverse;
  }
  .suite--block-margin {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .suite--block .img-height {
    height: 250px;
    align-items: flex-start;
  }
  .justify-content-start {
    display: flex;
    justify-content: flex-start;
  }
  .listing--adjust-location {
    align-items: initial;
    max-width: 100%;
  }

  .entry-size {
    display: flex;
    flex: 25%;
  }
  .display-none {
    display: none;
  }

  .key--box-temp {
    flex-direction: row;
    padding: 0%;
    align-items: unset;
    padding-left: 0px;
  }

  .key--box-temp {
    justify-content: space-between;
    border-color: transparent;
    text-align: start;
  }
  .container--expand,
  .fluid--expand,
  .navbar-expand-lg > .fluid--expand {
    padding-left: 32px;
    padding-right: 32px;
  }
  .container--large,
  .container--expand {
    max-width: 100%;
  }
  .navbar__list {
    padding: 15px;
  }
  .navbar--btns {
    padding: 0px 15px 15px 15px;
  }
  .navbar__header .navbar-collapse {
    background-color: #28313c;
    margin-top: 14px;
    box-shadow: 2px 0px 11px rgba(0, 0, 0, 0.15);
  }
  .nav__signin-button {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar__header .outline__button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .lists__heading {
    font-size: 26px;
  }
  .lists__subHeading {
    font-size: 22px;
  }
  .lists-info li {
    font-size: 20px;
  }
  .fields__icon {
    right: 20px;
    top: 13px;
    font-size: 22px;
  }
  .fields--group {
    margin-bottom: 16px;
  }
  .tour--status {
    font-size: 16px;
    right: 15px;
    top: 15px;
  }
  .heading__landing {
    font-size: 42px;
  }
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 26px;
  }
  .steps__paragraph {
    font-size: 16px;
  }
  .about--block .about--detail {
    margin-bottom: 40px;
    text-align: center;
  }
  .about--block .about--img {
    text-align: center;
  }
  .about__heading {
    margin-bottom: 16px;
  }
  .footer {
    margin-top: 40px;
  }
  .company__about {
    font-size: 16px;
    line-height: 28px;
  }
  .listing--map {
    height: auto;
    min-height: 320px;
  }
  .form--page {
    flex-direction: column-reverse;
  }
  .form--banner {
    display: none;
  }
  .app__button-sm {
    min-height: 48px;
  }
  .sidebar {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .sidebar.sidebar--hide {
    margin-left: 0;
    margin-right: 0;
  }
  .dashboard--select-n {
    width: 100%;
  }
  .broker__table {
    min-width: 880px;
  }
  .container--xs {
    max-width: 100%;
  }
  .listing--schedule {
    flex-direction: column;
  }
  .listing--schedule-col {
    padding-left: 0;
    padding-right: 0;
  }
  .hours__change {
    margin: 10px 0;
  }
  .al--block .al--block-detail {
    flex-direction: column;
  }
  .al--listing-box .al--listing,
  .al--listing-box .al--listing:last-child {
    margin-bottom: 16px;
  }
  .customer--block {
    margin: 0px 0px 16px 0px;
  }
  .buildings--block .bb-sf {
    width: 100%;
    justify-content: space-between;
    margin-top: 6px;
  }

  .heading--group .heading__edit {
    margin-top: 4px;
  }
  .vb--positioning {
    margin-right: 0;
  }
  .hours__del {
    margin: 20px 0px 20px auto;
  }
  .opinion__para {
    font-size: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .opinion__para:before {
    background-size: 18px;
    left: 0;
    top: -10px;
  }
  .opinion__para:after {
    background-size: 18px;
    right: 0;
    bottom: -14px;
  }
  .landing__header-n .navbar-collapse {
    background-color: #15235c;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link,
  .navSignin__btn {
    color: #fff;
  }
  .landing__header-n .schedule__btn-outline {
    border-color: #fff;
    color: #fff;
  }
  .landing__heading {
    font-size: 42px;
  }
  .landing__para {
    font-size: 18px;
  }
  .info--block {
    margin-bottom: 48px;
  }
  .info--content .row .col-12:last-child .info--block {
    margin-bottom: 0;
  }
  .info--component {
    padding-top: 140px;
  }
  .navbar-light .navbar__list-n .nav-link,
  .navbar__list-n .nav-link {
    margin-left: 0;
    margin-right: 0;
  }
  .tour--col {
    min-width: 43%;
  }
  .vp__box:after,
  .vp__logo:before,
  .vp__secure:after {
    content: none;
  }
  .approve--video {
    margin-bottom: 32px;
  }
  .vt--combine .adminSettings--head .adminSettings__icon {
    font-size: 20px;
  }
  .vt--combine .adminSettings--head .adminSettings__text {
    font-size: 24px;
    line-height: 30px;
  }
  .share--options {
    display: none;
  }
  .buildings--block .bb--inner {
    width: auto;
  }
  .available--listing-map {
    height: 400px;
    overflow: hidden;
    margin-top: 32px;
  }
  .al--listing-combine {
    justify-content: space-between;
  }
  .ls--box .ls--box-detail {
    max-width: 260px;
    min-width: 260px;
  }
  .dashbaord--opt {
    margin-top: 16px;
  }
  .al--block-alt .al--block-detail {
    padding-left: 0;
  }
  .al--block-alt .buildings__next {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .upcomingTour--block {
    flex-direction: column;
  }
  .upComTour--btn-canvas {
    width: 100%;
  }
  .upComTour__list {
    padding-right: 0;
  }
  .upcomingTour--block .upcomingTour--main {
    width: 100%;
    margin-bottom: 24px;
  }
  .al--block-alt .al--btn-component {
    flex-direction: row;
  }
  .building__btn:not([href]),
  .building__btn {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sidebar--user-canvas {
    padding: 20px 20px 24px 20px;
  }
  .sidebar--hide .sidebar--user-canvas {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sidebar--popover {
    left: -8px !important;
    margin-top: -20px !important;
  }
  .tabBuilding--nav .nav-link .tb__link-text,
  .tel__btn .tel__btn-text {
    display: none;
  }
  .tel__btn:not([href]),
  .tel__btn {
    min-width: 52px;
  }
  .tel--btns-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tel--btns-row .tel--btns-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourPre--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
  }
  .tourPre--top .liveTour--gallery-img {
    margin-bottom: 10px;
  }
  .map--block,
  .sdl--combine {
    padding: 60px 0px;
  }
  .video--canvas-expand {
    height: calc(100vh - 20px);
    margin: 10px;
  }
  .video--modal .cross__circle-modal {
    line-height: 20px;
    min-width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
    color: #6a7074;
    position: absolute;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .listing--btns-top .listing__btn {
    margin-bottom: 20px;
  }
  .listing--btns-top .row .col-12:last-child .listing__btn {
    margin-bottom: 0;
  }
  .lsBlock--btns-single .link__btn {
    margin-left: auto;
  }
  .listing__btn {
    min-height: 10px;
  }
  .listing__btn .para__sm-gray {
    -webkit-line-clamp: inherit;
  }
  .navbar-light .navbar__list-n .nav-link:hover,
  .navbar__list-n .nav-link:hover,
  .navbar-light .navbar__list-n .nav-link.active,
  .navbar__list-n .nav-link.active,
  .navSignin__btn:not(:disabled):not(.disabled).active,
  .navSignin__btn:not(:disabled):not(.disabled):active,
  .navSignin__btn:hover,
  .navSignin__btn:focus,
  .navSignin__btn.active {
    color: #fff;
  }
  .started--imgs {
    max-width: 320px;
  }
  .tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 33.33%;
  }
  .tourPre--top .liveTour--gallery .liveTour--container {
    max-width: 860px;
  }
  .updateAccount__list li {
    display: flex;
    flex: 0 0 50%;
    width: 50%;
    padding: 0px;
  }
  .updateAccount__list li:nth-of-type(2) {
    border-right: none;
  }
  .presentation--navbar .navbar-nav .app__button-blue {
    width: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .presentation--navbar .navbar-nav .app__button-blue .itinerary__icon {
    margin-left: 0;
  }
  .presentation--navbar .navbar-nav .app__button-blue .itinerary__text {
    display: none;
  }
  .presentation--container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .presentation--navbar .navbar-nav {
    flex-direction: row;
  }
  .suite--component {
    margin-right: 20px;
  }
  .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    width: 8px;
  }
  .presentation--control {
    width: 88px;
  }
  .presentation--menu .preMenu--main {
    padding-right: 4px;
    min-width: 88px;
  }
  .presentation--main-even .preContent--detail {
    padding: 0px 0px 0px 20px;
  }
  .presentation--main-odd .preContent--detail {
    padding: 0px 20px 0px 0px;
  }
  .presentation--content .detailComp__list {
    margin-top: 26px;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 30px;
    width: auto;
    margin-right: 16px;
  }
  .presentation--content .detailComp--box .heading__medium {
    font-size: 20px;
  }
  .presentation--content .preAmenities--col {
    flex-wrap: wrap;
  }
  .presentation--content .preAmenities--col .amenities--widget {
    width: calc(50% - 12px);
  }
  .presentation--content .prePdf--col {
    flex-wrap: wrap;
  }
  .presentation--content .prePdf--col .pdf__btn {
    width: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  /* .preVideo--canvas {
    width: calc(100% - 21%);
  }
  .presentation--content.presentation--content-video .preVideo--canvas {
    padding-top: calc(55.5% - 11%);
  } */
  .presentation--content.presentation--content-video .preVideo--canvas .static-video-player {
    border-radius: 14px;
  }
  .presentation--content-video .expand__btn {
    top: 12px;
    right: 12px;
  }
  /* .suite--block .suite--img {
    margin-bottom: 24px;
  } */
  .suite--block {
    margin-top: 60px;
  }
  .temp--about .tempAbout--col {
    min-height: 10px;
  }
  /* .temp--about {
    min-height: 10px;
    margin-top: 50px;
  } */
  .info--carousel-block .tempAbout--content {
    min-height: 640px;
  }

  .info--carousel-block:before {
    max-width: 100%;
    bottom: initial;
    min-height: 700px;
    background-color: transparent;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    bottom: 12%;
  }
  .info--carousel-block .ic--overlay {
    max-width: 100%;
    top: 800px;
    min-height: 640px;
    background-color: #1c1f20;
  }
  .info--carousel-block .tempAbout--col-inner {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .tempHeader--canvas {
    flex-direction: column;
  }
  .template--header .tempHeader--col {
    width: 100%;
  }
  .template--header .temp--view {
    margin-left: auto;
  }
  .template--header .tempHeader--right {
    margin-top: 20px;
  }
  .temp--about-alt .row .col:first-child {
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  }
  .temp--options .btn {
    width: calc(100% - 16px);
  }
  .temp--options .outline__button {
    margin: 0px 8px 0px 0px;
  }
  .temp--options .admin__button {
    margin: 0px 0px 0px 8px;
  }
  .buildingsBlock--update .buildingsBtn--canvas {
    margin-top: 20px;
  }
  .buildingsBlock--update .bb-sf {
    justify-content: space-between;
  }
  .buildingsBlock--update .bb--inner {
    width: auto;
  }
  .app--modal .modal-dialog.modal-lg {
    max-width: 100%;
    margin: 8px;
  }
  .template--mobile .drawer--right {
    margin-top: 168px;
    min-height: calc(100vh - 168px);
  }

  /* Pricing landing page media query */
  .fluid--expand--payment,
  .navbar-expand-lg > .fluid--expand--payment {
    padding-left: 32px;
    padding-right: 32px;
  }
  .navbar__header__payment .navbar__list-n .nav-link.active {
    color: #f2e9d0;
  }
  .pricing__main__heading {
    font-size: 40px;
    line-height: 52px;
  }
  /* Pricing landing page media query */

  /* Marketing homepage media query */
  .marketing--home--top--section {
    padding-top: 100px;
  }
  /* Marketing homepage media query */
}
@media screen and (max-width: 767px) {
  .magnifier-tools {
    right: 10px;
  }
  .vt--container,
  .container--expand,
  .container--large,
  .fluid--expand,
  .navbar-expand-lg > .fluid--expand {
    padding-left: 20px;
    padding-right: 20px;
  }
  .schedule__button {
    margin-top: 40px;
  }
  .social__list {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .social__list li a {
    margin-left: 10px;
    margin-right: 10px;
  }
  .gallery--box {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .schedule--content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .chat__app {
    bottom: 15px;
    right: 15px;
  }
  .dashboard--head,
  .listing--head {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard__heading,
  .heading--group {
    margin-bottom: 16px;
  }
  .editListing--head-btn {
    width: 100%;
  }
  .editListing--head-btn .outline__button {
    width: 100%;
    margin: 0px 5px 0px 0px;
  }
  .editListing--head-btn .admin__button {
    width: 100%;
    margin: 0px 0px 0px 5px;
  }
  .steps--content,
  .about--content {
    margin-top: 70px;
  }
  .empty--box {
    margin-top: 80px;
  }
  .f__list {
    justify-content: center;
  }
  .f__list li a {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .vp__box {
    right: -10px;
    top: 78px;
    width: 52px;
    height: 52px;
    min-width: 52px;
  }
  .vp__box:before {
    width: 36px;
    height: 36px;
  }
  .vp__secure {
    font-size: 18px;
    padding: 4px 20px;
    top: -58px;
    left: 50px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 18px;
    line-height: 18px;
  }
  .vp__logo {
    left: -10px;
    width: 154px;
    height: 48px;
  }
  .vp__logo img {
    width: 126px;
    height: 33px;
  }
  .tour--row {
    justify-content: flex-start;
  }
  .tour--col {
    min-width: 100%;
  }
  .floorplan--box .floorplanBox--img,
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 300px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 420px;
  }
  .tenant--video {
    margin-top: -210px;
    margin-bottom: 175px;
  }
  .vt--combine-right {
    padding-top: 40px;
  }
  .suite--box {
    justify-content: space-between;
    padding: 12px 15px;
  }
  .team--main .row .col-12:last-child .team--block {
    margin-bottom: 0;
  }
  .vt--options:after {
    right: auto;
    left: auto;
    top: initial;
    bottom: -18px;
    width: 200px;
    background-size: 200px;
    height: 74px;
  }
  .liveTour--options {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ct--radio-btn {
    min-height: 130px;
  }
  .ct--radio-alt {
    min-height: 10px;
  }
  .ct--radio-btn .checkmark {
    margin-right: 14px;
  }
  .render--component .ct--radio-btn .checkmark {
    margin-right: 5px;
  }
  .component--edit-overlay {
    padding-left: 30px;
  }
  .component--edit .componentEdit--head {
    padding: 20px 24px 10px 24px;
  }
  .component--edit .componentEdit--scrollable {
    padding: 20px 24px 20px 24px;
  }
  .componentEdit--scrollable {
    overflow: initial;
  }
  .search--bs {
    flex-direction: column;
  }
  .search--bs .search--bs-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .search--bs .search--bs-detail {
    flex-direction: column;
  }
  .search--bs .search--bs-inner {
    align-items: center;
    margin-bottom: 20px;
  }
  .search--bs .bs__next {
    margin-left: auto;
  }
  .search--bs-alt .search--bs-inner {
    width: 100%;
    flex-direction: column;
  }
  .search--bs-alt .bs__heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--box {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box,
  .video--iframe {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .search--group-lg .form-control {
    height: calc(2.8em + 0.75rem + 2px);
    font-size: 15px;
    line-height: 20px;
    padding: 10px 16px 10px 40px;
  }
  .search--group-lg .search__icon {
    font-size: 16px;
    left: 15px;
    top: 22px;
  }
  .search--group-lg .search__icon-cross {
    font-size: 12px;
    right: 15px;
    top: 23px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 300px;
  }
  .team--block-alt {
    flex-direction: column;
  }
  .team--block-alt .teamBlock--img {
    margin: 0px 0px 20px 0px;
  }
  .team--block-alt .teamBlock__user {
    margin-bottom: 6px;
  }
  .buildings--map,
  .subListing--video,
  .map--box {
    height: 320px;
  }
  .map--block,
  .sdl--combine {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--off {
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .viewBy {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .videos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .media--video-block {
    flex-direction: column;
  }
  .media--video-block .media--video {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .mediaContent--combine {
    margin-bottom: 10px;
  }
  .mediaContent--combine .mc--box {
    margin-bottom: 8px;
  }
  .timeBox--row .timeBox--col {
    flex-direction: column;
  }
  .started--imgs {
    max-width: 280px;
  }
  .started--canvas {
    padding-top: 320px;
  }
  .liveTour--page {
    height: auto;
  }
  .video--canvas-expand .video--box-expand {
    width: 520px;
    height: 293px;
    border-radius: 14px;
    border-radius: 20px;
  }
  .tourPulse--block .tourPulse--row {
    flex-direction: column;
  }
  .tourPulse--block .tourPulse--col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    border: none;
  }
  .tourPulse--block .tourPulse--col-detail {
    margin: 20px 0;
  }
  .looked--block .hl--top {
    flex-direction: column;
    align-items: flex-start;
  }
  .presentation--container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .presentation--content .preContent--box {
    display: none;
  }
  .presentation--content .preContent--detail {
    width: 100%;
    padding: 0;
  }
  .presentation--navbar .location--head {
    margin-right: 10px;
  }
  .presentation--navbar .location--head .liveTour__logo {
    width: 120px;
    height: auto;
    min-width: 10px;
    margin-right: 10px;
  }
  .presentation--navbar .location--head .location__destination {
    height: 30px;
    width: 100px;
    padding-left: 10px;
  }
  .suite--component {
    margin-right: 10px;
  }
  .presentation--menu {
    margin-left: 10px;
  }
  .presentation--navbar {
    min-height: 22px;
  }
  .presentation--content .expand__btn {
    display: none;
  }
  .presentation--content .building--widget {
    margin-bottom: 10px;
  }
  .presentation--content .preAmenities--col .amenities--widget {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .presentation--content .preAmenities--col {
    margin-left: 0;
    margin-right: 0;
  }
  .presentation--content .prePdf--col .pdf__btn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .preContent--floorplan {
    padding: 0;
    max-width: 100%;
  }
  .itinerary--back-canvas .app__button-blue {
    padding: 6px;
    min-width: 58px;
    font-size: 14px;
  }
  .presentation--content .detailComp__list {
    margin-top: 16px;
  }
  .presentation--content .minimize__pdf,
  .presentation--content.presentation--content-video .expand__btn {
    display: flex;
  }
  .preTour--content .tour--btn-canvas {
    bottom: 60px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    padding: 0px 0px 4px 0px;
    left: 10px;
    right: 10px;
  }
  .preTour--content .tour--btn-canvas .tour__btn:first-child {
    margin-left: 0;
  }
  .preTour--content .tour--btn-canvas .tour__btn:last-child {
    margin-right: 0;
  }
  .preTour--content .tour--btn-canvas::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .preTour--content .tour--btn-canvas::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
  }
  .presentation--content.presentation--content-video .expand__btn {
    width: 22px;
    height: 22px;
    font-size: 10px;
    border-radius: 5px;
  }
  .info--carousel-block .ic--overlay,
  .temp--about .tempAbout--col {
    padding: 50px 20px;
  }
  .template--head-three .template--banner {
    min-height: 100px;
  }
  .template--head-three .template--banner:after {
    bottom: initial;
    left: 0;
    width: 100%;
    height: 220px;
  }
  .template--head-three .template--banner .tempBannerTh--row {
    padding-top: 94px;
    padding-bottom: 40px;
  }
  .tempContact--th .row .col {
    border: none;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
    margin: 0;
  }
  .tempContact--th .row .col:last-child {
    border-bottom: 0;
  }
  .tempListing--th {
    padding-top: 80px;
  }
  .tempListing--th,
  .tempSpecs--th,
  .temp--sustain-th {
    padding-bottom: 80px;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    padding-left: 20px;
  }
  .info--carousel-block .ic--overlay {
    top: 724px;
  }
  .template--head-three .template--banner {
    position: static;
  }
  .template--head-three .template--carousel {
    width: 100%;
  }
  .template--head-three .template--carousel .carousel-item {
    height: 500px;
  }
  .temp--sustain-th,
  .tempSpecs--th {
    padding-top: 70px;
  }
  .tempSpecs--block-alt {
    min-height: 192px;
    padding: 22px 10px 22px 16px;
  }
  .tempSpecs--block-alt .tempSpecs__icon {
    font-size: 24px;
  }
  .tempSpecsTh--row .col:nth-child(odd) .tempSpecs--block-alt {
    margin-left: 0;
  }
  #main-app .sidebar,
  #main-app .toggle__btn,
  #main-app .menu__toggle {
    display: none;
  }
  .dashboard--opt {
    min-height: 1px;
  }
  .listingTour--nav {
    margin-left: auto;
  }
  #main-app {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  .tourListing--canvas-alt .tourListing--scroll {
    max-height: 580px;
  }

  /* Pricing landing page media query */
  .fluid--expand--payment,
  .navbar-expand-lg > .fluid--expand--payment {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pricing__main__heading {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 32px;
  }
  .pricing__choose__hd {
    font-size: 24px;
    line-height: 32px;
  }
  .pricing--choose--plan--form {
    margin-top: 24px;
  }
  /* .pricing--choose--plan--inner {
    max-width: 450px;
  } */
  .pricing--inquiry--hd--container {
    margin-top: 0px;
  }
  .footer--payment--pricing {
    padding-top: 130px;
  }
  /* Pricing landing page media query */

  /* Marketing homepage */
  .marketing--home--top--section {
    padding-top: 130px;
  }
  .marketing--home--top--video {
    margin-right: 0%;
  }
  .footer--marketing--home {
    padding-top: 200px;
  }
  .marketSt__radio {
    padding: 16px;
    min-height: 10px;
  }
  .marketSt__pickUse {
    flex-direction: column;
  }
  .marketSt__pickUse .marketSt__pick-img {
    margin-bottom: 20px;
  }
  .marketing--presentation--slider--btn {
    flex-direction: column;
  }
  .marketing--presentation--tog--div {
    margin-top: 20px;
    margin-left: auto;
  }
  .comment--block {
    height: auto;
    max-height: 505px;
  }
  /* Marketing homepage */
}
@media screen and (max-width: 575px) {
  .navbar--btns {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar--btns .app__button {
    width: 100%;
    margin-top: 2px;
  }
  .office--component {
    margin-top: 50px;
  }
  .detail--text {
    margin-top: 36px;
  }
  .gradient--component {
    padding-top: 120px;
  }
  .heading__landing {
    font-size: 32px;
  }
  .heading--group .form-control,
  .heading__component,
  .form__heading,
  .dashboard__heading {
    font-size: 24px;
    line-height: 34px;
  }
  .heading__medium {
    font-size: 22px;
    line-height: inherit;
  }
  .lists__heading {
    font-size: 24px;
  }
  .lists__subHeading {
    font-size: 20px;
  }
  .lists-info li {
    font-size: 18px;
  }
  .form--option {
    flex-direction: column;
    align-items: flex-start;
  }
  .form--opt-r {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
  }
  .form__link {
    margin-left: auto;
  }
  .signin--canvas {
    max-width: 100%;
  }
  .signin--btn-canvas,
  .btn-top {
    margin-top: 40px;
  }
  .btn-top {
    width: 100%;
  }
  .forms--btn-canvas .app__button,
  .forms--btn-canvas .admin__button,
  .adminSettings-btn .outline__button {
    width: 100%;
  }
  .form--content {
    padding-top: 40px;
    padding-bottom: 48px;
  }
  .signup--inner {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .stepBtn-canvas-1 {
    padding-top: 0px;
    margin-top: 24px;
  }
  .stepBtn-canvas .admin__button,
  .al--btn-component .admin__button,
  .annex--card-btn,
  .modal--btn-canvas .admin__button {
    width: 100%;
  }
  .al--btn-component {
    margin-top: 10px;
  }
  .al--block .al--block-img {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .al__heading-medium {
    font-size: 20px;
  }
  .annex--card {
    flex-direction: column;
    align-items: flex-start;
  }
  .annex--card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .listing--video,
  .virtualTour--video {
    height: 320px;
  }
  .listing--video video,
  .virtualTour--video video {
    height: 320px;
  }
  .listing__button {
    margin-top: 10px;
  }
  .bl--head-btn {
    width: 100%;
  }
  .b--sf-heading {
    font-size: 32px;
    line-height: 30px;
  }
  .al--block .al--block-detail {
    padding: 15px;
    margin-top: -1px;
  }
  .heading--group .heading__edit {
    margin-top: 6px;
  }
  .virtual--carousel .carousel-item {
    height: 400px;
  }
  .notfound__heading {
    font-size: 136px;
    line-height: 124px;
  }
  .nav__signin-button {
    margin-bottom: 10px;
  }
  .empty--box .admin__button {
    width: 100%;
  }
  .office-modal .modal-header,
  .office-modal .modal-body {
    padding-left: 28px;
    padding-right: 28px;
  }
  .personalize--block {
    padding: 32px 20px 20px 20px;
  }
  .landing__header-n .schedule__btn-outline {
    width: 100%;
  }
  .landing__header-n .schedule__btn-outline:hover,
  .landing__header-n .schedule__btn-outline:focus,
  .landing__header-n .schedule__btn-outline:active {
    border-color: #fff;
  }
  .floorplan--box .floorplanBox--img,
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 250px;
  }
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 320px;
  }
  .footer-n {
    padding-top: 240px;
  }
  .vp__secure {
    font-size: 10px;
    padding: 3px 12px;
    top: -42px;
    left: 37px;
  }
  .vp__secure .vp__secure-icon {
    font-size: 10px;
    line-height: 12px;
  }
  .personalize--block-about {
    margin-bottom: 112px;
  }
  .tenant--video {
    margin-top: -160px;
    margin-bottom: 112px;
  }
  .vp__box {
    width: 30px;
    height: 30px;
    min-width: 30px;
    top: 54px;
    right: -5px;
  }
  .vp__box:before {
    width: 20px;
    height: 20px;
  }
  .vp__logo {
    width: 106px;
    height: 36px;
    top: 16px;
  }
  .vp__logo img {
    width: 78px;
    height: 22px;
  }
  .vt--btns,
  .group-input {
    margin-bottom: 40px;
  }
  .group-input {
    flex-direction: column;
  }
  .group-input .outline__button {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
  .vt--combine .dashboard__heading {
    padding-bottom: 20px;
  }
  .recording__heading {
    font-size: 24px;
  }
  .recording__timer {
    font-size: 100px;
    line-height: 100px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(odd) {
    justify-content: flex-start;
    padding-right: 10px;
  }
  .voiceRecord--block .voiceRecord--box:nth-child(even) {
    justify-content: flex-end;
    padding-left: 10px;
    padding-right: 0;
  }
  .voiceRecord--box {
    width: 50%;
  }
  .record__voice {
    width: 42px;
    height: 42px;
    min-width: 42px;
  }
  .modal--video-set {
    height: 250px !important;
  }
  .mv__cross {
    right: 4px;
  }
  .c--post-canvas .admin__button,
  .ca--cover,
  .ca__btn-canvas {
    width: 100%;
  }
  .liveTour--top-custom {
    height: auto;
  }
  .users--content .row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .users--row-right {
    border-bottom: 1px solid rgba(213, 217, 224, 0.3);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .liveTour--options {
    margin: 0;
  }
  .liveTour--options li a {
    margin-bottom: 16px;
  }
  .tour--btn-canvas {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .tourMobile--block {
    padding: 24px 0;
  }
  .users--content-tenant .users--row-left {
    padding-top: 0px;
  }
  .notfound__img {
    width: 268px;
    height: 189px;
    margin-bottom: 40px;
  }
  .available--listing-map {
    height: 250px;
    margin-top: 20px;
  }
  .search__block .ls__search-icon {
    display: none;
  }
  .listing--group .form-control {
    font-size: 13px;
    padding: 6px 56px 6px 10px;
    height: calc(1.7em + 0.75rem + 2px);
    min-height: 40px;
  }
  .listing--group .enter__btn {
    font-size: 10px;
    padding: 3px 6px;
    min-width: 44px;
    min-height: 20px;
    top: 10px;
  }
  .cancel__link {
    font-size: 12px;
  }
  .liveTour--listing .container-fluid {
    justify-content: start;
  }
  .ls--sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .ls__label {
    margin: 10px 0px 12px 0px;
  }
  .ls__list {
    width: 100%;
  }
  .ls--listings::-webkit-scrollbar {
    height: 14px;
    width: 10px;
  }
  .ls__search-icon {
    margin-right: 10px;
    font-size: 24px;
  }
  .sec--vertical .sec--vertical-middle .heading__medium {
    margin-bottom: 18px;
  }
  .dashbaord--opt {
    flex-direction: column;
  }
  .dashbaord--opt .admin__button {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .white--rounded-box,
  .dashboard--card {
    padding-left: 16px;
    padding-right: 16px;
  }
  .upcomingTour--block .upcomingTour--main {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .upcomingTour--block .upcomingTour--left {
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    border-right: 0;
  }
  .upcomingTour--block .upcomingTour--right {
    border-left: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 8px;
  }
  .dashboard--card .card-header {
    padding: 5px 0px;
  }
  .dashboard--card .card-header .btn-link {
    font-size: 20px;
  }
  .listing--card .card-header .btn-link {
    font-size: 14px;
  }
  .al--sm-alt .al--block-detail,
  .al--lg-alt .al--block-detail,
  .al--block-alt .al--block-detail {
    padding: 0;
  }
  .ct__btn-canvas {
    padding-top: 0px;
  }
  .tour__btn-canvas .btn,
  .btns-mobile-expand .btn {
    width: 100%;
  }
  .tourListing--block {
    flex-direction: column;
    padding: 16px;
  }
  .tourListing--block .tourListing--row {
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
  }
  .tourListing--block .tourListing--row .para__md-dark {
    margin-right: initial;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .dashboard__btn:not(:disabled),
  .dashboard__btn {
    min-height: 60px;
  }
  .subDashboard--bar .heading__medium {
    width: 100%;
  }
  .tourEdit--top {
    height: 250px;
  }
  .tourEdit--top-gallery .liveTour--gallery-img {
    height: 58px;
    margin-bottom: 10px;
  }
  .pdf__btn-liveTour:not([href]),
  .pdf__btn-liveTour {
    min-height: 72px;
  }
  .liveTour--gallery-img.upload--pdf {
    height: 72px;
  }
  .upload--gallery-img {
    font-size: 9px;
  }
  .upload--gallery-img i {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .tourEdit__btn-canvas .btn {
    width: 100%;
  }
  .component--edit .form--group-alt .form-control {
    font-size: 12px;
  }
  .component--edit .search--group-alt .form-control {
    padding-left: 38px;
  }
  .component--edit .search--group-alt .search__icon {
    font-size: 14px;
    top: 18px;
  }
  .al--sm-alt .al--block-img,
  .al--lg-alt .al--block-img,
  .al--block-alt .al--block-img {
    height: 180px;
  }
  .dashboard__heading-alt {
    font-size: 26px;
  }
  .subdomain__footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .photos--box-lg {
    width: 100%;
    min-width: 10px;
  }
  .listingModal--request {
    min-height: 350px;
  }
  .tour--btn-nowrap {
    flex-wrap: nowrap;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .btn-sm-100 .btn,
  .lsBlock--btns .link__btn {
    width: 100%;
  }
  .floorplan--box {
    padding: 15px;
  }
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col,
  .tourPre--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 50%;
  }
  .liveTour--gallery-img.upload--pdf {
    font-size: 14px;
  }
  .liveTour--gallery-img.upload--pdf i {
    font-size: 16px;
  }
  .tourEdit--top .liveTour--gallery-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .tourEdit--top .liveTour--gallery-row .liveTour--gallery-col {
    padding-left: 5px;
    padding-right: 5px;
    width: 33.33%;
  }
  .tourEdit--top .liveTour--gallery-content {
    margin: -5px -10px;
  }
  .tourEdit--top .liveTour--pdf-content {
    margin-top: -5px;
  }
  .video--canvas-expand .video--box-expand {
    width: 420px;
    height: 236px;
  }
  .presentation--page {
    padding: 0;
  }
  .presentation--navbar {
    margin-top: 24px;
  }
  .presentation--welcome {
    padding: 48px 24px;
    margin-top: 0;
  }
  .preWelcome--head {
    margin-bottom: 16px;
  }
  .preWelcome--head .preWelcome__icon {
    font-size: 24px;
    margin-right: 10px;
  }
  .preWelcome--head .heading__lg-dark {
    font-size: 22px;
    font-weight: normal;
  }
  .preWelcome--main .para__large-gray {
    font-size: 16px;
    line-height: 24px;
  }
  .preWelcome--main .forms--group-alt {
    margin-bottom: 16px;
  }
  .preWelcome--main .admin__button {
    margin-top: 24px;
    width: 100%;
  }
  .carouselPre--li {
    height: calc(100vh - 184px);
    padding-bottom: 24px;
  }
  .presentation--content {
    flex-grow: 1;
    padding: 20px;
    width: 100%;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .tour-itinerary {
    margin-bottom: 20px;
    height: auto;
  }
  .presentation--content .presentation--main {
    flex-direction: column;
    overflow-y: auto;
  }
  .presentation--content .preContent--detail {
    overflow-y: auto;
  }
  .preAmenities--carousel,
  .preSpecs--carousel {
    align-items: initial !important;
    overflow-y: auto !important;
  }
  .preVideo--col {
    height: auto;
  }
  .preGallery--carousel {
    flex-grow: initial;
    overflow-y: initial;
    background-color: rgba(255, 255, 255, 0.7);
  }
  /* .presentation--content:before,
  .presentation--content:after {
    content: '';
    width: 8px;
    flex-shrink: 0;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.25);
    display: inline-block;
    position: absolute;
    top: 24px;
    bottom: 30px;
  }
  .presentation--content:before {
    left: -20px;
    border-radius: 0px 20px 20px 0px;
  }
  .presentation--content:after {
    right: -20px;
    border-radius: 20px 0px 0px 20px;
  } */
  .preControl--outer {
    top: 22%;
    bottom: 15%;
  }
  .presentation--navbar .presentation--menu {
    display: none;
  }
  .presentation--navbar .suite--component {
    display: none;
  }
  .suite--component-mobile {
    margin: 10px 0px 20px 0px;
    justify-content: center;
  }
  .presentation--content .detailComp--box .detailCompBox__icon {
    font-size: 20px;
    margin-right: 12px;
  }
  .presentation--content .detailComp--box .text__md-gray {
    font-size: 12px;
    line-height: 14px;
  }
  .presentation--content .detailComp__list li:last-child .detailComp--box .heading__medium,
  .presentation--content .detailComp--box .heading__medium {
    font-size: 16px;
    line-height: initial;
  }
  .presentation--content .detailComp--box {
    margin-bottom: 20px;
  }
  .presentation--content .preContent--head {
    padding-right: 0;
  }
  .presentation--content .preContent--head .heading__lg-dark {
    font-size: 22px;
  }
  .presentation--content .location--box .locationBox__heading {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .presentation--content .location--box .locationBox__img {
    margin-right: 12px;
  }
  .presentation--content .locationBox--badge .location__badge-link {
    padding: 4px 10px;
    font-size: 13px;
  }
  .presentation--off.preOff--space {
    /* padding-bottom: 82px; */
    min-height: 100%;
    max-height: 100%;
  }
  .presentation--off .presentation--container {
    justify-content: flex-start;
  }
  .presentation--content.presentation--content-video {
    margin-top: auto;
    margin-bottom: auto;
  }
  .presentation--control .preControl--box {
    color: #6a7074;
  }
  .presentation--control .preControl--canvas {
    position: fixed;
    bottom: 9px;
    z-index: 5;
    min-width: 88px;
    max-width: 120px;
  }
  .presentation--control-prev .preControl--canvas {
    left: 10%;
  }
  .presentation--control-next .preControl--canvas {
    right: 12%;
  }
  .presentation--control {
    padding: 0;
    width: auto;
  }
  .preControl--box .preControlBox__icon {
    margin-bottom: 8px;
    line-height: 14px;
    font-size: 14px;
  }
  .preControl--box .preControlBox__text {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 12px;
  }
  .preGallery--col .preGallery--box {
    height: 132px;
    width: calc(50% - 12px);
    max-width: 50%;
  }
  .preFooter--card .card-header .btn-link {
    margin-top: 8px;
  }
  .presentation--content .specs__list li {
    font-size: 16px;
    line-height: 18px;
  }
  .presentation--navbar .location--head .liveTour__logo {
    width: 90px;
  }
  .presentation--navbar .location--head .location__destination {
    height: 20px;
    width: 78px;
  }
  .tour--fixed .preTour--content .preContent--tour,
  .floorplan--fixed .preContent--floorplan,
  .location--fixed .preContent--location {
    /* position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
    height: 100%;
    z-index: 5;
    border-radius: 0;
    background-color: #e5e5e5;
  }
  .preContent--floorplan {
    background-color: #fff;
  }
  .preContent--floorplan .preFloorplan--inner {
    max-width: 70%;
    max-height: 68%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .preContent--floorplan .preFloorplan--inner img {
    object-fit: contain;
    border-radius: 10px;
  }
  .preVideo--canvas video {
    border-radius: 14px;
  }
  .video--content .videoContent--place {
    height: 180px;
    border-radius: 12px;
  }
  .videoContent--options .videoContent__play {
    font-size: 14px;
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }
  .videoContent--options .videoContent__clock .videoContent--time {
    font-size: 13px;
  }
  .videoContent--options .videoContent__speed-btn {
    font-size: 14px;
    min-width: 38px;
    min-height: 30px;
    padding: 2px;
  }
  .videoContent--options .videoContent__screen {
    margin-right: 14px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text,
  .videoContent--options .videoContent__screen .videoContent__screen-size {
    font-size: 14px;
  }
  .comment--block .commentBlock--top {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .presentation--content.presentation--content-video {
    padding: 10px;
  } */
  .presentation--container .suite--component .heading__md-dark {
    text-align: center;
    margin: 0px 4px 4px 0px !important;
  }
  .presentation--container .suite--component .suite__badge {
    margin-bottom: 4px;
  }
  /* .preControl--outer.preControl--outer-prev:before,
  .preControl--outer.preControl--outer-next:after {
    content: none;
  } */
  .presentation--content .prePdf--col .pdf__btn,
  .presentation--content .building--widget {
    background-color: rgba(255, 255, 255, 0.7);
  }
  .presentation--expanded {
    padding-top: 0;
  }
  .presentation--expanded .presentation--navbar {
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
  }
  .presentation--expanded .presentation--navbar .location--head {
    display: none;
  }
  .presentation--expanded .presentation--navbar .navbar-nav .app__button-blue {
    position: relative;
    z-index: 2;
  }
  .presentation--expanded .carousel--presentation .carouselPre--ul .carouselPre--li {
    height: calc(100vh - 84px);
  }
  .presentation--expanded .preControl--outer.preControl--outer-prev:before,
  .presentation--expanded .preControl--outer.preControl--outer-next:after {
    content: none;
  }
  .preVideo--col .preGallery--box {
    height: auto;
  }
  .preContent--off-pdf .pdf--options {
    right: 15px;
    bottom: 60px;
  }
  .preContent--off-pdf .pdf--options .pdfOtion__btn {
    width: 30px;
    height: 30px;
  }
  .pdf--fixed .presentation--content .preContent--off-pdf {
    padding: 10px 10px 0px 10px;
    position: fixed;
    bottom: 84px;
  }
  .preOff--pdf .presentation--content {
    position: static;
  }
  .presentation--content .minimize__pdf {
    right: 15px;
    top: 15px;
  }
  .dashTable--listing-alt {
    margin-left: 0;
  }
  .dashboard--table-th {
    min-width: 700px;
  }
  .dashboard--table-th tbody tr td:first-child {
    min-width: 280px;
    max-width: 280px;
  }
  .combine--dropdown .col:last-child .listing--dropdown .dropdown-toggle {
    z-index: 3;
  }
  .combine--dropdown .col:last-child .listing--dropdown .dropdown-menu {
    z-index: 2;
  }
  .sustain--box {
    padding: 20px;
  }
  .specs--carousel .carousel-inner {
    min-height: 496px;
  }

  .suite--block .suite--img {
    display: flex;
    flex-direction: flex-end;
    float: left;
    size: 2rem;
    margin-bottom: 0px;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  .tempBanner--btn-canvas .btn {
    width: 100%;
  }
  .tempBanner--btn-canvas {
    padding-top: 100px;
  }
  .temp--about .tempAbout--img-box,
  .template--head-two .tempBanner--img {
    height: 500px;
  }
  .temp--about .tempAbout--col {
    min-height: 10px;
  }
  .dashbaord--opt-head .outline__button-blue {
    max-width: 100%;
  }
  .admin__button:not([href]),
  .admin__button-outline:not([href]),
  .admin__button,
  .admin__button-outline,
  .outline__button:not([href]),
  .outline__button {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashbaord--opt-head .admin__button-sm .icon-eye-alt,
  .outline__button-blue .icon-user-question,
  .outline__button-blue .icon-edit-custom,
  .outline__button-blue .icon-globe {
    font-size: 18px;
  }
  .template--radio .template--radio-frame {
    height: 280px;
  }
  .info--carousel-block .info--carousel .carousel-indicators {
    bottom: 15%;
  }
  .info--carousel-block .tempAbout--img-box {
    height: 300px;
  }
  .info--carousel-block .tempAbout--content {
    min-height: 640px;
  }
  .temp--options,
  .template--header .tempHeader--right {
    flex-direction: column;
  }
  .temp--options .btn {
    width: 100%;
  }
  .temp--options .admin__button {
    margin: 0;
  }
  .temp--options .outline__button {
    margin: 0px 0px 16px 0px;
  }
  .template--header .cross__link {
    font-size: 20px;
    margin-left: auto;
  }
  .template--header .template__name {
    margin-right: 16px;
  }
  .temp--view .tempView__btn {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .info--carousel-block .ic--overlay {
    top: 400px;
  }
  .template--page .heading__lg-white {
    font-size: 24px;
  }
  .template--page .heading__tem-white {
    font-size: 16px;
  }
  .template--page .add--box .addBox__text {
    font-size: 14px;
  }
  .template--page .heading__lg-dark,
  .template--page .heading__lg-white {
    font-size: 22px;
  }
  .temp--about .heading__tem-white {
    font-size: 18px;
  }
  .temp--combine .link__blue-lg,
  .temp--about .para__large-white,
  .temp--about .para__md-white {
    font-size: 14px;
  }
  .suite--block .heading__th-dark {
    font-size: 20px;
  }
  .temp--specification .tempAbout--img-box {
    height: 300px;
  }
  .temp--specification .tempAbout--img {
    padding-top: 160px;
  }
  .specs--carousel .carousel-indicators {
    margin-bottom: 40px;
  }
  .temp--combine {
    padding-bottom: 160px;
  }
  .tempBanner--carousel {
    margin-top: 80px;
  }
  .template--head-two .tempBanner--btn-canvas {
    padding-top: 32px;
  }
  .temp--map {
    height: 300px;
    margin-top: 110px;
    margin-bottom: 30px;
  }
  .tempListing--row {
    margin-bottom: 28px;
  }
  .tempListing--block {
    padding: 16px;
    min-height: 10px;
    margin-bottom: 32px;
  }
  .tempListing--block .tempListing--img {
    height: 160px;
  }
  .tempListing--block .tempListing--detail {
    margin-top: 20px;
  }
  .tempListing--block .add--box {
    margin-top: 8px;
  }
  .info--item {
    margin-bottom: 18px;
  }
  .info--item .infoItem--col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .info--item .infoItem__para {
    font-size: 18px;
    line-height: 20px;
  }
  .info--item .infoItem--r .infoItem__text {
    font-size: 12px;
    line-height: 14px;
  }
  .tempSpecs--block .heading__md-dark {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .tempContact--box {
    height: 350px;
    margin-bottom: 24px;
  }
  .temp--contacts .row.mt-80 {
    margin-top: 60px;
  }
  .tempContact--box .heading__tem-white {
    font-size: 24px;
  }
  .temp--sustain-alt .sustain--box .heading__md-dark {
    font-size: 18px;
  }
  .sustain--row .col:last-child .sustain--box {
    margin-bottom: 0;
  }
  .template--head .add--box {
    margin-top: 20px;
  }
  .tempLink--canvas {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .tempLink--canvas .link__dark,
  .tempLink--canvas .link__blue-lg {
    margin: 30px auto 0px 0px;
  }
  .temp--about-alt .tempAbout--col {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .tempConTh--block {
    max-width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tempConTh--block .tempConTh--detail .heading__md-gray {
    margin-bottom: 16px;
  }
  .tempConTh--block .tempConTh--detail .heading__md-gray span {
    margin-bottom: 8px;
  }
  .tempListingTh--block {
    height: 200px;
    margin-bottom: 24px;
  }
  .tempListingTh--block .tempListingTh--overlay {
    padding: 16px;
  }
  .tempListingTh--block .tempListingTh--overlay .heading__tem-white {
    font-size: 24px;
  }
  .template--page .heading__tem-blue {
    font-size: 14px;
  }
  .app--modal .modal-content {
    padding: 24px;
  }
  .app--modal .modal-title {
    font-size: 24px;
    line-height: 34px;
  }
  .app--modal .modal__cross {
    font-size: 20px;
    margin-top: 6px;
  }
  .template--mobile .drawer--right {
    margin-top: 224px;
    min-height: calc(100vh - 224px);
  }
  .listingTour--nav {
    width: 100%;
  }
  .listingTour--nav .nav-item {
    flex: 1;
  }
  .listingTour--nav .nav-link {
    width: 100%;
  }
  .tempConTh--block .tempConTh--img img {
    object-fit: contain;
  }
  .tourEdit--top-box .tourEdit--top-start {
    flex-direction: column;
    align-items: flex-start;
  }
  .tourEdit--top-box .sd--main {
    margin-top: 20px;
  }
  .createTour--card .card-header .btn-link {
    font-size: 20px;
  }

  /* Pricing landing page media query */
  .bottom--header--component {
    padding-top: 120px;
  }
  .pricing__main__heading {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 25px;
  }
  .pricing__btm__hdr__para {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing__choose__hd {
    font-size: 20px;
    line-height: 30px;
  }
  .pricing--choose--card .pricing--card--plan--title {
    font-size: 17px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing--card--plan--building {
    font-size: 14px;
    line-height: 18px;
  }
  .pricing--choose--card .card-subtitle {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 18px;
  }
  .pricing--choose--card .pricing--card--listGrp--container {
    height: 250px;
    padding: 0px 4px 0px 14px;
    margin-bottom: 12px;
  }
  .pricing--choose--card .list-group {
    margin-bottom: 36px;
  }
  .pricing--choose--card .list-group:last-child {
    margin-bottom: 12px;
  }
  .pricing--choose--card .list-group-item {
    margin-bottom: 12px;
  }
  .pricing--choose--card .pricing--card--tick {
    min-width: 14px;
    max-width: 14px;
    min-height: 14px;
    max-height: 14px;
    padding: 0px 0px 2px 2px;
  }
  .pricing--choose--card .pricing--card--li--text {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing--card--total--container {
    margin: 18px 0px 24px 0px;
  }
  .pricing--choose--card .pricing--card--total {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--choose--card .pricing__card__btn,
  .pricing--choose--card .pricing__card__btn__link {
    min-height: 42px;
    font-size: 14px;
  }
  .footer--payment--pricing {
    padding-top: 100px;
  }
  .pricing--inqury--form .forms--group {
    margin-bottom: 16px;
  }
  .pricing--inqury--form .forms--group:nth-child(1) {
    padding-bottom: 18px;
  }
  .pricing--inqury--form .forms--group:nth-child(2) {
    padding-bottom: 9px;
  }
  .pricing--inqury--form .forms--group:nth-child(3) {
    padding-bottom: 12px;
  }
  .pricing--inqury--form .forms--group:nth-child(4) {
    padding-bottom: 22px;
  }
  .pricing--inqury--form .forms--group:nth-child(5) {
    padding-bottom: 0px;
  }
  .pricing--inqury--form .forms--group:last-child {
    margin-bottom: 0px;
  }
  .pricing--inqury--form .form-label {
    font-size: 14px;
    line-height: 20px;
  }
  .pricing--inqury--form .form-control {
    min-height: 40px;
  }
  .pricing--inqury--form .forms--group .pricing--inqury--sm--input {
    font-size: 28px;
    line-height: 36px;
    min-height: 60px;
    padding: 8px 25px;
  }
  .pricing--inqury--form .pricing--default--input--text {
    font-size: 20px;
    line-height: 26px;
    top: 42px;
    left: 10px;
  }
  .pricing--inqury--form .pricing--inqury--currency--container .pricing--inqury--currency--name {
    min-width: 28px;
    min-height: 24px;
    font-size: 14px;
    line-height: 20px;
    padding: 3px;
  }
  .pricing--inquiry--form--btn {
    min-height: 42px;
  }
  .button__xs__full {
    width: 100%;
  }
  /* Pricing landing page media query */

  /* Payment signup page media query*/
  .payment--signup--spacing {
    padding: 20px;
  }
  /* Payment signup page media query*/

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--main--section--spacing {
    padding-top: 120px;
  }
  .payment--method--container .nav {
    padding: 4px 8px;
  }
  .payment--method--container .nav-pills .nav-link {
    font-size: 14px;
    line-height: 18px;
    min-width: 130px;
    min-height: 32px;
    padding: 6px 8px;
  }
  .credit--card--payment--top {
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .credit--card--payment--container .card--payment--hd--container .heading__medium {
    font-size: 18px;
  }
  .crdCard--payment--top--icon {
    margin-right: 8px;
    font-size: 18px;
  }
  .credit--card--payment--form .forms--group--col {
    margin-bottom: 14px;
  }
  .credit--card--payment--form .forms--group .form-control {
    min-height: 40px;
    font-size: 14px;
  }
  .credit--card--payment--form .select--group .select__caret-down {
    top: 15px;
    font-size: 12px;
  }
  .card--payment--btn--container {
    padding-top: 26px;
  }
  .card--payment--btn--container .payment__completed__button:not([href]),
  .card--payment--btn--container .payment__completed__button {
    min-height: 42px;
  }
  .card--payment--btn--container .btn {
    margin-right: 0px;
    margin-bottom: 12px;
  }
  .card--payment--btn--container .btn:last-child {
    margin-bottom: 0px;
  }
  .payment--billing--card {
    padding: 28px 24px;
  }
  .payment--billing--card .payment__billing__card__listTxt {
    font-size: 14px;
    line-height: 20px;
  }
  .payment--billing--card .payment--billing--card--subTotal {
    margin: 40px 0px 28px 0px;
  }
  .payment--billing--card .payment--billing--card--subTotal .payment__bill__subTotal__txt {
    font-size: 20px;
    line-height: 26px;
  }
  .payment--billing--card .payment--billing--card--total {
    margin-top: 34px;
  }
  .payment--billing--card .payment--billing--card--total .payment__bill__totalTxt {
    font-size: 16px;
  }
  .payment__billing__card__lgTxt {
    font-size: 24px;
    line-height: 30px;
  }
  /* credit card payment page media query*/
  /* bank payment page */
  .bank--payment--container .credit--card--payment--top {
    margin-bottom: 26px;
  }
  .bank--payment--container .bank--payment--sub--top .bank__payment__top__para {
    font-size: 14px;
    line-height: 20px;
  }
  /* bank payment page */
  /* Pricing payment page media query*/

  /* Marketing homepage */

  .mktHome--top--video--container {
    max-width: 280px;
    min-width: 280px;
    min-height: 145px;
    max-height: 145px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 16px 16px 16px 0px;
    -webkit-border-radius: 16px 16px 16px 0px;
    -moz-border-radius: 16px 16px 16px 0px;
    -ms-border-radius: 16px 16px 16px 0px;
    -o-border-radius: 16px 16px 16px 0px;
    display: flex;
    justify-content: space-between;
  }
  .simple__paragraph {
    font-size: 14px;
    line-height: 22px;
  }
  .mktHome--top--video--btn .btn {
    min-height: 40px;
    min-width: 40px;
    font-size: 16px;
  }
  .marketing--home--personalize--component .personalize--block {
    padding: 20px;
  }
  .marketing--home--personalize--component .personalize--block .heading__component {
    margin-bottom: 32px;
  }
  .marketing--home--personalize--component .personalize--block .forms--group {
    margin-bottom: 20px;
  }
  .footer--marketing--home {
    padding-top: 180px;
  }
  .marketing--home--opinion--component .marketing--home--opinion--hd {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  .marketing--home--opinion--component .op--info {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .marketing--home--opinion--component .opinion__para {
    font-size: 14px;
    line-height: 22px;
  }
  .marketing--home--opinion--component .op--name {
    font-size: 16px;
    line-height: 24px;
  }
  .marketing--presentation--btn--container {
    flex-direction: column;
    width: 100%;
  }
  .marketing--presentation--btn--container .schedule__btn,
  .marketing--presentation--btn--container .schedule__btn-outline {
    font-size: 14px;
    min-height: 40px;
  }
  .marketing--presentation--btn--container .btn {
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .marketing--presentation--btn--container .btn:last-child {
    margin-bottom: 0px;
  }
  .marketing--get--started--slider .marketing--presentation--slider--btn {
    width: 100%;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--img {
    max-width: 65px;
    min-width: 65px;
    max-height: 100px;
    min-height: 100px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt {
    margin-left: 16px;
    margin-top: 10px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--hd {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0px;
    color: #ffffff;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt ul {
    margin: 18px 0px 0px 6px;
  }
  .marketing--presentation--carousal--canvas .marketing--slider--list--txt ul li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    margin-left: 12px;
  }
  .mktSlider--video--link .mktHome--top--video--container {
    max-width: 180px;
    min-width: 180px;
    min-height: 80px;
    max-height: 80px;
  }
  .mktSlider--video--link .mktHome--top--video--text .para__xs {
    margin-bottom: 4px;
  }
  .mktSlider--video--link .mktHome--top--video--text .para__lg {
    font-size: 14px;
  }
  .mktSlider--video--link .mktHome--top--video--btn .btn {
    min-height: 30px;
    min-width: 30px;
    font-size: 13px;
    padding: 1px 1px 1px 2px;
  }
  .marketing--presentation--carousal--canvas .mktSlider--track--container {
    bottom: -36px;
  }
  .mktSlider--tour--link--position {
    top: -36px;
    right: -9px;
  }
  .mktSlider--tour--link--box {
    max-width: 220px;
    min-width: 220px;
    max-height: 80px;
    min-height: 80px;
  }
  .mktSlider--tour--link--btn {
    font-size: 14px;
    line-height: 20px;
    min-width: 168px;
    min-height: 40px;
    padding: 4px 8px;
  }
  .mktSlider--tour--link--btn .mktTour--link--btn--icon {
    font-size: 14px;
    margin-left: 8px;
  }
  .marketing--home--opinion--component .opinion--block .nav-item {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    margin-right: 12px;
    margin-bottom: 18px;
  }
  .marketing--home--opinion--component .opinion--block .nav-link {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    padding: 10px;
  }
  .marketing--home--opinion--component .opinion--block .nav--link--img--container {
    max-width: 45px;
    min-width: 45px;
    max-height: 45px;
    min-height: 45px;
  }
  /* Marketing homepage */

  .component--edit-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 0px;
  }
  .component--edit-overlay .component--edit {
    max-height: 86vh;
    border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -ms-border-radius: 20px 20px 0px 0px;
    -o-border-radius: 20px 20px 0px 0px;
  }
  .component--edit-overlay .component--edit .componentEdit--scrollable {
    padding-bottom: 0px;
  }
  .component--edit-overlay .component--edit .btn-sm-100 .admin__button {
    margin-bottom: 20px;
  }
  .marketing--presentation--btn--container .btn:first-child {
    margin-bottom: 20px;
  }
  .video--profile-img-canvas {
    left: -12px;
  }
  .video--profile-img {
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    margin-bottom: 7px;
  }
  .market--home-pre-last .marketing--home--slider .marketing--home--slider--item .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .marketing--slider--top--txt {
    width: 100%;
  }
  .navbar__header__payment {
    padding-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .key--details .row .col-6,
  .listings .row .col-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .gallery--box {
    height: auto;
  }
  .listing--schedule-col {
    flex-direction: column;
  }
  .listing--schedule-col .select--group {
    margin: 0px 0px 16px 0px;
    width: 100%;
  }
  .hours__change {
    margin: 6px 0px 16px 0px;
  }
  .week--time {
    padding-right: 0;
  }
  .hours--time {
    width: 100%;
  }
  .editListing--head-btn {
    flex-direction: column;
  }
  .editListing--head-btn .sm__button {
    margin: 0px 0px 20px 0px;
  }
  .virtual--carousel .carousel-item {
    height: 300px;
  }
  .hours__del {
    margin-top: 0;
  }
  .broker--video-placement,
  .broker--video-placement video,
  .broker--video-placement iframe {
    height: 180px;
  }
  .media--video-block .media--video,
  .buildings--map,
  .map--box,
  .subListing--video,
  .tenant--video-placement,
  .tenant--video-placement video,
  .tenant--video-placement iframe {
    height: 220px;
  }
  .tenant--video {
    margin-top: -110px;
  }
  .vp__secure {
    top: -34px;
    left: 32px;
  }
  .form-label {
    font-size: 11px;
  }
  .modal--video-set {
    height: 200px !important;
  }
  .drawer--right {
    width: 90%;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: -100%;
  }
  .drawer--right-show {
    margin-right: 0;
  }
  .photos--search-row .block--search-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .photos--listing-row .block--search-col,
  .photos--row-lg .block--search-col,
  .videos--search-row .block--search-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .video--box,
  .video--iframe {
    width: 100%;
    min-width: 10px;
  }
  .timeBox--row .timeBox--options {
    justify-content: space-between;
  }
  .tourEdit--top .liveTour--pdf-row .liveTour--pdf-col {
    width: 100%;
  }
  .video--canvas-expand .video--box-expand {
    width: 320px;
    height: 180px;
  }
  .updateAccount__icon {
    flex: 0.5;
    justify-content: flex-end;
    padding-right: 5px;
  }
  .updateAccount__detail {
    flex: 1;
  }
  .presentation--control-prev .preControl--canvas {
    left: 8%;
  }
  .presentation--control-next .preControl--canvas {
    right: 8%;
  }
  .videoContent--options .videoContent__play {
    font-size: 12px;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .videoContent--options .videoContent__clock .videoContent--time {
    font-size: 11px;
  }
  .videoContent--options .videoContent__speed-btn {
    font-size: 12px;
    min-width: 24px;
    min-height: 20px;
    line-height: 12px;
  }
  .videoContent--options .videoContent__screen {
    margin-right: 8px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text,
  .videoContent--options .videoContent__screen .videoContent__screen-size {
    font-size: 11px;
  }
  .videoContent--options .videoContent__screen .videoContent__screen-text {
    margin-right: 6px;
  }

  /* Pricing payment page media query*/
  /* credit card payment page media query*/
  .payment--method--container .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* credit card payment page media query*/
  /* Pricing payment page media query*/

  .video--profile-img-canvas {
    left: -7px;
  }
  .video--profile-img {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 375px) {
  .mobileNav--menu .mobileNav__icon {
    font-size: 14px;
  }
  .mobileNav--menu .mobileNav__text {
    font-size: 12px;
  }
  .video--profile-img-canvas {
    left: -8px;
  }
  .video--profile-img {
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
  }
}
@media screen and (max-width: 350px) {
  .presentation--content .preContent--head .heading__lg-dark {
    font-size: 18px;
  }
  .presentation--content .specs__list li {
    font-size: 13px;
    line-height: 14px;
  }
  .presentation--content.presentation--content-video {
    margin-top: 20%;
  }
}

/*
  Media Queries
 */
