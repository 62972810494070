.building-intro-container {
  gap: 20px;
}

.building-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  color: #222222;
}

.building-location {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #6a7074;
}

.building-map {
  height: 202px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
