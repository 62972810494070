.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 166px 20px;
  gap: 80px;

  width: 100%;
  height: 252px;

  background: #343434;
  flex: none;
  order: 5;
  align-self: stretch;
  flex-grow: 0;
}

.footer-company-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
}

.footer-company-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.footer-contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.footer-address {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
}

.footer-phone-number {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.footer-copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  text-transform: uppercase;
}
