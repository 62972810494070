.basic-building-name-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.basic-building-name {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  color: #071033;
}

.basic-building-location-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.basic-building-location {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-decoration-line: underline;
  color: #071033;
}
