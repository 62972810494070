.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-1 {
  bottom: 0.25rem;
}

.right-3 {
  right: 0.75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.bg-blue {
  background-color: blue;
}
.ml-115px {
  margin-left: 115px;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.template-button-hover:hover {
  background-color: #3a4e47;
  transition: 0.2s ease;
  color: white;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  z-index: 99;
  top: 0;
}
.sticky-b-0 {
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  bottom: 0;
}
.max-w-440 {
  max-width: 440px;
}
.sticky-100 {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  z-index: 9;
  top: 100px;
}
.px-5p {
  padding-left: 5px;
  padding-right: 5px;
}
.scale-1:hover {
  scale: 1.1;
  transition: 0.2s ease;
}
.overlay-image:hover {
  filter: brightness(0.7);
  transition: 0.2s ease;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.gallery-image {
  margin-bottom: 5px;
  width: 178px;
}
.px-20p {
  padding-left: 20px;
  padding-right: 20px;
}
.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}
.width-178 {
  width: 178px;
}
.width-246 {
  width: 246px;
  height: 210px;
}
.main-image {
  width: 646px;
  /* height: 559px; */
}
.main-image-classic {
  /* min-width: 668px; */
  height: 430px;
  /* max-width: 668px;
  max-height: 430px;
  width: 100%; */
}
.template-button-outlined-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 10px;

  width: 100%;
  height: 48px;

  border: 1px solid #587d71;
  border-radius: 10px;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}
.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}
.border-1p-white {
  border: 1px solid white;
}

.border-1p-gray {
  border: 1px solid #bcbab8;
}
.border-1p {
  border-width: 1px;
  border-style: solid;
}
.template-button-filled-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 10px;

  width: 100%;
  height: 48px;

  background: #587d71;
  border-radius: 10px;
}
.hidden {
  display: none;
}
.hidden-important {
  display: none !important;
}
.h-60 {
  height: 15rem;
}

.h-\[300px\] {
  height: 300px;
}

.h-96 {
  height: 24rem;
}

.h-72 {
  height: 18rem;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-40 {
  width: 10rem;
}

.max-w-1180p {
  max-width: 1180px;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}
.w-60p {
  width: 100px;
  height: 100px;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}
.bg-none {
  background: none;
}

.border-2 {
  border-width: 2px;
  border-style: solid;
}

.border-4 {
  border-width: 4px;
}

.border-b-2 {
  border-bottom-width: 2px;
  border-color: #f6f6f6 !important;
}

.border-b-new-2 {
  border-bottom-width: 2px !important;
  border-color: #f6f6f6 !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-button-new {
  --tw-border-opacity: 1;
  border-color: rgb(88 125 113 / var(--tw-border-opacity)) !important;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-secondary-new {
  --tw-bg-opacity: 1;
  background-color: rgb(88 112 125 / var(--tw-bg-opacity));
}

.bg-button-new {
  --tw-bg-opacity: 1;
  background-color: rgb(88 125 113 / var(--tw-bg-opacity));
}

.bg-primary-new {
  --tw-bg-opacity: 1;
  background-color: rgb(65 63 84 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-mobile-new {
  --tw-bg-opacity: 1;
  background-color: rgb(65 63 84 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-4 {
  padding: 1rem;
}
.template-white-button {
  padding: 14px 20px;
  gap: 10px;

  width: 350px;
  height: 48px;

  /* B&W/White */

  border: 1px solid #ffffff;
  border-radius: 10px;
}
.template-button-filled-two {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 48px;

  background: #587d71;
  border-radius: 10px;
}
.template-button-outlined-two {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  padding: 14px 20px;
  gap: 10px;
  width: 300px;
  height: 48px;
  border: 1px solid #587d71;
  color: #587d71;
  border-radius: 10px;
  flex: none;
  order: 12;
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: black;
}
.template-heading-color {
  color: #413f54;
}
.template-gray-color {
  color: #777777;
}
.font-medium {
  font-weight: 500;
}
.color-none {
  color: none;
}
.font-w-500 {
  font-weight: 500;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-active {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pt-0 {
  padding-top: 0px;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 2.188rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.875rem;
  line-height: 2rem;
}
.template-color-gray-three {
  color: #353535 !important;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-secondary-new {
  --tw-text-opacity: 1;
  color: rgb(88 112 125 / var(--tw-text-opacity));
}

.text-button-new {
  --tw-text-opacity: 1;
  color: rgb(88 125 113 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.import-input-field-full {
  padding: 14px 32px 14px 13px;
  gap: 10px;

  width: 75%;
  height: 49px;

  /* B&W/White */

  background: #ffffff;
  /* Neutral/500 */

  border: 1px solid #bcbab8;
  border-radius: 10px 0px 0px 10px;
}

@media (min-width: 768px) {
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:import-input-field {
    padding: 14px 32px 14px 13px;
    gap: 10px;

    width: 654px;
    height: 49px;

    /* B&W/White */

    background: #ffffff;
    /* Neutral/500 */

    border: 1px solid #bcbab8;
    border-radius: 10px 0px 0px 10px;
  }
  .md\:import-input-field-new {
    padding: 14px 32px 14px 13px;
    gap: 10px;
    width: 503px;
    height: 49px;

    /* B&W/White */

    background: #ffffff;
    /* Neutral/500 */

    border: 1px solid #bcbab8;
    border-radius: 10px 0px 0px 10px;
  }
  .md\:w-2\/5 {
    width: 40%;
  }
  /* .md\:mt-0 {
    margin-top: 0px !important;
  } */

  .md\:w-3\/5 {
    width: 60%;
  }
  .md\:px-2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md\:ml-4 {
    margin-left: 50px;
  }
  .md\:text-template-gray-two {
    color: #6a7074 !important;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:w-80p {
    width: 80px;
    height: 80px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }
  .md\:template-button-filled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    width: 143px;
    height: 48px;

    background: #587d71;
    border-radius: 10px;
  }
  .md\:template-button-outlined {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    width: 143px;
    height: 48px;

    border: 1px solid #587d71;
    border-radius: 10px;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:text-black {
    color: black !important;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .md\:border-button-new {
    border: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(88 125 113 / var(--tw-border-opacity)) !important;
  }
  .md\:outline-button-new {
    --tw-border-opacity: 1;
    outline: 2px;
    outline: rgb(88 125 113 / var(--tw-border-opacity)) !important;
  }

  .md\:bg-transparent {
    background-color: transparent;
  }

  .md\:bg-dark-gray-new {
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity));
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:text-left-new {
    text-align: left !important;
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .md\:text-button-new {
    --tw-text-opacity: 1;
    color: rgb(88 125 113 / var(--tw-text-opacity));
  }
}
@media (min-width: 1024px) {
  .lg\:w-380px {
    width: 380px;
  }
}
@media (min-width: 1180px) {
  .main-image-classic {
    min-width: 668px;
  }
}
