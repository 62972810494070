.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  gap: 10px;
  font-weight: 500;
}

.button-sm {
  padding: 8px 20px;
  border-radius: 10px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.button-lg {
  padding: 14px 20px;
  border-radius: 10px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.button-primary {
  background: #587d71;
  color: #ffffff;
  border: none;
}

.button-primary-outline {
  box-sizing: border-box;
  border: 1px solid #587d71;
  background: none;
  color: #587d71;
}

.button-white {
  background: #ffffff;
  color: #587d71;
  border: none;
}

.button-white-outline {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  color: white;
  background: none;
}

.button-blue-outline {
  box-sizing: border-box;
  border: 1px solid #071033;
  color: #071033;
  background: none;
}
