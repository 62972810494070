.basic-gallery-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 430px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.basic-gallery-primary-image-container {
  height: 100%;
  flex-grow: 1;
}

.basic-gallery-secondary-images-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.basic-gallery-secondary-images-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.basic-gallery-secondary-image-container {
  width: 230px;
  height: 50%;
  object-fit: cover;
  position: relative;
}

.basic-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.basic-image-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
