.units-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.unit-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e5e5e4;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
}

.unit-primary-image {
  width: 126px;
  height: 126px;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  object-fit: cover;
}

.unit-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.unit-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.unit-name {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #000000;
}

.unit-status {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0a893d;
}

.unit-status-unavailable {
  color: #6a7074;
}

.unit-price-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.unit-price {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #587d71;
}

.unit-price-duration {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7074;
}

.unit-details-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 28px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.unit-details-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.unit-details-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #222222;
}

.unit-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
}

.available-units-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
  cursor: pointer;
}

.units-filter-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222222;
}

.units-filter-dropdown-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 12px 14px 13px;
  gap: 10px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  right: 0px;
  top: calc(100% + 10px);
  min-width: 230px;
  cursor: pointer;
}

.units-filter-dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}
