.website-padding {
  padding: 0px 216px;
}

@media (max-width: 1024px) {
  .website-padding {
    padding: 0px 40px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.content-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
}

.website-tabs-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 40px;
}

.hr {
  border: 1px solid #bcbab8;
  height: 0px;
  width: 100%;
}

.building-features-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
