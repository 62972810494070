.tabs-container {
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #bcbab8;
  display: flex;
  gap: 12px;
  display: flex;
  flex-direction: row;
}

.tab-item {
  padding: 0px 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #6a7074;
}

.tab-item-active {
  color: #071033;
  border-bottom: 3px solid #071033;
}
