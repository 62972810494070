.dropdown-items-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 12px 14px 13px;
  gap: 10px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  right: 0px;
  top: calc(100% + 10px);
  min-width: 230px;
  cursor: pointer;
}

.dropdown-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.dropdown-item-hr {
  border: 1px solid #cacaca;
  height: 0px;
  width: 100%;
}
