.features-main-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.feature-collapse-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 100%;
  border: 1px solid #e5e5e4;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.feature-head-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.feature-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.feature-chevron-inactive {
  transform: rotate(270deg);
}

.feature-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  color: #222222;
}

.feature-images-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 258px;
  width: 100%;
}

.feature-image-row {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}

.feature-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
