.headline-container {
  width: 100%;
  height: 350px;
  background-color: antiquewhite;
  position: relative;
  display: flex;
  align-items: center;
}

.headline-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  background-color: black;
}

.headline-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.headline-content-container {
  z-index: 1000;
}

.headline-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: white;
  display: block;
}

.headline-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-decoration-line: underline;
  color: #ffffff;
}

.headline-action-container {
  display: flex;
  gap: 10px;
}
