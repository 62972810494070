.gallery-container {
  width: 100%;
  height: 318px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.secondary-images-col {
  width: 121px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.primary-image {
  display: flex;
  flex-grow: 1;
  object-fit: cover;
}

.secondary-image-container {
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 30%;
  position: relative;
}

.secondary-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.secondary-image-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
