.header-container {
  height: 91px;
  background-color: #ffffff;
  border-color: #e0e0e0;
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  align-items: center;
}

.header-subcontainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.header-contact-container {
  gap: 20px;
  display: flex;
  align-items: center;
}

.header-logo {
  max-height: 80px;
  max-width: 300px;
  object-fit: contain;
}

.header-contact-number {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #222222;
  text-decoration-line: underline;
  line-height: 21px;
}
