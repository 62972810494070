.contact-board-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 12px;
  width: 380px;
  background: #f9f9f9;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.contact-units-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #071033;
}

.price-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.price-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #071033;
}

.price-value {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #587d71;
}

.price-subtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7074;
}

.request-tour-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #071033;
}

.contact-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #071033;
}

.agent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;
  flex: none;
  flex-grow: 0;
}

.agent-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 40px;
}

.agent-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #071033;
}

.agent-email {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #6a7074;
}

.hr {
  height: 0px;
  width: 100%;
  border: 1px solid #dcdcdc;
}

.w-100 {
  width: 100%;
}
